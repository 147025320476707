import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from 'client/ui/Button';
import { H3 } from 'client/ui/Headings';
import Collapse from 'client/ui/Collapse';
import Menu from './ConfigurationMenu';
import CollapseItem from './CollapseItem';
import ContentWrapperOptions from './ContentWrapperOptions';

const StyledButton = styled(Button)`
  margin-bottom: 1rem;
`;

const AddButton = styled(Button)`
  margin-bottom: 1rem;
  display: flex;
  margin-right: auto;
  margin-left: auto;
`;

const MenuCollapseItem = styled.div`
  padding: 1rem 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid ${props => props.theme.color.grayTable};
  border-radius: ${props => props.theme.radius};
  box-shadow: 0px 2px ${props => props.theme.color.grayTable};
`;

const WrapperCollapse = styled.div`
  margin-bottom: 1rem;
`;

const StyledNoContentText = styled(H3)`
  text-align: center;
`;


class ContentCollapse extends Component {
  constructor() {
    super();
    this.state = {
      configurationVisible: false,
    };
    this.handleDisplayMenu = this.handleDisplayMenu.bind(this);
    this.onItemDelete = this.onItemDelete.bind(this);
    this.onItemMove = this.onItemMove.bind(this);
    this.close = this.close.bind(this);
  }

  onItemDelete(index) {
    const { id, removeCollapseItem } = this.props;
    removeCollapseItem(id, index);
  }

  onItemMove(index, direction) {
    const { id, moveCollapseItem } = this.props;
    moveCollapseItem(id, index, direction);
  }

  handleDisplayMenu() {
    const { configurationVisible } = this.state;
    this.setState({ configurationVisible: !configurationVisible });
  }

  close() {
    this.setState({ configurationVisible: false });
  }


  render() {
    const { id, addItem, onTitleChange, onTextChange, items, savePicture } = this.props;
    const { configurationVisible } = this.state;
    return (
      <div>
        <Menu
          title="Editar botón en cascada"
          width="25rem"
          isOpen={configurationVisible}
          close={this.close}
        >
          <div>
            <AddButton
              onClick={() => addItem(id)}
            >
              Añadir item
            </AddButton>
            {items && items.map((item, index) => (
              <MenuCollapseItem
                key={`Content_Wrapper_${index}`}
              >
                <ContentWrapperOptions
                  id={index.toString()}
                  remove={this.onItemDelete}
                  moveContent={this.onItemMove}
                  noBottomProps
                >
                  <CollapseItem
                    key={`collapse${index}${id}`}
                    id={id}
                    index={index}
                    text={item.text}
                    title={item.title}
                    picture={item.url}
                    savePicture={savePicture}
                    onTitleChange={onTitleChange}
                    onTextChange={onTextChange}
                    previewMaxWidth="100px"
                  />
                </ContentWrapperOptions>
              </MenuCollapseItem>
            ))}
          </div>
        </Menu>
        <StyledButton
          hollow
          color="black"
          onClick={() => this.handleDisplayMenu()}
        >
          Editar
        </StyledButton>
        <WrapperCollapse>
          {items.length === 0
            && <StyledNoContentText>No hay items que mostrar</StyledNoContentText>
          }
          {items && items.length > 0 && items.map((item, index) => (
            <Collapse
              key={`collapse${index}${id}`}
              title={item.title}
              headerBg="grayLight"
              borderColor="borderBg"
              colorText="modalTitle"
              borderBottomColor="borderBg"
              fontWeight="bold"
              isOpen
            >
              <CollapseItem
                key={`collapse${index}-${id}`}
                id={id}
                index={index}
                text={item.text}
                title={item.title}
                picture={item.url}
                savePicture={savePicture}
                onTitleChange={onTitleChange}
                onTextChange={onTextChange}
              />
            </Collapse>
          ))}
        </WrapperCollapse>
      </div>
    );
  }
}

ContentCollapse.propTypes = {
  id: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  onTitleChange: PropTypes.func.isRequired,
  onTextChange: PropTypes.func.isRequired,
  savePicture: PropTypes.func.isRequired,
  addItem: PropTypes.func.isRequired,
  removeCollapseItem: PropTypes.func.isRequired,
  moveCollapseItem: PropTypes.func.isRequired,
};

ContentCollapse.defaultProps = {};

export default ContentCollapse;
