/* eslint no-restricted-globals: 0, react/destructuring-assignment: 0,
react/no-access-state-in-setstate: 0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Divider from 'client/ui/Divider';
import Button from 'client/ui/Button';
import CodeName from 'client/ui/CodeName';
import { Tabs, TabsPane, Tab } from 'client/ui/Tabs';
import SectionCircle from 'client/ui/SectionCircle';
import ContentWrapper from 'client/ui/ContentWrapper';
import Img from 'client/ui/Img';
import List from 'client/modules/dashboard-mfs/containers/List';
import ViewProration from 'client/modules/view-ua/components/ViewProration';
import GeneralDescription from './GeneralDescription';
import GeneralData from './GeneralData';
import BibliographicReferences from './BibliographicReferences';
import MfIndex from '../containers/MfIndex';
import MfMindmap from '../containers/MfMindmap';

const CircleText = styled.div`
  font-size: 21px;
  font-weight: bold;
  color: white;
  position: absolute;
  top: 12px;
  right: 5px;
`;

const Header = styled.div`
  margin-bottom: .5rem;
`;

const Section = ContentWrapper.extend`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;
`;

const MfName = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: .25rem;
`;

const StyledSeparation = styled.div`
  width: 100%;
  min-width: 0;
  margin-right: 1rem;
`;

const DividerWrapper = styled.div`
  position: relative;
`;

const MfsDivider = Divider.extend`
  position: absolute;
  top: 0;
  left: 0,
`;

const CoverWrapper = styled.div`
  width: 100%;
  max-height: 330px;
  overflow: hidden;
  max-width: ${props => props.theme.maxWidth};
  margin-left: auto;
  margin-right: auto;
`;

const StyledImg = styled(Img)`
  max-width: 1200px;
  max-height: 330px;
  object-fit: contain;
`;

const LogoWrapper = styled.div`
  width: 100%;
  max-width: 650px;
  max-height: 81px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
`;

class Wrapper extends Component {
  constructor() {
    super();
    this.state = {};
    this.handleTabClick = this.handleTabClick.bind(this);
    this.changeToEdit = this.changeToEdit.bind(this);
  }

  changeToEdit() {
    const { history, match, tab } = this.props;
    let parsedTab = tab;
    const { id } = match.params;
    if (parsedTab === '#map' || parsedTab === '#indice') {
      parsedTab = '#generalDescription';
    }
    const path = { ...location, hash: parsedTab };
    path.name = `/dashboard/mfs/${id}`;
    path.pathname = `/dashboard/mfs/${id}`;
    history.replace(path);
  }

  handleTabClick(tab) {
    const { history } = this.props;
    const path = { ...location, hash: tab };
    history.replace(path);
  }

  renderUForUaList() {
    const { moduleToView } = this.props;
    if (moduleToView && moduleToView.id && moduleToView.colorMf) {
      const { haveUf, ufs, uas, colorMf, colorUf, colorUa } = moduleToView;
      if (haveUf) {
        return (
          <TabsPane
            name="#formativeUnits"
            label="Unidades Formativas"
          >
            <List
              haveUf={haveUf}
              items={ufs}
              typeMf
              modeEdit={false}
              colorMf={colorMf}
              colorUf={colorUf}
              colorUa={colorUa}
            />
          </TabsPane>
        );
      }
      return (
        <TabsPane
          name="#learningUnits"
          label="Unidades de Aprendizaje"
        >
          <List
            haveUf={haveUf}
            items={uas}
            typeMf
            modeEdit={false}
            colorMf={colorMf}
            colorUf={colorUf}
            colorUa={colorUa}
          />
        </TabsPane>
      );
    }
    return null;
  }

  render() {
    const { tab, moduleToView, isAdmin } = this.props;
    if (Object.keys(moduleToView).length === 0) {
      return null;
    }
    const { id, bibliographicReferences, codeCert, codeMf, nameCert, nameMf, centre, title,
      introduction, subtitles, objectives, coverPicture, haveUf, centreLogo,
      colorMf, proration, status } = moduleToView;
    return (
      <div>
        {haveUf
          ? (
            <DividerWrapper>
              <Divider borderWidth="2px" borderColor="grayLight" />
              <MfsDivider width="25%" borderWidth="2px" borderColor={colorMf} />
            </DividerWrapper>)
          : (
            <DividerWrapper>
              <Divider borderWidth="2px" borderColor="grayLight" />
              <MfsDivider width="33.33333%" borderWidth="2px" borderColor={colorMf} />
            </DividerWrapper>)}
        <Header>
          <Section>
            <MfName>
              <SectionCircle size="42px" background={colorMf}>
                <CircleText>MF</CircleText>
              </SectionCircle>
            </MfName>
            <StyledSeparation>
              <CodeName>{codeMf} {nameMf}</CodeName>
            </StyledSeparation>
            <Button
              hollow
              color="black"
              borderColor="black"
              onClick={() => this.changeToEdit()}
              disabled={status !== 'DRAFT' && !isAdmin}
            >
              Editar
            </Button>
          </Section>
          <Divider
            borderWidth="2px"
            borderColor="grayLight"
            marginBottom="0.5rem"
          />
          <CoverWrapper>
            <StyledImg src={coverPicture && coverPicture.src} alt="" />
          </CoverWrapper>
          <LogoWrapper>
            <Img src={centreLogo} alt="" />
          </LogoWrapper>
        </Header>
        <Tabs
          tabsBarBorderColor="gray2"
          selected={tab}
          component={Tab}
          componentProps={{ color: colorMf }}
          onTabClick={currentTab => this.handleTabClick(currentTab)}
          tabsTitleMaxWidth="1400px"
        >
          <TabsPane
            name="#generalDescription"
            label="Descripción general"
            activeColor={colorMf}
          >
            <GeneralDescription
              moduleToView={{ codeCert, codeMf, nameCert, nameMf, centre, colorMf }}
            />
          </TabsPane>

          <TabsPane
            name="#generalData"
            label="Datos generales"
          >
            <GeneralData
              moduleToView={{ title, subtitles, introduction, objectives, colorMf }}
            />
          </TabsPane>

          <TabsPane
            name="#indice"
            label="Índice"
          >
            {id && colorMf
              ? <MfIndex mfId={id} colorMf={colorMf} />
              : <div />
            }
          </TabsPane>

          <TabsPane
            name="#map"
            label="Mapa conceptual"
          >
            <div>
              <MfMindmap mfId={id} colorMf={colorMf} />
            </div>
          </TabsPane>

          { this.renderUForUaList() }

          {!haveUf
            && (
              <TabsPane
                name="#BibliographicReferences"
                label="Referencias Bibliográficas"
              >
                <BibliographicReferences
                  moduleToView={{ bibliographicReferences }}
                />
              </TabsPane>)}
          <TabsPane
            name="#evaluationConfiguration"
            label="Configuración de la evaluación"
          >
            <ContentWrapper>
              {id && colorMf && (
                <ViewProration proration={proration} color={colorMf} />
              )}
            </ContentWrapper>
          </TabsPane>
        </Tabs>
      </div>
    );
  }
}

Wrapper.propTypes = {
  moduleToView: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  tab: PropTypes.string.isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

Wrapper.defaultProps = {
  moduleToView: {},
};

export default Wrapper;
