import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import MediumEditor from 'client/ui/MediumEditor';
import { mediumEditorBasic } from 'client/modules/dashboard/utils/medium-editor';
import Circle from 'client/ui/Circle';
import ButtonFa from 'client/ui/ButtonFa';

const CircleText = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: white;
  position: absolute;
  top: 6px;
  right: 12px;
  ${props => props.index > 8 && css`
    right: 5px;
  `}
  ${props => props.index === 0 && css`
    right: 14px;
  `}
`;

const StyledList = styled.div`
  text-align: left;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const StyledCircle = styled(Circle)`
  margin-right: 1rem;
  margin-bottom: 1rem;
  position: relative;
`;

const RemoveIcon = styled.div`
  color: red;
  position: absolute;
  top: -20px;
  right: 10px;
  button {
    text-transform: none;
  }
`;

const Li = ({ id, text, onLiChange, type, index, handleLiRemove }) => (
  <StyledList>
    {type === 'ul'
      ? <StyledCircle size="24px" background="darkGray" />
      : <StyledCircle size="37px" background="darkGray"><CircleText index={index}>{index + 1}</CircleText></StyledCircle>
    }
    {index > 0
      && (
        <RemoveIcon>
          <ButtonFa
            fa="fa-trash-alt"
            transparent
            color="red"
            onClick={() => handleLiRemove(id, index)}
          />
        </RemoveIcon>)}
    <MediumEditor
      text={text}
      onChange={(value) => { onLiChange(id, value, index); }}
      options={{
        toolbar: { buttons: mediumEditorBasic },
        placeholder: { text: 'Añadir texto', hideOnClick: true },
      }}
    />
  </StyledList>
);

Li.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  onLiChange: PropTypes.func.isRequired,
  handleLiRemove: PropTypes.func.isRequired,
};

Li.defaultProps = {};

export default Li;
