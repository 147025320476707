const mediumEditorButtons = [
  'bold', 'italic', 'underline', 'anchor', 'h2', 'h3', 'quote',
  'orderedlist', 'unorderedlist',
  'justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull',
  'removeFormat',
];

const mediumEditorBasic = [
  {
    name: 'bold',
    action: 'bold',
    aria: 'Negrita',
    contentDefault: '<b>N</b>',
  },
  {
    name: 'italic',
    action: 'italic',
    aria: 'Cursiva',
    contentDefault: '<b>K</b>',
  },
  {
    name: 'underline',
    action: 'underline',
    aria: 'Subrayado',
    contentDefault: '<b>S</b>',
  },
  {
    name: 'justifyFull',
    action: 'justifyFull',
    aria: 'Justificar texto',
  },
  {
    name: 'removeFormat',
    action: 'removeFormat',
    aria: 'Eliminar formato',
    contentDefault: '<b>X</b>',
  },
];

const mediumEditorMedium = [
  {
    name: 'bold',
    action: 'bold',
    aria: 'Negrita',
    contentDefault: '<b>N</b>',
  },
  {
    name: 'italic',
    action: 'italic',
    aria: 'Cursiva',
    contentDefault: '<b>K</b>',
  },
  {
    name: 'underline',
    action: 'underline',
    aria: 'Subrayado',
    contentDefault: '<b>S</b>',
  },
  {
    name: 'anchor',
    action: 'anchor',
    aria: 'Enlace',
    contentDefault: '<b>enlace</b>',
  },
  {
    name: 'orderedlist',
    action: 'insertorderedlist',
    aria: 'Lista numerada',
  },
  {
    name: 'unorderedlist',
    action: 'insertunorderedlist',
    aria: 'Listado',
  },
  {
    name: 'justifyFull',
    action: 'justifyFull',
    aria: 'Justificar texto',
  },
  {
    name: 'removeFormat',
    action: 'removeFormat',
    aria: 'Eliminar formato',
    contentDefault: '<b>X</b>',
  },
];

const mediumEditorContentTypeText = [
  {
    name: 'bold',
    action: 'bold',
    aria: 'Negrita',
    contentDefault: '<b>N</b>',
  },
  {
    name: 'italic',
    action: 'italic',
    aria: 'Cursiva',
    contentDefault: '<b>K</b>',
  },
  {
    name: 'underline',
    action: 'underline',
    aria: 'Subrayado',
    contentDefault: '<b>S</b>',
  },
  {
    name: 'underline',
    action: 'underline',
    aria: 'Subrayado',
    contentDefault: '<b>S</b>',
  },
  {
    name: 'h2',
    action: 'append-h2',
    aria: 'Cabecera tipo 2',
    contentDefault: '<b>H2</b>',
  },
  {
    name: 'h3',
    action: 'append-h3',
    aria: 'Cabecera tipo 3',
    contentDefault: '<b>H3</b>',
  },
  {
    name: 'orderedlist',
    action: 'insertorderedlist',
    aria: 'Lista numerada',
  },
  {
    name: 'unorderedlist',
    action: 'insertunorderedlist',
    aria: 'Listado',
  },
  {
    name: 'justifyLeft',
    action: 'justifyLeft',
    aria: 'Alinear a la izquierda',
    contentDefault: '<b>I</b>',
  },
  {
    name: 'justifyCenter',
    action: 'justifyCenter',
    aria: 'Centrar texto',
  },
  {
    name: 'justifyRight',
    action: 'justifyRight',
    aria: 'Alinear a la derecha',
    contentDefault: '<b>D</b>',
  },
  {
    name: 'justifyFull',
    action: 'justifyFull',
    aria: 'Justificar texto',
  },
  {
    name: 'removeFormat',
    action: 'removeFormat',
    aria: 'Eliminar formato',
    contentDefault: '<b>X</b>',
  },
];

const mediumSelection = [
  {
    name: 'bold',
    action: 'bold',
    aria: 'Seleccionar',
    contentDefault: '<b><i class="fas fa-text-width"></i></b>',
  },
];

export {
  mediumEditorButtons,
  mediumEditorBasic,
  mediumEditorMedium,
  mediumEditorContentTypeText,
  mediumSelection,
};
