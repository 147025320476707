import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import Layout from './PrivateLayout';

const LayoutRoute = ({ component: Component, noMaxWidth, noMargins, noPaddings, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <Layout
        noMaxWidth={noMaxWidth}
        noMargins={noMargins}
        noPaddings={noPaddings}
      >
        <Component {...props} />
      </Layout>)}
    onEnter={window.scrollTo(0, 0)}
  />
);

LayoutRoute.propTypes = {
  component: PropTypes.func.isRequired,
  noMaxWidth: PropTypes.bool,
  noMargins: PropTypes.bool,
  noPaddings: PropTypes.bool,
};

LayoutRoute.defaultProps = {
  noMaxWidth: false,
  noMargins: false,
  noPaddings: false,
};

export default LayoutRoute;
