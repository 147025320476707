import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Datatables from 'client/ui/Datatables';
import { H1, H2 } from 'client/ui/Headings';
import ContentWrapper from 'client/ui/ContentWrapper';

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
`;

const StyledH1 = H1.extend`
  margin-bottom: 0;
`;

const NoResult = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
`;

const Modules = ({ modules, notFound }) => {
  const buttons = [];
  buttons.push({
    type: 'link',
    icon: 'fa-eye',
    classButton: 'basic',
    routeName: '/viewMf/',
    target: 'target="_blank"',
    tooltip: 'Leer',
    module: 'PUBLICATEDREPO',
  });
  return (
    <ContentWrapper lateralPadding="2rem" maxWidth="100%">
      <StyledHeader>
        <StyledH1>Módulos Formativos</StyledH1>
      </StyledHeader>
      {modules.length > 0
        ? (
          <div>
            <Datatables
              id="newsTable"
              headers={[
                { name: 'Nombre' },
                { name: 'Centro' },
                { name: 'Autor' },
                { name: 'Co-autor' },
                { name: 'Estado' },
                { name: 'Fecha modificación' },
              ]}
              rows={modules}
              buttons={buttons}
              config={{
                dom: 'Bfrtip',
                buttons: [
                  // {
                  //   extend: 'excelHtml5',
                  //   title: 'Lista de modulos',
                  //   className: '',
                  //   exportOptions: { columns: ':visible' },
                  // },
                  {
                    extend: 'colvis',
                    className: '',
                    collectionLayout: 'fixed',
                  },
                ],
                order: [[4, 'desc']],
                columns: [
                  null,
                  { width: '20%' },
                  { width: '10%' },
                  { width: '105px' },
                  { width: '105px' },
                  { width: '10%' },
                  { width: '102px' },
                ],
              }}
            />
          </div>)
        : (
          <NoResult>
            {notFound
              ? <H2>No hay Módulos Formativos publicados actualmente</H2>
              : <H2>Cargando Módulos Formativos</H2>
            }
          </NoResult>
        )
      }
    </ContentWrapper>
  );
};

Modules.propTypes = {
  modules: PropTypes.arrayOf(PropTypes.array).isRequired,
  notFound: PropTypes.bool.isRequired,
};

Modules.defaultProps = {};

export default Modules;
