import styled, { css } from 'styled-components';
// import { css } from 'styled-components';
import PropTypes from 'prop-types';
import { getThemeColor } from '../style-utils';

const Span = styled.span`
  font-weight: ${props => props.weight};
  color: ${props => getThemeColor(props.color)};

  ${props => props.spanForm && css`
      text-align: left;
      font-weight: bold;
      font-size: 0.875rem;
      color: ${props.theme.color.black};
    `}
`;

Span.propTypes = {
  color: PropTypes.string,
  weight: PropTypes.string,
};

Span.defaultProps = {
  color: 'black',
  weight: '400',
};

export default Span;
