import React, { Component } from 'react';
import moment from 'moment';
import List from '../components/Repository';
import fetchPublicatedModules from '../actions/repository';

class ModulesContainer extends Component {
  constructor() {
    super();
    this.state = {
      mfs: [],
      notFound: false,
    };
    this.loadModules = this.loadModules.bind(this);
  }

  componentWillMount() {
    this.loadModules();
  }

  loadModules() {
    fetchPublicatedModules().then((mfsData) => {
      if (mfsData.length === 0) {
        this.setState({ notFound: true });
      }
      const mfs = [];
      mfsData.forEach((module) => {
        const { _id, nameMf, centre, updatedAt, createdBy, coAutor } = module;
        const id = _id;
        const parsedStatus = '<i style="width:10px;padding-right:12px;color:#29B6F6;" class="fas fa-circle"></i>Publicado';
        const parsedCentre = centre && centre.name ? centre.name : 'Sin centro';
        const parsedUpdatedAt = moment(updatedAt).format('DD/MM/YYYY');
        const parsedUsername = `${createdBy.firstName} ${createdBy.lastName}`;
        const parsedCoAutor = coAutor ? `${coAutor.firstName} ${coAutor.lastName}` : '';
        const current = [
          id,
          nameMf,
          parsedCentre,
          parsedUsername,
          parsedCoAutor,
          parsedStatus,
          parsedUpdatedAt,
        ];
        mfs.push(current);
      });
      this.setState({ mfs });
    });
  }

  render() {
    const { mfs, notFound } = this.state;
    return (
      <List
        modules={mfs}
        notFound={notFound}
      />
    );
  }
}

ModulesContainer.propTypes = {};

ModulesContainer.defaultProps = {};

export default ModulesContainer;
