import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { api } from 'config';
import Span from 'client/ui/Span';
import Img from 'client/ui/Img';
import IconFa from 'client/ui/IconFa';

const StyledImagePreview = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 400px;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  img {
    display: block;
    margin: auto;
    width: auto;
    max-width: 100%;
  }
`;

const StyledSpan = styled.div`
  line-height: 27px;
  padding: 0 2.5rem;
  font-size: 20px;
  font-weight: bold;
`;

const CheckWrapper = styled.div`
  margin: 0.5rem 2.5rem;
  display: flex;
  flex-direction: row;
  padding: 0.5rem 0;
  padding-right: 1rem;
  ${props => props.correct === true && css`
    border: 1px solid #93CA40;
  `}
`;

const CorrectAnswerIcon = styled(IconFa)`
  color: ${props => props.theme.color.correctCheck};
  padding-left: 1rem;
`;

const StyledCheck = styled.input`
  margin-right: 1rem;
  margin-left: 1.5rem;
  align-self: center;
`;

const AnswerWrapper = styled.div`
  display: flex;
  width: 100%;
  min-width: 0;
  align-items: center;
`;

const AnswerSpan = styled(Span)`
  width: 100%;
  min-width: 0;
`;

const MultipleQuestion = ({ item }) => (
  <div>
    { item.url && (
      <StyledImagePreview>
        <Img src={`${api.baseUrl}${item.url}`} alt="imagen" />
      </StyledImagePreview>
    )}
    <StyledSpan>
      <Span color="modalTitle">{item.question}</Span>
    </StyledSpan>
    {item.options.map(option => (
      <CheckWrapper key={option.id} correct={option.correctAnswer}>
        <StyledCheck
          type="checkbox"
          readOnly
          checked={option.correctAnswer}
        />
        <AnswerWrapper>
          <AnswerSpan>{option.text}</AnswerSpan>
          {option.correctAnswer === true && (
            <CorrectAnswerIcon
              name="check-circle"
              solid
            />
          )}
        </AnswerWrapper>
      </CheckWrapper>
    ))}
  </div>
);

MultipleQuestion.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
};

MultipleQuestion.defaultProps = {};

export default MultipleQuestion;
