import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Popup from './ButtonPopupItemView';
import TextView from './TextView';

const StyledPopupPane = styled.div`
  padding-top: 1rem;
`;

const TextPopupWrapper = styled.div`
  width: 100%;
  min-height: 200px;
  display: flex;
  align-items: center;
  ${props => props.position.value === 'left' && css`
    flex-direction: row-reverse;
    @media (max-width: 650px) {
      flex-direction: column !important;
    }
  `}
  @media (max-width: 650px) {
    flex-direction: column-reverse;
  }
`;

const ContentWrapper = styled.div`
  padding: 1rem;
  padding-left: 0;
  ${props => props.width && css`
    width: ${props.width};
  `}
  p:first-child {
    margin-top: 0;
  }

  ${props => props.position.value === 'left' && css`
    @media (min-width: 650px) {
      margin-right: 1rem;
    }
  `}

  ${props => props.width === '25%' && css`
    @media (min-width: 650px) {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin-left: 10%;
    }
  `}

  ${props => props.position.value === 'left' && props.width === '25%' && css`
    @media (min-width: 650px) {
      display: flex;
      flex-direction: column;
      justify-content: flex-start !important;
      margin-left: 0 !important;
      margin-right: 10%;
    }
  `}
`;

class TextPopupView extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const { items, id, data, props, colorMf } = this.props;
    const { position } = props;
    if (position.value === 'left') {
      return (
        <TextPopupWrapper className="animated bounceInLeft" position={position}>
          <ContentWrapper width="75%" position={position}>
            <TextView
              data={data}
            />
          </ContentWrapper>
          <ContentWrapper width="25%" position={position}>
            {items && items.map((item, index) => (
              <StyledPopupPane>
                <Popup
                  key={`popups${index}-${id}`}
                  buttonText={item.buttonText}
                  title={item.title}
                  text={item.text}
                  picture={item.url}
                  colorMf={colorMf}
                />
              </StyledPopupPane>
            ))}
          </ContentWrapper>
        </TextPopupWrapper>
      );
    }
    return (
      <TextPopupWrapper className="animated bounceInRight" position={position}>
        <ContentWrapper width="75%" position={position}>
          <TextView
            data={data}
          />
        </ContentWrapper>
        <ContentWrapper width="25%" position={position}>
          {items && items.map((item, index) => (
            <StyledPopupPane>
              <Popup
                key={`popups${index}-${id}`}
                buttonText={item.buttonText}
                title={item.title}
                text={item.text}
                picture={item.url}
                colorMf={colorMf}
                positionImage={item.positionImage}
              />
            </StyledPopupPane>
          ))}
        </ContentWrapper>
      </TextPopupWrapper>
    );
  }
}

TextPopupView.propTypes = {
  id: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  data: PropTypes.string.isRequired,
  props: PropTypes.objectOf(PropTypes.any).isRequired,
  colorMf: PropTypes.string.isRequired,
};

TextPopupView.defaultProps = {};

export default TextPopupView;
