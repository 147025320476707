import { css } from 'styled-components';

const trStyles = css`

  ${props => props.noBG && css`
    background-color:transparent;
  `}

  ${props => props.paddingTopBotton && css`
    td, th{
      padding: 0.5rem 0.25rem;
    }
  `}

`;

export default trStyles;
