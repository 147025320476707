/* eslint-disable react/no-unused-prop-types */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledTabsPane = styled.div`
  ${''/* padding: 1.5rem 0 0; */}

  ${'' /* > :first-child,
  > div > :first-child {
    margin-top: 0;
  } */}
`;

/**
 * Componente para el contenido de una Tab
 */
const TabsPane = ({ children, name, ...rest }) => (
  <StyledTabsPane name={name} {...rest}>
    {children}
  </StyledTabsPane>
);

TabsPane.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  /** Custom component for the tab */
  component: PropTypes.func,
  /** Custom component props for the tab */
  componentProps: PropTypes.objectOf(PropTypes.any),
};

TabsPane.defaultProps = {
  component: null,
  componentProps: null,
};

export default TabsPane;
