/* eslint react/no-array-index-key: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ContentWrapper from 'client/ui/ContentWrapper';
import { H3, H4 } from 'client/ui/Headings';

const Div = styled.div`
  font-weight: bold;
  color: ${props => props.theme.color.modalTitle};
`;

const DangerousDiv = styled.div`
  line-height: 23px;
  color: ${props => props.theme.color.modalTitle};
`;

const StyledH3 = styled(H3)`
  text-align: center;
  margin-bottom: 2.5rem;
  font-size: 1.25rem;
  line-height: 24px;
`;

const StyledH4 = styled(H4)`
  text-align: center;
  font-weight: 400;
`;

const StyledDiv = styled.div`
  margin-top: 8rem;
`;

const BibliographicReferences = ({ moduleToView }) => {
  const { bibliographicReferences } = moduleToView;
  return (
    <ContentWrapper marginTop="2rem">
      {bibliographicReferences && bibliographicReferences.length === 0 && (
        <StyledDiv className="animated fadeIn">
          <StyledH3>Aún no existen<br />Referencias bibliográficas para mostrar</StyledH3>
          <StyledH4>Para crear una ir a modo edición</StyledH4>
        </StyledDiv>
      )}
      {bibliographicReferences && bibliographicReferences.length === 1 && bibliographicReferences[0].title === '' && (
        <StyledDiv className="animated fadeIn">
          <StyledH3>Aún no existen<br />Referencias bibliográficas para mostrar</StyledH3>
          <StyledH4>Para crear una ir a modo edición</StyledH4>
        </StyledDiv>
      )}
      {bibliographicReferences && bibliographicReferences.length === 1 && bibliographicReferences[0].title !== '' && (
        <div key="ref0">
          <Div className="animated fadeIn">{bibliographicReferences[0].title}</Div>
          <DangerousDiv className="animated fadeIn delay-1s" dangerouslySetInnerHTML={{ __html: bibliographicReferences[0].text }} />
        </div>
      )}
      {bibliographicReferences && bibliographicReferences.length > 1 && (
        <div>
          {bibliographicReferences.map((reference, index) => (
            <div key={`ref-${index}`}>
              <Div className="animated fadeIn">{reference.title}</Div>
              <DangerousDiv className="animated fadeIn delay-1s" dangerouslySetInnerHTML={{ __html: reference.text }} />
            </div>
          ))}
        </div>
      )
      }
    </ContentWrapper>
  );
};

BibliographicReferences.propTypes = {
  moduleToView: PropTypes.objectOf(PropTypes.any).isRequired,
};

BibliographicReferences.defaultProps = {};

export default BibliographicReferences;
