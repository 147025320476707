import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import List from '../components/List';
import { clearNotification, deleteRole, fetchAllRoles } from '../actions/roles';

class RolesContainer extends Component {
  constructor() {
    super();
    this.state = {
      roles: [],
      modalIsOpen: false,
      rolId: '',
    };
    this.hideModal = this.hideModal.bind(this);
    this.showModal = this.showModal.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
  }

  componentWillMount() {
    this.loadRoles();
  }

  componentWillReceiveProps(nextProps) {
    /* Obtenemos los roles */
    if (nextProps.notification.type === 'success') {
      this.loadRoles();
    }
  }

  onConfirm() {
    const { rolId } = this.state;
    const { remove } = this.props;
    this.hideModal();
    remove(rolId);
  }

  loadRoles() {
    fetchAllRoles().then((rolesData) => {
      const roles = rolesData.map(rol => ({ id: rol._id, text: rol.label, rolName: rol.rid }));
      this.setState({ roles });
    });
  }

  showModal(rolId) {
    this.setState({ modalIsOpen: true, rolId });
  }

  hideModal() {
    this.setState({ modalIsOpen: false });
  }

  render() {
    const { notification, clearMessages } = this.props;
    const { roles, modalIsOpen } = this.state;
    return (
      <List
        roles={roles}
        notification={notification}
        clearMessages={clearMessages}
        modalIsOpen={modalIsOpen}
        showModal={this.showModal}
        hideModal={this.hideModal}
        onConfirm={this.onConfirm}
      />
    );
  }
}

RolesContainer.propTypes = {
  clearMessages: PropTypes.func.isRequired,
  notification: PropTypes.objectOf(PropTypes.any).isRequired,
  remove: PropTypes.func.isRequired,
};

RolesContainer.defaultProps = {};

const mapStateToProps = (state) => {
  const { error, success, message } = state.roles;
  const notification = {};

  if (error) {
    notification.type = 'error';
    notification.content = message;
  } else if (success) {
    notification.type = 'success';
    notification.content = message;
  }

  return {
    notification,
  };
};

const mapDispatchToProps = dispatch => ({
  clearMessages: () => { dispatch(clearNotification()); },
  remove: (roleId) => { dispatch(deleteRole(roleId)); },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RolesContainer);
