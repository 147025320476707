import { api } from 'config';
import {
  TOPIC_SUCCESS,
  TOPIC_ERROR,
  TOPIC_CLEAR_NOTIFICATION,
} from '../constants/actionTypes';

function handleStateTopic(type, message, id) {
  return {
    type,
    message,
    id,
  };
}

function clearNotification() {
  return {
    type: TOPIC_CLEAR_NOTIFICATION,
  };
}

function updateTopic(data, id) {
  const token = localStorage.getItem('token') || null;
  const reqConfig = {
    method: 'PUT',
    headers: Object.assign({}, {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
    body: JSON.stringify(data),
  };

  return (dispatch) => {
    fetch(`${api.baseUrl}/api/topics/${id}`, reqConfig)
      .then((response) => {
        if (response.status >= 400) {
          dispatch(handleStateTopic(TOPIC_ERROR, response.statusText));
        }
        return response.json();
      }).then((ev) => {
        dispatch(handleStateTopic(TOPIC_SUCCESS, 'Actualizada correctamente', ev._id));
      })
      .catch(error => console.log(`Error: ${error}`));
  };
}

function createTopic(data) {
  const token = localStorage.getItem('token') || null;
  const reqConfig = {
    method: 'POST',
    headers: Object.assign({}, {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
    body: JSON.stringify(data),
  };

  return (dispatch) => {
    fetch(`${api.baseUrl}/api/uas/${data.ua}/topics/`, reqConfig)
      .then((response) => {
        if (response.status >= 400) {
          dispatch(handleStateTopic(TOPIC_ERROR, response.statusText));
        }
        return response.json();
      }).then((doc) => {
        dispatch(handleStateTopic(TOPIC_SUCCESS, 'Guardada correctamente', doc._id));
      })
      .catch(error => console.log(`Error: ${error}`));
  };
}

function saveTopic(data) {
  return (dispatch) => {
    if (!data) {
      return dispatch(handleStateTopic(TOPIC_ERROR, 'Debes completar el formulario de la Unidad de Aprendizaje'));
    }
    if (data.id) {
      const { id } = data;
      delete data.id;
      dispatch(updateTopic(data, id));
    } else {
      dispatch(createTopic(data));
    }
  };
}

const fetchTopicPromise = id => new Promise((resolve, reject) => {
  const token = localStorage.getItem('token') || null;
  const reqConfig = {
    method: 'GET',
    headers: Object.assign({}, {
      Authorization: `Bearer ${token}`,
    }),
  };
  fetch(`${api.baseUrl}/api/topics/${id}`, reqConfig)
    .then((response) => {
      if (response.status >= 400) {
        return reject(response.statusText);
      }
      return resolve(response.json());
    });
});

async function fetchTopic(id) {
  try {
    const topicData = await fetchTopicPromise(id);
    return topicData;
  } catch (error) {
    return error;
  }
}

const fetchUaTopicsPromise = id => new Promise((resolve, reject) => {
  const token = localStorage.getItem('token') || null;
  const reqConfig = {
    method: 'GET',
    headers: Object.assign({}, {
      Authorization: `Bearer ${token}`,
    }),
  };
  fetch(`${api.baseUrl}/api/uas/${id}/topics`, reqConfig)
    .then((response) => {
      if (response.status >= 400) {
        return reject(response.statusText);
      }
      return resolve(response.json());
    });
});

async function fetchUaTopics(id) {
  try {
    const topicsData = await fetchUaTopicsPromise(id);
    return topicsData;
  } catch (error) {
    return error;
  }
}

function deleteTopic(id) {
  const token = localStorage.getItem('token') || null;
  const reqConfig = {
    method: 'DELETE',
    headers: Object.assign({}, {
      Authorization: `Bearer ${token}`,
    }),
  };
  return (dispatch) => {
    fetch(`${api.baseUrl}/api/topics/${id}`, reqConfig)
      .then((response) => {
        if (response.status >= 400) {
          dispatch(handleStateTopic(TOPIC_ERROR, response.statusText));
        } else {
          dispatch(handleStateTopic(TOPIC_SUCCESS, 'Eliminado correctamente'));
        }
      })
      .catch(error => console.log(`Error: ${error}`));
  };
}

export {
  fetchTopic,
  fetchUaTopics,
  clearNotification,
  deleteTopic,
  saveTopic,
};
