import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Form from '../components/Form';
import { save, clearNotification, fetchAllRoles } from '../actions/roles';

class FormContainer extends Component {
  constructor() {
    super();
    this.state = {
      roleToUpdate: {},
      createMode: false,
      modalIsOpen: false,
      modalMessage: '',
    };
    this.hideModal = this.hideModal.bind(this);
    this.showModal = this.showModal.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
  }

  componentWillMount() {
    const { match } = this.props;

    if (match.params.id) {
      const { id } = match.params;
      this.loadRolData(id);
      const modalMessage = '¿Desea cancelar la edición del rol?';
      this.setState({ modalMessage });
    } else {
      const modalMessage = '¿Desea cancelar la creación del rol?';
      this.setState({ createMode: true, modalMessage });
    }
  }

  componentWillReceiveProps(nextProps) {
    /* Obtenemos los datos del rol actualizados */
    if (nextProps.notification.type === 'success') {
      const { match } = this.props;
      if (match.params.id) {
        const { id } = match.params;
        this.loadRolData(id);
      }
    }
  }

  onConfirm() {
    const { history } = this.props;
    this.hideModal();
    history.replace('/dashboard/roles');
  }

  showModal() {
    this.setState({ modalIsOpen: true });
  }

  hideModal() {
    this.setState({ modalIsOpen: false });
  }

  loadRolData(roleId) {
    fetchAllRoles().then((rolesData) => {
      const rol = rolesData.find(r => r._id === roleId);
      this.setState({ roleToUpdate: rol });
    });
  }

  render() {
    const { roleToUpdate, createMode, modalIsOpen, modalMessage } = this.state;
    const { saveRoleData, clearMessages, notification, history } = this.props;
    return (
      <Form
        save={saveRoleData}
        notification={notification}
        roleToUpdate={roleToUpdate}
        clearNotification={clearMessages}
        createMode={createMode}
        history={history}
        modalIsOpen={modalIsOpen}
        showModal={this.showModal}
        hideModal={this.hideModal}
        onConfirm={this.onConfirm}
        modalMessage={modalMessage}
      />
    );
  }
}

FormContainer.propTypes = {
  clearMessages: PropTypes.func.isRequired,
  notification: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  saveRoleData: PropTypes.func.isRequired,
};

FormContainer.defaultProps = {};

const mapStateToProps = (state) => {
  const { error, success, message } = state.roles;
  const notification = {};

  if (error) {
    notification.type = 'error';
    notification.content = message;
  } else if (success) {
    notification.type = 'success';
    notification.content = message;
  }

  return {
    notification,
  };
};

const mapDispatchToProps = dispatch => ({
  clearMessages: () => { dispatch(clearNotification()); },
  saveRoleData: (userData) => { dispatch(save(userData)); },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FormContainer);
