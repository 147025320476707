import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledSpan = styled.div`
  line-height: 23px;
  padding: 0 2.5rem;
`;

const parseQuestion = (item) => {
  const inicio = item.question.indexOf('<b>') + 3;
  const fin = item.question.indexOf('</b>');
  let parsedQuestion = item.question.substring(0, inicio) + item.question.substring(fin);
  const defaultIndex = item.options.findIndex(op => op.default === true);
  parsedQuestion = parsedQuestion.replace('<b></b>', `<select disabled><option>${item.options[defaultIndex].text}</option></select>`);
  return parsedQuestion;
};

const ClozeSelectView = ({ item }) => (
  <StyledSpan>
    <div dangerouslySetInnerHTML={{ __html: parseQuestion(item) }} />
  </StyledSpan>
);

ClozeSelectView.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
};

ClozeSelectView.defaultProps = {};

export default ClozeSelectView;
