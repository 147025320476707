/* eslint no-useless-escape: 0, import/prefer-default-export: 0 */

function isValidUrl(url) {
  if (url === '') {
    return false;
  }
  const pattern = /^(http|https)\:\/\/[a-z0-9\.-]+\.[a-z]{2,4}/gi;
  if (url && url.match(pattern)) {
    return true;
  }
  return false;
}

/**
 * Turn array into comma-separated list
 * @param  {Array} list
 * @return {String}
 */
function arrayToCommaSeparated(list) {
  return list.join(', ');
}

function capitalizeFirstLetter(string) {
  return string[0].toUpperCase() + string.slice(1).toLowerCase();
}

function capitalize(str) {
  return str.replace(/(?:^|\s)\S/g, txt => txt.toUpperCase());
}

function debounce(callback, wait, context = this) {
  let timeout = null;
  let callbackArgs = null;

  const later = () => callback.apply(context, callbackArgs);

  return function res() {
    callbackArgs = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export {
  isValidUrl,
  arrayToCommaSeparated,
  capitalizeFirstLetter,
  capitalize,
  debounce,
};
