const actionNames = {
  READ: 'Leer',
  WRITE: 'Crear',
  UPDATE: 'Modificar',
  DELETE: 'Eliminar',
  OWN: 'Leer propio',
  RESET: 'Resetear',
  EXPORT: 'Exportar',
  VALIDATE: 'Validar',
  CLONE: 'Clonar',
};

const modulesName = {
  PERMISSIONS: 'Permisos',
  CENTRES: 'Centros',
  USERS: 'Usuarios',
  MODULES: 'Módulos Formativos',
  PASSWORD: 'Contraseña',
  DASHBOARD: 'Panel de administración',
  ROLES: 'Roles',
  CENTREREPO: 'Repositorio centro',
};

function getRoleModuleActions(permissions, module) {
  const userPermissions = permissions.find(mod => mod.module === module);
  if (userPermissions) {
    return userPermissions.actions;
  }
  return {};
}

function getActionName(action) {
  let name = action;
  Object.keys(actionNames).forEach((a) => {
    if (a === action) {
      name = actionNames[a];
    }
  });
  return name;
}

function getModuleName(module) {
  let name = module;
  Object.keys(modulesName).forEach((a) => {
    if (a === module) {
      name = modulesName[a];
    }
  });
  return name;
}

export {
  getRoleModuleActions,
  getActionName,
  getModuleName,
};
