/* eslint no-underscore-dangle: 0, no-undef: 0, global-require: 0 */
import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { apiMiddleware } from 'redux-api-middleware';
import apiError from 'client/middlewares/apiError';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import rootReducer from 'reducers';

/**
 * Configura el store de producción
 */
const configureStoreProd = (initialState, history) => {
  // Crea un historial mejorado que sincroniza los eventos de navigation con el Store
  const historyMiddleware = routerMiddleware(history);
  const middlewares = [
    // Añade otro middleware en esta linea...
    apiMiddleware,
    apiError,
    historyMiddleware,

    // Thunk middleware can also accept an extra argument to be passed to each thunk action
    // @see https://github.com/gaearon/redux-thunk#injecting-a-custom-argument
    thunk,
  ];

  // Crea un store de Redux que mantiene el árbol de estado de la app
  // @see http://redux.js.org/docs/api/createStore.html
  const store = createStore(
    connectRouter(history)(rootReducer),
    initialState,
    compose(applyMiddleware(...middlewares)),
  );

  return store;
};

/**
 * Configura el store de desarrollo
 */
const configureStoreDev = (initialState, history) => {
  const historyMiddleware = routerMiddleware(history);
  const middlewares = [
    // Añade otro middleware en esta linea...
    apiMiddleware,
    apiError,
    historyMiddleware,

    // Thunk middleware can also accept an extra argument to be passed to each thunk action
    // @see https://github.com/gaearon/redux-thunk#injecting-a-custom-argument
    thunk,
  ];

  // Añade soporte para Redux dev tools
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  // Crea un store de Redux que mantiene el árbol de estado de la app
  // @see http://redux.js.org/docs/api/createStore.html
  const store = createStore(
    connectRouter(history)(rootReducer),
    initialState,
    composeEnhancers(applyMiddleware(...middlewares)),
  );

  if (module.hot) {
    // Habilita el hot module replacement de Webpack para los reducers
    module.hot.accept('reducers', () => {
      const nextReducer = require('reducers');
      store.replaceReducer(nextReducer);
    });
  }

  return store;
};

// Configura el store dependiendo si la app está en producción o desarrollo
const configureStore = process.env.NODE_ENV === 'production' ? configureStoreProd : configureStoreDev;

export default configureStore;
