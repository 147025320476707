import { api } from 'config';

function resetPasswordPromise(email) {
  return new Promise((resolve, reject) => {
    const reqConfig = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': false,
      },
      body: JSON.stringify({ password: email }),
    };
    fetch(`${api.baseUrl}/auth/forgotten-password`, reqConfig)
      .then((response) => {
        if (response.status === 200) {
          return resolve({ status: 'success', message: 'Se ha enviado un correo con su nueva contraseña' });
        }
        return reject({ status: 'error', message: 'Se ha producido un error, vuelva a intentarlo' });
      });
  });
}

async function resetPassword(email) {
  try {
    const response = await resetPasswordPromise(email);
    return response;
  } catch (error) {
    return error;
  }
}

export default resetPassword;
