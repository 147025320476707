import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { api } from 'config';
import Input from 'client/ui/Input';
import Button from 'client/ui/Button';
import Label from 'client/ui/Label';
import ButtonFa from 'client/ui/ButtonFa';
import DragHandle from 'client/ui/DragHandle';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import UploadPictureMenu from './UploadPictureMenu';

const StyledOption = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin: 0.5rem 0;
  border: 1px solid ${props => props.theme.color.grayTable};
  border-radius: ${props => props.theme.radius};
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.5);
`;

const StyledInput = styled(Input)`
  border: 1px solid ${props => props.theme.color.gray};
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const Radio = styled.input`

`;

const ButtonWrapper = styled.div`
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const TitleWrapper = styled.div`
  display: flex;
`;

const StyledLabel = styled(Label)`
  margin-right: 1rem;
`;

const RadioWrapper = styled.div`
  padding: 1.5rem;
  margin-right: 0.5rem;
`;

const StyledButtonFa = styled(ButtonFa)`
  display: inline-block;
  cursor: pointer;
  padding: 0.875em 1em;
  font-size: 1rem;
  background-color: transparent;
`;

const Column = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  min-width: 0;
`;

const HandleFullHeight = styled(DragHandle)`
  height: 68px;
`;

const SortableItem = SortableElement(({ option, optionCheckChange, contentId, questionId,
  optionTextChange, removeAnswer }) => (
    <StyledOption>
      <RadioWrapper>
        <Radio
          type="radio"
          name={`radio-${option.id}`}
          value={option.id}
          checked={option.correctAnswer}
          onChange={() => { optionCheckChange(contentId, questionId, option.id); }}
        />
      </RadioWrapper>
      <Column>
        <StyledInput
          type="text"
          name="text"
          value={option.text}
          onChange={(value) => { optionTextChange(contentId, questionId, option.id, value); }}
          placeholder="Añadir una respuesta"
        />
        <StyledButtonFa
          fa="fa-trash-alt"
          transparent
          onClick={() => removeAnswer(contentId, questionId, option.id)}
          color="black"
        />
      </Column>
      <HandleFullHeight />
    </StyledOption>
));

const SortableList = SortableContainer(({ options, optionCheckChange, contentId, questionId,
  optionTextChange, removeAnswer }) => (
    <div>
      {options.map((option, index) => (
        <SortableItem
          key={`option-${index}`}
          option={option}
          optionCheckChange={optionCheckChange}
          contentId={contentId}
          questionId={questionId}
          optionTextChange={optionTextChange}
          removeAnswer={removeAnswer}
          index={index}
        />
      ))}
    </div>
));

class SimpleQuestionItem extends Component {
  constructor() {
    super();
    this.state = {};
    this.onSortEnd = this.onSortEnd.bind(this);
  }

  onSortEnd({ oldIndex, newIndex }) {
    const { contentId, questionId, sortQuestionOption } = this.props;
    sortQuestionOption(contentId, questionId, oldIndex, newIndex);
  }

  render() {
    const { contentId, picture, previewMaxWidth, addAnswer, removeAnswer,
      onQuestionChange, question, savePicture, options, optionCheckChange, questionId,
      optionTextChange } = this.props;
    return (
      <div>
        <Input
          type="text"
          value={question}
          onChange={(value) => { onQuestionChange(contentId, questionId, value); }}
          placeholder="Añadir una pregunta"
        />
        <UploadPictureMenu
          id={`id-${questionId}${contentId}`}
          image={picture && picture.length > 0 ? `${api.baseUrl}${picture}` : ''}
          onChange={(selected) => { savePicture(selected, contentId, questionId); }}
          previewMaxWidth={previewMaxWidth}
          required=""
        />
        <ButtonWrapper>
          <Button
            onClick={() => addAnswer(contentId, questionId)}
          >
            Añadir respuesta
          </Button>
        </ButtonWrapper>
        <TitleWrapper>
          <StyledLabel>Correcta</StyledLabel>
          <StyledLabel>Respuestas</StyledLabel>
        </TitleWrapper>
        {options && options.length > 0
            && (
              <SortableList
                lockAxis="y"
                useDragHandle
                onSortEnd={this.onSortEnd}
                options={options}
                optionCheckChange={optionCheckChange}
                contentId={contentId}
                questionId={questionId}
                optionTextChange={optionTextChange}
                removeAnswer={removeAnswer}
              />
            )}
      </div>
    );
  }
}

SimpleQuestionItem.propTypes = {
  contentId: PropTypes.string.isRequired,
  questionId: PropTypes.string.isRequired,
  savePicture: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  picture: PropTypes.string,
  previewMaxWidth: PropTypes.string,
  addAnswer: PropTypes.func.isRequired,
  onQuestionChange: PropTypes.func.isRequired,
  question: PropTypes.string.isRequired,
  sortQuestionOption: PropTypes.func.isRequired,
  optionCheckChange: PropTypes.func.isRequired,
  optionTextChange: PropTypes.func.isRequired,
  removeAnswer: PropTypes.func.isRequired,
};

SimpleQuestionItem.defaultProps = {
  picture: '',
  previewMaxWidth: '150px',
};

export default SimpleQuestionItem;
