import { api } from 'config';
import {
  UA_SUCCESS,
  UA_ERROR,
  UA_CLEAR_NOTIFICATION,
} from '../constants/actionTypes';

function handleStateUa(type, message, id) {
  return {
    type,
    message,
    id,
  };
}

function clearNotification() {
  return {
    type: UA_CLEAR_NOTIFICATION,
  };
}

function updateUa(data, id) {
  const token = localStorage.getItem('token') || null;
  const reqConfig = {
    method: 'PUT',
    headers: Object.assign({}, {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
    body: JSON.stringify(data),
  };

  return (dispatch) => {
    fetch(`${api.baseUrl}/api/uas/${id}`, reqConfig)
      .then((response) => {
        if (response.status >= 400) {
          dispatch(handleStateUa(UA_ERROR, response.statusText));
        }
        return response.json();
      }).then((ev) => {
        dispatch(handleStateUa(UA_SUCCESS, 'Actualizada correctamente', ev._id));
      })
      .catch(error => console.log(`Error: ${error}`));
  };
}

function saveUa(data) {
  const token = localStorage.getItem('token') || null;
  const reqConfig = {
    method: 'POST',
    headers: Object.assign({}, {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
    body: JSON.stringify(data),
  };

  let parsedRoute = `${api.baseUrl}/api/`;
  if (data.mf) {
    parsedRoute += `mfs/${data.mf}/uas`;
  } else {
    parsedRoute += `ufs/${data.uf}/uas`;
  }

  return (dispatch) => {
    fetch(parsedRoute, reqConfig)
      .then((response) => {
        if (response.status >= 400) {
          dispatch(handleStateUa(UA_ERROR, response.statusText));
        }
        return response.json();
      }).then((doc) => {
        dispatch(handleStateUa(UA_SUCCESS, 'Guardada correctamente', doc._id));
      })
      .catch(error => console.log(`Error: ${error}`));
  };
}

function save(data) {
  return (dispatch) => {
    if (!data) {
      return dispatch(handleStateUa(UA_ERROR, 'Debes completar el formulario de la Unidad de Aprendizaje'));
    }
    if (data.id) {
      const { id } = data;
      delete data.id;
      dispatch(updateUa(data, id));
    } else {
      dispatch(saveUa(data));
    }
  };
}

const fetchUaPromise = id => new Promise((resolve, reject) => {
  const token = localStorage.getItem('token') || null;
  const reqConfig = {
    method: 'GET',
    headers: Object.assign({}, {
      Authorization: `Bearer ${token}`,
    }),
  };
  fetch(`${api.baseUrl}/api/uas/${id}`, reqConfig)
    .then((response) => {
      if (response.status >= 400) {
        return reject(response.statusText);
      }
      return resolve(response.json());
    });
});

async function fetchUa(id) {
  try {
    const uaData = await fetchUaPromise(id);
    return uaData;
  } catch (error) {
    return error;
  }
}

const uaScreenCounterPromise = id => new Promise((resolve, reject) => {
  const token = localStorage.getItem('token') || null;
  const reqConfig = {
    method: 'GET',
    headers: Object.assign({}, {
      Authorization: `Bearer ${token}`,
    }),
  };
  fetch(`${api.baseUrl}/api/uas/${id}/contentsScreenCounter`, reqConfig)
    .then((response) => {
      if (response.status >= 400) {
        return reject(response.statusText);
      }
      return resolve(response.json());
    });
});


async function uaScreenCounter(id) {
  try {
    const screensCounter = await uaScreenCounterPromise(id);
    return screensCounter;
  } catch (error) {
    return error;
  }
}

function deleteUa(id) {
  const token = localStorage.getItem('token') || null;
  const reqConfig = {
    method: 'DELETE',
    headers: Object.assign({}, {
      Authorization: `Bearer ${token}`,
    }),
  };
  return (dispatch) => {
    fetch(`${api.baseUrl}/api/uas/${id}`, reqConfig)
      .then((response) => {
        if (response.status >= 400) {
          dispatch(handleStateUa(UA_ERROR, response.statusText));
        } else {
          dispatch(handleStateUa(UA_SUCCESS, 'Eliminada la unidad de Aprendizaje correctamente'));
        }
      })
      .catch(error => console.log(`Error: ${error}`));
  };
}

export {
  fetchUa,
  clearNotification,
  uaScreenCounter,
  deleteUa,
  save,
};
