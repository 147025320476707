/* eslint no-unused-expressions: 0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { api } from 'config';
import { fetchModule } from 'client/modules/dashboard-mfs/actions/mfs';
import { fetchCentre } from 'client/modules/dashboard-centres/actions/centres';
import Wrapper from '../components/Wrapper';

class WrapperContainer extends Component {
  constructor() {
    super();
    this.state = {
      moduleToView: {},
      tab: '#generalDescription',
    };
    this.loadData = this.loadData.bind(this);
  }

  componentWillMount() {
    const { match, history } = this.props;
    if (match.params.id) {
      const { id } = match.params;
      localStorage.setItem('mfId', id);
      this.loadData(id);
    }
    if (history.location && history.location.hash) {
      this.setState({ tab: history.location.hash });
    }
  }

  loadData(moduleId) {
    fetchModule(moduleId).then((moduleData) => {
      const { _id, centre, coverPicture, introduction, title, subtitles, objectives,
        haveUf } = moduleData;
      const moduleToView = moduleData;
      let mfCentre = { value: '', label: '' };
      if (centre && centre._id) {
        mfCentre = { value: centre._id, label: centre.name };
      }
      const haveSubtitles = subtitles && subtitles.length ? subtitles : [''];
      const haveObjectives = objectives && objectives.length ? objectives : [''];
      Object.assign(moduleToView,
        {
          id: _id,
          centre: mfCentre,
          title: title || '',
          subtitles: haveSubtitles,
          introduction: introduction || '',
          objectives: haveObjectives,
        });

      if (coverPicture) {
        coverPicture.src = `${api.baseUrl}${coverPicture.url.l}`;
        Object.assign(moduleToView, { coverPicture });
      }

      const proration = [];
      if (moduleData && moduleData.proration) {
        if (haveUf) {
          moduleData.proration.forEach((pro) => {
            const currentUf = moduleData.ufs.find(uf => uf._id === pro._id);
            if (currentUf) {
              Object.assign(pro, { title: currentUf.title });
              proration.push(pro);
            }
          });
        } else {
          moduleData.proration.forEach((pro) => {
            const currentUa = moduleData.uas.find(ua => ua._id === pro._id);
            if (currentUa) {
              Object.assign(pro, { title: currentUa.title });
              proration.push(pro);
            }
          });
        }
      }

      this.setState({ moduleToView });
      if (moduleData.centre.value) {
        this.loadCentre(moduleData.centre.value);
      }
    });
  }

  loadCentre(centreId) {
    fetchCentre(centreId).then((centreData) => {
      const { moduleToView } = this.state;
      const { colorMf, colorUf, colorUa, logo } = centreData;
      Object.assign(moduleToView, {
        colorMf,
        colorUf,
        colorUa,
        centreLogo: `${api.baseUrl}${logo.url.l}`,
      });
      this.setState({ moduleToView });
    });
  }

  render() {
    const { history, match } = this.props;
    const { moduleToView, tab } = this.state;
    const userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
    const isAdmin = userInfo.roles.find(rol => rol.rid === 'ADMIN');
    let isAdminCurrentUser = false;

    if (isAdmin) {
      isAdminCurrentUser = true;
    }

    return (
      <Wrapper
        moduleToView={moduleToView}
        history={history}
        match={match}
        tab={tab}
        isAdmin={isAdminCurrentUser}
      />
    );
  }
}

WrapperContainer.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

WrapperContainer.defaultProps = {};

export default WrapperContainer;
