import styled from 'styled-components';
import PropTypes from 'prop-types';
import { getThemeColor } from '../style-utils';

const Divider = styled.hr`
  margin-top: ${props => props.marginTop};
  margin-bottom: ${props => props.marginBottom};
  border: 0;
  border-bottom: ${props => props.borderWidth};
  border-style: ${props => props.borderStyle};
  border-color: ${props => getThemeColor(props.borderColor)};
  width: ${props => props.width};
`;

Divider.propTypes = {
  borderWidth: PropTypes.string,
  borderStyle: PropTypes.string,
  borderColor: PropTypes.string,
  marginTop: PropTypes.string,
  marginBottom: PropTypes.string,
  width: PropTypes.string,
};

Divider.defaultProps = {
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'grey',
  marginTop: '0',
  marginBottom: '0',
  width: '100%',
};

export default Divider;
