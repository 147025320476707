/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Img from 'client/ui/Img';
import FileUploadButton from 'client/ui/FileUploadButton';

const Wrapper = styled.div`
  display: flex;
`;

const StyledImagePreview = styled.div`
  position: relative;
  max-width: 150px;
  margin-left: auto;
  margin-right: auto;
  ${props => props.previewMaxWidth && css`
    max-width: ${props.previewMaxWidth}
  `}
`;

const StyledButton = styled.div`
  width: 150px;
  margin: 0 0 1rem 0.5rem;
`;

class UploadPicture extends Component {
  constructor() {
    super();
    this.state = {
      picture: null,
    };

    this.handleUploadPicture = this.handleUploadPicture.bind(this);
  }

  componentWillMount() {
    const { image } = this.props;
    this.setState({
      picture: image,
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      picture: nextProps.image,
    });
  }

  handleUploadPicture(event) {
    const { onChange } = this.props;

    if (event.target.files && event.target.files[0]) {
      const fileReader = new FileReader();
      const file = event.target.files[0];
      fileReader.onload = (upload) => {
        const picture = {
          src: upload.target.result,
          file,
        };
        this.setState({ picture });

        onChange(this.state.picture);
      };
      fileReader.readAsDataURL(event.target.files[0]);
    }
  }


  render() {
    const { id, textButton, required, previewMaxWidth } = this.props;
    return (
      <Wrapper className="row">
        <StyledButton>
          <FileUploadButton
            id={id}
            label={textButton}
            type="image/*"
            onChange={this.handleUploadPicture}
            required={required}
          />
        </StyledButton>

        <div>
          { this.state.picture && this.state.picture && (
            <StyledImagePreview previewMaxWidth={previewMaxWidth}>
              <Img src={this.state.picture} alt="imagen" />
            </StyledImagePreview>
          )}
        </div>
      </Wrapper>
    );
  }
}

UploadPicture.propTypes = {
  id: PropTypes.string,
  textButton: PropTypes.string,
  image: PropTypes.string.isRequired,
  required: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  previewMaxWidth: PropTypes.string.isRequired,
};

UploadPicture.defaultProps = {
  id: 'pictureUpload',
  textButton: 'Subir imagen',
};

export default UploadPicture;
