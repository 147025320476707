import React from 'react';
import Alert from 'react-s-alert';
import styled from 'styled-components';
import Img from 'client/ui/Img';
import Label from 'client/ui/Label';
import Input from 'client/ui/Input';
import Span from 'client/ui/Span';
import Button from 'client/ui/Button';
import ButtonGroup from 'client/ui/ButtonGroup';
import resetPassword from '../actions/forgottenPassword';

const notificationConfig = { position: 'top-right', effect: 'slide', timeout: 3000 };

const Wrapper = styled.div`
  margin-top: 4.37rem;
  max-width: 485px;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
`;

const LogoWrapper = styled.div`
  max-width: 153px;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
`;

const LoginWrapper = styled.div`
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
`;

const LoginForm = styled.div`
  background-color: white;
  padding: 1.5rem 1rem;
  margin: .5rem 0;
  border-radius: ${props => props.theme.radius};
  border: 1px solid ${props => props.theme.color.gray2};
  box-shadow: 0 0 9px 0 rgba(0,0,0,0.5);
`;

const LoginSpan = styled.span`
  font-size: 1.625rem;
  font-weight: 600;
`;

const handleSubmit = (event) => {
  event.preventDefault();
  resetPassword(event.target.username.value).then((message) => {
    if (message.status === 'error') {
      Alert.error(message.message, notificationConfig);
    } else {
      Alert.success(message.message, notificationConfig);
    }
  });
};

const ForgotPassword = () => (
  <Wrapper>
    <Alert stack={{ limit: 1 }} />
    <LogoWrapper>
      <Img src="images/logos/logo-vertical.png" alt="Logo ATLAS" />
    </LogoWrapper>
    <LoginWrapper>
      <LoginSpan>Reiniciar contraseña</LoginSpan>
      <LoginForm>
        <form
          onSubmit={handleSubmit}
        >
          <Label>
            <Span weight="700">E-mail</Span>
            <Input
              type="text"
              name="username"
              required
            />
          </Label>
          <ButtonGroup align="right">
            <Button type="submit">Enviar</Button>
          </ButtonGroup>
        </form>
      </LoginForm>
      <Button transparent color="red" to="/login">Volver a login</Button>
    </LoginWrapper>
  </Wrapper>
);

ForgotPassword.propTypes = {};

ForgotPassword.defaultProps = {};

export default ForgotPassword;
