import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Alert from 'react-s-alert';
import { H2 } from 'client/ui/Headings';
import Table from 'client/ui/Table';
import Td from 'client/ui/Td';
import Tr from 'client/ui/Tr';
import Th from 'client/ui/Th';
import Input from 'client/ui/Input';
import Button from 'client/ui/Button';
import ButtonGroup from 'client/ui/ButtonGroup';

const notificationConfig = { position: 'top-right', effect: 'slide', timeout: 3000 };

const TableWrapper = styled.div`
  width: 100%;
  max-width: 677px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;

  tr {
    :nth-child(even) {
      background-color: #F1F0F0;
    }
    th, td {
      padding: .75rem;
      vertical-align: middle;
      &:first-child {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        width: 75%;
      }
      &:last-child {
        padding-right: 1.5rem;
        width: 25%;
      }
    }
  }
`;

const StyledInput = styled(Input)`
  margin-bottom: 0;
`;

const StyleTable = styled(Table)`
  margin-bottom: 1rem;
  border-radius: ${props => props.theme.radius};
  overflow: hidden;
`;

const StyledTbody = styled.tbody`
  padding: 1rem;
`;

const StyledMessage = styled(H2)`
  margin-top: 8rem;
  text-align: center;
`;

class Proration extends Component {
  constructor() {
    super();
    this.state = { proration: [] };
    this.changeValue = this.changeValue.bind(this);
    this.saveProration = this.saveProration.bind(this);
  }

  componentWillMount() {
    const { proration } = this.props;
    this.setState({ proration });
  }

  componentWillReceiveProps(nextProps) {
    const { proration } = nextProps;
    this.setState({ proration });
  }

  changeValue(event, index) {
    const { target } = event;
    const { value } = target;
    const { proration } = this.state;
    proration[index].proration = value;
    this.setState({ proration });
  }

  saveProration() {
    const { proration } = this.state;
    const { id, errorMessage, save } = this.props;
    let total = 0;
    proration.forEach((pro) => {
      const current = parseFloat(pro.proration);
      total += current;
    });
    const fixed = total.toFixed(2);
    console.log(fixed);
    if (fixed !== '99.99' && fixed !== '100.00' && fixed !== '100.01' && fixed !== '100.02' && fixed !== '100.03') {
      Alert.error(errorMessage, notificationConfig);
      return false;
    }
    const data = {
      id,
      proration,
    };
    save(data);
  }

  render() {
    const { proration } = this.state;
    if (proration.length === 0) {
      return (<StyledMessage>No existen pruebas aún</StyledMessage>);
    }
    return (
      <TableWrapper>
        <StyleTable onlyBorderMainTr whiteBG>
          <thead>
            <Tr paddingTopBotton1rem>
              <Th darkGray whiteText>Nombre</Th>
              <Th darkGray whiteText>Ponderación</Th>
            </Tr>
          </thead>
          <StyledTbody>
            {proration.map((pro, index) => (
              <Tr paddingTopBotton key={pro._id}>
                <Td>{pro.title}</Td>
                <Td>
                  <StyledInput
                    type="number"
                    min="0"
                    max="100"
                    step=".5"
                    value={pro.proration}
                    onChange={(event) => { this.changeValue(event, index); }}
                  />
                </Td>
              </Tr>
            ))}
          </StyledTbody>
        </StyleTable>
        <ButtonGroup align="right">
          <Button onClick={this.saveProration}>Guardar</Button>
        </ButtonGroup>
      </TableWrapper>
    );
  }
}

Proration.propTypes = {
  id: PropTypes.string.isRequired,
  proration: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  errorMessage: PropTypes.string,
  save: PropTypes.func.isRequired,
};

Proration.defaultProps = {
  proration: [],
  errorMessage: 'La ponderación debe sumar un total de 100',
};

export default Proration;
