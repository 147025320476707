import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRoleModuleActions } from 'client/modules/dashboard-permissions/utils/modules-actions';
import { arrayToCommaSeparated } from 'client/modules/core/utils/utils';
import List from '../components/List';
import { clearNotification, deleteUser, fetchAllUsers } from '../actions/users';

class UsersContainer extends Component {
  constructor() {
    super();
    this.state = {
      users: [],
      modalIsOpen: false,
      userId: '',
      notFound: false,
      userPermissions: {},
    };
    this.hideModal = this.hideModal.bind(this);
    this.showModal = this.showModal.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
  }

  componentWillMount() {
    const { permissions } = this.props;
    const userPermissions = getRoleModuleActions(permissions, 'USERS');
    this.setState({ userPermissions });
    this.loadUser();
  }

  componentWillReceiveProps(nextProps) {
    const { permissions } = nextProps;
    if (nextProps.notification.type === 'success') {
      this.loadUser();
    }
    const userPermissions = getRoleModuleActions(permissions, 'CENTRES');
    this.setState({ userPermissions });
  }

  onConfirm() {
    const { userId } = this.state;
    const { remove } = this.props;
    this.hideModal();
    remove(userId);
  }

  loadUser() {
    fetchAllUsers().then((usersData) => {
      if (usersData.length === 0) {
        this.setState({ notFound: true });
      }
      const users = [];
      usersData.forEach((userData) => {
        const { _id, email, firstName, lastName, roles } = userData;
        const id = _id;
        const userRoles = roles.map(rol => rol.label);
        const parsedRoles = arrayToCommaSeparated(userRoles);
        const current = [
          id,
          firstName,
          lastName,
          email,
          parsedRoles,
        ];
        users.push(current);
      });
      this.setState({ users });
    });
  }

  showModal(e) {
    const userId = e.currentTarget.getAttribute('data-id');
    if (userId) {
      this.setState({ modalIsOpen: true, userId });
    }
  }

  hideModal() {
    this.setState({ modalIsOpen: false });
  }

  render() {
    const { users, modalIsOpen, notFound, userPermissions } = this.state;
    const { notification, clearMessages } = this.props;

    return (
      <List
        users={users}
        notification={notification}
        clearMessages={clearMessages}
        modalIsOpen={modalIsOpen}
        showModal={this.showModal}
        hideModal={this.hideModal}
        onConfirm={this.onConfirm}
        notFound={notFound}
        userPermissions={userPermissions}
      />
    );
  }
}

UsersContainer.propTypes = {
  clearMessages: PropTypes.func.isRequired,
  notification: PropTypes.objectOf(PropTypes.any).isRequired,
  remove: PropTypes.func.isRequired,
  permissions: PropTypes.arrayOf(PropTypes.object).isRequired,
};

UsersContainer.defaultProps = {};

const mapStateToProps = (state) => {
  const { error, success, message } = state.users;
  const notification = {};

  if (error) {
    notification.type = 'error';
    notification.content = message;
  } else if (success) {
    notification.type = 'success';
    notification.content = message;
  }

  return {
    notification,
    permissions: state.permissions.userPermissions,
  };
};

const mapDispatchToProps = dispatch => ({
  clearMessages: () => { dispatch(clearNotification()); },
  remove: (userId) => { dispatch(deleteUser(userId)); },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UsersContainer);
