import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { api } from 'config';
import { connect } from 'react-redux';
import uploadPicture from 'client/modules/core/utils/upload-uf-picture';
import { clearNotification as clearNotificationUa } from 'client/modules/dashboard-uas/actions/uas';
import { save, clearNotification, fetchUf } from '../actions/ufs';

import Wrapper from '../components/Wrapper';

class WrapperContainer extends Component {
  constructor() {
    super();
    this.state = {
      ufToUpdate: {},
      createMode: true,
      pictureId: null,
      tab: '#generalData',
      mfId: null,
    };
    this.loadData = this.loadData.bind(this);
    this.savePicture = this.savePicture.bind(this);
  }

  componentWillMount() {
    const { match, history } = this.props;
    if (match.params.id) {
      const { id } = match.params;
      this.loadData(id);
      localStorage.setItem('ufId', id);
      this.setState({ createMode: false });
    }
    if (history.location && history.location.hash) {
      this.setState({ tab: history.location.hash });
    }
    const mfId = localStorage.getItem('mfId');
    if (mfId) {
      this.setState({ mfId });
    }
  }

  componentWillReceiveProps(nextProps) {
    /* Obtenemos los datos actualizados */
    if (nextProps.notification.type === 'success') {
      const { match } = this.props;
      if (match.params.id) {
        const { id } = match.params;
        this.loadData(id);
      }
    }
  }

  loadData(ufId) {
    fetchUf(ufId).then((ufData) => {
      const { _id, coverPicture, introduction, title, subtitles, objectives,
        bibliographicReferences } = ufData;
      const ufToUpdate = ufData;
      const haveSubtitles = subtitles && subtitles.length ? subtitles : [''];
      const haveObjectives = objectives && objectives.length ? objectives : [''];
      let picture = null;
      let references = [];
      if (!bibliographicReferences || bibliographicReferences.length === 0) {
        references.push({ title: '', text: '' });
      } else {
        references = bibliographicReferences;
      }
      if (coverPicture) {
        picture = {
          id: coverPicture._id,
          src: `${api.baseUrl}${coverPicture.url.s}`,
        };
      }

      const proration = [];
      if (ufData && ufData.proration) {
        ufData.proration.forEach((pro) => {
          const currentUa = ufData.uas.find(ua => ua._id === pro._id);
          if (currentUa) {
            Object.assign(pro, { title: currentUa.title });
            proration.push(pro);
          }
        });
      }

      Object.assign(ufToUpdate,
        {
          id: _id,
          title: title || '',
          subtitles: haveSubtitles,
          introduction: introduction || '',
          objectives: haveObjectives,
          coverPicture: picture,
          bibliographicReferences: references,
          proration,
        });
      this.setState({ ufToUpdate });
    });
  }

  savePicture(picture, name) {
    const pictureId = uploadPicture({ picture: picture.file, description: `Portada de la UF ${name}`, name });
    pictureId.then((response) => {
      if (response && response.image) {
        this.setState({ pictureId: response.image._id });
      }
    });
  }

  render() {
    const { saveForm, notification, clearMessages, history, match, notificationUa,
      clearUaMessage } = this.props;
    const { ufToUpdate, createMode, tab, pictureId, mfId } = this.state;
    return (
      <Wrapper
        save={saveForm}
        notification={notification}
        notificationUa={notificationUa}
        clearMessages={clearMessages}
        clearUaMessage={clearUaMessage}
        ufToUpdate={ufToUpdate}
        history={history}
        createMode={createMode}
        pictureId={pictureId}
        savePicture={this.savePicture}
        match={match}
        mfId={mfId}
        tab={tab}
      />
    );
  }
}

WrapperContainer.propTypes = {
  saveForm: PropTypes.func.isRequired,
  clearMessages: PropTypes.func.isRequired,
  clearUaMessage: PropTypes.func.isRequired,
  notification: PropTypes.objectOf(PropTypes.any).isRequired,
  notificationUa: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

WrapperContainer.defaultProps = {};
const mapStateToProps = (state) => {
  const { error, success, message, id } = state.ufs;
  const notification = {};
  const notificationUa = {};

  if (error) {
    notification.type = 'error';
    notification.content = message;
  } else if (success) {
    notification.type = 'success';
    notification.content = message;
    notification.id = id;
  }

  if (state.uas.success) {
    notificationUa.type = 'success';
    notificationUa.content = state.uas.message;
  }

  return {
    notification,
    notificationUa,
  };
};

const mapDispatchToProps = dispatch => ({
  saveForm: (data) => { dispatch(save(data)); },
  clearMessages: () => { dispatch(clearNotification()); },
  clearUaMessage: () => { dispatch(clearNotificationUa()); },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WrapperContainer);
