import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import RandomId from 'short-unique-id';
import Input from 'client/ui/Input';
import Button from 'client/ui/Button';
import Label from 'client/ui/Label';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import DragHandle from 'client/ui/DragHandle';
import ButtonFa from 'client/ui/ButtonFa';
import MediumEditor from 'client/ui/MediumEditor';
import { mediumSelection } from 'client/modules/dashboard/utils/medium-editor';

const StyledOption = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin: 0.5rem 0;
  border: 1px solid ${props => props.theme.color.grayTable};
  border-radius: ${props => props.theme.radius};
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.5);
`;

const StyledInput = styled(Input)`
  border: 1px solid ${props => props.theme.color.gray};
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const Radio = styled.input`

`;

const ButtonWrapper = styled.div`
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const TitleWrapper = styled.div`
  display: flex;
`;

const StyledLabel = styled(Label)`
  margin-right: 1rem;
`;

const RadioWrapper = styled.div`
  padding: 1.5rem;
  margin-right: 0.5rem;
`;

const StyledButtonFa = styled(ButtonFa)`
  display: inline-block;
  cursor: pointer;
  padding: 0.875em 1em;
  font-size: 1rem;
  background-color: transparent;
`;

const StyledMedium = styled(MediumEditor)`
  margin-bottom: 0.5rem;
  b {
    background-color: #4A90E2;
  }
`;

const Empty = styled.div`
  width: 53px;
  height: 15px;
`;

const Column = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  min-width: 0;
`;

const HandleFullHeight = styled(DragHandle)`
  height: 68px;
`;

const StyledInfo = styled.div`
  font-size: 0.875rem;
  color: ${props => props.theme.color.addContentColor};
`;

const SortableItem = SortableElement(({ option, optionCheckChange, contentId, questionId,
  optionTextChange, removeAnswer }) => (
    <StyledOption>
      <RadioWrapper>
        <Radio
          type="radio"
          name={`radio-${option.id}`}
          value={option.id}
          checked={option.correctAnswer}
          onChange={() => { optionCheckChange(contentId, questionId, option.id); }}
          disabled="disabled"
        />
      </RadioWrapper>
      <Column>
        <StyledInput
          type="text"
          name="text"
          value={option.text}
          onChange={(value) => { optionTextChange(contentId, questionId, option.id, value); }}
          readOnly={option.default}
          placeholder="Añadir una respuesta"
        />
        {option.default
          ? (<Empty />)
          : (
            <StyledButtonFa
              fa="fa-trash-alt"
              transparent
              onClick={() => removeAnswer(contentId, questionId, option.id)}
              color="black"
            />
          )
        }
      </Column>
      <HandleFullHeight />
    </StyledOption>
));

const SortableList = SortableContainer(({ options, optionCheckChange, contentId, questionId,
  optionTextChange, removeAnswer }) => (
    <div>
      {options.map((option, index) => (
        <SortableItem
          key={`option-${index}`}
          option={option}
          optionCheckChange={optionCheckChange}
          contentId={contentId}
          questionId={questionId}
          optionTextChange={optionTextChange}
          removeAnswer={removeAnswer}
          index={index}
        />
      ))}
    </div>
));

class ClozeSelectItem extends Component {
  constructor() {
    super();
    this.state = { questionUid: null };
    this.onSortEnd = this.onSortEnd.bind(this);
  }

  componentWillMount() {
    const uid = new RandomId();
    this.setState({ questionUid: uid.randomUUID(12) });
  }

  componentWillReceiveProps(nextProps) {
    const { forceClozeChange } = nextProps;
    if (forceClozeChange) {
      const uid = new RandomId();
      this.setState({ questionUid: uid.randomUUID(12) });
    }
  }

  onSortEnd({ oldIndex, newIndex }) {
    const { contentId, questionId, sortQuestionOption } = this.props;
    sortQuestionOption(contentId, questionId, oldIndex, newIndex);
  }

  render() {
    const { contentId, addAnswer, removeAnswer, onClozeQuestionChange, question, options,
      optionCheckChange, questionId, optionTextChange } = this.props;
    const { questionUid } = this.state;
    return (
      <div>
        <StyledMedium
          key={questionUid}
          minHeight="1rem"
          text={question}
          onChange={(value) => { onClozeQuestionChange(contentId, questionId, value); }}
          options={{
            toolbar: { buttons: mediumSelection },
            placeholder: { text: 'Añadir texto', hideOnClick: true },
          }}
        />
        <StyledInfo>
          Nota: Añada una frase y seleccione la palabra correcta.
        </StyledInfo>
        <ButtonWrapper>
          <Button
            onClick={() => addAnswer(contentId, questionId)}
          >
            Añadir respuesta
          </Button>
        </ButtonWrapper>
        <TitleWrapper>
          <StyledLabel>Correcta</StyledLabel>
          <StyledLabel>Respuestas</StyledLabel>
        </TitleWrapper>
        {options && options.length > 0
            && (
              <SortableList
                lockAxis="y"
                useDragHandle
                onSortEnd={this.onSortEnd}
                options={options}
                optionCheckChange={optionCheckChange}
                contentId={contentId}
                questionId={questionId}
                optionTextChange={optionTextChange}
                removeAnswer={removeAnswer}
              />
            )}
      </div>
    );
  }
}


ClozeSelectItem.propTypes = {
  contentId: PropTypes.string.isRequired,
  questionId: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  addAnswer: PropTypes.func.isRequired,
  onClozeQuestionChange: PropTypes.func.isRequired,
  question: PropTypes.string.isRequired,
  optionCheckChange: PropTypes.func.isRequired,
  optionTextChange: PropTypes.func.isRequired,
  removeAnswer: PropTypes.func.isRequired,
  sortQuestionOption: PropTypes.func.isRequired,
  forceClozeChange: PropTypes.bool.isRequired,
};

ClozeSelectItem.defaultProps = {};

export default ClozeSelectItem;
