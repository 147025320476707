import React from 'react';
import Helmet from 'react-helmet';
import { H1 } from 'client/ui/Headings';

const withoutPermission = () => (
  <div className="not-found">
    <Helmet>
      <title>No tiene permisos</title>
    </Helmet>
    <H1>No tiene permisos</H1>
  </div>
);

export default withoutPermission;
