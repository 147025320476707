/* eslint no-restricted-globals: 0, react/destructuring-assignment: 0,
react/no-access-state-in-setstate: 0, react/no-array-index-key: 0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import deepEqual from 'fast-deep-equal';
import Notification from 'client/ui/Notification';
import theme from 'client/config/theme';
import Modal from 'client/ui/Modal';
import Label from 'client/ui/Label';
import Span from 'client/ui/Span';
import Input from 'client/ui/Input';
import Divider from 'client/ui/Divider';
import Button from 'client/ui/Button';
import ButtonFa from 'client/ui/ButtonFa';
import CodeName from 'client/ui/CodeName';
import ButtonGroup from 'client/ui/ButtonGroup';
import IconFa from 'client/ui/IconFa';
import { Tabs, TabsPane, Tab } from 'client/ui/Tabs';
import SectionCircle from 'client/ui/SectionCircle';
import { H2 } from 'client/ui/Headings';
import { redirectTo } from 'index';
import ContentWrapper from 'client/ui/ContentWrapper';
import TopicList from 'client/modules/dashboard-topics/containers/topics';
import Proration from './Proration';

const CircleText = styled.div`
  font-size: 21px;
  font-weight: bold;
  color: white;
  position: absolute;
  top: 11px;
  right: 7px;
`;

const Header = styled.div`
  margin-bottom: .5rem;
`;

const SectionTitle = styled.div`
  text-align: center;
  background-color: ${props => props.theme.color.greenUa};
  color: white;
  padding: .5rem;
  h2 {
    margin-bottom: 0;
  }
`;

const Section = ContentWrapper.extend`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;
`;

const StyledSeparation = styled.div`
  width: 100%;
  min-width: 0;
  margin-right: 1rem;
`;

const MfName = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: .25rem;
`;

const StyledH2 = styled(H2)`
  color: ${props => props.theme.color.white};
`;

const DividerWrapper = styled.div`
  position: relative;
`;

const MfsDivider = Divider.extend`
  position: absolute;
  top: 0;
  left: 0;
`;

const UfsDivider = MfsDivider.extend`
  left: 25%;

`;
const UasDivider = MfsDivider.extend`
  left: 50%;
  &.mf {
    left: 33.3333%;
  }
`;

const TitleInputWrapper = styled.div`
  display: flex;
  align-items: center;
  input {
    margin-bottom: 0;
  }
`;

const SaveButton = styled(Button)`
  margin-left: 1rem;
`;

const UaTitleWrapper = styled.div`
  margin-bottom: 2rem;
`;

const StyledReturn = styled.span`
  margin-right: .7rem;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
`;

const PrevFa = styled(IconFa)`
  margin-right: .5rem;
  ${props => props.color && css`
    color: ${props.theme.color[props.color]};
  `}
  ${props => props.doubleMargin && css`
    margin-right: .8rem;
  `}
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 0;
`;

const PrevLink = (data) => {
  if (data.ufId !== null) {
    return (
      <StyledReturn onClick={() => redirectTo(`/dashboard/ufs/${data.ufId}#learningUnits`)}>
        <PrevFa name="chevron-left" doubleMargin />
        <PrevFa name="circle" color="orangeUf" />
        <span>UF</span>
      </StyledReturn>
    );
  }
  if (data.mfId !== null) {
    return (
      <StyledReturn onClick={() => redirectTo(`/dashboard/mfs/${data.mfId}#learningUnits`)}>
        <PrevFa name="chevron-left" doubleMargin />
        <PrevFa name="circle" color="blueMfs" />
        <span>MF</span>
      </StyledReturn>
    );
  }
  return null;
};

class Wrapper extends Component {
  constructor() {
    super();
    this.state = {
      id: null,
      title: '',
      modalIsOpen: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.afterNotification = this.afterNotification.bind(this);
    this.handleChangeTab = this.handleChangeTab.bind(this);
    this.changeToPreview = this.changeToPreview.bind(this);
    this.handleTabClick = this.handleTabClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.confirmModal = this.confirmModal.bind(this);
    this.cancelModal = this.cancelModal.bind(this);
    this.isFormDirty = this.isFormDirty.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { uaToUpdate } = nextProps;
    if (uaToUpdate && uaToUpdate.id) {
      const { id, title } = uaToUpdate;
      this.setState({
        id,
        title,
      });
    }
  }

  isFormDirty() {
    const { uaToUpdate } = this.props;
    const { title } = uaToUpdate;

    const initialData = { title };

    const currentData = {
      title: this.state.title,
    };

    return !deepEqual(initialData, currentData);
  }

  afterNotification() {
    const { nextTab } = this.state;
    const { clearMessages, history, match, notification } = this.props;
    clearMessages();
    if (!match.params.id && notification.id) {
      setTimeout(() => {
        history.replace(`/dashboard/uas/edit/${notification.id}`);
      }, 1000);
    }

    if (notification.id && nextTab) {
      setTimeout(() => {
        this.handleChangeTab(nextTab);
      }, 1000);
    }
  }

  showModal() {
    this.setState({ modalIsOpen: true });
  }

  hideModal() {
    this.setState({ modalIsOpen: false });
  }

  confirmModal() {
    this.hideModal();
    this.handleSubmit();
  }

  cancelModal() {
    const { nextTab } = this.state;
    this.hideModal();
    this.handleChangeTab(nextTab);
  }

  changeToPreview() {
    const { history, match, tab } = this.props;
    const { id } = match.params;
    const path = { ...location, hash: tab };
    path.name = `/viewUa/${id}`;
    path.pathname = `/viewUa/${id}`;
    history.replace(path);
  }

  handleInputChange(event) {
    const { target } = event;
    const { name, value } = target;
    this.setState({ [name]: value });
  }

  handleTabClick(tab) {
    if (this.isFormDirty()) {
      this.setState({ nextTab: tab });
      this.showModal();
    } else {
      this.handleChangeTab(tab);
    }
  }

  handleChangeTab(tab) {
    const { history } = this.props;
    const path = { ...location, hash: tab };
    history.replace(path);
  }

  handleSubmit(event) {
    if (event) {
      event.preventDefault();
    }
    const { save, mfId, ufId } = this.props;
    const { id, title } = this.state;
    const data = {
      id,
      title,
    };
    if (mfId) {
      Object.assign(data, { mf: mfId });
    }
    if (ufId) {
      Object.assign(data, { uf: ufId });
    }
    save(data);
  }

  render() {
    const { notification, tab, createMode, mfId, ufId, uaToUpdate, save } = this.props;
    const savedTitle = uaToUpdate.title;
    const { uf, mf, proration } = uaToUpdate;
    const { id, title, modalIsOpen } = this.state;
    return (
      <div>
        {uf && (
          <DividerWrapper>
            <Divider borderWidth="2px" borderColor="grayLight" />
            <MfsDivider width="25%" borderWidth="2px" borderColor="blueMfs" />
            <UfsDivider width="25%" borderWidth="2px" borderColor="orangeUf" />
            <UasDivider width="25%" borderWidth="2px" borderColor="greenUa" />
          </DividerWrapper>
        )}
        {mf && (
          <DividerWrapper>
            <Divider borderWidth="2px" borderColor="grayLight" />
            <MfsDivider width="33.3333%" borderWidth="2px" borderColor="blueMfs" />
            <UasDivider className="mf" width="33.3333%" borderWidth="2px" borderColor="greenUa" />
          </DividerWrapper>
        )}
        <Header>
          <Section>
            <TitleWrapper>
              <MfName>
                <SectionCircle size="42px" background="greenUa">
                  <CircleText>UA</CircleText>
                </SectionCircle>
              </MfName>
              <StyledSeparation>
                <CodeName>
                  { savedTitle !== ''
                    ? <div>{savedTitle}</div>
                    : <div>UA</div>
                  }
                </CodeName>
              </StyledSeparation>
            </TitleWrapper>
            {!createMode
              && (
                <Button
                  hollow
                  color="black"
                  borderColor="black"
                  onClick={() => this.changeToPreview()}
                >
                  Previsualizar
                </Button>)}
          </Section>
          <SectionTitle><StyledH2>UNIDAD DE APRENDIZAJE</StyledH2></SectionTitle>
        </Header>
        <Notification
          message={notification}
          afterNotification={this.afterNotification}
        />
        <Modal
          isOpen={modalIsOpen}
          title="Guardar cambios"
          content="Ha realizado cambios, ¿desea guardar los cambios antes de continuar?"
          onCancel={this.cancelModal}
          onConfirm={this.confirmModal}
        />
        <Tabs
          tabsBarBorderColor="gray2"
          selected={tab}
          component={Tab}
          componentProps={{ color: 'greenUa' }}
          onTabClick={currentTab => this.handleTabClick(currentTab)}
          tabsTitleMaxWidth={theme.maxWidth}
        >
          <TabsPane name="#mf" label="Descripción" component={PrevLink} componentProps={{ mfId, ufId }}>
            <div />
          </TabsPane>
          <TabsPane
            name="#unidad"
            label="Unidad de Aprendizaje"
          >
            <ContentWrapper>
              <H2>Panel de contenidos</H2>
              <UaTitleWrapper>
                <Label>
                  <Span spanForm>Título de la Unidad de Aprendizaje</Span>
                  <TitleInputWrapper>
                    <Input
                      type="text"
                      name="title"
                      value={title}
                      onChange={(event) => { this.handleInputChange(event); }}
                      required
                    />
                    <SaveButton
                      onClick={() => this.handleSubmit()}
                    >
                      Guardar
                    </SaveButton>
                  </TitleInputWrapper>
                </Label>
              </UaTitleWrapper>
              {!createMode && id != null
                && (
                  <div>
                    <TopicList uaId={id} modeEdit />
                    <ButtonGroup align="left">
                      {ufId !== null
                        && (
                        <ButtonFa
                          fa="fa-chevron-left"
                          side="left"
                          onClick={() => redirectTo(`/dashboard/ufs/${ufId}#learningUnits`)}
                        >
                          Volver a UF
                        </ButtonFa>)}
                      {mfId !== null
                        && (
                        <ButtonFa
                          fa="fa-chevron-left"
                          side="left"
                          onClick={() => redirectTo(`/dashboard/mfs/${mfId}#learningUnits`)}
                        >
                          Volver a MF
                        </ButtonFa>)}
                    </ButtonGroup>
                  </div>)}
            </ContentWrapper>
          </TabsPane>
          <TabsPane
            name="#evaluationConfiguration"
            label="Configuración de la evaluación"
          >
            <ContentWrapper>
              {id && (
                <Proration id={id} proration={proration} save={save} />
              )}
            </ContentWrapper>
          </TabsPane>
        </Tabs>
      </div>
    );
  }
}

Wrapper.propTypes = {
  save: PropTypes.func.isRequired,
  notification: PropTypes.objectOf(PropTypes.any).isRequired,
  clearMessages: PropTypes.func.isRequired,
  createMode: PropTypes.bool.isRequired,
  uaToUpdate: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  tab: PropTypes.string.isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  mfId: PropTypes.string,
  ufId: PropTypes.string,
};

Wrapper.defaultProps = {
  uaToUpdate: {},
  mfId: null,
  ufId: null,
};

export default Wrapper;
