import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { toogleNavigation } from 'client/modules/core/actions/navigation';
import { getThemeColor } from '../style-utils';
import IconFa from '../IconFa';


const StyledCollapse = styled.div`
  border-width: 1px;
  border-style: ${props => (props.borderColor ? 'solid' : 'none')};
  border-color: ${props => (props.borderColor ? getThemeColor(props.borderColor) : 'none')};
`;

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${props => props.paddingText};
  color: ${props => props.colorText};
  font-size: ${props => props.fontSize};
  font-weight:${props => props.fontWeight};
  background: ${props => getThemeColor(props.background)};
  border-bottom: 1px solid ${props => getThemeColor(props.borderBottomColor)};
  cursor: pointer;
`;

const StyledTitleIcon = styled.span`
  margin-right: 0.5rem;
`;

const StyledIcon = styled.i`
  font-size: 0.5rem;
  transform: rotate(${props => (props.isOpen ? '0' : '180')}deg);
  transition: transform ${props => props.transitionDuration} ease;
`;

/**
 * 1. Hace una transición con la altura dependiendo si está abierto o cerrado
 * @see https://css-tricks.com/using-css-transitions-auto-dimensions/
 */
const StyledBody = styled.div`
  height: ${props => props.height}; ${''/* 1 */}
  overflow: hidden;
  background: ${props => getThemeColor(props.background)};
  transition: height ${props => props.transitionDuration} ease; ${''/* 1 */}
`;

const StyledBodyContent = styled.div`
  padding: ${props => props.paddingContent};

  > :last-child {
    margin-bottom: 0;
  }
`;

/**
 * Collapse component
 * @TODO Plantearse si es mejor usar react-collapse!
 * @see https://github.com/nkbt/react-collapse
 *
 * @see https://css-tricks.com/using-css-transitions-auto-dimensions/
 * @see https://github.com/SparebankenVest/react-css-collapse
 */
class Collapse extends Component {
  constructor(props) {
    super(props);
    const { isOpen } = this.props;
    this.state = {
      isOpen,
      height: isOpen ? 'auto' : '0',
    };

    this.onTransitionEnd = this.onTransitionEnd.bind(this);
    this.setCollapsed = this.setCollapsed.bind(this);
    this.setAnimateCollapsed = this.setAnimateCollapsed.bind(this);
    this.setExpanded = this.setExpanded.bind(this);
    this.setAnimateExpanded = this.setAnimateExpanded.bind(this);
    this.toggleOpen = this.toggleOpen.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { isOpen } = nextProps;
    if (isOpen) {
      this.setState({ isOpen: true });
    } else {
      this.setAnimateCollapsed();
    }
  }

  // componentDidUpdate(prevProps) {
  //   console.log('COMPONENTDIDUPDATE!-->', prevProps);
  //   if (prevProps !== this.props) {
  //     console.log('COMPONENTDIDUPDATE! -->if (prevProps !== this.props) -->');
  //     if (this.state.isOpen) {
  //       console.log('COMPONENTDIDUPDATE! -->if (prevProps !== this.props) --> if (this.state.isOpen)-->');
  //       this.setAnimateExpanded();
  //     }
  //   }
  // }

  onTransitionEnd() {
    if (this.state.isOpen) {
      this.setExpanded();
    }
  }

  setCollapsed() {
    this.setState({ isOpen: false, height: '0' });
  }

  setAnimateCollapsed() {
    this.setState({ isOpen: false, height: '0' }, () => {
      window.requestAnimationFrame(() => {
        // "Pausing" the JavaScript execution to let the rendering threads catch up
        // see: http://stackoverflow.com/questions/779379/why-is-settimeoutfn-0-sometimes-useful
        setTimeout(() => { this.setCollapsed(); }, 0);
      });
    });
  }

  setExpanded() {
    this.setState({ isOpen: true, height: 'auto' });
  }

  setAnimateExpanded() {
    this.setState({ isOpen: true, height: `${this.content.scrollHeight}px` });
  }

  toggleOpen() {
    const { change } = this.props;
    // Collapse...
    if (this.state.isOpen) {
      this.setAnimateCollapsed();
    }

    // Expand...
    if (!this.state.isOpen) {
      change(this.props.id, this.state.isOpen);
      this.setAnimateExpanded();
    }

    // change(this.props.id, !this.state.isOpen);
  }

  render() {
    const { title, iconFa, children, colorText, fontSize, fontWeight, paddingText, paddingContent,
      headerBg, borderBottomColor, bodyBg, borderColor, transitionDuration, ...rest } = this.props;

    return (
      <StyledCollapse borderColor={borderColor} {...rest}>
        <StyledHeader
          colorText={colorText}
          background={headerBg}
          fontSize={fontSize}
          fontWeight={fontWeight}
          paddingText={paddingText}
          borderBottomColor={borderBottomColor}
          onClick={this.toggleOpen}
        >
          <div>
            {iconFa &&
              <StyledTitleIcon>
                <IconFa name={iconFa} />
              </StyledTitleIcon>
            }
            {title}
          </div>
          <StyledIcon
            isOpen={this.state.isOpen}
            transitionDuration={transitionDuration}
            className="fa fa-chevron-up"
            aria-hidden="true"
          />
        </StyledHeader>
        <StyledBody
          background={bodyBg}
          isOpen={this.state.isOpen}
          height={this.state.height}
          transitionDuration={transitionDuration}
          onTransitionEnd={this.onTransitionEnd}
        >
          <StyledBodyContent
            paddingContent={paddingContent}
            innerRef={(node) => { this.content = node; }}
          >
            {children}
          </StyledBodyContent>
        </StyledBody>
      </StyledCollapse>
    );
  }
}

Collapse.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  iconFa: PropTypes.string,
  headerBg: PropTypes.string,
  bodyBg: PropTypes.string,
  borderColor: PropTypes.string,
  transitionDuration: PropTypes.string,
  colorText: PropTypes.string,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.string,
  paddingText: PropTypes.string,
  paddingContent: PropTypes.string,
  borderBottomColor: PropTypes.string,
  change: PropTypes.func.isRequired,
};

Collapse.defaultProps = {
  isOpen: false,
  iconFa: null,
  headerBg: '#fff',
  bodyBg: 'white',
  borderColor: null,
  transitionDuration: '0.3s',
  colorText: 'black',
  fontSize: null,
  fontWeight: null,
  paddingText: '1rem',
  paddingContent: '1rem',
  borderBottomColor: '#D6D6D6',
};

const mapsDispatchToProps = dispatch => ({
  change: (id, open) => { dispatch(toogleNavigation(id, open)); },
});

// export default Collapse;
export default connect(
  null,
  mapsDispatchToProps,
)(Collapse);
