import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { H2 } from 'client/ui/Headings';
import TextView from './TextView';

const StyledH2 = styled(H2)`
  color: ${props => props.theme.color.black};
`;

const TextTitle = ({ data, title }) => (
  <div className="animated fadeInDown">
    <StyledH2>{title}</StyledH2>
    <TextView
      data={data}
    />
  </div>
);

TextTitle.propTypes = {
  data: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

TextTitle.defaultProps = {};

export default TextTitle;
