import React from 'react';
import PropTypes from 'prop-types';
import { api } from 'config';
import styled from 'styled-components';
import { H4 } from 'client/ui/Headings';
import Audio from 'client/ui/Audio';
import TextView from './TextView';

const AudioWrapper = styled.div`
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
`;

const AudioView = ({ id, url, data }) => (
  <div>
    <AudioWrapper >
      <Audio id={id} url={`${api.baseUrl}${url}`} />
    </AudioWrapper>
    {data.length > 0
      && (
        <div className="animated fadeIn delay-1s">
          <H4>Transcripción</H4>
          <TextView data={data} />
        </div>
      )}
  </div>
);

AudioView.propTypes = {
  id: PropTypes.string.isRequired,
  url: PropTypes.string,
  data: PropTypes.string.isRequired,
};

AudioView.defaultProps = {
  url: '',
};

export default AudioView;
