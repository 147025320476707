import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Circle from 'client/ui/Circle';

const StyledList = styled.div`
  display: flex;
  align-items: center;
  padding-left: 3rem;
  p:first-child {
    margin-top: 0;
  }
`;

const CircleText = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: white;
  position: absolute;
  top: 6px;
  right: 12px;
  ${props => props.index > 8 && css`
    right: 5px;
  `}
  ${props => props.index === 0 && css`
    right: 14px;
  `}
`;

const StyledCircle = Circle.extend`
  margin-right: 1rem;
  margin-bottom: 1rem;
  position: relative;
`;

const DangerousDiv = styled.div`
  line-height: 23px;
  color: ${props => props.theme.color.black};
`;

const Li = ({ text, type, index, colorMf }) => {
  if (type === 'ul') {
    return (
      <StyledList className="animated bounceInDown slower">
        <StyledCircle size="24px" background={colorMf} />
        <DangerousDiv dangerouslySetInnerHTML={{ __html: text }} />
      </StyledList>
    );
  }
  return (
    <StyledList className="animated bounceInLeft slower">
      <StyledCircle size="37px" background={colorMf}><CircleText index={index}>{index + 1}</CircleText></StyledCircle>
      <DangerousDiv dangerouslySetInnerHTML={{ __html: text }} />
    </StyledList>
  );
};

Li.propTypes = {
  type: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  colorMf: PropTypes.string.isRequired,
};

Li.defaultProps = {};

export default Li;
