import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Editor from 'react-medium-editor';
import inputStyles from '../Input/inputStyles';

/**
 * Fix temporal para `Warning: Unknown props` cuando se estiliza un componente externo
 * @see https://github.com/styled-components/styled-components/issues/305
 *
 * 1. Sobreescribo el valor `inline-block` con `block` dado a un bug
 * que hace que al escribir la primera letra el cursor se coloque al inicio de la línea
 */
const MediumEditor = styled(({ minHeight, ...rest }) => (
  <Editor {...rest} />
))`
  ${inputStyles};
  display: block; /* 1 */

  &.medium-editor-element {
    min-height: ${props => props.minHeight};
  }
  > :first-child {
    margin-top: 0;
  }
  > :last-child {
    margin-bottom: 0;
  }
`;

MediumEditor.propTypes = {
  minHeight: PropTypes.string,
};

MediumEditor.defaultProps = {
  minHeight: '4rem',
};

export default MediumEditor;
