import { css } from 'styled-components';
import { shade } from 'polished';
import { getThemeColor } from '../style-utils';

const getButtonShadow = props => `
  0 0 0 1px ${props.hollow ? shade(0.8, getThemeColor(props.color)) : 'rgba(34, 36, 38, 0.15)'} inset,
  0 -0.3em 0 0px rgba(34, 36, 38, 0.15) inset
`;

const buttonStyles = css`
  ${''/* Botón Base */}
  display: inline-block;
  vertical-align: middle;
  padding: 0.5em 1em;
  font-family: ${props => props.theme.font.openSans};
  font-size: 0.875rem;
  text-align: center;
  text-transform: ${props => props.textTransform};
  background: ${props => getThemeColor(props.background)};
  color: ${props => getThemeColor(props.color)};
  border: 1px solid;
  border-color: ${props => (props.borderColor ? props.borderColor : 'transparent')};
  border-radius: ${props => props.theme.oval};
  cursor: pointer;
  user-select: none;
  outline: 0;
  transition: all 0.2s linear;

  ${props => props.noContent && css`
    background: ${shade(0.5, getThemeColor(props.background))};
  `};

  ${''/* Botón Base activo */}
  ${props => props.active && css`
    background: ${shade(0.8, getThemeColor(props.background))};
  `};
  ${''/* Botón Base estados */}
  &:hover, &:active, &:focus {
    background: ${props => shade(0.8, getThemeColor(props.background))};
  }
  &:disabled {
    background-color: #D7D5D8;
    color: white;
    cursor: not-allowed;
    border-color: #D7D5D8;
  }

  ${''/* Botón Hueco */}
  ${props => props.hollow && css`
    border-color: ${props.borderColor ? getThemeColor(props.borderColor) : getThemeColor(props.color)};
    ${''/* activo */}
    background: ${props.active ? shade(0.9, '#ffffff') : 'transparent'};
    background: ${props.noContent ? shade(0.6, '#ffffff') : 'transparent'};
    ${''/* estados */}
    &:hover, &:active, &:focus {
      background: ${shade(0.9, '#ffffff')};
    }
    &:disabled {
      background-color: #D7D5D8;
      color: white;
      cursor: not-allowed;
      border-color: #D7D5D8;
    }
  `};

  ${''/* Botón Elevado */}
  ${props => props.raised && css`
    padding-bottom: 0.65em;
    border: 0;
    box-shadow: ${getButtonShadow(props)};
  `};

  ${''/* Botón Transparente */}
  ${props => props.transparent && css`
    padding: 0;
    background: transparent;
    border-color: transparent;
    box-shadow: none;
    ${''/* activo */}
    background: ${props.active && 'transparent'};
    ${''/* estados */}
    &:hover, &:active, &:focus {
      background: transparent;
    }
  `};

  ${''/* Alinea los iconos SVG con el texto del botón */}
  svg {
    vertical-align: top;
  }
`;

export default buttonStyles;
