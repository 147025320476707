// import styled from 'styled-components';
// import liStyles from './liStyles';
// const Li = styled.li`
//   ${liStyles};
// `;
// export default Li;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import liStyles from './liStyles';

const StyledLiListStyleNone = styled.li`
  list-style-type: none;
  ${liStyles};
`;
const LiSimple = styled.li`
  ${liStyles};
`;

const Li = ({ listStyleNone, ...rest }) => {
  if (listStyleNone) {
    return <StyledLiListStyleNone {...rest} />;
  }
  return <LiSimple {...rest} />;
};


Li.propTypes = {
  listStyleNone: PropTypes.bool,
};

Li.defaultProps = {
  listStyleNone: null,
};


export default Li;
