import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import IconFa from 'client/ui/IconFa';
import Img from 'client/ui/Img';
import Divider from 'client/ui/Divider';
import MenuCollapse from '../containers/MenuCollapse';

const Nav = styled.div`
  position: relative;
  height: 72px;
  background-color: white;
  display: flex;
  align-items: center;
  color: white;
  font-size: .875rem;
`;

const Hamburger = styled.div`
  cursor: pointer;
  margin-left: 2rem;
  color: black;
  i {
    font-size: 25px;
  }
`;

const MenuCollapseWrap = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  max-width: 280px;
  transform: translateY(100%);
  z-index: 99;
  @media screen and (min-width:0\0) {
    /* Estilos CSS para IE9, IE10, IE11 */
    top:41px;
    left:20px;
  }
`;

const LogoWrapper = styled.div`
  margin-left: 1.5rem;
`;

class Header extends Component {
  constructor() {
    super();
    this.state = { menuIsOpen: false };
    this.toggleNavigationIsClosed = this.toggleNavigationIsClosed.bind(this);
  }

  toggleNavigationIsClosed = () => {
    const { menuIsOpen } = this.state;
    if (menuIsOpen) {
      this.setState({ menuIsOpen: false });
    }
  }

  toggleNavigation = () => {
    const { menuIsOpen } = this.state;
    this.setState({ menuIsOpen: !menuIsOpen });
  }

  render() {
    const { menuIsOpen } = this.state;
    const { location } = this.props;
    return (
      <div>
        <Nav>
          <Hamburger onClick={this.toggleNavigation}>
            <IconFa name="bars" />
          </Hamburger>
          <LogoWrapper>
            <Img src="/images/logos/logo.png" alt="Logo ATLAS" />
          </LogoWrapper>
          <MenuCollapseWrap>
            <MenuCollapse
              isOpen={menuIsOpen}
              onLinkClick={this.toggleNavigation}
              location={location}
            />
          </MenuCollapseWrap>
        </Nav>
        <Divider borderColor="grayLight" />
      </div>
    );
  }
}

Header.propTypes = {
  location: PropTypes.objectOf(PropTypes.any),
};

Header.defaultProps = {
  location: {},
};

export default Header;
