import styled from 'styled-components';
import PropTypes from 'prop-types';
/**
 * Contenedor para organizar en grupos los campos de un formulario
 * Nota: Pensar en cambiar los selectores de `> :last-child` a `Label`
 * cuando añadan los selectores de componente en la versión 2...
 * @see https://github.com/styled-components/styled-components/issues/142
 */

const Fieldset = styled.fieldset`
  margin: 0;
  padding: 0;
  border: 0;
  margin-bottom: ${props => props.marginBottom};

  > :last-child,
  > :last-child :last-child {
    margin-bottom: 0;
  }
`;

Fieldset.propTypes = {
  marginBottom: PropTypes.string,
};

Fieldset.defaultProps = {
  marginBottom: '1.5rem',
};

export default Fieldset;
