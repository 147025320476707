import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Img from 'client/ui/Img';
import Label from 'client/ui/Label';
import Input from 'client/ui/Input';
import Span from 'client/ui/Span';
import Button from 'client/ui/Button';
import ButtonGroup from 'client/ui/ButtonGroup';

const Wrapper = styled.div`
  margin-top: 4.37rem;
  max-width: 485px;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
`;

const LogoWrapper = styled.div`
  max-width: 153px;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
`;

const LoginWrapper = styled.div`
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
`;

const LoginForm = styled.div`
  background-color: white;
  padding: 1.5rem 1rem;
  margin: .5rem 0;
  border-radius: ${props => props.theme.radius};
  border: 1px solid ${props => props.theme.color.gray2};
  box-shadow: 0 0 9px 0 rgba(0,0,0,0.5);
`;

const LoginSpan = styled.span`
  font-size: 1.625rem;
  font-weight: 600;
`;

const StyledP = styled.p`
  color: red;
`;

const ResetButton = styled(Button)`
  font-weight: bold;
`;

const Login = ({ onSubmit, errorMessage }) => (
  <Wrapper>
    <LogoWrapper>
      <Img src="images/logos/logo-vertical.png" alt="Logo ATLAS" />
    </LogoWrapper>
    <LoginWrapper>
      <LoginSpan>Login</LoginSpan>
      <LoginForm>
        {/* Error */}
        {errorMessage && <StyledP>{errorMessage}</StyledP>}
        <form
          onSubmit={(event) => {
            event.preventDefault();
            onSubmit(event.target.username.value, event.target.password.value);
          }}
        >
          <Label>
            <Span weight="700">E-mail</Span>
            <Input
              type="text"
              name="username"
              required
            />
          </Label>
          <Label>
            <Span weight="700">Contraseña</Span>
            <Input
              type="password"
              name="password"
              required
            />
          </Label>
          <ButtonGroup align="right">
            <Button type="submit">Enviar</Button>
          </ButtonGroup>
        </form>
      </LoginForm>
      <ResetButton transparent color="red" to="/forgottenPassword">¿Ha olvidado su clave de acceso?</ResetButton>
    </LoginWrapper>
  </Wrapper>
);

Login.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  // history: PropTypes.objectOf(PropTypes.any).isRequired,
};

Login.defaultProps = {
  errorMessage: null,
};

export default Login;
