import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from '../components/Header';

const HeaderContainer = ({ location }) => (
  <Header
    location={location}
  />
);

HeaderContainer.propTypes = {
  location: PropTypes.objectOf(PropTypes.any),
};

HeaderContainer.defaultProps = {
  location: {},
};

const mapStateToProps = state => ({
  location: state.router.location,
});

export default connect(
  mapStateToProps,
  null,
)(HeaderContainer);
