import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import IconFa from 'client/ui/IconFa';
import ButtonFa from 'client/ui/ButtonFa';
import SubMenu from './SubMenu';

/**
 * 1. Hace que el alto sea el mismo que el del contenedor y no mayor.
 *    Comentar la propiedad si no se desea este comportamiento.
 */
const StyledNavigation = styled.nav`
  position: fixed;
  top: 0;
  width: ${props => props.width};
  min-height: 100%;
  background-color: ${props => props.theme.color.white};
  border-right: 1px solid ${props => props.theme.color.gray};
  z-index: 150;
  transform: translateX(${props => (props.isOpen ? '0' : '-100%')});
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  height: 100vh; ${''/* 1 */}
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 1rem 0;
  text-align: center;
`;

const CloseButton = styled(IconFa)`
  margin-right: 1rem;
  cursor: pointer;
`;

/**
 * 1. Hace que aparezca scroll cuando el contenido sea mayor al tamaño del contenedor.
 *    Comentar la propiedad si no se desea este comportamiento.
 */
const StyledBody = styled.div`
  flex: 1;
  overflow: auto; ${''/* 1 */}
  border-top: 1px solid #D6D6D6;
`;

const StyledContentButton = styled.div`
  padding: .7rem;
  padding-left: 1rem;
  width: 100%;
  border-bottom: 1px solid #D6D6D6;
  cursor: pointer;
  &:hover {
    background: #EEEEEE;
  }
`;

const ContentButton = ({ onClick, icon, name }) => (
  <StyledContentButton onClick={onClick}>
    <ButtonFa
      textTransform="none"
      transparent
      color="black"
      fa={icon}
    >
      {name}
    </ButtonFa>
  </StyledContentButton>
);

ContentButton.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.string,
};

ContentButton.defaultProps = {
  icon: '',
};

class Menu extends Component {
  constructor() {
    super();
    this.state = { subMenuVisible: false, contentType: '' };
    this.closeSubmenu = this.closeSubmenu.bind(this);
    this.addNewContent = this.addNewContent.bind(this);
    this.handleShowSubMenu = this.handleShowSubMenu.bind(this);
    this.closeSmartNavigation = this.closeSmartNavigation.bind(this);
  }

  /**
   * Oculta la navegación dependiendo del ancho de la ventana
   * Además situa al usuario al inicio de la vista, notable en dispositivos móviles o menús largos
   */
  closeSmartNavigation() {
    const { stickyAt, toggleNavigation } = this.props;
    this.setState({ subMenuVisible: false });
    // window.scrollTo(0, 0);
    if (!stickyAt || window.innerWidth < stickyAt) {
      toggleNavigation();
    }
  }

  addNewContent(type) {
    const { addContent } = this.props;
    this.closeSmartNavigation();
    addContent(type);
  }

  handleShowSubMenu(type) {
    this.setState({ subMenuVisible: true, contentType: type });
  }

  closeSubmenu() {
    this.setState({ subMenuVisible: false });
  }

  render() {
    const { subMenuVisible, contentType } = this.state;
    const { isOpen, width } = this.props;
    return (
      <div>
        <SubMenu
          isOpen={subMenuVisible}
          type={contentType}
          addContent={this.addNewContent}
          close={this.closeSubmenu}
        />
        <StyledNavigation isOpen={isOpen} width={width}>
          <StyledHeader>
            <CloseButton name="times" onClick={this.closeSmartNavigation} />
          </StyledHeader>
          <StyledBody>
            <ContentButton
              name="Texto"
              icon="fa-align-left"
              onClick={() => this.handleShowSubMenu('text')}
            />
            <ContentButton
              name="Listado"
              icon="fa-list-ul"
              onClick={() => this.handleShowSubMenu('list')}
            />
            <ContentButton
              name="Imagen"
              icon="fa-image"
              onClick={() => this.handleShowSubMenu('picture')}
            />
            <ContentButton
              name="Galería"
              icon="fa-th-large"
              onClick={() => this.handleShowSubMenu('gallery')}
            />
            <ContentButton
              name="Multimedia"
              icon="fa-play"
              onClick={() => this.handleShowSubMenu('multimedia')}
            />
            <ContentButton
              name="Popup"
              icon="fa-window-maximize"
              onClick={() => this.handleShowSubMenu('popup')}
            />
            <ContentButton
              name="Interactivo"
              icon="fa-spinner"
              onClick={() => this.handleShowSubMenu('interactive')}
            />
            <ContentButton
              name="Preguntas asimilación"
              icon="fa-check"
              onClick={() => this.handleShowSubMenu('assimilation')}
            />
          </StyledBody>
        </StyledNavigation>
      </div>
    );
  }
}

Menu.propTypes = {
  toggleNavigation: PropTypes.func.isRequired,
  addContent: PropTypes.func.isRequired,
  stickyAt: PropTypes.number,
  isOpen: PropTypes.bool.isRequired,
  width: PropTypes.string,
};

Menu.defaultProps = {
  stickyAt: 0,
  width: '14rem',
};

export {
  Menu,
  ContentButton,
};
