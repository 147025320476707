import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactModal from 'react-modal';
import Button from 'client/ui/Button';
import ButtonGroup from 'client/ui/ButtonGroup';
import IconFa from 'client/ui/IconFa';
import { media } from '../style-utils';

// Estiliza el Overlay del Modal
// 1. Hace que el Overlay quede encima del resto de elementos (navigation...)
const customStyles = {
  overlay: {
    zIndex: 99, /* 1 */
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
};

// Estiliza el Contenido del modal
const StyledModal = styled(ReactModal)`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  min-width: 300px;
  padding: 24px;
  padding-top: .5rem;
  background: ${props => props.theme.color.white};
  border: 1px solid ${props => props.theme.color.gray};
  border-radius: 4px;
  outline: none;
  transform: translate(-50%, -50%);

  ${media.sm`width: auto`};
`;

const StyledFaIcon = styled.div`
  margin-bottom: 0.5rem;
  font-size: 2rem;
  text-align: center;
  color: ${props => props.theme.color.grayBlueDark};
`;

const StyledTitle = styled.div`
  margin-bottom: 1rem;
  margin-top: .5rem;
  font-size: 1.93rem;
  font-weight: bold;
  text-align: left;
  font-family: ${props => props.theme.font.montserrat};
  color: ${props => props.theme.color.modalTitle};
`;

const StyledContent = styled.div`
  margin-bottom: 1.5rem;
  color: ${props => props.theme.color.black};
`;

const StyledCloseIcon = styled.div`
  margin-right: 0.5rem;
  margin-top: 0.75rem;
  font-size: 1.2rem;
  color: ${props => props.theme.color.gray2};
  position: absolute;
  top: 6px;
  right: 6px;
`;

const Modal = ({ isOpen, iconFa, title, content, onCancel, onConfirm, confirmText, ...rest }) => (
  <StyledModal isOpen={isOpen} contentLabel={title} style={customStyles} {...rest}>
    {iconFa
      && <StyledFaIcon><IconFa name={iconFa} /></StyledFaIcon>
    }
    <StyledTitle>{title}</StyledTitle>
    <StyledCloseIcon onClick={onCancel}><IconFa name="times-circle" /></StyledCloseIcon>

    {content && <StyledContent>{content}</StyledContent>}
    <ButtonGroup align="right">
      <Button
        hollow
        borderColor="black"
        color="black"
        onClick={onConfirm}
      >
        {confirmText}
      </Button>
      <Button
        color="white"
        onClick={onCancel}
      >
        Cancelar
      </Button>
    </ButtonGroup>
  </StyledModal>
);

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  iconFa: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.node,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func.isRequired,
  confirmText: PropTypes.string,
};

Modal.defaultProps = {
  iconFa: null,
  content: null,
  confirmText: 'Acepto',
  onCancel: null,
  title: '',
};

export default Modal;
