import { CALL_API } from 'redux-api-middleware';
import { api } from 'config';

import {
  CENTRE_SUCCESS,
  CENTRE_ERROR,
  CENTRES_REQUEST,
  CENTRES_SUCCESS,
  CENTRES_ERROR,
  CENTRE_CLEAR_NOTIFICATION,
} from '../constants/actionTypes';

function handleStateCentre(type, message) {
  return {
    type,
    message,
  };
}

function clearNotification() {
  return {
    type: CENTRE_CLEAR_NOTIFICATION,
  };
}

const fetchCentrePromise = id => new Promise((resolve, reject) => {
  const token = localStorage.getItem('token') || null;
  const reqConfig = {
    method: 'GET',
    headers: Object.assign({}, {
      Authorization: `Bearer ${token}`,
    }),
  };
  fetch(`${api.baseUrl}/api/centres/${id}`, reqConfig)
    .then((response) => {
      if (response.status >= 400) {
        return reject(response.statusText);
      }
      return resolve(response.json());
    });
});

async function fetchCentre(id) {
  try {
    const centreData = await fetchCentrePromise(id);
    return centreData;
  } catch (error) {
    return error;
  }
}

function fetchCentres() {
  const token = localStorage.getItem('token') || null;
  return {
    [CALL_API]: {
      types: [CENTRES_REQUEST, CENTRES_SUCCESS, CENTRES_ERROR],
      endpoint: `${api.baseUrl}/api/centres/`,
      method: 'GET',
      headers: Object.assign({}, {
        Authorization: `Bearer ${token}`,
      }),
    },
  };
}

const fetchAllCentresPromise = () => new Promise((resolve, reject) => {
  const token = localStorage.getItem('token') || null;
  const reqConfig = {
    method: 'GET',
    headers: Object.assign({}, {
      Authorization: `Bearer ${token}`,
    }),
  };
  fetch(`${api.baseUrl}/api/centres`, reqConfig)
    .then((response) => {
      if (response.status >= 400) {
        return reject(response.statusText);
      }
      return resolve(response.json());
    });
});

async function fetchAllCentres() {
  try {
    const centresData = await fetchAllCentresPromise();
    return centresData;
  } catch (error) {
    return error;
  }
}

function saveCentre(data) {
  const token = localStorage.getItem('token') || null;
  const reqConfig = {
    method: 'POST',
    headers: Object.assign({}, {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
    body: JSON.stringify(data),
  };
  return (dispatch) => {
    fetch(`${api.baseUrl}/api/centres/`, reqConfig)
      .then((response) => {
        if (response.status >= 400) {
          dispatch(handleStateCentre(CENTRE_ERROR, response.statusText));
        } else {
          dispatch(handleStateCentre(CENTRE_SUCCESS, 'Guardado correctamente'));
        }
      })
      .catch(error => console.log(`Error: ${error}`));
  };
}

function updateCentre(data, id) {
  const token = localStorage.getItem('token') || null;
  const reqConfig = {
    method: 'PUT',
    headers: Object.assign({}, {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
    body: JSON.stringify(data),
  };

  return (dispatch) => {
    fetch(`${api.baseUrl}/api/centres/${id}`, reqConfig)
      .then((response) => {
        if (response.status >= 400) {
          dispatch(handleStateCentre(CENTRE_ERROR, response.statusText));
        } else {
          dispatch(handleStateCentre(CENTRE_SUCCESS, 'Actualizado correctamente'));
        }
      })
      .catch(error => console.log(`Error: ${error}`));
  };
}

function save(data) {
  return (dispatch) => {
    if (!data) {
      return dispatch(handleStateCentre(CENTRE_ERROR, 'Debes completar el formulario'));
    }

    if (data.id) {
      const { id } = data;
      delete data.id;
      dispatch(updateCentre(data, id));
    } else {
      dispatch(saveCentre(data));
    }
  };
}

function deleteCentre(id) {
  const token = localStorage.getItem('token') || null;
  const reqConfig = {
    method: 'DELETE',
    headers: Object.assign({}, {
      Authorization: `Bearer ${token}`,
    }),
  };

  return (dispatch) => {
    fetch(`${api.baseUrl}/api/centres/${id}`, reqConfig)
      .then((response) => {
        if (response.status >= 400) {
          dispatch(handleStateCentre(CENTRE_ERROR, response.statusText));
        } else {
          dispatch(handleStateCentre(CENTRE_SUCCESS, 'Eliminado correctamente'));
        }
      })
      .catch(error => console.log(`Error: ${error}`));
  };
}

export {
  clearNotification,
  fetchCentre,
  fetchCentres,
  fetchAllCentres,
  save,
  deleteCentre,
};
