/* eslint no-underscore-dangle: 0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Label from 'client/ui/Label';
import Input from 'client/ui/Input';
import Select from 'client/ui/Select';
import Notification from 'client/ui/Notification';
import ModalConfirm from 'client/ui/Modal';
import Button from 'client/ui/Button';
import Span from 'client/ui/Span';
import ButtonGroup from 'client/ui/ButtonGroup';
import ContentWrapper from 'client/ui/ContentWrapper';
import { H2 } from 'client/ui/Headings';
import Alert from 'react-s-alert';

const Wrapper = styled.div`
  margin-bottom: 1rem;
  margin-top: 1rem;
  &.middle {
    margin-bottom: .5rem;
    margin-top: .5rem;
  }
`;

const Selectors = styled(Label)`
  margin-bottom: 1rem;
`;

class Form extends Component {
  constructor() {
    super();
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      repeatPassword: '',
      currentRoles: [],
      modalIsOpen: false,
      currentCentres: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.afterNotification = this.afterNotification.bind(this);
    this.handleModalAction = this.handleModalAction.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { userToUpdate } = nextProps;
    if (userToUpdate && userToUpdate.id) {
      const { id, firstName, lastName, currentRoles, email, currentCentres } = userToUpdate;
      this.setState({
        id,
        firstName,
        lastName,
        currentRoles,
        currentCentres,
        email,
      });
    }
  }

  afterNotification() {
    const { notification, clearMessages, history } = this.props;
    clearMessages();
    if (notification.type === 'success') {
      setTimeout(() => {
        history.replace('/dashboard/users');
      }, 1000);
    }
  }

  handleInputChange(event) {
    const { target } = event;
    const { name, value } = target;
    this.setState({ [name]: value });
  }

  handleSelectChange(name, value) {
    this.setState({ [name]: value });
  }

  showModal() {
    this.setState({ modalIsOpen: true });
  }

  hideModal() {
    this.setState({ modalIsOpen: false });
  }

  handleModalAction() {
    const { history } = this.props;
    history.replace('/dashboard/users');
    this.hideModal();
  }

  handleSubmit(event) {
    event.preventDefault();
    const { save, createMode, roles } = this.props;
    const { id, firstName, lastName, email, password, repeatPassword, currentRoles,
      currentCentres } = this.state;

    if (password !== repeatPassword) {
      const notificationConfig = { position: 'top-right', effect: 'slide', timeout: 3000 };
      Alert.error('Las contraseñas no coinciden', notificationConfig);
      return null;
    }

    const userRoles = currentRoles.map(rol => rol.value);

    const data = {
      id,
      firstName,
      lastName,
      email,
      roles: userRoles,
    };

    if (currentCentres && currentCentres.length > 0) {
      const adminCenterId = roles.find(r => r.rid === 'ADMINISTRADORCENTRO');
      const isAdminCenter = currentRoles.find(rol => rol.value === adminCenterId._id);

      if (isAdminCenter) {
        const centres = currentCentres.map(center => center.value);
        Object.assign(data, {
          centres,
        });
      }
    }

    if (createMode) {
      Object.assign(data, {
        password,
      });
    }
    save(data);
  }

  render() {
    const { firstName, lastName, email, password, repeatPassword, currentRoles,
      modalIsOpen, currentCentres } = this.state;
    const { notification, createMode, roles, centres } = this.props;
    const adminCenterId = roles.find(r => r.rid === 'ADMINISTRADORCENTRO');
    const parsedRoles = roles.map(role => ({ value: role._id, label: role.label }));
    const parsedCentres = centres.map(centre => ({ value: centre._id, label: centre.name }));
    return (
      <ContentWrapper>
        <H2>{createMode ? 'Creación de usuario' : 'Edición de usuario'}</H2>
        <Notification
          message={notification}
          afterNotification={this.afterNotification}
        />
        <form onSubmit={this.handleSubmit}>
          <Label>
            <Span spanForm>Nombre</Span>
            <Input
              type="text"
              name="firstName"
              value={firstName}
              onChange={this.handleInputChange}
              required
              maxLength="100"
            />
          </Label>
          <Label>
            <Span spanForm>Apellidos</Span>
            <Input
              type="text"
              name="lastName"
              value={lastName}
              onChange={this.handleInputChange}
              required
              maxLength="100"
            />
          </Label>
          <Label>
            <Span spanForm>Email</Span>
            <Input
              type="email"
              name="email"
              value={email}
              onChange={this.handleInputChange}
              required
              maxLength="100"
            />
          </Label>
          {createMode
          && (
            <div>
              <Label>
                <Span spanForm>Contraseña</Span>
                <Input
                  type="password"
                  name="password"
                  value={password}
                  onChange={this.handleInputChange}
                  isRequired
                  maxLength="100"
                />
              </Label>
              <Label>
                <Span spanForm>Repetir contraseña</Span>
                <Input
                  type="password"
                  name="repeatPassword"
                  value={repeatPassword}
                  onChange={this.handleInputChange}
                  isRequired
                  maxLength="100"
                />
              </Label>
            </div>
          )}
          <Selectors>
            <span>Roles</span>
            <Select
              isMulti
              isSearchable
              name="currentRoles"
              options={parsedRoles}
              value={currentRoles}
              onChange={(value) => { this.handleSelectChange('currentRoles', value); }}
              valueKey="value"
              labelKey="label"
              placeholder="Seleccionar"
              noResultsText="No se ha encontrado coincidencias"
              required
            />
          </Selectors>
          {currentRoles.find(rol => rol.value === adminCenterId._id)
            && (
            <Selectors>
              <span>Centros</span>
              <Select
                isMulti
                isSearchable
                name="currentCentres"
                options={parsedCentres}
                value={currentCentres}
                onChange={(value) => { this.handleSelectChange('currentCentres', value); }}
                valueKey="value"
                labelKey="label"
                placeholder="Seleccionar"
                noResultsText="No se ha encontrado coincidencias"
                required
              />
            </Selectors>)}

          <Wrapper>
            <ButtonGroup align="right">
              <Button
                onClick={() => this.showModal()}
                background="#6F7C7C"
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                background="grayBlueDark"
              >
                Guardar
              </Button>
            </ButtonGroup>
          </Wrapper>
        </form>

        <ModalConfirm
          isOpen={modalIsOpen}
          title="Cancelar"
          content="¿Desea cancelar, se perderán todos los datos no guardados?"
          onCancel={this.hideModal}
          onConfirm={this.handleModalAction}
        />
      </ContentWrapper>
    );
  }
}

Form.propTypes = {
  notification: PropTypes.objectOf(PropTypes.any).isRequired,
  userToUpdate: PropTypes.objectOf(PropTypes.any),
  clearMessages: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  createMode: PropTypes.bool.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  roles: PropTypes.arrayOf(PropTypes.object).isRequired,
  centres: PropTypes.arrayOf(PropTypes.object).isRequired,
};

Form.defaultProps = {
  userToUpdate: {},
};

export default Form;
