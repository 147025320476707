import { css } from 'styled-components';

const headingStyles = css`
  ${props => props.center && css`
    text-align: center;
  `}
  ${props => props.marginTop && css`
    margin-top: 2rem;
  `}

  ${props => props.marginTop3rem && css`
    margin-top: 3rem;
  `}

  ${props => props.marginBottom1rem && css`
    margin-bottom: 1rem;
  `}

`;

export default headingStyles;
