import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { api } from 'config';
import { fetchUf } from 'client/modules/dashboard-ufs/actions/ufs';
import Wrapper from '../components/Wrapper';

class WrapperContainer extends Component {
  constructor() {
    super();
    this.state = {
      ufToView: {},
      tab: '#generalData',
      mfId: null,
    };
    this.loadData = this.loadData.bind(this);
  }

  componentWillMount() {
    const { match, history } = this.props;
    if (match.params.id) {
      const { id } = match.params;
      this.loadData(id);
      localStorage.setItem('ufId', id);
    }
    if (history.location && history.location.hash) {
      this.setState({ tab: history.location.hash });
    }
    const mfId = localStorage.getItem('mfId');
    if (mfId) {
      this.setState({ mfId });
    }
  }

  loadData(ufId) {
    fetchUf(ufId).then((ufData) => {
      const { _id, coverPicture, introduction, title, subtitles, objectives,
        bibliographicReferences, mf } = ufData;
      const centreLogo = `${api.baseUrl}${mf.centre.logo.url.l}`;
      const ufToView = ufData;
      const haveSubtitles = subtitles && subtitles.length ? subtitles : [''];
      const haveObjectives = objectives && objectives.length ? objectives : [''];
      let picture = null;
      let references = [];
      if (!bibliographicReferences || bibliographicReferences.length === 0) {
        references.push({ title: '', text: '' });
      } else {
        references = bibliographicReferences;
      }
      if (coverPicture) {
        picture = {
          id: coverPicture._id,
          src: `${api.baseUrl}${coverPicture.url.s}`,
        };
      }

      const proration = [];
      if (ufData && ufData.proration) {
        ufData.proration.forEach((pro) => {
          const currentUa = ufData.uas.find(ua => ua._id === pro._id);
          if (currentUa) {
            Object.assign(pro, { title: currentUa.title });
            proration.push(pro);
          }
        });
      }

      Object.assign(ufToView,
        {
          id: _id,
          title: title || '',
          subtitles: haveSubtitles,
          introduction: introduction || '',
          objectives: haveObjectives,
          coverPicture: picture,
          bibliographicReferences: references,
          centreLogo,
          proration,
        });
      this.setState({ ufToView });
    });
  }

  render() {
    const { history, match } = this.props;
    const { ufToView, tab, mfId } = this.state;
    return (
      <Wrapper
        ufToView={ufToView}
        history={history}
        match={match}
        mfId={mfId}
        tab={tab}
      />
    );
  }
}

WrapperContainer.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

WrapperContainer.defaultProps = {};


export default WrapperContainer;
