/* eslint import/prefer-default-export: 0, no-undef: 0 */

import { api } from 'config';

function uploadPicturePromise({ picture, description, name }) {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem('token') || null;
    const document = new FormData();
    document.append('name', name);
    document.append('description', description);
    document.append('picture', picture);
    const reqConfig = {
      method: 'POST',
      headers: Object.assign({}, {
        Authorization: `Bearer ${token}`,
      }),
      body: document,
    };
    fetch(`${api.baseUrl}/api/media/mfs/cover`, reqConfig)
      .then((response) => {
        if (response.status >= 400) {
          return reject(response.statusText);
        }
        return resolve(response.json());
      });
  });
}

async function uploadPicture(picture) {
  try {
    const fileUploaded = await uploadPicturePromise(picture);
    return fileUploaded;
  } catch (error) {
    return error;
  }
}

export default uploadPicture;
