import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { fetchUa } from 'client/modules/dashboard-uas/actions/uas';
import { fetchModule } from 'client/modules/dashboard-mfs/actions/mfs';
import { fetchPage } from 'client/modules/dashboard-pages/actions/pages';
import { fetchCentre } from 'client/modules/dashboard-centres/actions/centres';
import Wrapper from '../components/Wrapper';

class WrapperContainer extends Component {
  constructor() {
    super();
    this.state = {
      uaToView: {},
      tab: '#unidad',
      mfId: null,
      ufId: null,
    };
    this.loadData = this.loadData.bind(this);
    this.loadCentre = this.loadCentre.bind(this);
  }

  componentWillMount() {
    const { match, history } = this.props;
    if (match.params.id) {
      const { id } = match.params;
      this.loadData(id);
    }
    if (history.location && history.location.hash) {
      this.setState({ tab: history.location.hash });
    }
  }

  loadData(uaId) {
    fetchUa(uaId).then((uaData) => {
      const uaToView = uaData;
      const { _id, mf, uf } = uaData;
      Object.assign(uaToView,
        {
          id: _id,
        });

      if (mf) {
        this.setState({ uaToView, mfId: mf._id });
        this.loadCentre(uaData.mf.centre);
      }

      if (uf) {
        this.setState({ uaToView, ufId: uf._id });
        fetchModule(uaData.uf.mf).then((moduleData) => {
          this.loadCentre(moduleData.centre._id);
        });
      }

      if (uaData.proration && uaData.proration.length > 0) {
        const testIds = uaData.proration.map(t => t._id);
        Promise.all(testIds.map(id => fetchPage(id))).then((result) => {
          const proration = [];
          uaData.proration.forEach((test) => {
            const currentTest = result.find(t => t._id === test._id);
            if (currentTest) {
              Object.assign(test, { title: currentTest.title });
              proration.push(test);
            }
          });
          uaToView.proration = proration;
          this.setState({ uaToView });
        });
      }
    });
  }

  loadCentre(centreId) {
    fetchCentre(centreId).then((centreData) => {
      const { uaToView } = this.state;
      const { colorMf, colorUf, colorUa } = centreData;
      Object.assign(uaToView, {
        colorMf,
        colorUf,
        colorUa,
      });
      this.setState({ uaToView });
    });
  }

  render() {
    const { history, match } = this.props;
    const { uaToView, tab, mfId, ufId } = this.state;
    if (!uaToView.id || !uaToView.colorMf) {
      return null;
    }
    return (
      <Wrapper
        uaToView={uaToView}
        history={history}
        match={match}
        mfId={mfId}
        ufId={ufId}
        tab={tab}
      />
    );
  }
}

WrapperContainer.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

WrapperContainer.defaultProps = {};

export default WrapperContainer;
