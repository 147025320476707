import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { savePage, fetchTopicPages, deletePage } from '../actions/pages';
import List from '../components/ListPages';

class PageList extends Component {
  constructor() {
    super();
    this.state = {
      pages: [],
      modalIsOpen: false,
      PageId: '',
    };
    this.loadPages = this.loadPages.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.showModal = this.showModal.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.savePageItem = this.savePageItem.bind(this);
    this.handleAddPage = this.handleAddPage.bind(this);
  }

  componentWillMount() {
    const { topicId } = this.props;
    this.loadPages(topicId);
  }

  componentWillReceiveProps(nextProps) {
    const { newPageId } = nextProps;
    const { topicId } = this.props;
    this.loadPages(topicId, newPageId);
  }

  onConfirm() {
    const { PageId, pages } = this.state;
    const res = deletePage(PageId);
    res.then((response) => {
      if (response) {
        const index = pages.findIndex(page => page._id === response._id);
        if (index > -1) {
          const newPages = pages.filter(page => page._id !== response._id);
          this.setState({ pages: newPages });
        }
      }
    });
    this.hideModal();
  }

  handleAddPage(data) {
    const { topicId } = this.props;
    const { pages } = this.state;
    Object.assign(data, { topic: topicId });
    const res = savePage(data);
    res.then((response) => {
      const current = pages;
      current.pop();
      current.push(response);
      this.setState({ pages: current });
    });
  }

  savePageItem(data) {
    const { topicId } = this.props;
    Object.assign(data, { topic: topicId });
    const { pages } = this.state;
    const res = savePage(data);
    res.then((response) => {
      const index = pages.findIndex(page => page._id === response._id);
      if (index > -1) {
        pages[index].title = response.title;
        this.setState({ pages });
      }
    });
  }

  loadPages(topicId, newPageId) {
    fetchTopicPages(topicId).then((pagesData) => {
      if (newPageId) {
        const { pages } = this.state;
        const currenPages = pages;
        const newElement = pagesData.filter(page => page._id === newPageId);
        const data = {
          _id: newElement[0]._id,
          contents: newElement[0].contents,
          title: newElement[0].title,
          topic: newElement[0].topic,
        };
        currenPages.push(data);
        this.setState({ pages: currenPages });
      } else {
        this.setState({ pages: pagesData });
      }
    });
  }

  showModal(page) {
    if (page) {
      this.setState({ modalIsOpen: true, PageId: page });
    }
  }

  hideModal() {
    this.setState({ modalIsOpen: false });
  }

  render() {
    const { topicId, modeEdit, colorMf } = this.props;
    const { pages, modalIsOpen } = this.state;
    return (
      <List
        modalIsOpen={modalIsOpen}
        showModal={this.showModal}
        hideModal={this.hideModal}
        onConfirm={this.onConfirm}
        pages={pages}
        save={this.savePageItem}
        addPage={this.handleAddPage}
        topicId={topicId}
        modeEdit={modeEdit}
        colorMf={colorMf}
      />
    );
  }
}

PageList.propTypes = {
  topicId: PropTypes.string.isRequired,
  newPageId: PropTypes.string,
  modeEdit: PropTypes.bool.isRequired,
  colorMf: PropTypes.string,
};

PageList.defaultProps = {
  newPageId: null,
  colorMf: '',
};

export default PageList;
