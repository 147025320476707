import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { api } from 'config';
import Img from 'client/ui/Img';

const DangerousDiv = styled.div`
  line-height: 23px;
  color: ${props => props.theme.color.black};
`;

const StyledImagePreview = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 400px;
  width: 100%;
  img {
    display: block;
    margin: auto;
    width: auto;
    max-width: 100%;
  }
`;

const Div = styled.div`
  padding: 1rem 1.5rem;
`;

const CollapseItem = ({ text, picture }) => (
  <Div>
    { picture && (
      <StyledImagePreview>
        <Img src={`${api.baseUrl}${picture}`} alt="imagen" />
      </StyledImagePreview>
    )}
    <DangerousDiv className="animated slideInDown" dangerouslySetInnerHTML={{ __html: text }} />
  </Div>
);

CollapseItem.propTypes = {
  text: PropTypes.string.isRequired,
  picture: PropTypes.string,
};

CollapseItem.defaultProps = {
  picture: '',
};

export default CollapseItem;
