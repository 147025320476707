import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from 'client/ui/Button';
import Label from 'client/ui/Label';
import Input from 'client/ui/Input';
import ContentText from './ContentText';
import Menu from './ConfigurationMenu';

const StyledButton = styled(Button)`
  margin-bottom: 1rem;
`;

const PreviewWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding-left: 7rem;
  padding-right: 7rem;
`;

const LinkTextWrapper = styled.div`
  width: 100%;
`;

const LinkButton = styled(Button)`
  width: 8.81rem;
  margin-left: 1rem;
`;

const UrlWrapper = styled.div`
  margin-top: 0.5rem;
`;

class Link extends Component {
  constructor() {
    super();
    this.state = {
      configurationVisible: false,
    };
    this.handleDisplayMenu = this.handleDisplayMenu.bind(this);
    this.close = this.close.bind(this);
  }

  handleDisplayMenu() {
    const { configurationVisible } = this.state;
    this.setState({ configurationVisible: !configurationVisible });
  }

  close() {
    this.setState({ configurationVisible: false });
  }

  render() {
    const { data, onChange, id, changeUrl, url } = this.props;
    const { configurationVisible } = this.state;
    return (
      <div>
        <Menu
          title="Editar enlace"
          width="25rem"
          isOpen={configurationVisible}
          close={this.close}
        >
          <div>
            <UrlWrapper>
              <Label>Url de destino</Label>
              <Input
                type="text"
                value={url}
                onChange={(value) => { changeUrl(id, value); }}
                small
              />
            </UrlWrapper>
            <Label>Texto del enlace</Label>
            <ContentText
              id={id}
              data={data}
              onChange={onChange}
              mediumType="mediumEditorBasic"
              placeHolder="Añadir titulo"
              noMarginBottom
            />
          </div>
        </Menu>
        <StyledButton
          hollow
          color="black"
          onClick={() => this.handleDisplayMenu()}
        >
          Editar
        </StyledButton>
        <PreviewWrapper>
          <LinkTextWrapper>
            {/* <Label>Descripción del enlace</Label> */}
            <ContentText
              id={id}
              data={data}
              onChange={onChange}
              mediumType="mediumEditorBasic"
              noMarginBottom
              placeHolder="Añadir titulo"
            />
          </LinkTextWrapper>
          <LinkButton
            onClick={() => this.handleDisplayMenu()}
          >
            Enlace
          </LinkButton>
        </PreviewWrapper>
      </div>
    );
  }
}

Link.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  changeUrl: PropTypes.func.isRequired,
};

Link.defaultProps = {};

export default Link;
