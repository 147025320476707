import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Table from 'client/ui/Table';
import { H2 } from 'client/ui/Headings';
import Td from 'client/ui/Td';
import Tr from 'client/ui/Tr';
import Th from 'client/ui/Th';

const TableWrapper = styled.div`
  width: 100%;
  max-width: 677px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;

  tr {
    :nth-child(even) {
      background-color: #F1F0F0;
    }
    th, td {
      padding: .75rem;
      vertical-align: middle;
      &:first-child {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        width: 75%;
      }
      &:last-child {
        padding-right: 1.5rem;
        width: 25%;
        text-align: center;
      }
    }
  }
`;

const StyleTable = styled(Table)`
  margin-bottom: 1rem;
  border-radius: ${props => props.theme.radius};
  overflow: hidden;
`;

const StyledTbody = styled.tbody`
  padding: 1rem;
`;

const StyledMessage = styled(H2)`
  margin-top: 8rem;
  text-align: center;
`;

const ViewProration = ({ proration, color }) => {
  if (proration.length === 0) {
    return (<StyledMessage>No existen pruebas aún</StyledMessage>);
  }
  return (
    <TableWrapper>
      <StyleTable onlyBorderMainTr whiteBG>
        <thead>
          <Tr paddingTopBotton1rem>
            <Th color={color} whiteText>Nombre</Th>
            <Th color={color} whiteText>Ponderación</Th>
          </Tr>
        </thead>
        <StyledTbody>
          {proration.map(pro => (
            <Tr paddingTopBotton key={pro._id}>
              <Td>{pro.title}</Td>
              <Td>{pro.proration}</Td>
            </Tr>
          ))}
        </StyledTbody>
      </StyleTable>
    </TableWrapper>
  );
};

ViewProration.propTypes = {
  proration: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  color: PropTypes.string.isRequired,
};

ViewProration.defaultProps = {
  proration: [],
};

export default ViewProration;
