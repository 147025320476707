import styled from 'styled-components';
import { media } from 'client/ui/style-utils';
import headingStyles from './headingStyles';

const H1 = styled.h1`
  margin-top: 0;
  font-size: 2rem;
  line-height: 1.1;
  font-weight: 700;
  color: ${props => props.theme.color.modalTitle};
  ${headingStyles};
`;
H1.displayName = 'H1';

const H2 = H1.withComponent('h2').extend`
  font-size: 1.375rem;
  ${media.sm`
    font-size: 1.625rem;
  `}
`;
H2.displayName = 'H2';

const H3 = H1.withComponent('h3').extend`
  font-size: 1.5rem;
`;
H3.displayName = 'H3';

const H4 = H1.withComponent('h4').extend`
  font-size: 1.125rem;
`;
H4.displayName = 'H4';

const H5 = H1.withComponent('h5').extend`
  font-size: 1rem;
`;
H5.displayName = 'H5';


export {
  H1,
  H2,
  H3,
  H4,
  H5,
};
