import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { redirectTo } from 'index';
import theme from 'client/config/theme';
import RandomId from 'short-unique-id';
import Alert from 'react-s-alert';
import { arrayMove } from 'react-sortable-hoc';
import ModalConfirm from 'client/ui/Modal';
import styled, { css } from 'styled-components';
import Divider from 'client/ui/Divider';
import { H2 } from 'client/ui/Headings';
import SectionCircle from 'client/ui/SectionCircle';
import Circle from 'client/ui/Circle';
import CodeName from 'client/ui/CodeName';
import Button from 'client/ui/Button';
import ButtonGroup from 'client/ui/ButtonGroup';
import IconFa from 'client/ui/IconFa';
import { Tabs, TabsPane, Tab } from 'client/ui/Tabs';
import ContentWrapper from 'client/ui/ContentWrapper';
import EvaluationMenu from './EvaluationMenu';
import ContentWrapperOptions from './ContentWrapperOptions';
import ContentSimpleQuestion from './ContentSimpleQuestion';
import ContentMultipleQuestion from './ContentMultipleQuestion';
import ClozeSelect from './ClozeSelect';
import Cloze from './Cloze';
import EvaluationConfiguration from './EvaluationConfiguration';
import MixedQuestions from './MixedQuestions';

const notificationConfig = { position: 'top-right', effect: 'slide', timeout: 3000 };

const CircleText = styled.div`
  font-size: 21px;
  font-weight: bold;
  color: white;
  position: absolute;
  top: 10px;
  right: 14px;
`;

const Header = styled.div`
  margin-bottom: .5rem;
`;

const SectionTitle = styled.div`
  text-align: center;
  background-color: ${props => props.theme.color.redEvaluation};
  color: white;
  padding: .5rem;
  h2 {
    margin-bottom: 0;
  }
`;

const Section = ContentWrapper.extend`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;
  justify-content: space-between;
`;

const StyledH2 = styled(H2)`
  color: ${props => props.theme.color.white};
`;

const StyledSeparation = styled.div`
  width: 100%;
  min-width: 0;
  margin-right: 1rem;
`;

const MfName = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: .25rem;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 0;
`;

const DividerWrapper = styled.div`
  position: relative;
`;

const MfsDivider = Divider.extend`
  position: absolute;
  top: 0;
  left: 0;
`;

const UfsDivider = MfsDivider.extend`
  left: 25%;
`;

const UasDivider = MfsDivider.extend`
  left: 50%;
  &.mf {
    left: 33.3333%;
  }
`;

const ContentDivider = MfsDivider.extend`
  left: 75%;
  &.mf {
    left: 66.6666%;
  }
`;

const NoContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AddButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;

  hr {
    position: absolute;
    left: 0;
    top: 26px;
    border: 0;
    border-top: dotted 1px;
  }
`;

const CircleButton = styled(Circle)`
  position: relative;
  z-index: 5;
  cursor: pointer;
  i {
    font-size: 2rem;
    color:white;
    position: absolute;
    top: 10px;
    left: 11px;
  }
  &:hover {
    background-color: #374850;
  }
`;

const InfoText = styled.div`
  margin-bottom: 1rem;
  text-align: center;
`;

const StyledReturn = styled.span`
  margin-right: .7rem;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
`;

const PrevFa = styled(IconFa)`
  margin-right: .5rem;
  ${props => props.color && css`
    color: ${props.theme.color[props.color]};
  `}
  ${props => props.doubleMargin && css`
    margin-right: .8rem;
  `}
`;

const StyledButtonGroup = styled(ButtonGroup)`
  margin: 2rem 0;
`;

const FixedSpace = styled.div`
  margin-top: 0.75rem;
`;

const ButtonsWrapper = styled.div`
  min-width: 245px;
  width: 245px;
`;

const PrevLink = data => (
  <StyledReturn onClick={() => redirectTo(`/dashboard/uas/edit/${data.uaId}`)}>
    <PrevFa name="chevron-left" doubleMargin />
    <PrevFa name="circle" color="greenUa" />
    <span>UA</span>
  </StyledReturn>
);

const CloseButton = styled(Button)`
  margin-right: 0.5rem;
`;

const ConfigurationButtonWrapper = styled.div`
  position: absolute;
  width: 100%;
`;

const ConfigurationButton = styled.div`
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  z-index: 10;
  display: flex;
  justify-content: flex-end;
  button.desktop {
    display: none;
  }
  @media(min-width: 560px) {
    button.desktop {
      z-index: 10;
      display: block;
    }
    div {
      display: none;
    }
  }
`;

const ConfigIcon = styled.div`
  font-size: 1.75rem;
  z-index: 10;
`;

class EvaluationWrapper extends Component {
  constructor() {
    super();
    this.state = {
      menuVisible: false,
      configurationVisible: false,
      contents: [],
      requiredPoints: 80,
      attempts: 1,
      showFeedback: false,
      messQuestions: false,
      messAnswers: false,
      forceClozeChange: false,
    };
    this.addQuestionToMixedContent = this.addQuestionToMixedContent.bind(this);
    this.toggleConfigurationVisibility = this.toggleConfigurationVisibility.bind(this);
    this.onConfigurationNumberChange = this.onConfigurationNumberChange.bind(this);
    this.handleSaveAllContents = this.handleSaveAllContents.bind(this);
    this.onClozeQuestionChange = this.onClozeQuestionChange.bind(this);
    this.sortQuestionOption = this.sortQuestionOption.bind(this);
    this.optionMultipleCheckChange = this.optionMultipleCheckChange.bind(this);
    this.handleDisplayMenu = this.handleDisplayMenu.bind(this);
    this.addSimpleQuestion = this.addSimpleQuestion.bind(this);
    this.addMultipleQuestion = this.addMultipleQuestion.bind(this);
    this.optionCheckChange = this.optionCheckChange.bind(this);
    this.onQuestionChange = this.onQuestionChange.bind(this);
    this.optionTextChange = this.optionTextChange.bind(this);
    this.wrapperContents = this.wrapperContents.bind(this);
    this.changeToPreview = this.changeToPreview.bind(this);
    this.renderAddButton = this.renderAddButton.bind(this);
    this.addClozeSelect = this.addClozeSelect.bind(this);
    this.renderContent = this.renderContent.bind(this);
    this.toggleSwitch = this.toggleSwitch.bind(this);
    this.removeAnswer = this.removeAnswer.bind(this);
    this.addContent = this.addContent.bind(this);
    this.addAnswer = this.addAnswer.bind(this);
    this.addCloze = this.addCloze.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  componentWillMount() {
    const { contents, config } = this.props;
    const { requiredPoints, attempts, showFeedback, messQuestions, messAnswers } = config;
    this.setState({ contents, requiredPoints, attempts, showFeedback, messQuestions, messAnswers });
  }

  componentWillReceiveProps(nextProps) {
    const { contents, config } = nextProps;
    const { requiredPoints, attempts, showFeedback, messQuestions, messAnswers } = config;
    this.setState({ contents, requiredPoints, attempts, showFeedback, messQuestions, messAnswers });
  }

  onClose() {
    const { uaId } = this.props;
    this.handleSaveAllContents(`/dashboard/uas/edit/${uaId}`);
  }

  onQuestionChange(contentId, questionId, event) {
    const { contents } = this.state;
    const { value } = event.target;
    const index = contents.findIndex(content => content.id === contentId);
    if (index > -1) {
      const currentQuestionIndex = contents[index].items
        .findIndex(question => question.id === questionId);
      if (currentQuestionIndex > -1) {
        contents[index].items[currentQuestionIndex].question = value;
        this.setState({ contents });
      }
    }
  }

  onConfigurationNumberChange(name, event) {
    const { target } = event;
    let { value } = target;
    value = parseInt(value, 10);
    this.setState({ [name]: value });
  }

  onClozeQuestionChange(contentId, questionId, value) {
    const { contents } = this.state;
    let forceClozeChange = false;
    const index = contents.findIndex(content => content.id === contentId);
    if (index > -1) {
      const currentQuestionIndex = contents[index].items
        .findIndex(question => question.id === questionId);
      if (currentQuestionIndex > -1) {
        const currentQuestion = contents[index].items[currentQuestionIndex];
        const count = (value.match(/<b>/g) || []).length;
        let parseString = value;
        let correctAnswer = '';

        let correctAnswerIndex = value.indexOf('<b>') + 3;
        let correctAnswerLastIndex = value.indexOf('</b>');

        if (correctAnswerIndex > 2) {
          correctAnswer = value.substring(correctAnswerIndex, correctAnswerLastIndex);
        }

        if (count > 1) {
          const firstIndex = value.indexOf('<b>');
          const currentIndex = currentQuestion.question.indexOf('<b>');
          forceClozeChange = true;
          if (currentIndex > firstIndex) {
            // Si currentIndex es mayor la respuesta correcta sigue siendo la primera
            const inicio = value.lastIndexOf('<b>');
            const fin = value.lastIndexOf('</b>');
            correctAnswer = value.substring(correctAnswerIndex, correctAnswerLastIndex);
            parseString = value.substring(0, inicio) + value.substring(inicio + 3, fin)
              + value.substring(fin + 4, value.length);
          } else {
            const inicio = value.indexOf('<b>');
            const fin = value.indexOf('</b>');
            correctAnswerIndex = value.lastIndexOf('<b>') + 3;
            correctAnswerLastIndex = value.lastIndexOf('</b>');
            correctAnswer = value.substring(correctAnswerIndex, correctAnswerLastIndex);
            parseString = value.substring(0, inicio) + value.substring(inicio + 3, fin)
              + value.substring(fin + 4, value.length);
          }
        }

        correctAnswer = correctAnswer.trim();
        contents[index].items[currentQuestionIndex].question = parseString;
        if (currentQuestion.type === 'clozeSelect') {
          const defaultIndex = currentQuestion.options.findIndex(op => op.default === true);
          contents[index].items[currentQuestionIndex].options[defaultIndex].text = correctAnswer;
        } else {
          contents[index].items[currentQuestionIndex].options[0].text = correctAnswer;
        }
        this.setState({ contents, forceClozeChange });
      }
    }
  }

  toggleConfigurationVisibility() {
    const { configurationVisible } = this.state;
    this.setState({ configurationVisible: !configurationVisible });
  }

  toggleSwitch(name) {
    const value = this.state[name];
    this.setState({ [name]: !value });
  }

  sortQuestionOption(contentId, questionId, oldIndex, newIndex) {
    const { contents } = this.state;
    const index = contents.findIndex(content => content.id === contentId);
    if (index > -1) {
      const contentIndex = contents[index].items.findIndex(content => content.id === questionId);
      if (contentIndex > -1) {
        const currentOrder = contents[index].items[contentIndex].options;
        const newOrder = arrayMove(currentOrder, oldIndex, newIndex);
        contents[index].items[contentIndex].options = newOrder;
        this.setState({ contents });
      }
    }
  }

  handleDisplayMenu() {
    const { menuVisible } = this.state;
    this.setState({ menuVisible: !menuVisible });
  }

  optionCheckChange(contentId, questionId, optionId) {
    const { contents } = this.state;
    const index = contents.findIndex(content => content.id === contentId);
    if (index > -1) {
      const contentIndex = contents[index].items.findIndex(content => content.id === questionId);
      if (contentIndex > -1) {
        const currentIndex = contents[index].items[contentIndex].options
          .findIndex(opt => opt.id === optionId);
        if (currentIndex > -1) {
          contents[index].items[contentIndex].options.forEach((option, curr) => {
            contents[index].items[contentIndex].options[curr].correctAnswer = false;
          });
          contents[index].items[contentIndex].options[currentIndex].correctAnswer = true;
          this.setState({ contents });
        }
      }
    }
  }

  optionMultipleCheckChange(contentId, questionId, optionId) {
    const { contents } = this.state;
    const index = contents.findIndex(content => content.id === contentId);
    if (index > -1) {
      const contentIndex = contents[index].items.findIndex(content => content.id === questionId);
      if (contentIndex > -1) {
        const currentIndex = contents[index].items[contentIndex].options
          .findIndex(opt => opt.id === optionId);
        if (currentIndex > -1) {
          const checked = contents[index].items[contentIndex].options[currentIndex].correctAnswer;
          contents[index].items[contentIndex].options[currentIndex].correctAnswer = !checked;
          this.setState({ contents });
        }
      }
    }
  }

  optionTextChange(contentId, questionId, optionId, event) {
    const { contents } = this.state;
    const { value } = event.target;
    const index = contents.findIndex(content => content.id === contentId);
    if (index > -1) {
      const contentIndex = contents[index].items.findIndex(content => content.id === questionId);
      if (contentIndex > -1) {
        const currentIndex = contents[index].items[contentIndex].options
          .findIndex(opt => opt.id === optionId);
        if (currentIndex > -1) {
          contents[index].items[contentIndex].options[currentIndex].text = value;
          this.setState({ contents });
        }
      }
    }
  }

  addContent(type) {
    this.handleDisplayMenu();
    const { pageId, contents, save } = this.props;
    const uid = new RandomId();

    const content = {
      page: pageId,
      type,
    };
    switch (type) {
      case 'simple':
      case 'multiple':
        Object.assign(content, {
          items: [{ id: uid.randomUUID(12),
            question: '',
            type,
            options: [{ id: uid.randomUUID(12), text: '', correctAnswer: false },
              { id: uid.randomUUID(12), text: '', correctAnswer: false }] }],
        });
        break;
      case 'clozeSelect':
        Object.assign(content, {
          items: [{ id: uid.randomUUID(12),
            question: '',
            type,
            options: [{ id: uid.randomUUID(12), text: '', correctAnswer: true, default: true },
              { id: uid.randomUUID(12), text: '', correctAnswer: false }] }],
        });
        break;
      case 'cloze':
        Object.assign(content, {
          items: [{ id: uid.randomUUID(12),
            question: '',
            type,
            options: [{ id: uid.randomUUID(12), text: '' },
              { id: uid.randomUUID(12), text: '' }] }],
        });
        break;
      default:
        break;
    }

    const newContent = save(content);
    newContent.then((contentData) => {
      const currents = contents;
      const parsedNewContent = contentData;
      parsedNewContent.id = contentData._id;
      parsedNewContent.page = contentData.page._id;
      delete parsedNewContent.updatedAt;
      delete parsedNewContent.deletedAt;
      delete parsedNewContent.createdAt;
      delete parsedNewContent._id;
      currents.push(contentData);
      this.setState({ contents: currents });
    });
  }

  changeToPreview() {
    const { match } = this.props;
    const { id } = match.params;
    this.handleSaveAllContents(`/viewContent/${id}`);
  }

  addSimpleQuestion(contentId) {
    const { contents } = this.state;
    const uid = new RandomId();
    const index = contents.findIndex(content => content.id === contentId);
    if (index > -1) {
      contents[index].items.push({ id: uid.randomUUID(12),
        question: '',
        type: 'simple',
        options: [{ id: uid.randomUUID(12), text: '', correctAnswer: false },
          { id: uid.randomUUID(12), text: '', correctAnswer: false }] });
      this.setState({ contents });
    }
  }

  addMultipleQuestion(contentId) {
    const { contents } = this.state;
    const uid = new RandomId();
    const index = contents.findIndex(content => content.id === contentId);
    if (index > -1) {
      contents[index].items.push({ id: uid.randomUUID(12),
        question: '',
        type: 'multiple',
        options: [{ id: uid.randomUUID(12), text: '', correctAnswer: false },
          { id: uid.randomUUID(12), text: '', correctAnswer: false }] });
      this.setState({ contents });
    }
  }

  addAnswer(contentId, questionId) {
    const { contents } = this.state;
    const uid = new RandomId();
    const index = contents.findIndex(content => content.id === contentId);
    if (index > -1) {
      const currentQuestionIndex = contents[index].items
        .findIndex(question => question.id === questionId);
      if (currentQuestionIndex > -1) {
        contents[index].items[currentQuestionIndex].options.push({ id: uid.randomUUID(12), text: '', correctAnswer: false });
        this.setState({ contents });
      }
    }
  }

  addClozeSelect(contentId) {
    const { contents } = this.state;
    const uid = new RandomId();
    const index = contents.findIndex(content => content.id === contentId);
    if (index > -1) {
      contents[index].items.push({ id: uid.randomUUID(12),
        question: '',
        type: 'clozeSelect',
        options: [{ id: uid.randomUUID(12), text: '', correctAnswer: true, default: true },
          { id: uid.randomUUID(12), text: '', correctAnswer: false }] });
      this.setState({ contents });
    }
  }

  addCloze(contentId) {
    const { contents } = this.state;
    const uid = new RandomId();
    const index = contents.findIndex(content => content.id === contentId);
    if (index > -1) {
      contents[index].items.push({ id: uid.randomUUID(12),
        question: '',
        type: 'cloze',
        options: [{ id: uid.randomUUID(12), text: '' },
          { id: uid.randomUUID(12), text: '' }] });
      this.setState({ contents });
    }
  }

  addQuestionToMixedContent(contentId, type) {
    const { contents } = this.state;
    const index = contents.findIndex(content => content.id === contentId);
    const question = '';
    if (index > -1) {
      const uid = new RandomId();
      if (type === 'simple' || type === 'multiple') {
        contents[index].items.push({ id: uid.randomUUID(12),
          question,
          type,
          options: [{ id: uid.randomUUID(12), text: '', correctAnswer: false },
            { id: uid.randomUUID(12), text: '', correctAnswer: false }] });
      } else if (type === 'cloze') {
        contents[index].items.push({ id: uid.randomUUID(12),
          question,
          type,
          options: [{ id: uid.randomUUID(12), text: '' },
            { id: uid.randomUUID(12), text: '' }] });
      } else if (type === 'clozeSelect') {
        contents[index].items.push({ id: uid.randomUUID(12),
          question,
          type,
          options: [{ id: uid.randomUUID(12), text: '', correctAnswer: true, default: true },
            { id: uid.randomUUID(12), text: '', correctAnswer: false }] });
      }
      this.setState({ contents });
    }
  }

  handleSaveAllContents(callback) {
    const { save, history, savePage, pageId } = this.props;
    const { contents, requiredPoints, attempts, showFeedback, messQuestions,
      messAnswers } = this.state;

    Promise.all(contents.map((content) => {
      const parsedContent = {
        id: content.id,
        page: content.page,
        props: content.props,
        type: content.type,
      };

      if (content.type === 'simple' || content.type === 'multiple' || content.type === 'clozeSelect'
        || content.type === 'cloze' || content.type === 'mixedQuestions') {
        Object.assign(parsedContent, { items: content.items });
      }

      return save(parsedContent).then(response => response);
    })).then(() => {
      if (callback) {
        history.push(callback);
      } else {
        Alert.success('Guardado correctamente', notificationConfig);
      }
    });
    const pageData = {
      id: pageId,
      config: {
        requiredPoints,
        attempts,
        showFeedback,
        messQuestions,
        messAnswers,
      },
    };
    savePage(pageData);
  }

  removeAnswer(contentId, questionId, optionId) {
    const { contents } = this.state;
    const index = contents.findIndex(content => content.id === contentId);
    if (index > -1) {
      const currentQuestionIndex = contents[index].items
        .findIndex(question => question.id === questionId);
      if (currentQuestionIndex > -1) {
        const currentIndex = contents[index].items[currentQuestionIndex].options
          .findIndex(opt => opt.id === optionId);
        if (currentIndex > -1) {
          const { options } = contents[index].items[currentQuestionIndex];
          options.splice(currentIndex, 1);
          this.setState({ contents });
        }
      }
    }
  }

  wrapperContents() {
    const { contents, moveContent, removeContent } = this.props;
    return (
      <div>
        {contents.map(content => (
          <ContentWrapperOptions
            key={`Content_Wrapper_${content.id}`}
            id={content.id}
            remove={removeContent}
            moveContent={moveContent}
            noMove
          >
            {this.renderContent(content)}
          </ContentWrapperOptions>
        ))}
      </div>
    );
  }

  renderContent(content) {
    const { removeItem, moveItem,
      saveQuestionPicture } = this.props;
    const { forceClozeChange } = this.state;
    switch (content.type) {
      case 'simple':
        return (
          <ContentSimpleQuestion
            key={`Content_${content.id}`}
            contentId={content.id}
            items={content.items}
            addAnswer={this.addAnswer}
            addSimpleQuestion={this.addSimpleQuestion}
            savePicture={saveQuestionPicture}
            onQuestionChange={this.onQuestionChange}
            optionCheckChange={this.optionCheckChange}
            optionTextChange={this.optionTextChange}
            removeAnswer={this.removeAnswer}
            removeQuestionItem={removeItem}
            sortQuestionOption={this.sortQuestionOption}
            moveQuestionItem={moveItem}
          />
        );
      case 'multiple':
        return (
          <ContentMultipleQuestion
            key={`Content_${content.id}`}
            contentId={content.id}
            items={content.items}
            addAnswer={this.addAnswer}
            addMultipleQuestion={this.addMultipleQuestion}
            savePicture={saveQuestionPicture}
            onQuestionChange={this.onQuestionChange}
            optionMultipleCheckChange={this.optionMultipleCheckChange}
            optionTextChange={this.optionTextChange}
            removeAnswer={this.removeAnswer}
            removeQuestionItem={removeItem}
            sortQuestionOption={this.sortQuestionOption}
            moveQuestionItem={moveItem}
          />
        );
      case 'clozeSelect':
        return (
          <ClozeSelect
            key={`Content_${content.id}`}
            contentId={content.id}
            items={content.items}
            addAnswer={this.addAnswer}
            addClozeSelect={this.addClozeSelect}
            onClozeQuestionChange={this.onClozeQuestionChange}
            optionCheckChange={this.optionCheckChange}
            optionTextChange={this.optionTextChange}
            removeAnswer={this.removeAnswer}
            removeQuestionItem={removeItem}
            sortQuestionOption={this.sortQuestionOption}
            moveQuestionItem={moveItem}
            forceClozeChange={forceClozeChange}
          />
        );
      case 'cloze':
        return (
          <Cloze
            key={`Content_${content.id}`}
            contentId={content.id}
            items={content.items}
            addAnswer={this.addAnswer}
            addCloze={this.addCloze}
            onClozeQuestionChange={this.onClozeQuestionChange}
            optionTextChange={this.optionTextChange}
            removeAnswer={this.removeAnswer}
            removeQuestionItem={removeItem}
            sortQuestionOption={this.sortQuestionOption}
            moveQuestionItem={moveItem}
            forceClozeChange={forceClozeChange}
          />
        );
      case 'mixedQuestions':
        return (
          <MixedQuestions
            key={`Content_${content.id}`}
            contentId={content.id}
            items={content.items}
            addQuestion={this.addQuestionToMixedContent}
            addAnswer={this.addAnswer}
            removeQuestionItem={removeItem}
            moveQuestionItem={moveItem}
            savePicture={saveQuestionPicture}
            onQuestionChange={this.onQuestionChange}
            onClozeQuestionChange={this.onClozeQuestionChange}
            optionCheckChange={this.optionCheckChange}
            optionTextChange={this.optionTextChange}
            removeAnswer={this.removeAnswer}
            sortQuestionOption={this.sortQuestionOption}
            optionMultipleCheckChange={this.optionMultipleCheckChange}
            forceClozeChange={forceClozeChange}
          />
        );
      default:
        return null;
    }
  }

  renderAddButton() {
    return (
      <AddButtonWrapper>
        <CircleButton size="50px" background="addContentColor" onClick={() => this.handleDisplayMenu()}>
          <IconFa name="plus" color="white" />
        </CircleButton>
        <Divider borderWidth="1px" borderColor="gray2" />
      </AddButtonWrapper>
    );
  }

  render() {
    const { tab, title, uaId, haveUf, modalIsOpen, hideModal, onConfirm } = this.props;
    const { contents, menuVisible, configurationVisible, requiredPoints, attempts, showFeedback,
      messQuestions, messAnswers } = this.state;
    return (
      <div>
        {haveUf
          ? (
            <DividerWrapper>
              <Divider borderWidth="2px" borderColor="gray2" />
              <MfsDivider width="25%" borderWidth="2px" borderColor="blueMfs" />
              <UfsDivider width="25%" borderWidth="2px" borderColor="orangeUf" />
              <UasDivider width="25%" borderWidth="2px" borderColor="greenUa" />
              <ContentDivider width="25%" borderWidth="2px" borderColor="redEvaluation" />
            </DividerWrapper>)
          : (
            <DividerWrapper>
              <Divider borderWidth="2px" borderColor="gray2" />
              <MfsDivider width="33.3333%" borderWidth="2px" borderColor="blueMfs" />
              <UasDivider className="mf" width="33.3333%" borderWidth="2px" borderColor="greenUa" />
              <ContentDivider className="mf" width="33.3333%" borderWidth="2px" borderColor="redEvaluation" />
            </DividerWrapper>
          )
      }
        <EvaluationMenu
          isOpen={menuVisible}
          toggleNavigation={this.handleDisplayMenu}
          showSubMenu={this.handleDisplaySubMenu}
          addContent={this.addContent}
          close={this.handleDisplayMenu}
        />
        <Header>
          <Section>
            <TitleWrapper>
              <MfName>
                <SectionCircle size="42px" background="redEvaluation">
                  <CircleText>E</CircleText>
                </SectionCircle>

              </MfName>
              <StyledSeparation>
                <CodeName>
                  { title !== '' && <div>{title}</div> }
                </CodeName>
              </StyledSeparation>
            </TitleWrapper>
            <ButtonsWrapper>
              <CloseButton
                onClick={() => this.onClose()}
              >
                Cerrar
              </CloseButton>
              <Button
                hollow
                color="black"
                borderColor="black"
                onClick={() => this.changeToPreview()}
              >
                Previsualizar
              </Button>
            </ButtonsWrapper>
          </Section>
          <SectionTitle><StyledH2>CREACIÓN DE ACTIVIDADES DE EVALUACIÓN</StyledH2></SectionTitle>
        </Header>
        <ConfigurationButtonWrapper>
          <ConfigurationButton>
            <Button
              hollow
              color="black"
              borderColor="black"
              className="desktop"
              onClick={() => this.toggleConfigurationVisibility()}
            >
              {configurationVisible ? 'cerrar' : 'Configuración'}
            </Button>
            <ConfigIcon>
              {configurationVisible
                ? (
                  <Button
                    hollow
                    color="black"
                    borderColor="black"
                    onClick={() => this.toggleConfigurationVisibility()}
                  >
                    cerrar
                  </Button>)
                : (
                  <IconFa
                    name="cog"
                    onClick={() => this.toggleConfigurationVisibility()}
                  />
                )
              }
            </ConfigIcon>
          </ConfigurationButton>
        </ConfigurationButtonWrapper>
        <FixedSpace>
          <Tabs
            tabsBarBorderColor="gray2"
            selected={tab}
            component={Tab}
            componentProps={{ color: 'greyContent' }}
            tabsTitleMaxWidth={theme.maxWidth}
          >
            <TabsPane name="#ua" label="UA" component={PrevLink} componentProps={{ uaId }}>
              <div />
            </TabsPane>
            <TabsPane
              name="#evaluacion"
              label="Evaluación"
            >
              {configurationVisible
                ? (
                  <EvaluationConfiguration
                    toggleSwitch={this.toggleSwitch}
                    numberChange={this.onConfigurationNumberChange}
                    requiredPoints={requiredPoints}
                    messQuestions={messQuestions}
                    showFeedback={showFeedback}
                    messAnswers={messAnswers}
                    attempts={attempts}
                  />)
                : (
                  <ContentWrapper>
                    <Alert stack={{ limit: 1 }} />
                    {contents.length === 0 && (
                      <NoContent>
                        <InfoText>
                          Pulse para añadir un tipo de test
                        </InfoText>
                        {this.renderAddButton()}
                      </NoContent>
                    )}
                    {contents.length > 0 && this.wrapperContents()}
                    {contents.length > 0 && (
                      <StyledButtonGroup align="right">
                        <Button onClick={() => this.handleSaveAllContents()}>Guardar</Button>
                      </StyledButtonGroup>
                    )}
                  </ContentWrapper>
                )}
            </TabsPane>
          </Tabs>
        </FixedSpace>
        <ModalConfirm
          isOpen={modalIsOpen}
          title="Eliminar"
          content="¿Desea eliminar el contenido?"
          onCancel={hideModal}
          onConfirm={onConfirm}
        />
      </div>
    );
  }
}

EvaluationWrapper.propTypes = {
  saveQuestionPicture: PropTypes.func.isRequired,
  removeContent: PropTypes.func.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  moveContent: PropTypes.func.isRequired,
  removeItem: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  moveItem: PropTypes.func.isRequired,
  contents: PropTypes.arrayOf(PropTypes.any),
  savePage: PropTypes.func.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  pageId: PropTypes.string.isRequired,
  config: PropTypes.objectOf(PropTypes.any).isRequired,
  haveUf: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  save: PropTypes.func.isRequired,
  uaId: PropTypes.string.isRequired,
  tab: PropTypes.string.isRequired,
};

EvaluationWrapper.defaultProps = {
  contents: [],
};

export default EvaluationWrapper;
