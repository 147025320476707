import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { api } from 'config';
import { H3 } from 'client/ui/Headings';
import { Carousel } from 'react-responsive-carousel';
import Img from 'client/ui/Img';

const StyledCarrousel = styled.div`
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;

  img {
    width: 100%;
  }

  .carousel .slide {
    background: none;
  }
  .carousel .slider-wrapper {
      width: 90%;
  }

  .carousel .control-next.control-arrow:before {
    border-left: 11px solid ${props => props.color};
    border-top: 11px solid transparent;
    border-bottom: 11px solid transparent;
  }

  .carousel .control-next.control-arrow {
    right: -10px;
  }

  .carousel .control-prev.control-arrow {
    left: -10px;
  }

  .carousel .control-prev.control-arrow:before {
    border-right: 11px solid ${props => props.color};
    border-top: 11px solid transparent;
    border-bottom: 11px solid transparent;
  }

  @media(min-width: 768px) {
    .carousel .control-next.control-arrow:before {
      border-left: 20px solid ${props => props.color};
      border-top: 20px solid transparent;
      border-bottom: 20px solid transparent;
    }

    .carousel .control-prev.control-arrow:before {
      border-right: 20px solid ${props => props.color};
      border-top: 20px solid transparent;
      border-bottom: 20px solid transparent;
    }
  }

  @media(min-width: 768px) {
    .carousel .control-next.control-arrow:before {
      border-left: 33px solid ${props => props.color};
      border-top: 33px solid transparent;
      border-bottom: 33px solid transparent;
    }

    .carousel .control-prev.control-arrow:before {
      border-right: 33px solid ${props => props.color};
      border-top: 33px solid transparent;
      border-bottom: 33px solid transparent;
    }
  }

  .carousel .control-arrow, .carousel.carousel-slider .control-arrow {
    opacity: .7;
  }

  .carousel .control-arrow, .carousel.carousel-slider .control-arrow:hover {
    opacity: 1;
  }

  .carousel.carousel-slider .control-arrow:hover{
    background: none;
  }

  .carousel .control-dots {
    padding: 0;
    .dot {
      background: #B3B3B3;
      &.selected {
        background: ${props => props.color};
      }
    }
  }
`;

const TextWrapper = styled.div`
  margin-top: 1rem;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  line-height: 23px;
  color: ${props => props.theme.color.black};
`;

class Carrousel extends Component {
  constructor() {
    super();
    this.state = { pictureNumber: 0 };
    this.onChange = this.onChange.bind(this);
  }

  onChange(number) {
    this.setState({ pictureNumber: number });
  }

  render() {
    const { elements, color } = this.props;
    const { pictureNumber } = this.state;
    if (elements.length === 0) {
      return (<H3>No hay imágenes en la galería</H3>);
    }
    return (
      <StyledCarrousel color={color}>
        <Carousel
          showThumbs={false}
          showStatus={false}
          showIndicators
          dynamicHeight
          infiniteLoop
          onChange={(number) => { this.onChange(number); }}
          selectedItem={pictureNumber}
        >
          {elements.map((element, index) => {
            if (element && element.url && element.url.length > 0) {
              return (
                <div key={`${element.url}-${index}`}>
                  <img src={`${api.baseUrl}${element.url}`} alt={element.alt} />
                </div>
              );
            }
            return <Img key={`gallery${index}`} src="/images/contents/picture.jpg" alt="imagen" />;
          })}
        </Carousel>
        {elements.length > 0
          && <TextWrapper dangerouslySetInnerHTML={{ __html: elements[pictureNumber].text }} />
        }
      </StyledCarrousel>
    );
  }
}

Carrousel.propTypes = {
  elements: PropTypes.arrayOf(PropTypes.object).isRequired,
  color: PropTypes.string,
};

Carrousel.defaultProps = {
  color: '#90A4AE',
};

export default Carrousel;
