import React from 'react';
import PropTypes from 'prop-types';
import App from './App';
import MainContent from './MainContent';
import Header from '../containers/Header';

const Layout = ({ children, noMaxWidth, noMargins, noPaddings }) => (
  <App>
    <Header />
    <MainContent
      noMaxWidth={noMaxWidth}
      noMargins={noMargins}
      noPaddings={noPaddings}
    >
      {children}
    </MainContent>
  </App>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  noMaxWidth: PropTypes.bool.isRequired,
  noMargins: PropTypes.bool.isRequired,
  noPaddings: PropTypes.bool.isRequired,
};

Layout.defaultProps = {};

export default Layout;
