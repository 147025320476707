import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Datatables from 'client/ui/Datatables';
import { H1, H2 } from 'client/ui/Headings';
import Button from 'client/ui/Button';
import Notification from 'client/ui/Notification';
import ModalConfirm from 'client/ui/Modal';
import ContentWrapper from 'client/ui/ContentWrapper';
import SectionTitle from 'client/modules/dashboard/components/SectionTitle';

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
`;

const StyledH1 = H1.extend`
  margin-bottom: 0;
`;

const NoResult = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
`;


const Users = ({ users, notification, clearMessages, modalIsOpen,
  hideModal, onConfirm, showModal, notFound, userPermissions }) => {
  const buttons = [];
  let canWrite = false;
  Object.keys(userPermissions).forEach((per) => {
    switch (per) {
      case 'WRITE':
        canWrite = true;
        break;
      case 'UPDATE':
        buttons.push({
          type: 'link',
          icon: 'fa-pencil-alt',
          classButton: 'basic',
          routeName: '/dashboard/users/edit/',
        });
        break;
      case 'DELETE':
        buttons.push({
          type: 'button',
          icon: 'fa-trash-alt',
          classButton: 'red',
          action: showModal,
          actionName: 'delete-user',
        });
        break;
      default: break;
    }
  });

  return (
    <div>
      <SectionTitle />
      <ContentWrapper>
        <StyledHeader>
          <StyledH1>Usuarios</StyledH1>
          {canWrite
            && <Button to="/dashboard/users/add">Nuevo usuario</Button>
          }
        </StyledHeader>
        {/* Notificaciones */}
        <Notification
          message={notification}
          afterNotification={clearMessages}
        />
        {users.length > 0
          ? (
            <div>
              <Datatables
                id="newsTable"
                headers={[
                  // { name: 'Id', noSort: true },
                  { name: 'Nombre' },
                  { name: 'Apellidos' },
                  { name: 'Email' },
                  { name: 'Roles' },
                ]}
                rows={users}
                buttons={buttons}
                // config={{
                //   dom: 'Bfrtip',
                //   buttons: [
                //     {
                //       extend: 'excelHtml5',
                //       title: 'Lista de usuarios',
                //       className: '',
                //       exportOptions: { columns: ':visible' },
                //     },
                //     {
                //       extend: 'colvis',
                //       className: '',
                //       collectionLayout: 'fixed',
                //     },
                //   ],
                // }}
              />
            </div>)
          : (
            <NoResult>
              {notFound
                ? <H2>No hay usuarios aún</H2>
                : <H2>Cargando usuarios</H2>
              }
            </NoResult>
          )
        }

        <ModalConfirm
          isOpen={modalIsOpen}
          title="Eliminar"
          content="¿Desea eliminar el usuario?"
          onCancel={hideModal}
          onConfirm={onConfirm}
        />
      </ContentWrapper>
    </div>
  );
};

Users.propTypes = {
  users: PropTypes.arrayOf(PropTypes.array).isRequired,
  clearMessages: PropTypes.func.isRequired,
  notification: PropTypes.objectOf(PropTypes.any).isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  notFound: PropTypes.bool.isRequired,
  userPermissions: PropTypes.objectOf(PropTypes.any).isRequired,
};

Users.defaultProps = {};

export default Users;
