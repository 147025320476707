
const canDo = (permissions, module, accessAction) => {
  const moduleFound = permissions.find(permission => permission.module === module);
  if (moduleFound && moduleFound.actions[accessAction]) {
    return true;
  }
  return false;
};

export default canDo;
