import styled from 'styled-components';
import PropTypes from 'prop-types';
import { getThemeColor } from '../style-utils';

const Circle = styled.div`
    display: inline-block;
    border-radius: 50%;
    background: ${props => getThemeColor(props.background)};
    width: ${props => props.size};
    height: ${props => props.size};
    padding:${props => props.padding};
    vertical-align: middle;
`;

Circle.propTypes = {
  background: PropTypes.string,
  size: PropTypes.string,
  padding: PropTypes.string,
};

Circle.defaultProps = {
  background: 'primary',
  size: '1rem',
  padding: '0.5rem',
};

export default Circle;
