import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import ButtonFa from 'client/ui/ButtonFa';
import ButtonGroup from 'client/ui/ButtonGroup';

const StyledButtonFa = styled(ButtonFa)`
  display: inline-block;
  cursor: pointer;
  padding: 0.65em 0.60em;
  font-size: 1rem;
  background-color: transparent;
`;

const StyledButtonGroup = styled(ButtonGroup)`
  display: flex;
  justify-content: flex-end;
`;

const ContentWrapper = styled.div`
  ${props => !props.noBottomProps && css`
    padding-bottom: 2rem;
    margin-bottom: 1rem;
    border-bottom: 1px dotted ${props.theme.color.gray2};
  `}
`;

const ContentWrapperOptions = ({ children, id, remove, moveContent, noBottomProps, noMove }) => (
  <ContentWrapper noBottomProps={noBottomProps}>
    <StyledButtonGroup className="options" align="right">
      {!noMove && (
        <div>
          <StyledButtonFa
            fa="fa-arrow-up"
            transparent
            color="modalTitle"
            onClick={() => moveContent(id, 'up')}
          />
          <StyledButtonFa
            fa="fa-arrow-down"
            transparent
            color="modalTitle"
            onClick={() => moveContent(id, 'down')}
          />
        </div>
      )}
      <StyledButtonFa
        fa="fa-trash-alt"
        transparent
        color="red"
        onClick={() => remove(id)}
      />
    </StyledButtonGroup>
    {children}
  </ContentWrapper>
);

ContentWrapperOptions.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  remove: PropTypes.func.isRequired,
  moveContent: PropTypes.func.isRequired,
  noBottomProps: PropTypes.bool,
  noMove: PropTypes.bool,
};

ContentWrapperOptions.defaultProps = {
  noBottomProps: false,
  noMove: false,
};

export default ContentWrapperOptions;
