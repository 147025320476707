import { api } from 'config';

const fetchPublicatedModulesPromise = () => new Promise((resolve, reject) => {
  const token = localStorage.getItem('token') || null;
  const reqConfig = {
    method: 'GET',
    headers: Object.assign({}, {
      Authorization: `Bearer ${token}`,
    }),
  };
  fetch(`${api.baseUrl}/api/mfs/publicated`, reqConfig)
    .then((response) => {
      if (response.status >= 400) {
        return reject(response.statusText);
      }
      return resolve(response.json());
    });
});

async function fetchPublicatedModules() {
  try {
    const documentData = await fetchPublicatedModulesPromise();
    return documentData;
  } catch (error) {
    return error;
  }
}

export default fetchPublicatedModules;
