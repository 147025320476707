import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import uploadPicture from 'client/modules/core/utils/upload-centre-picture';

import { save, fetchAllCentres, clearNotification } from '../actions/centres';
import Form from '../components/Form';

class FormContainer extends Component {
  constructor() {
    super();
    this.state = {
      centreToUpdate: {},
      createMode: true,
      pictureId: null,
    };
    this.savePicture = this.savePicture.bind(this);
  }

  componentWillMount() {
    const { match } = this.props;
    if (match.params.id) {
      const { id } = match.params;
      this.loadData(id);
      this.setState({ createMode: false });
    }
  }

  componentWillReceiveProps(nextProps) {
    /* Obtenemos los datos de la noticia actualizados */
    if (nextProps.notification.type === 'success') {
      const { match } = this.props;
      if (match.params.id) {
        const { id } = match.params;
        this.loadData(id);
      }
    }
  }

  loadData(centreId) {
    fetchAllCentres().then((centresData) => {
      const centreToUpdate = centresData.find(c => c._id === centreId);
      this.setState({ centreToUpdate });
    });
  }

  savePicture(picture, name) {
    const pictureId = uploadPicture({ picture: picture.file, description: `Logos del centro ${name}`, name });
    pictureId.then((response) => {
      this.setState({ pictureId: response.image._id });
    });
  }

  render() {
    const { saveForm, notification, clearMessages, history } = this.props;
    const { centreToUpdate, createMode, pictureId } = this.state;
    return (
      <Form
        save={saveForm}
        notification={notification}
        clearMessages={clearMessages}
        centreToUpdate={centreToUpdate}
        history={history}
        createMode={createMode}
        pictureId={pictureId}
        savePicture={this.savePicture}
      />
    );
  }
}

FormContainer.propTypes = {
  saveForm: PropTypes.func.isRequired,
  clearMessages: PropTypes.func.isRequired,
  notification: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

FormContainer.defaultProps = {};

const mapStateToProps = (state) => {
  const { error, success, message } = state.centres;
  const notification = {};

  if (error) {
    notification.type = 'error';
    notification.content = message;
  } else if (success) {
    notification.type = 'success';
    notification.content = message;
  }

  return {
    notification,
  };
};

const mapDispatchToProps = dispatch => ({
  saveForm: (data) => { dispatch(save(data)); },
  clearMessages: () => { dispatch(clearNotification()); },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FormContainer);
