const projectName = 'Herramienta de autoría';

const projectDescription = 'Herramienta de autoría para Atlas System';

// Backend Mitca: http://34.216.243.203

const baseUrl = 'http://contenidoscorm.docentedigital.net:5000';

const api = {
  baseUrl: `${baseUrl}`,
  headers: {
    'Content-Type': 'application/json',
    'x-disable-pagination': 'True',
    'Accept-Language': 'es',
  },
};

export {
  projectName,
  projectDescription,
  baseUrl,
  api,
};
