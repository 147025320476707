import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ChangePassword from '../components/ChangePassword';
import { clearNotification, changePassword } from '../actions/users';

const PasswordContainer = ({ userId, savePassword, clearMessages, notification, history }) => (
  <ChangePassword
    userId={userId}
    save={savePassword}
    clearMessages={clearMessages}
    notification={notification}
    history={history}
  />
);

PasswordContainer.propTypes = {
  userId: PropTypes.string.isRequired,
  clearMessages: PropTypes.func.isRequired,
  notification: PropTypes.objectOf(PropTypes.any).isRequired,
  savePassword: PropTypes.func.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

PasswordContainer.defaultProps = {};

const mapStateToProps = (state) => {
  const { error, success, message } = state.users;
  const notification = {};

  if (error) {
    notification.type = 'error';
    notification.content = message;
  } else if (success) {
    notification.type = 'success';
    notification.content = message;
  }

  return {
    userId: state.auth.userInfo._id,
    notification,
  };
};

const mapDispatchToProps = dispatch => ({
  clearMessages: () => { dispatch(clearNotification()); },
  savePassword: (id, passwordData) => { dispatch(changePassword(id, passwordData)); },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PasswordContainer);
