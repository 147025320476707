// -------------------------------------------------------------------------------------------------
// Add style utils here...
// @see https://github.com/styled-components/styled-components/blob/master/docs/tips-and-tricks.md#using-javascript-to-our-advantage
// -------------------------------------------------------------------------------------------------

/* eslint max-len: 0 */

import { css } from 'styled-components';
import theme from '../config/theme';

const { breakpoints } = theme;

/**
 * Media queries
 * @see https://github.com/styled-components/styled-components/blob/master/docs/tips-and-tricks.md#media-templates
 * @example ${media.small`width: 100%`}
 */
const media = Object.keys(breakpoints).reduce((accumulator, label) => {
  const result = accumulator;
  // Use em in breakpoints to work properly cross-browser and support users
  // changing their browsers font-size: https://zellwk.com/blog/media-query-units/
  const emSize = breakpoints[label] / 16;
  result[label] = (...args) => css`
    @media (min-width: ${emSize}em) {
      ${css(...args)}
    }
  `;
  return result;
}, {});

/**
 * Devuelve el color del theme dado el nombre, en caso de no existir devuelve el color
 * @param  {String} color  - Nombre del color
 * @return {String}        - Color del tema o color recibido en caso de no encontralo en el tema
 */
function getThemeColor(color) {
  return theme.color[color] ? theme.color[color] : color;
}

/**
 * Devuelve un gradiente lineal
 * @param  {String} startColor                   - Color inicial
 * @param  {String} endColor                     - Color final
 * @param  {String} [direction='to left']        - Dirección
 * @return {String}                              - Gradiente
 */
function linearGradient(startColor, endColor, direction = 'to left') {
  const start = getThemeColor(startColor);
  const end = getThemeColor(endColor);
  return `linear-gradient(${direction}, ${start}, ${end})`;
}

/**
 * Mantiene el aspect ratio del elemento
 * @param  {Number} [width=16]                  - Ancho
 * @param  {Number} [height=9]                  - Alto
 * @param  {String} [childSelector='.content']  - Selector para el elemento hijo (si es necesario añadir contenido)
 * @return {String}                             - CSS para mantener el aspect ratio
 */
function aspectRatio(width = 16, height = 9, childSelector = '.content') {
  return `
    position: relative;

    &:before {
      content: '';
      display: block;
      width: 100%;
      padding-top: ${(height / width) * 100}%;
    }

    > ${childSelector} {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  `;
}

export {
  media,
  getThemeColor,
  linearGradient,
  aspectRatio,
};
