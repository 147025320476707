import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// @TODO Añadir soporte para background...
// @see https://gitlab.com/mitca-studio/catapum/blob/develop/client/modules/ui/components/proportional-box.jsx
const StyledWrap = styled.div`
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: ${props => `${(props.y / props.x) * 100}%`};
  }
`;

const StyledContent = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const KeepAspectRatio = ({ aspectRatio, children }) => (
  <StyledWrap x={aspectRatio.x} y={aspectRatio.y}>
    <StyledContent>
      {children}
    </StyledContent>
  </StyledWrap>
);

KeepAspectRatio.propTypes = {
  children: PropTypes.node,
  aspectRatio: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }),
};

KeepAspectRatio.defaultProps = {
  children: null,
  aspectRatio: { x: 1, y: 1 },
};

export default KeepAspectRatio;
