/* eslint import/prefer-default-export: 0, no-undef: 0 */

import { api } from 'config';

function uploadFilePromise({ file, props }) {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem('token') || null;
    const document = new FormData();
    document.append('file', file);
    document.append('props', JSON.stringify(props));
    const reqConfig = {
      method: 'POST',
      headers: Object.assign({}, {
        Authorization: `Bearer ${token}`,
      }),
      body: document,
    };

    fetch(`${api.baseUrl}/api/resources/contents/`, reqConfig)
      .then((response) => {
        if (response.status >= 400) {
          return reject(response.statusText);
        }
        return resolve(response.json());
      });
  });
}

async function uploadFile(file) {
  try {
    const fileUploaded = await uploadFilePromise(file);
    return fileUploaded;
  } catch (error) {
    return error;
  }
}

export default uploadFile;
