/* eslint no-restricted-globals: 0, react/destructuring-assignment: 0,
react/no-access-state-in-setstate: 0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import deepEqual from 'fast-deep-equal';
import Alert from 'react-s-alert';
import Notification from 'client/ui/Notification';
import theme from 'client/config/theme';
import Modal from 'client/ui/Modal';
import Divider from 'client/ui/Divider';
import Button from 'client/ui/Button';
import CodeName from 'client/ui/CodeName';
import Select from 'client/ui/Select';
import { Tabs, TabsPane, Tab } from 'client/ui/Tabs';
import SectionCircle from 'client/ui/SectionCircle';
import { H2 } from 'client/ui/Headings';
import ContentWrapper from 'client/ui/ContentWrapper';
import Proration from 'client/modules/dashboard-uas/components/Proration';
import Form from './Form';
import GeneralData from './GeneralData';
import BibliographicReferences from './BibliographicReferences';
import List from '../containers/List';

const notificationConfig = { position: 'top-right', effect: 'slide', timeout: 3000 };

const CircleText = styled.div`
  font-size: 21px;
  font-weight: bold;
  color: white;
  position: absolute;
  top: 12px;
  right: 5px;
`;

const StyledH2 = styled(H2)`
  color: ${props => props.theme.color.white};
`;

const Header = styled.div`
  margin-bottom: .5rem;
`;

const SectionTitle = styled.div`
  text-align: center;
  background-color: ${props => props.theme.color.blueMfs};
  color: white;
  padding: .5rem;
  h2 {
    margin-bottom: 0;
  }
`;

const Section = ContentWrapper.extend`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;
`;

const StyledSeparation = styled.div`
  width: 100%;
  min-width: 0;
  margin-right: 1rem;
`;

const MfName = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: .25rem;
`;

const DividerWrapper = styled.div`
  position: relative;
`;

const MfsDivider = Divider.extend`
  position: absolute;
  top: 0;
  left: 0;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 0;
`;

const ChangeModal = styled(Modal)`
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 16px;
`;

const ValidateButton = styled(Button)`
  margin-left: 1rem;
`;

const SelectStatusWrapper = styled.div`
  width: 300px;
  margin-left: 1rem;
`;

class Wrapper extends Component {
  constructor() {
    super();
    this.state = {
      id: null,
      codeMf: '',
      codeCert: '',
      nameMf: '',
      nameCert: '',
      centre: null,
      haveUf: false,
      title: '',
      subtitles: [''],
      objectives: [''],
      introduction: '',
      coverPicture: null,
      bibliographicReferences: [],
      modalIsOpen: false,
      previewModalIsOpen: false,
      changeModalIsOpen: false,
      confirmChangeModalIsOpen: false,
      validateModalIsOpen: false,
      nextTab: null,
      toPreview: false,
      readOnly: false,
    };
    this.handleBibliographicEditorChange = this.handleBibliographicEditorChange.bind(this);
    this.handleChangeItemOnCollection = this.handleChangeItemOnCollection.bind(this);
    this.handleReferenceTitleChange = this.handleReferenceTitleChange.bind(this);
    this.handleMediumEditorChange = this.handleMediumEditorChange.bind(this);
    this.handleStatusSelectChange = this.handleStatusSelectChange.bind(this);
    this.removeFromCollection = this.removeFromCollection.bind(this);
    this.reConfirmChangeModal = this.reConfirmChangeModal.bind(this);
    this.handlePictureChange = this.handlePictureChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.cancelPreviewModal = this.cancelPreviewModal.bind(this);
    this.confirmChangeModal = this.confirmChangeModal.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.afterNotification = this.afterNotification.bind(this);
    this.cancelChangeModal = this.cancelChangeModal.bind(this);
    this.showValidateModal = this.showValidateModal.bind(this);
    this.adminChangeStatus = this.adminChangeStatus.bind(this);
    this.showPreviewModal = this.showPreviewModal.bind(this);
    this.handleUfOrNotUf = this.handleUfOrNotUf.bind(this);
    this.addToCollection = this.addToCollection.bind(this);
    this.handleChangeTab = this.handleChangeTab.bind(this);
    this.changeToPreview = this.changeToPreview.bind(this);
    this.handleTabClick = this.handleTabClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.confirmModal = this.confirmModal.bind(this);
    this.saveAndNext = this.saveAndNext.bind(this);
    this.cancelModal = this.cancelModal.bind(this);
    this.isFormDirty = this.isFormDirty.bind(this);
    this.validateMf = this.validateMf.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { moduleToUpdate, pictureId } = nextProps;
    if (moduleToUpdate && moduleToUpdate.id && !pictureId) {
      const { id, bibliographicReferences, codeMf, codeCert, nameMf, nameCert, centre, haveUf,
        title, subtitles, introduction, objectives, coverPicture, ufs, uas,
        status } = moduleToUpdate;
      let parsedStatus = { value: 'DRAFT', label: 'Borrador' };
      if (status === 'VALIDATEDTEACHER') {
        parsedStatus = { value: 'VALIDATEDTEACHER', label: 'Finalizado (docente)' };
      } else if (status === 'VALIDATED') {
        parsedStatus = { value: 'VALIDATED', label: 'Validado (centro)' };
      } else if (status === 'PUBLICATED') {
        parsedStatus = { value: 'PUBLICATED', label: 'Publicado' };
      }

      this.setState({
        id,
        bibliographicReferences,
        codeMf,
        codeCert,
        nameMf,
        nameCert,
        centre,
        haveUf,
        title,
        subtitles,
        introduction,
        objectives,
        coverPicture,
        ufs,
        uas,
        status: parsedStatus,
      });
    }
    if (pictureId) {
      const { coverPicture } = this.state;
      coverPicture.id = pictureId;
      this.setState({ coverPicture });
    }
  }

  isFormDirty() {
    const { moduleToUpdate } = this.props;
    const { bibliographicReferences, codeMf, codeCert, nameMf, nameCert, centre, haveUf, title,
      subtitles, introduction, objectives, coverPicture } = moduleToUpdate;

    const initialData = {
      bibliographicReferences,
      codeMf,
      codeCert,
      nameMf,
      nameCert,
      centre,
      haveUf,
      title,
      subtitles,
      introduction,
      objectives,
    };

    if (coverPicture) {
      Object.assign(initialData, {
        coverPicture: {
          id: coverPicture.id,
        },
      });
    }

    const currentData = {
      bibliographicReferences: this.state.bibliographicReferences,
      codeMf: this.state.codeMf,
      codeCert: this.state.codeCert,
      nameMf: this.state.nameMf,
      nameCert: this.state.nameCert,
      centre: this.state.centre,
      haveUf: this.state.haveUf,
      title: this.state.title,
      subtitles: this.state.subtitles,
      introduction: this.state.introduction,
      objectives: this.state.objectives,
    };

    if (this.state.coverPicture) {
      Object.assign(currentData, {
        coverPicture: {
          id: this.state.coverPicture.id,
        },
      });
    }

    return !deepEqual(initialData, currentData);
  }

  afterNotification() {
    const { nextTab, toPreview, readOnly } = this.state;
    const { clearMessages, history, match, notification } = this.props;
    clearMessages();
    if (!match.params.id && notification.id) {
      setTimeout(() => {
        history.replace(`/dashboard/mfs/${notification.id}`);
      }, 1000);
    }

    if (notification.id && readOnly) {
      const path = { ...location };
      path.name = '/dashboard/myMfs';
      path.pathname = '/dashboard/myMfs';
      history.replace(path);
    }

    if (notification.id && toPreview) {
      const { tab } = this.props;
      const { id } = match.params;
      const path = { ...location, hash: tab };
      path.name = `/viewMf/${id}`;
      path.pathname = `/viewMf/${id}`;
      history.replace(path);
    }

    if (notification.id && nextTab && !toPreview) {
      setTimeout(() => {
        this.handleChangeTab(nextTab);
      }, 1000);
    }
  }

  handleMediumEditorChange(value, name) {
    const emptyValue = '<p><br></p>';
    this.setState({
      [name]: value !== emptyValue ? value.trim() : '',
    });
  }

  handleStatusSelectChange(state) {
    const { id } = this.state;
    const { save } = this.props;
    const data = {
      id,
      status: state.value,
    };
    save(data);
  }

  showModal() {
    this.setState({ modalIsOpen: true });
  }

  showValidateModal() {
    this.setState({ validateModalIsOpen: true });
  }

  showPreviewModal() {
    this.setState({ previewModalIsOpen: true });
  }

  showChangeModal() {
    this.setState({ changeModalIsOpen: true });
  }

  cancelChangeModal() {
    this.hideModal();
  }

  confirmChangeModal() {
    this.hideModal();
    this.setState({ confirmChangeModalIsOpen: true });
  }

  reConfirmChangeModal() {
    const { haveUf } = this.state;
    const { save } = this.props;
    this.setState({ haveUf: !haveUf, nextTab: null }, () => {
      const { id, haveUf, uas, ufs } = this.state;
      const data = {
        id,
        haveUf,
      };
      if (haveUf) {
        Object.assign(data, { uas: [], ufs });
      } else {
        Object.assign(data, { ufs: [], uas });
      }
      save(data);
    });
    this.hideModal();
  }

  hideModal() {
    this.setState({
      modalIsOpen: false,
      previewModalIsOpen: false,
      changeModalIsOpen: false,
      confirmChangeModalIsOpen: false,
      validateModalIsOpen: false,
    });
  }

  confirmModal() {
    this.hideModal();
    this.handleSubmit();
  }

  cancelModal() {
    const { nextTab } = this.state;
    this.hideModal();
    this.handleChangeTab(nextTab);
  }

  cancelPreviewModal() {
    this.hideModal();
    const { history, match, tab } = this.props;
    const { id } = match.params;
    const path = { ...location, hash: tab };
    path.name = `/viewMf/${id}`;
    path.pathname = `/viewMf/${id}`;
    history.replace(path);
  }

  changeToPreview() {
    const { history, match, tab } = this.props;
    const { id } = match.params;
    const path = { ...location, hash: tab };
    if (this.isFormDirty()) {
      this.setState({ toPreview: true });
      this.showPreviewModal();
    } else {
      path.name = `/viewMf/${id}`;
      path.pathname = `/viewMf/${id}`;
      history.replace(path);
    }
  }

  handleBibliographicEditorChange(index, value) {
    const { bibliographicReferences } = this.state;
    const emptyValue = '<p><br></p>';
    bibliographicReferences[index].text = value !== emptyValue ? value.trim() : '';
    this.setState({ bibliographicReferences });
  }

  handleReferenceTitleChange(index, event) {
    const { bibliographicReferences } = this.state;
    const { target } = event;
    const { value } = target;
    bibliographicReferences[index].title = value;
    this.setState({ bibliographicReferences });
  }

  handleInputChange(event) {
    const { target } = event;
    const { name, value } = target;
    this.setState({ [name]: value });
  }

  addToCollection(collection) {
    const current = this.state[collection];
    if (collection === 'bibliographicReferences') {
      current.push({ title: '', text: '' });
    } else {
      current.push('');
    }
    this.setState({ [collection]: current });
  }

  removeFromCollection(collection, index) {
    const current = this.state[collection];
    current.splice(index, 1);
    this.setState({ [collection]: current });
  }

  handleChangeItemOnCollection(collection, index, event) {
    const current = this.state[collection];
    const { target } = event;
    const { value } = target;
    current[index] = value;
    this.setState({ [collection]: current });
  }

  handleTabClick(tab) {
    if (this.isFormDirty()) {
      this.setState({ nextTab: tab });
      this.showModal();
    } else {
      this.handleChangeTab(tab);
    }
  }

  saveAndNext(tab) {
    this.setState({ nextTab: tab });
  }

  handleChangeTab(tab) {
    const { history } = this.props;
    const path = { ...location, hash: tab };
    history.replace(path);
  }

  handlePictureChange(coverPicture) {
    const { savePicture } = this.props;
    const { title } = this.state;
    this.setState({
      coverPicture,
    });
    if (coverPicture) {
      savePicture(coverPicture, title);
    }
  }

  handleSelectChange(name, value) {
    this.setState({ [name]: value });
  }

  validateMf() {
    const { save } = this.props;
    const { id } = this.state;
    const data = {
      id,
      status: 'VALIDATEDTEACHER',
    };
    this.setState({ readOnly: true });
    this.hideModal();
    save(data);
  }

  adminChangeStatus() {
    const { save } = this.props;
    const { id, status } = this.state;
    const data = {
      id,
      status: status.value,
    };
    save(data);
  }

  handleSubmit(event, nextTab) {
    if (event) {
      event.preventDefault();
    }
    if (nextTab) {
      this.setState({ nextTab });
    }

    const { save, tab } = this.props;
    const { id, bibliographicReferences, codeMf, codeCert, nameMf, nameCert, centre,
      haveUf, title, introduction, objectives, subtitles, coverPicture } = this.state;
    if (tab === '#BibliographicReferences') {
      const data = {
        id,
        bibliographicReferences,
      };
      save(data);
    } else if (tab === '#generalDescription') {
      const centreId = centre ? centre.value : null;
      if (!centre || centre.value === '' || !centreId || centreId === '') {
        Alert.error('Debe seleccionar un centro', notificationConfig);
        return false;
      }

      const data = {
        codeMf,
        codeCert,
        nameMf,
        nameCert,
        haveUf,
        centre: centreId,
      };

      if (id) {
        Object.assign(data, { id });
      }

      save(data);
    } else if (tab === '#generalData') {
      const data = {
        id,
        title,
        introduction,
        subtitles,
        objectives,
      };
      if (coverPicture) {
        Object.assign(data, { coverPicture: coverPicture.id });
      }
      save(data);
    }
  }

  handleUfOrNotUf() {
    const { createMode } = this.props;
    if (!createMode) {
      this.setState({ changeModalIsOpen: true });
    } else {
      const { haveUf } = this.state;
      this.setState({ haveUf: !haveUf });
    }
  }

  renderUForUaList() {
    const { moduleToUpdate } = this.props;
    if (moduleToUpdate && moduleToUpdate.id) {
      const { haveUf, ufs, uas } = this.state;
      if (haveUf) {
        return (
          <TabsPane
            name="#formativeUnits"
            label="Unidades Formativas"
          >
            <List haveUf={haveUf} items={ufs} typeMf modeEdit />
          </TabsPane>
        );
      }
      return (
        <TabsPane
          name="#learningUnits"
          label="Unidades de Aprendizaje"
        >
          <List haveUf={haveUf} items={uas} typeMf modeEdit />
        </TabsPane>
      );
    }
    return null;
  }

  render() {
    const { notification, tab, createMode, centres, savePicture, moduleToUpdate, notificationUa,
      clearUaMessage, save, isAdmin } = this.props;
    const { id, bibliographicReferences, codeCert, codeMf, nameCert, nameMf, centre, title,
      introduction, subtitles, objectives, coverPicture, haveUf, modalIsOpen,
      previewModalIsOpen, changeModalIsOpen, confirmChangeModalIsOpen,
      validateModalIsOpen, status } = this.state;
    const savedCodeMf = moduleToUpdate.codeMf;
    const savedNameMf = moduleToUpdate.nameMf;
    const { proration } = moduleToUpdate;
    const parsedStatus = [
      { value: 'DRAFT', label: 'Borrador' },
      { value: 'VALIDATEDTEACHER', label: 'Finalizado (docente)' },
      { value: 'VALIDATED', label: 'Validado (centro)' },
      { value: 'PUBLICATED', label: 'Publicado' },
    ];
    return (
      <div>
        {haveUf
          ? (
            <DividerWrapper>
              <Divider borderWidth="2px" borderColor="grayLight" />
              <MfsDivider width="25%" borderWidth="2px" borderColor="blueMfs" />
            </DividerWrapper>)
          : (
            <DividerWrapper>
              <Divider borderWidth="2px" borderColor="grayLight" />
              <MfsDivider width="33.33333%" borderWidth="2px" borderColor="blueMfs" />
            </DividerWrapper>)}
        <Header>
          <Section>
            <TitleWrapper>
              <MfName>
                <SectionCircle size="42px" background="blueMfs">
                  <CircleText>MF</CircleText>
                </SectionCircle>
              </MfName>
              <StyledSeparation>
                <CodeName>
                  { savedCodeMf !== '' && savedNameMf !== ''
                    ? <div>{savedCodeMf} {savedNameMf}</div>
                    : <div>MF</div>
                  }
                </CodeName>
              </StyledSeparation>
            </TitleWrapper>
            {!createMode
              && (
                <Button
                  hollow
                  color="black"
                  borderColor="black"
                  onClick={() => this.changeToPreview()}
                >
                  Previsualizar
                </Button>)}
            {!createMode && status && status.value === 'DRAFT' && !isAdmin
              && (
                <ValidateButton
                  hollow
                  color="red"
                  borderColor="red"
                  onClick={() => this.showValidateModal()}
                >
                  Validar
                </ValidateButton>)}
            {!createMode && status && status.value !== 'DRAFT' && !isAdmin
              && (
                <ValidateButton
                  color="red"
                  background="red"
                  borderColor="red"
                  disabled
                >
                  Validado
                </ValidateButton>)}
            {!createMode && isAdmin
              && (
                <SelectStatusWrapper>
                  <Select
                    isSearchable
                    name="status"
                    options={parsedStatus}
                    value={status}
                    onChange={(value) => { this.handleStatusSelectChange(value); }}
                  />
                </SelectStatusWrapper>
              )}
          </Section>
          <SectionTitle><StyledH2>MÓDULO FORMATIVO</StyledH2></SectionTitle>
        </Header>

        <Notification
          message={notification}
          afterNotification={this.afterNotification}
        />

        <Notification
          message={notificationUa}
          afterNotification={clearUaMessage}
        />

        <Modal
          isOpen={modalIsOpen}
          title="Guardar cambios"
          content="Ha realizado cambios, ¿desea guardar los cambios antes de continuar?"
          onCancel={this.cancelModal}
          onConfirm={this.confirmModal}
        />

        <Modal
          isOpen={previewModalIsOpen}
          title="Guardar cambios"
          content="Ha realizado cambios, ¿desea guardar los cambios antes de previsualizar?"
          onCancel={this.cancelPreviewModal}
          onConfirm={this.confirmModal}
        />

        <Modal
          isOpen={validateModalIsOpen}
          title="Validar MF"
          content="¿Desea validar el Módulo Formativo? Una vez hecha esta acción no podrá realizar cambios"
          onCancel={this.hideModal}
          onConfirm={this.validateMf}
        />

        <ChangeModal
          isOpen={changeModalIsOpen}
          title="CONTIENE UNIDADES FORMATIVAS"
          content="Al cambiar el tipo de MF, se perderán las UF o UA creadas"
          onCancel={this.cancelChangeModal}
          onConfirm={this.confirmChangeModal}
        />

        <ChangeModal
          isOpen={confirmChangeModalIsOpen}
          title="CONTIENE UNIDADES FORMATIVAS"
          content="¿Está seguro que quiere cambiar el tipo de MF?"
          onCancel={this.cancelChangeModal}
          onConfirm={this.reConfirmChangeModal}
        />

        <Tabs
          tabsBarBorderColor="gray2"
          selected={tab}
          component={Tab}
          componentProps={{ color: 'blueMfs' }}
          onTabClick={currentTab => this.handleTabClick(currentTab)}
          tabsTitleMaxWidth={theme.maxWidth}
        >
          <TabsPane
            name="#generalDescription"
            label="Descripción general"
            activeColor="red"
          >
            <Form
              handleSubmit={this.handleSubmit}
              saveAndNext={this.saveAndNext}
              moduleToUpdate={{ id, codeCert, codeMf, nameCert, nameMf, centre, haveUf }}
              createMode={createMode}
              handleUfOrNotUf={this.handleUfOrNotUf}
              inputChange={this.handleInputChange}
              handleSelectChange={this.handleSelectChange}
              centres={centres}
            />
          </TabsPane>

          {!createMode
            && (
              <TabsPane
                name="#generalData"
                label="Datos generales"
              >
                <GeneralData
                  moduleToUpdate={{ title, introduction, subtitles, objectives, coverPicture, haveUf }}
                  inputChange={this.handleInputChange}
                  addToCollection={this.addToCollection}
                  removeFromCollection={this.removeFromCollection}
                  changeItemOnCollection={this.handleChangeItemOnCollection}
                  handlePictureChange={this.handlePictureChange}
                  handleMediumEditorChange={this.handleMediumEditorChange}
                  createMode={createMode}
                  savePicture={savePicture}
                  saveAndNext={this.saveAndNext}
                  submit={this.handleSubmit}
                />
              </TabsPane>)}

          {!createMode
            && this.renderUForUaList() }

          {!createMode && !haveUf
            && (
              <TabsPane
                name="#BibliographicReferences"
                label="Referencias Bibliográficas"
              >
                <BibliographicReferences
                  moduleToUpdate={{ id, bibliographicReferences }}
                  titleChange={this.handleReferenceTitleChange}
                  mediumEditorChange={this.handleBibliographicEditorChange}
                  addReference={this.addToCollection}
                  removeReference={this.removeFromCollection}
                  submit={this.handleSubmit}
                />
              </TabsPane>)}
          {!createMode
            && (
            <TabsPane
              name="#evaluationConfiguration"
              label="Configuración de la evaluación"
            >
              <ContentWrapper>
                {id && (
                  <Proration id={id} proration={proration} save={save} />
                )}
              </ContentWrapper>
            </TabsPane>)}
        </Tabs>
      </div>
    );
  }
}

Wrapper.propTypes = {
  save: PropTypes.func.isRequired,
  savePicture: PropTypes.func.isRequired,
  pictureId: PropTypes.string,
  centres: PropTypes.arrayOf(PropTypes.object).isRequired,
  notification: PropTypes.objectOf(PropTypes.any).isRequired,
  notificationUa: PropTypes.objectOf(PropTypes.any).isRequired,
  clearMessages: PropTypes.func.isRequired,
  clearUaMessage: PropTypes.func.isRequired,
  createMode: PropTypes.bool.isRequired,
  moduleToUpdate: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  tab: PropTypes.string.isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

Wrapper.defaultProps = {
  moduleToUpdate: {},
  pictureId: null,
};

export default Wrapper;
