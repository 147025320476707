import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DragHandle from 'client/ui/DragHandle';
import Button from 'client/ui/Button';
import Img from 'client/ui/Img';

const StyledItem = styled.div`
  display: flex;
  width: 100%;
  height: 80px;
  margin-bottom: .5rem;
  border: 1px solid ${props => props.theme.color.grayTable};
  border-radius: ${props => props.theme.radius};
  box-shadow: 0px 2px ${props => props.theme.color.grayTable};
`;

const NumberWrapper = styled.div`
  display: flex;
  height: 100%;
  vertical-align: middle;
`;

const Number = styled.div`
  font-size: 1.875rem;
  font-weight: bold;
  width: 50px;
  margin-right: 1rem;
  text-align: center;
  align-self: center;
`;

const UaColor = styled.div`
  width: 7px;
  background-color: ${props => props.theme.color.greenUa};
  margin-right: 1rem;
  height: 100%;
  z-index: 2;
`;

const TitleWrapper = styled.div`
  margin-right: 1rem;
  align-self: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-grow: 1;
`;

const Buttons = styled.div`
  margin-right: .5rem;
  align-self: center;
  display: flex;
  flex-wrap: nowrap;
  a, button {
    margin-right: .5rem;
    font-size: 1rem;
    i {
      font-size: 1rem;
    }
  }
`;

const UfPicture = styled.div`
  width: 126px;
  min-width: 126px;
  height: 78px;
  overflow: hidden;
  border-top-left-radius: 0.24em;
  border-bottom-left-radius: 0.24em;
  margin-right: 1rem;
  text-align: center;
  img {
    width: 126px;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
`;

const StyledButton = styled(Button)`
  display: inline-block;
  cursor: pointer;
  padding: 0.65em 0.60em;
  font-size: 1rem;
  background-color: transparent;
`;

const ScreensCounter = styled.div`
  font-size: 0.75rem;
  color: ${props => props.theme.color.modalTitle};
  margin-bottom: .5rem;
`;

const Title = styled.div`
  color: ${props => props.theme.color.black};
  font-weight: bold;
  font-size: 1rem;
`;

const Item = ({ title, codeUf, id, uf, index, onRemove, canEdit, canDelete, exportScorm,
  canExport, picture, screens }) => {
  const edit = uf ? '/dashboard/ufs' : '/dashboard/uas/edit';
  return (
    <StyledItem>
      {!uf
        ? <NumberWrapper><UaColor /><Number>{index}</Number></NumberWrapper>
        : <UfPicture><Img src={picture} /></UfPicture>
      }
      <TitleWrapper>
        <ScreensCounter>Pantallas {screens}</ScreensCounter>
        <Title>{codeUf} {title}</Title>
      </TitleWrapper>
      <Buttons>
        {canEdit
          && (
            <StyledButton transparent to={`${edit}/${id}`} color="black">
              <i className="fas fa-pencil-alt" />
            </StyledButton>
          )}
        {uf && canExport && (
        <StyledButton transparent onClick={() => exportScorm(id)} color="black">
          <i className="fas fa-download" />
        </StyledButton>
        )}
        {canDelete
          && (
            <StyledButton transparent onClick={() => onRemove(id)} color="red">
              <i className="fa fa-fw fa-trash-alt" />
            </StyledButton>
          )}
      </Buttons>
      <DragHandle />
    </StyledItem>
  );
};

Item.propTypes = {
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  codeUf: PropTypes.string.isRequired,
  screens: PropTypes.number.isRequired,
  onRemove: PropTypes.func.isRequired,
  uf: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
  canDelete: PropTypes.bool.isRequired,
  canExport: PropTypes.bool,
  exportScorm: PropTypes.func.isRequired,
  picture: PropTypes.string,
};

Item.defaultProps = {
  canExport: false,
  picture: '',
};

export default Item;
