import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from 'client/ui/Button';

const DangerousDiv = styled.div`
  width: 100%;
  line-height: 23px;
  color: ${props => props.theme.color.black};
  p:first-child {
    margin: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
`;

const PreviewWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 720px;
  @media (max-width: 650px) {
    flex-direction: column;
  }
`;

const LinkButton = styled(Button)`
  margin-left: 2.5rem;
  min-width: 120px;
  max-width: 120px;
  @media (max-width: 650px) {
    margin: 0 !important;
  }
`;

const Link = ({ data, url, colorMf }) => (
  <PreviewWrapper className="animated pulse">
    <DangerousDiv dangerouslySetInnerHTML={{ __html: data }} />
    {colorMf
      && (
        <LinkButton
          href={url}
          target="_blank"
          background={colorMf}
        >
          Enlace
        </LinkButton>
      )}
  </PreviewWrapper>
);

Link.propTypes = {
  data: PropTypes.string.isRequired,
  url: PropTypes.string,
  colorMf: PropTypes.string.isRequired,
};

Link.defaultProps = {
  url: '',
};

export default Link;
