/* eslint max-len: 0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Label from 'client/ui/Label';
import Video from 'client/ui/Video';
import Input from 'client/ui/Input';
import Button from 'client/ui/Button';
import Img from 'client/ui/Img';
import { getVideoService, parseVideoId } from 'client/modules/core/utils/parse-video';
import ContentText from './ContentText';

const VideoPreviewWrapper = styled.div`
  max-width: 500px;
  margin-left:auto;
  margin-right: auto;
  margin-bottom: 2rem;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  button {
    width: 140px;
    margin-left: 1rem;
    margin-bottom: 1rem;
  }
`;


class VideoWrapper extends Component {
  constructor() {
    super();
    this.state = { currentUrl: '' };
    this.changeCurrentUrl = this.changeCurrentUrl.bind(this);
  }

  componentWillMount() {
    const { url } = this.props;
    this.setState({ currentUrl: url });
  }

  componentWillReceiveProps(nextProps) {
    const { url } = nextProps;
    this.setState({ currentUrl: url });
  }

  changeCurrentUrl(event) {
    const { target } = event;
    const { value } = target;
    this.setState({ currentUrl: value });
  }

  render() {
    const { url, data, id, transcriptionChange, onChange } = this.props;
    const { currentUrl } = this.state;
    const service = getVideoService(url);
    const videoId = parseVideoId(url);
    return (
      <div>
        <VideoPreviewWrapper>
          {/* El div que envuelve el video evita un error de ejecución al intentar eliminar el nodo
          @see: https:@//stackoverflow.com/questions/42956884/failed-to-execute-removechild-on-node */}
          {url && url.length > 5 && service && service !== '' && videoId !== false
            ? <div><Video service={service} id={videoId} /></div>
            : <Img src="/images/contents/video.jpg" />
          }
        </VideoPreviewWrapper>
        <InputWrapper>
          <Input
            value={currentUrl}
            onChange={this.changeCurrentUrl}
            placeholder="Url del video (youtube o vimeo)"
          />
          <Button
            onClick={() => { onChange(id, currentUrl); }}
          >
            Guardar
          </Button>
        </InputWrapper>
        <Label>Transcripción</Label>
        <ContentText
          data={data}
          id={id}
          onChange={transcriptionChange}
          mediumType="mediumEditorBasic"
        />
      </div>
    );
  }
}

VideoWrapper.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  transcriptionChange: PropTypes.func.isRequired,
  url: PropTypes.string,
};

VideoWrapper.defaultProps = {
  url: '',
};

export default VideoWrapper;
