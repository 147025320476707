/* eslint-disable max-len */
/**
 * Parsea una URL de un vídeo y devuelve su URL para poder embeberlo fácilmente
 *
 * @TODO Parsear videos de otros servicios proveedores (Youtube, etc.) y añadirlos a getVideoService()
 *
 * @see http://stackoverflow.com/questions/29167006/user-friendly-vimeo-embedding-get-embed-code-from-url
 * @see http://stackoverflow.com/questions/12934034/get-vimeo-id-using-embed-source-javascript-jquery
 * @see http://stackoverflow.com/questions/22544625/javascript-convert-youtube-vimeo-urls-into-embed-versions-for-use-on-a-forum
 *
 * @param  {String} url  - URL de vídeo
 * @return {String}      - URL lista para ser embebida
 */
const parseVideoUrl = (url) => {
  if (url && url.indexOf('vimeo') > -1) {
    const vimeoRegex = /(?:vimeo)\.com.*(?:videos|video|channels|)\/([\d]+)/i;
    const parsed = url.match(vimeoRegex);

    return `//player.vimeo.com/video/${parsed[1]}`;
  }

  if (url && url.indexOf('youtu') > -1) {
    const youtubeRegex = /(?:http?s?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be|youtube-nocookie\.com)\/(?:watch\?v=|embed\/)?(.+)/g;
    const replacement = '//www.youtube.com/embed/$1';
    return url.replace(youtubeRegex, replacement);
  }

  return false;
};

const getVideoService = (url) => {
  let service = '';

  if (url && url.indexOf('vimeo') > -1) {
    service = 'vimeo';
  } else if (url && url.indexOf('youtu') > -1) {
    service = 'youtube';
  }

  return service;
};

const parseVideoId = (url) => {
  if (url && url.indexOf('vimeo') > -1) {
    const vimeoRegex = /(?:vimeo)\.com.*(?:videos|video|channels|)\/([\d]+)/i;
    const parsed = url.match(vimeoRegex);
    return `//player.vimeo.com/video/${parsed[1]}`;
  }

  if (url && url.indexOf('youtu') > -1) {
    const youtubeRegex = /(?:http?s?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be|youtube-nocookie\.com)\/(?:watch\?v=|embed\/)?(.+)/g;
    const replacement = '$1';
    const urlReplaced = url.replace(youtubeRegex, replacement);

    if (urlReplaced.indexOf('/') > -1 || urlReplaced.indexOf('www.') > -1) {
      return false;
    }
    return urlReplaced;
  }

  return false;
};

export {
  parseVideoUrl,
  getVideoService,
  parseVideoId,
};
