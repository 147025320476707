/* eslint consistent-return: 0, no-undef: 0, no-unused-vars: 0 */
/* eslint import/prefer-default-export: 0, no-console: 0 */
import { api } from 'config';
import moment from 'moment';
import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_ERROR } from '../constants/actionTypes';

function requestLogin(credentials) {
  return {
    type: LOGIN_REQUEST,
    isFetching: true,
    isAuthenticated: false,
  };
}

function receiveLogin(authToken, userInfo) {
  return {
    type: LOGIN_SUCCESS,
    isFetching: false,
    isAuthenticated: true,
    token: authToken,
    userInfo,
  };
}

function loginError(message) {
  let parsedMessage = message;
  if (message === 'Not Found') {
    parsedMessage = 'Credenciales no validas';
  }
  return {
    type: LOGIN_ERROR,
    isFetching: false,
    isAuthenticated: false,
    message: parsedMessage,
  };
}

/**
 * @param  {String} authToken                     - Token del localStorage
 * @param  {Object} userInfo                      - Info del usuario del localStorage
 * @return {[type]}                               [description]
 */
export function loginWithLocalStorage({ authToken, userInfo }) {
  return (dispatch) => {
    dispatch(receiveLogin(authToken, userInfo));
  };
}

function loginUser({ email, password }) {
  const reqConfig = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Credentials': false,
    },
    body: JSON.stringify({ email, password }),
  };
  return (dispatch) => {
    fetch(`${api.baseUrl}/auth/login`, reqConfig)
      .then((response) => {
        if (response.status >= 400) {
          dispatch(loginError(response.statusText));
        }
        return response.json();
      })
      .then((user) => {
        if (user && user.token && user.user && user.user.deletedAt === null) {
          const { _id, firstName, lastName, roles, centres } = user.user;
          const userInfo = {
            _id,
            name: `${firstName} ${lastName}`,
            roles,
            centres,
          };
          // Si el login fue correcto guardamos el token en localStorage
          localStorage.setItem('token', user.token);
          // Si el login fue correcto guardamos la info del usuario en localStorage
          localStorage.setItem('userInfo', JSON.stringify(userInfo));

          dispatch(receiveLogin(user.token, userInfo));
        } else {
          dispatch(loginError('Not Found'));
        }
      })
      .catch(error => console.log(`Error: ${error}`));
  };
}

export default loginUser;
