/* eslint no-underscore-dangle: 0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchRoles } from 'client/modules/core/actions/roles';
import { fetchCentres } from 'client/modules/dashboard-centres/actions/centres';
import { save, fetchUser, clearNotification } from '../actions/users';
import Form from '../components/Form';

class FormContainer extends Component {
  constructor() {
    super();
    this.state = {
      userToUpdate: {},
      createMode: true,
    };
  }

  componentWillMount() {
    const { match, roles, loadRoles, centres, loadCentres } = this.props;
    if (match.params.id) {
      const { id } = match.params;
      this.loadUserData(id);
      this.setState({ createMode: false });
    }
    if (!roles.length) {
      loadRoles();
    }
    if (!centres.length) {
      loadCentres();
    }
  }

  componentWillReceiveProps(nextProps) {
    const { match } = this.props;
    if (match.params.id) {
      const { id } = match.params;
      this.loadUserData(id);
    }
  }

  loadUserData(userId) {
    fetchUser(userId).then((userData) => {
      const { roles, centres } = this.props;
      const { _id, firstName, lastName, email } = userData;
      const userRoles = userData.roles;
      const userCentres = userData.centres;
      if (roles.length > 0) {
        const currentRoles = [];
        userRoles.forEach((userRol) => {
          const currentRol = roles.find(rol => rol._id === userRol);
          if (currentRol) {
            currentRoles.push({ value: currentRol._id, label: currentRol.label });
          }
        });
        const currentCentres = [];
        userCentres.forEach((userCentre) => {
          const currentCentre = centres.find(centre => centre._id === userCentre);
          if (currentCentre) {
            currentCentres.push({ value: currentCentre._id, label: currentCentre.name });
          }
        });
        const id = _id;
        const userToUpdate = {
          id,
          firstName,
          lastName,
          email,
          currentRoles,
          currentCentres,
        };
        this.setState({ userToUpdate });
      }
    });
  }

  render() {
    const { saveUser, notification, clearMessages, history, roles, centres } = this.props;
    const { userToUpdate, createMode } = this.state;
    return (
      <Form
        save={saveUser}
        roles={roles}
        notification={notification}
        clearMessages={clearMessages}
        userToUpdate={userToUpdate}
        history={history}
        createMode={createMode}
        centres={centres}
      />
    );
  }
}

FormContainer.propTypes = {
  saveUser: PropTypes.func.isRequired,
  clearMessages: PropTypes.func.isRequired,
  centres: PropTypes.arrayOf(PropTypes.object).isRequired,
  notification: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  roles: PropTypes.arrayOf(PropTypes.any).isRequired,
  loadRoles: PropTypes.func.isRequired,
  loadCentres: PropTypes.func.isRequired,
};

FormContainer.defaultProps = {};

const mapStateToProps = (state) => {
  const { error, success, message } = state.users;
  const notification = {};

  if (error) {
    notification.type = 'error';
    notification.content = message;
  } else if (success) {
    notification.type = 'success';
    notification.content = message;
  }

  return {
    roles: state.common.roles,
    centres: state.centres.centres,
    notification,
  };
};

const mapDispatchToProps = dispatch => ({
  saveUser: (data) => { dispatch(save(data)); },
  clearMessages: () => { dispatch(clearNotification()); },
  loadRoles: () => { dispatch(fetchRoles()); },
  loadCentres: () => { dispatch(fetchCentres()); },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FormContainer);
