import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Popup from './ButtonPopupItemView';

const StyledPopupPane = styled.div`
  padding-top: 1rem;
  margin-bottom: 1rem;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-flow: row wrap;
  align-content: flex-end;
  justify-content: center;
`;

class ButtonPopupView extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const { items, id, colorMf } = this.props;
    return (
      <Wrapper className="animated fadeIn">
        {items && items.map((item, index) => (
          <StyledPopupPane>
            <Popup
              key={`popups${index}-${id}`}
              buttonText={item.buttonText}
              title={item.title}
              text={item.text}
              picture={item.url}
              colorMf={colorMf}
              positionImage={item.positionImage}
            />
          </StyledPopupPane>
        ))}
      </Wrapper>
    );
  }
}

ButtonPopupView.propTypes = {
  id: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  colorMf: PropTypes.string.isRequired,
};

ButtonPopupView.defaultProps = {};

export default ButtonPopupView;
