import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getActionName, getModuleName } from '../utils/modules-actions';

const StyledModule = styled.div`
  display: flex;
  background-color: #f9f9f9;
  :nth-child(even) {
    background-color: white;
  }
`;

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StyledActions = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
  min-width: 140px;
`;

const StyledTitle = styled.div`
  padding: 1rem .5rem;
  min-width: 120px;
  width: 150px;
`;

const PermissionGrid = ({ name, actions, onChange }) => (
  <StyledModule>
    <StyledTitle>{getModuleName(name)}</StyledTitle>
    <ActionsWrapper>
      {Object.keys(actions).map((action, index) => (
        <StyledActions key={index}>
          <label htmlFor={action}>
            <input
              type="checkbox"
              id={`${name}-${action}`}
              name={action}
              checked={actions[action]}
              onChange={event => onChange(name, event)}
            />
            &nbsp;{getActionName(action)}
          </label>
        </StyledActions>
      ))}
    </ActionsWrapper>
  </StyledModule>
);

PermissionGrid.propTypes = {
  name: PropTypes.string.isRequired,
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func.isRequired,
};

PermissionGrid.defaultProps = {};

export default PermissionGrid;
