/* eslint no-restricted-globals: 0, react/destructuring-assignment: 0,
react/no-access-state-in-setstate: 0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import theme from 'client/config/theme';
import Divider from 'client/ui/Divider';
import Button from 'client/ui/Button';
import { Tabs, TabsPane, Tab } from 'client/ui/Tabs';
import IconFa from 'client/ui/IconFa';
import SectionCircle from 'client/ui/SectionCircle';
import CodeName from 'client/ui/CodeName';
import ContentWrapper from 'client/ui/ContentWrapper';
import Img from 'client/ui/Img';
import ButtonFa from 'client/ui/ButtonFa';
import List from 'client/modules/dashboard-mfs/containers/List';
import ViewProration from 'client/modules/view-ua/components/ViewProration';
import { redirectTo } from 'index';
import GeneralData from './GeneralData';
import BibliographicReferences from './BibliographicReferences';
import UfIndex from '../containers/UfIndex';
import UfMindmap from '../containers/UfMindmap';

const CircleText = styled.div`
  font-size: 21px;
  font-weight: bold;
  color: white;
  position: absolute;
  top: 11px;
  right: 7px;
`;

const Header = styled.div`
  margin-bottom: .5rem;
`;

const Section = ContentWrapper.extend`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;
`;

const StyledSeparation = styled.div`
  width: 100%;
  min-width: 0;
  margin-right: 1rem;
`;

const MfName = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: .25rem;
`;

const DividerWrapper = styled.div`
  position: relative;
`;

const MfsDivider = Divider.extend`
  position: absolute;
  top: 0;
  left: 0;
`;

const UfsDivider = MfsDivider.extend`
  left: 25%;
`;

const CoverWrapper = styled.div`
  width: 100%;
  height: auto;
  max-height: 330px;
  overflow: hidden;
  max-width: ${props => props.theme.maxWidth};
  margin-left: auto;
  margin-right: auto;
`;

const StyledImg = styled(Img)`
  width: 100%;
  height: auto;
  max-width: 1200px;
  max-height: 330px;
  object-fit: contain;
`;

const LogoWrapper = styled.div`
  width: 100%;
  max-width: 650px;
  max-height: 81px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
`;

const StyledReturn = styled.span`
  margin-right: .7rem;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
`;

const PrevFa = styled(IconFa)`
  margin-right: .5rem;
  ${props => props.color && css`
    color: ${props.color};
  `}
  ${props => props.doubleMargin && css`
    margin-right: .8rem;
  `}
`;

const BackButtonWrapper = styled.div`
  max-width: ${props => props.theme.maxWidth};
  margin-left: auto;
  margin-right: auto;
  padding-left: ${props => props.theme.outerMargin};
  padding-right: ${props => props.theme.outerMargin};
  margin-bottom: 2rem;
`;

const PrevLink = data => (
  <StyledReturn onClick={() => redirectTo(`/viewMf/${data._id}#formativeUnits`)}>
    <PrevFa name="chevron-left" doubleMargin />
    <PrevFa name="circle" color={data.color} />
    <span>MF</span>
  </StyledReturn>
);

class Wrapper extends Component {
  constructor() {
    super();
    this.state = {};
    this.handleTabClick = this.handleTabClick.bind(this);
    this.changeToEdit = this.changeToEdit.bind(this);
  }

  changeToEdit() {
    const { history, match, tab } = this.props;
    let parsedTab = tab;
    const { id } = match.params;
    if (parsedTab === '#map' || parsedTab === '#indice') {
      parsedTab = '#generalDescription';
    }
    const path = { ...location, hash: parsedTab };
    path.name = `/dashboard/ufs/${id}`;
    path.pathname = `/dashboard/ufs/${id}`;
    history.replace(path);
  }

  handleTabClick(tab) {
    const { history } = this.props;
    const path = { ...location, hash: tab };
    history.replace(path);
  }

  render() {
    const { tab, ufToView } = this.props;

    if (Object.keys(ufToView).length === 0) {
      return null;
    }
    const { id, bibliographicReferences, codeUf, title, introduction, subtitles,
      objectives, coverPicture, uas, mf, proration, centreLogo } = ufToView;
    const { _id } = mf;
    const { colorMf, colorUf, colorUa } = mf.centre;
    return (
      <div>
        <DividerWrapper>
          <Divider borderWidth="2px" borderColor="grayLight" />
          <MfsDivider width="25%" borderWidth="2px" borderColor={colorMf} />
          <UfsDivider width="25%" borderWidth="2px" borderColor={colorUf} />
        </DividerWrapper>
        <Header>
          <Section>
            <MfName>
              <SectionCircle size="42px" background={colorUf}>
                <CircleText>UF</CircleText>
              </SectionCircle>
            </MfName>
            <StyledSeparation>
              <CodeName>{codeUf} {title}</CodeName>
            </StyledSeparation>
            <Button
              hollow
              color="black"
              borderColor="black"
              onClick={() => this.changeToEdit()}
            >
              Editar
            </Button>
          </Section>
          <CoverWrapper>
            <StyledImg preview src={coverPicture && coverPicture.src} alt="" />
          </CoverWrapper>
          <LogoWrapper>
            <Img src={centreLogo} alt="" />
          </LogoWrapper>
        </Header>
        <Tabs
          tabsBarBorderColor="gray2"
          selected={tab}
          component={Tab}
          componentProps={{ color: colorMf }}
          onTabClick={currentTab => this.handleTabClick(currentTab)}
          tabsTitleMaxWidth={theme.maxWidth}
        >
          <TabsPane name="#mf" label="Descripción" component={PrevLink} componentProps={{ _id }}>
            <div />
          </TabsPane>

          <TabsPane
            name="#generalData"
            label="Datos generales"
          >
            <GeneralData
              ufToView={{ codeUf, title, subtitles, introduction, objectives, colorMf }}
            />
          </TabsPane>

          <TabsPane
            name="#indice"
            label="Índice"
          >
            {id
              ? <UfIndex ufId={id} colorMf={colorMf} />
              : <div />
            }
          </TabsPane>

          <TabsPane
            name="#map"
            label="Mapa conceptual"
          >
            <UfMindmap
              ufId={id}
              colorUf={colorUf}
              colorUa={colorUa}
            />
          </TabsPane>

          <TabsPane
            name="#learningUnits"
            label="Unidades de Aprendizaje"
          >
            <List
              haveUf={false}
              items={uas}
              typeMf={false}
              modeEdit={false}
              colorMf={colorMf}
              colorUf={colorUf}
              colorUa={colorUa}
            />
            <BackButtonWrapper>
              <ButtonFa
                fa="fa-chevron-left"
                side="left"
                background={colorMf}
                onClick={() => redirectTo(`/viewMf/${_id}#formativeUnits`)}
              >
                Volver a MF
              </ButtonFa>
            </BackButtonWrapper>
          </TabsPane>

          <TabsPane
            name="#BibliographicReferences"
            label="Referencias Bibliográficas"
          >
            <BibliographicReferences
              ufToView={{ bibliographicReferences }}
            />
          </TabsPane>
          <TabsPane
            name="#evaluationConfiguration"
            label="Configuración de la evaluación"
          >
            <ContentWrapper>
              {id && (
                <ViewProration proration={proration} color={colorMf} />
              )}
            </ContentWrapper>
          </TabsPane>
        </Tabs>
      </div>
    );
  }
}

Wrapper.propTypes = {
  ufToView: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  tab: PropTypes.string.isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

Wrapper.defaultProps = {
  ufToView: {},
};

export default Wrapper;
