import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Img from 'client/ui/Img';

const PictureWrapper = styled.div`
  margin-bottom: 2rem;
  ${props => props.noMargin && css`
    margin-bottom: 0;
  `}
`;

const Wrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-bottom: .5rem;
`;

const StyledImagePreview = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  img {
    width: auto;
    max-width: 100%;
  }
`;

const PictureText = styled.div`
  font-size: 0.875rem;
  color: ${props => props.theme.color.gray2};
  text-align: center;
`;

const PictureView = ({ picture, props, noMargin }) => {
  const { text } = props;
  return (
    <PictureWrapper className="row" noMargin={noMargin}>
      <Wrapper>
        { picture && picture.src && (
          <StyledImagePreview>
            <Img className="animated pulse" src={picture.src} alt="imagen" />
          </StyledImagePreview>
        )}
      </Wrapper>
      <Wrapper className="col-xs-12">
        <PictureText className="animated fadeIn delay-1s">{text}</PictureText>
      </Wrapper>
    </PictureWrapper>
  );
};

PictureView.propTypes = {
  picture: PropTypes.objectOf(PropTypes.any),
  props: PropTypes.objectOf(PropTypes.any),
  noMargin: PropTypes.bool,
};

PictureView.defaultProps = {
  picture: { src: '/images/contents/picture.jpg' },
  props: {},
  noMargin: false,
};

export default PictureView;
