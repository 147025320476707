import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const DangerousDiv = styled.div`
  line-height: 23px;
  color: ${props => props.theme.color.black};
  p:first-child {
    margin: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
`;

const TextView = ({ data }) => (
  <DangerousDiv className="animated fadeIn" dangerouslySetInnerHTML={{ __html: data }} />
);

TextView.propTypes = {
  data: PropTypes.string.isRequired,
};

TextView.defaultProps = {};

export default TextView;
