/* eslint react/no-array-index-key: 0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import jszip from 'jszip'; // eslint-disable-line no-unused-vars
import dataTablesI18n from './datatables.i18n.js';

window.JSZip = jszip;
const $ = require('jquery');
const dt = require('datatables.net'); // eslint-disable-line no-unused-vars

require('datatables.net-buttons/js/dataTables.buttons.js'); // eslint-disable-line no-unused-vars
require('datatables.net-buttons/js/buttons.colVis.js'); // Column visibility
require('datatables.net-buttons/js/buttons.html5.js'); // HTML 5 file export
require('datatables.net-select/js/dataTables.select.js'); // Select
require('./datetime-moment.js');

const StyledDatatable = styled.div`
  color: ${props => props.theme.color.modalTitle};
  .basic {
    color: ${props => props.theme.color.modalTitle};
  }

  .red {
    color: ${props => props.theme.color.red};
  }

  .blue {
    background-color: ${props => props.theme.color.black};
  }

  .action-buttons {
    display: flex;
  }

  .action-buttons .button:not(:last-child) {
  }
  a.button {
    text-decoration: none;
  }
  .button {
    display: inline-block;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 3px;
    padding: 0.65em 0.60em;
    font-size: 1rem;
    background-color: transparent;
  }


  .button.disabled {
    color: #e5e5e5;
    cursor: not-allowed;
  }

  .button-text {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid;
    border-radius: 1.5em;
    text-align: center;
    text-transform: uppercase;
    padding: 0.5em 1em;
    font-size: 0.875rem;
    background-color: transparent;
    user-select: none;
    outline: 0;
  }

  .button-text:hover {
    background: #e5e5e5;
  }

  .button-text.disabled {
    cursor: not-allowed;
    background-color: #e5e5e5;
    color: white;
    border: 0;
  }
  .hidden{
    display: none;
  }
  /* Tooltip container */
  .tooltip {
      position: relative;
      display: inline-block;
      margin-right: 0.25rem;
  }

  .tooltip.button-text-wrapper {
    padding-top: 4px;
  }

  @media (min-width: 640px) {
    .button {
      padding: 0.25em 0.25em;
    }
    .tooltip.button-text-wrapper {
      padding-top: 0;
    }
  }


  /* Tooltip text */
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 85px;
    background-color: rgba(0,0,0,.7);
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    font-size: 14px;

    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    left: 50%;
    margin-left: -50px;

    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;
  }

  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip:hover .tooltiptext {
      visibility: visible;
      opacity: 1;
      margin-top: .25rem
  }

  input[type="search"] {
    margin-bottom: 1rem;
  }

  div.dt-buttons {
    margin-bottom: .5rem;
  }

  div.dataTables_scroll {
    border-radius: ${props => props.theme.radius};
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.26), 0 2px 10px 0 rgba(0,0,0,0.16);
    margin-bottom: .5rem;
  }

  .dataTables_wrapper.no-footer .dataTables_scrollBody {
    border-bottom: 0;
  }

  thead {
    tr {
      th {
        text-align: left;
      }
      th.center-text {
        text-align: center;
      }
      th:first-child {
        border-top-left-radius: ${props => props.theme.radius};
      }
      th:last-child {
        border-top-right-radius: ${props => props.theme.radius};
      }
    }
  }
  tbody {
    tr {
      td {
        padding-left: 1rem;
      }
    }
  }
`;

const Th = styled.th`
  background-color: ${props => props.theme.color.darkGray};
  color: ${props => props.theme.color.white};
`;

function renderButton({ id, type, classButton, icon, routeName, actionName, target, module, text,
  enabledStatus, status }) {
  if (module && module === 'MODULES') {
    if (actionName === 'update' && enabledStatus !== status) {
      return `
        <div class="tooltip">
          <a class="button ${classButton} disabled">
            <i class="fas ${icon}"></i>
          </a>
        </div>`;
    }

    if ((actionName === 'export' && enabledStatus !== status)
    || (actionName === 'delete-module' && enabledStatus !== status)
    ) {
      return `
        <div class="tooltip">
          <button class="button ${classButton} ${actionName} disabled">
            <i class="fa fa-fw ${icon}"></i>
          </button>
        </div>`;
    }

    if (actionName === 'validate' && enabledStatus !== status) {
      return `
        <div class="tooltip button-text-wrapper">
          <button class="button-text ${classButton} ${actionName} disabled">
            ${text}
          </button>
        </div>`;
    }
  } else if (module && module === 'CENTREREPO') {
    if (actionName === 'export' && (status === 'DRAFT' || status === 'VALIDATEDTEACHER')) {
      return `
        <div class="tooltip">
          <button class="button ${classButton} ${actionName} disabled">
            <i class="fa fa-fw ${icon}"></i>
          </button>
        </div>`;
    }
    if (actionName === 'clone-module' && (status === 'DRAFT' || status === 'VALIDATEDTEACHER')) {
      return `
        <div class="tooltip">
          <button class="button ${classButton} ${actionName} disabled">
            <i class="fa fa-fw ${icon}"></i>
          </button>
        </div>`;
    }
  }

  if (type === 'link') {
    const path = `${routeName}${id}`;
    return `
      <div class="tooltip">
        <a class="button ${classButton}" href=${path} ${target}>
          <i class="fas ${icon}"></i>
        </a>
      </div>`;
  }

  if (type === 'button') {
    return `
      <div class="tooltip">
        <button class="button ${classButton} ${actionName}" data-id=${id} id="${actionName}">
          <i class="fa fa-fw ${icon}"></i>
        </button>
      </div>`;
  }

  if (type === 'button-text') {
    return `
      <div class="tooltip button-text-wrapper">
        <button class="button-text ${classButton} ${actionName}" data-id=${id} id="${actionName}">
          ${text}
        </button>
      </div>`;
  }
  if (type === 'selector') {
    let response = `<select data-id=${id} id="${actionName}">`;
    if (status === 'VALIDATEDTEACHER') {
      response += `<option value="DRAFT" data-id=${id}>Borrador</option>`;
      response += '<option value="VALIDATEDTEACHER" selected="true">Finalizado (docente)</option>';
      response += `<option value="VALIDATED" data-id=${id}>Validado (centro)</option>`;
      response += `<option value="PUBLICATED" data-id=${id}>Publicado</option>`;
    } else if (status === 'DRAFT') {
      response += `<option value="DRAFT" selected="true" data-id=${id}>Borrador</option>`;
      response += '<option value="VALIDATEDTEACHER">Finalizado (docente)</option>';
      response += `<option value="VALIDATED" data-id=${id}>Validado (centro)</option>`;
      response += `<option value="PUBLICATED" data-id=${id}>Publicado</option>`;
    } else if (status === 'VALIDATED') {
      response += `<option value="DRAFT" data-id=${id}>Borrador</option>`;
      response += '<option value="VALIDATEDTEACHER">Finalizado (docente)</option>';
      response += `<option value="VALIDATED" selected="true" data-id=${id}>Validado (centro)</option>`;
      response += `<option value="PUBLICATED" data-id=${id}>Publicado</option>`;
    } else {
      response += `<option value="DRAFT" data-id=${id}>Borrador</option>`;
      response += '<option value="VALIDATEDTEACHER">Finalizado (docente)</option>';
      response += `<option value="VALIDATED" data-id=${id}>Validado (centro)</option>`;
      response += `<option value="PUBLICATED" selected="true" data-id=${id}>Publicado</option>`;
    }

    response += '</select>';
    return response;
  }

  return null;
}

class Datatables extends Component {
  constructor() {
    super();
    this.state = {
      config: {
        stateSave: true,
        language: dataTablesI18n.getTranslation('es'),
        lengthChange: false,
        scrollX: true,
        columnDefs: [
          {
            targets: 'no-sort',
            orderable: false,
          },
          {
            targets: 'col-hidden',
            visible: false,
          },
        ],
      },
    };
    this.initDatatable = this.initDatatable.bind(this);
    this.renderHeaders = this.renderHeaders.bind(this);
    this.setDataToDatatable = this.setDataToDatatable.bind(this);
  }

  componentWillMount() {
    const { rows, config } = this.props;
    this.setState({
      rows,
      config: Object.assign(this.state.config, config),
    });
  }

  componentDidMount() {
    this.initDatatable();
  }

  componentWillReceiveProps(nextProps) {
    const { rows } = nextProps;
    this.setState({
      rows,
    });
  }

  shouldComponentUpdate(nextProps) {
    const { rows } = this.props;
    return !(JSON.stringify(rows) === JSON.stringify(nextProps.rows));
  }

  setDataToDatatable(datatable) {
    const { rows, datatableElement } = this.state;
    const { buttons } = this.props;
    const newRows = [];
    if (rows) {
      rows.forEach((row) => {
        const id = row[0];
        const newRow = row.slice(1);
        newRow.push(this.renderButtonsActions(id, row));
        newRows.push(newRow);
      });
    }
    const $currentDatatable = datatableElement || datatable;
    if ($currentDatatable) {
      $currentDatatable.clear().rows.add(newRows).draw();
      const buttonsWithActions = buttons.filter(x => x.type !== 'link');
      buttonsWithActions.forEach((button) => {
        $('#newsTable_wrapper').on('click', `.${button.actionName}`, (e) => {
          button.action(e);
        });
      });
      const selector = buttons.filter(x => x.type === 'selector');
      selector.forEach((select) => {
        $('#newsTable_wrapper').on('change', 'select', (e) => {
          select.action(e);
        });
      });
    }
  }

  initDatatable() {
    const { id } = this.props;
    const $table = $(`#${id}`);
    if ($table) {
      const { config } = this.state;
      let datatable = null;
      try {
        $.fn.dataTable.moment('DD/MM/YYYY');
        datatable = $table.DataTable(config);
      } catch (e) {
        datatable = null;
      } finally {
        this.setState({
          datatableElement: datatable,
        });
        this.setDataToDatatable(datatable);
      }
    }
  }

  renderButtonsActions(id, row) {
    const { buttons } = this.props;
    const renderedButtons = buttons.map((button) => {
      let status = '';
      if (button.module === 'MODULES') {
        status = row[3].substring(row[3].indexOf('</i>') + 4);
      }
      if (button.module === 'CENTREREPO') {
        status = row[5].substring(row[5].indexOf('</i>') + 4);
      }
      switch (status) {
        case 'Borrador':
          status = 'DRAFT';
          break;
        case 'Validado (centro)':
          status = 'VALIDATED';
          break;
        case 'Finalizado (docente)':
          status = 'VALIDATEDTEACHER';
          break;
        case 'Publicado':
          status = 'PUBLICATED';
          break;
        default: break;
      }
      Object.assign(button, { id, status });
      return renderButton(button);
    });
    return `
    <div class="action-buttons">
      ${renderedButtons.length ? renderedButtons.join(' ') : null}
    </div>
    `;
  }

  renderHeaders() {
    const { headers, buttons } = this.props;
    if (headers && headers.length) {
      const rows = headers.map((header, index) => {
        let cellClass = '';
        if (header.isHidden) {
          cellClass += 'col-hidden ';
        }
        if (header.sortDefault) {
          cellClass += 'sort-default ';
        }
        if (header.noSort) {
          cellClass += 'sort-sort ';
        }
        return (
          <Th key={index} className={cellClass}>
            {header.name}
          </Th>
        );
      });
      if (buttons && buttons.length) {
        rows.push((
          <Th key="actions" className="no-sort btn-actions">
            Acciones
          </Th>
        ));
      }
      return (
        <tr>
          {rows}
        </tr>
      );
    }
    return null;
  }

  render() {
    const { id } = this.props;
    this.setDataToDatatable();
    return (
      <StyledDatatable>
        <table id={id} className="display" width="100%">
          <thead>
            {this.renderHeaders()}
          </thead>
          <tbody />
        </table>
      </StyledDatatable>
    );
  }
}

Datatables.propTypes = {
  id: PropTypes.string.isRequired,
  headers: PropTypes.arrayOf(PropTypes.object).isRequired,
  rows: PropTypes.arrayOf(PropTypes.any).isRequired,
  buttons: PropTypes.arrayOf(PropTypes.object),
  config: PropTypes.objectOf(PropTypes.any),
};

Datatables.defaultProps = {
  buttons: [],
  config: {},
};

export default Datatables;
