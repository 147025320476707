import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import KeepAspectRatio from 'client/ui/KeepAspectRatio';
import IconFa from 'client/ui/IconFa';
import { getThemeColor, media } from 'client/ui/style-utils';

const StyledLink = styled(Link)`
  display: block;
  margin-bottom: 1rem;
  text-decoration: none;
`;

const StyledItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  padding: 0.5rem;
  text-align: center;
  color: ${props => getThemeColor(props.color)};
  background-color: #F9F9F9;
  border: 1px solid ${props => props.theme.color.gray};
  border-radius: ${props => props.theme.radius}
  box-shadow: 0 1px 6px #888;
`;

const StyledIcon = styled.div`
  margin-bottom: 0.5rem;
  font-size: 2rem;

  ${''/* 420 / 16 = 26.25em */}
  @media (min-width: 26.25em) {
    font-size: 3rem;
  }

  ${media.sm`
    font-size: 4rem;
  `}
`;

const RepositoryItem = ({ route, name, icon, color }) => (
  <StyledLink to={route} key={route}>
    <KeepAspectRatio>
      <StyledItem color={color}>
        <StyledIcon>
          <IconFa name={icon} />
        </StyledIcon>
        <div>{name}</div>
      </StyledItem>
    </KeepAspectRatio>
  </StyledLink>
);

RepositoryItem.propTypes = {
  route: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  color: PropTypes.string,
};

RepositoryItem.defaultProps = {
  color: 'primary',
};

export default RepositoryItem;
