import { api } from 'config';
import { handleStateModule } from 'client/modules/dashboard-mfs/actions/mfs';
import {
  MODULE_SUCCESS,
  MODULE_ERROR,
} from 'client/modules/dashboard-mfs/constants/actionTypes';

const fetchCentreModulesPromise = () => new Promise((resolve, reject) => {
  const token = localStorage.getItem('token') || null;
  const user = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
  const userId = user._id;
  const reqConfig = {
    method: 'GET',
    headers: Object.assign({}, {
      Authorization: `Bearer ${token}`,
    }),
  };
  fetch(`${api.baseUrl}/api/users/${userId}/centremfs/`, reqConfig)
    .then((response) => {
      if (response.status >= 400) {
        return reject(response.statusText);
      }
      return resolve(response.json());
    });
});

async function fetchCentreModules() {
  try {
    const documentData = await fetchCentreModulesPromise();
    return documentData;
  } catch (error) {
    return error;
  }
}

function cloneModule(data) {
  const token = localStorage.getItem('token') || null;
  const reqConfig = {
    method: 'POST',
    headers: Object.assign({}, {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
    body: JSON.stringify(data),
  };
  return (dispatch) => {
    fetch(`${api.baseUrl}/api/mfs/${data.id}/clone`, reqConfig)
      .then((response) => {
        if (response.status >= 400) {
          dispatch(handleStateModule(MODULE_ERROR, response.statusText));
        } else {
          dispatch(handleStateModule(MODULE_SUCCESS, 'Módulo Formativo clonado correctamente'));
        }
      })
      .catch(error => console.log(`Error: ${error}`));
  };
}

export {
  fetchCentreModules,
  cloneModule,
};
