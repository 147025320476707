/* eslint react/no-array-index-key: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { redirectTo } from 'index';
import styled, { css } from 'styled-components';
import ContentWrapper from 'client/ui/ContentWrapper';
import Li from 'client/ui/Li';

const StyledUl = styled.ul`
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 1.5rem;
  li {
    margin-bottom: 1rem;
  }
  ${props => props.paddingLeft && css`
    padding-left: 20px;
  `}
`;

const StyledLi = styled(Li)`
  display: flex;
  color: ${props => props.theme.color.black};
  &:before {
    content: "• ";
    color: ${props => props.color};
    margin-right: 10px;
  }
  animation-delay: 0.5s;
`;

const CustomUl = styled.ul`
  margin: 0;
  margin-bottom: .5rem;
  font-size: 1.125rem;
  li {
    margin-bottom: 1rem;
  }
`;

const UlExtended = styled(CustomUl)`
  font-size: 1rem;
`;

const CustomLi = styled.li`
  margin-bottom: .5rem;
  font-size: 0.875rem;
  cursor: pointer;
`;

const LiExtended = styled(CustomLi)`
  font-size: 1.25rem;
`;

const BasicLi = styled.li`
  cursor: pointer;
  div {
    display: inline-block;
  }
`;

const ContentUl = StyledUl.extend`
  li {
    margin-bottom: .5rem !important;
  }
`;

const MfIndex = ({ ufIndex, ufId, colorMf }) => (
  <ContentWrapper marginTop="2rem">
    <CustomUl>
      <BasicLi
        className="animated fadeIn"
        key={ufId}
        onClick={() => redirectTo(`/viewUf/${ufId}#generalData`)}
      >
        Objetivos y resumen
      </BasicLi>
      <li className="animated fadeIn">Mapa conceptual</li>
    </CustomUl>
    <UlExtended>
      {ufIndex.map(ua => (
        <div key={`div-${ua.id}`}>
          <LiExtended
            className="animated fadeIn"
            key={`obj-${ua.id}`}
            onClick={() => redirectTo(`/viewUa/${ua.id}`)}
          >
            {ua.title}
          </LiExtended>
          <ContentUl>
            {ua.topics.map((topic, index) => (
              <StyledLi className="animated fadeIn" color={colorMf} key={`obj-${topic}${index}`}>{topic}</StyledLi>
            ))}
          </ContentUl>
        </div>
      ))}
    </UlExtended>
    <CustomUl>
      <BasicLi
        className="animated fadeIn"
      >
        <div
          key={ufId}
          onClick={() => redirectTo(`/viewUf/${ufId}#BibliographicReferences`)}
          onKeyDown={() => redirectTo(`/viewUf/${ufId}#BibliographicReferences`)}
          role="button"
          tabIndex={0}
        >
          Referencias Bibliográficas
        </div>
      </BasicLi>
    </CustomUl>
  </ContentWrapper>
);

MfIndex.propTypes = {
  ufIndex: PropTypes.arrayOf(PropTypes.any).isRequired,
  ufId: PropTypes.string.isRequired,
  colorMf: PropTypes.string.isRequired,
};

MfIndex.defaultProps = {};

export default MfIndex;
