import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from 'client/ui/Button';
import Menu from './ConfigurationMenu';
import GalleryItem from './GalleryItem';
import ContentWrapperOptions from './ContentWrapperOptions';
import Carrousel from './Carrousel';

const AddButton = styled(Button)`
  margin-bottom: 1rem;
  display: flex;
  margin-right: auto;
  margin-left: auto;
`;

const MenuGalleryItem = styled.div`
  padding: 1rem 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid ${props => props.theme.color.grayTable};
  border-radius: ${props => props.theme.radius};
  box-shadow: 0px 2px ${props => props.theme.color.grayTable};
`;

const StyledButton = styled(Button)`
  margin-bottom: 1rem;
`;

const SliderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
`;

class Gallery extends Component {
  constructor() {
    super();
    this.state = {
      configurationVisible: false,
    };
    this.handleDisplayMenu = this.handleDisplayMenu.bind(this);
    this.close = this.close.bind(this);
    this.onItemDelete = this.onItemDelete.bind(this);
    this.onItemMove = this.onItemMove.bind(this);
  }

  onItemDelete(index) {
    const { id, removeGalleryItem } = this.props;
    removeGalleryItem(id, index);
  }

  onItemMove(index, direction) {
    const { id, moveGalleryItem } = this.props;
    moveGalleryItem(id, index, direction);
  }

  handleDisplayMenu() {
    const { configurationVisible } = this.state;
    this.setState({ configurationVisible: !configurationVisible });
  }

  close() {
    this.setState({ configurationVisible: false });
  }

  render() {
    const { id, addGalleryItem, onTextChange, items, savePicture } = this.props;
    const { configurationVisible } = this.state;
    return (
      <div>
        <Menu
          title="Editar galería de imagen"
          width="25rem"
          isOpen={configurationVisible}
          close={this.close}
        >
          <div>
            <AddButton
              onClick={() => addGalleryItem(id)}
            >
              Añadir item
            </AddButton>
            {items && items.map((item, index) => (
              <MenuGalleryItem
                key={`Content_Wrapper_${index}`}
              >
                <ContentWrapperOptions
                  id={index.toString()}
                  remove={this.onItemDelete}
                  moveContent={this.onItemMove}
                  noBottomProps
                >
                  <GalleryItem
                    key={`gallery${index}${id}`}
                    id={id}
                    index={index}
                    text={item.text}
                    picture={item.url}
                    savePicture={savePicture}
                    onTextChange={onTextChange}
                    previewMaxWidth="100px"
                  />
                </ContentWrapperOptions>
              </MenuGalleryItem>
            ))}
          </div>
        </Menu>
        <StyledButton
          hollow
          color="black"
          onClick={() => this.handleDisplayMenu()}
        >
          Editar
        </StyledButton>
        <SliderWrapper>
          <Carrousel elements={items} />
        </SliderWrapper>
      </div>
    );
  }
}

Gallery.propTypes = {
  id: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  onTextChange: PropTypes.func.isRequired,
  savePicture: PropTypes.func.isRequired,
  addGalleryItem: PropTypes.func.isRequired,
  removeGalleryItem: PropTypes.func.isRequired,
  moveGalleryItem: PropTypes.func.isRequired,
};

Gallery.defaultProps = {};

export default Gallery;
