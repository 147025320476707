import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Img from 'client/ui/Img';
import Input from 'client/ui/Input';
import FileUploadButton from 'client/ui/FileUploadButton';

const Wrapper = styled.div`
  margin-bottom: 1rem;
`;

const StyledImagePreview = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
  img {
    width: auto;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
`;

const StyledInput = styled(Input)`
  margin-bottom: 0;
`;

class Picture extends Component {
  constructor() {
    super();
    this.state = {
      picture: null,
      props: {},
    };
    this.handleUploadPicture = this.handleUploadPicture.bind(this);
  }

  componentWillMount() {
    const { picture, props } = this.props;
    this.setState({ picture, props });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ picture: nextProps.picture, props: nextProps.props });
  }

  handleUploadPicture(event) {
    const { changePicture, id } = this.props;
    if (event.target.files && event.target.files[0]) {
      const fileReader = new FileReader();
      const file = event.target.files[0];
      fileReader.onload = (upload) => {
        const picture = {
          src: upload.target.result,
          file,
        };
        this.setState({ picture });

        changePicture(id, picture);
      };
      fileReader.readAsDataURL(event.target.files[0]);
    }
  }

  render() {
    const { id, changePictureText } = this.props;
    const { picture, props } = this.state;
    return (
      <div className="row">
        <Wrapper className="col-xs-12">
          { picture && picture.src && (
            <StyledImagePreview>
              <Img src={picture.src} alt="imagen" />
            </StyledImagePreview>
          )}
        </Wrapper>
        <Wrapper className="col-xs-12">
          <StyledInput
            placeholder="Pie de imagen"
            type="text"
            name="text"
            value={props && props.text}
            onChange={(value) => { changePictureText(id, value); }}
            required
          />
        </Wrapper>
        <div className="col-xs-12 center-xs">
          <FileUploadButton
            id={id}
            label="Añadir imagen"
            type="image/*"
            onChange={this.handleUploadPicture}
            required="required"
          />
        </div>
      </div>
    );
  }
}

Picture.propTypes = {
  id: PropTypes.string.isRequired,
  picture: PropTypes.objectOf(PropTypes.any),
  changePicture: PropTypes.func.isRequired,
  changePictureText: PropTypes.func.isRequired,
  props: PropTypes.objectOf(PropTypes.any),
};

Picture.defaultProps = {
  props: {},
  picture: { src: '/images/contents/picture.jpg' },
};

export default Picture;
