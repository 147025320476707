import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import IconFa from 'client/ui/IconFa';
import Img from 'client/ui/Img';

/**
 * 1. Hace que el alto sea el mismo que el del contenedor y no mayor.
 *    Comentar la propiedad si no se desea este comportamiento.
 */
const StyledNavigation = styled.nav`
  position: fixed;
  top: 0;
  width: ${props => props.width};
  min-height: 100%;
  background-color: #EEEEEE;
  border-right: 1px solid ${props => props.theme.color.gray};
  z-index: 110;
  transform: translateX(${props => (props.isOpen ? props.menuWidth : '-100%')});
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  height: 100vh; ${''/* 1 */}
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 1rem 0;
  text-align: center;
`;

const CloseButton = styled(IconFa)`
  margin-right: 1rem;
  cursor: pointer;
`;

/**
 * 1. Hace que aparezca scroll cuando el contenido sea mayor al tamaño del contenedor.
 *    Comentar la propiedad si no se desea este comportamiento.
 */
const StyledBody = styled.div`
  flex: 1;
  overflow: auto; ${''/* 1 */}
`;

const OptionTitle = styled.div`
  font-weight: bold;
  margin-bottom: .5rem;
`;

const OptionWrapper = styled.div`
  cursor: pointer;
  margin: 1rem 2.65rem 2rem 1rem;

  &:hover{
    opacity: 0.80;
  }
`;

const OptionContent = styled.div`
  background-color: white;
  height: 125px;
  overflow: hidden;
`;

const Option = ({ title, onClick, children }) => (
  <OptionWrapper
    role="button"
    onClick={onClick}
    tabIndex={0}
  >
    <OptionTitle>{title}</OptionTitle>
    <OptionContent>
      {children}
    </OptionContent>
  </OptionWrapper>
);

Option.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

Option.defaultProps = {};

class SubMenu extends Component {
  constructor() {
    super();
    this.state = {};
    this.renderTextComponents = this.renderTextComponents.bind(this);
    this.renderListComponents = this.renderListComponents.bind(this);
    this.renderPictureComponents = this.renderPictureComponents.bind(this);
    this.renderInteractiveComponents = this.renderInteractiveComponents.bind(this);
    this.renderPopupComponents = this.renderPopupComponents.bind(this);
    this.renderMultimediaComponents = this.renderMultimediaComponents.bind(this);
    this.renderGalleryComponents = this.renderGalleryComponents.bind(this);
    this.renderAssimilationComponents = this.renderAssimilationComponents.bind(this);
    this.renderContent = this.renderContent.bind(this);
  }

  renderContent() {
    const { type } = this.props;
    switch (type) {
      case 'text':
        return this.renderTextComponents();
      case 'picture':
        return this.renderPictureComponents();
      case 'gallery':
        return this.renderGalleryComponents();
      case 'list':
        return this.renderListComponents();
      case 'interactive':
        return this.renderInteractiveComponents();
      case 'popup':
        return this.renderPopupComponents();
      case 'multimedia':
        return this.renderMultimediaComponents();
      case 'assimilation':
        return this.renderAssimilationComponents();
      default:
        return null;
    }
  }

  renderTextComponents() {
    const { addContent } = this.props;
    return (
      <div>
        <Option onClick={() => addContent('text')} title="Texto">
          <Img src="/images/contents/text.png" alt="Texto" />
        </Option>
        <Option onClick={() => addContent('textTitle')} title="Texto con titulo">
          <Img src="/images/contents/textWithTitle.png" alt="Texto con título" />
        </Option>
        <Option onClick={() => addContent('title')} title="Titulo">
          <Img src="/images/contents/title.png" alt="Título" />
        </Option>
      </div>);
  }

  renderPictureComponents() {
    const { addContent } = this.props;
    return (
      <div>
        <Option onClick={() => addContent('picture')} title="Imagen">
          <Img src="/images/contents/picture.png" alt="Imágen" />
        </Option>
        <Option onClick={() => addContent('textPicture')} title="Imagen con texto lateral">
          <Img src="/images/contents/textPicture.png" alt="Imágen" />
        </Option>
      </div>);
  }

  renderGalleryComponents() {
    const { addContent } = this.props;
    return (
      <div>
        <Option onClick={() => addContent('gallery')} title="Carrusel">
          <Img src="/images/contents/carrusel.png" alt="Galería" />
        </Option>
      </div>
    );
  }

  renderListComponents() {
    const { addContent } = this.props;
    return (
      <div>
        <Option onClick={() => addContent('ul')} title="Lista">
          <Img src="/images/contents/ul.png" alt="Listado" />
        </Option>
        <Option onClick={() => addContent('ol')} title="Lista numerada">
          <Img src="/images/contents/ol.png" alt="Texto" />
        </Option>
      </div>);
  }

  renderInteractiveComponents() {
    const { addContent } = this.props;
    return (
      <div>
        <Option onClick={() => addContent('link')} title="Enlace">
          <Img src="/images/contents/link.png" alt="Enlace" />
        </Option>
      </div>);
  }

  renderPopupComponents() {
    const { addContent } = this.props;
    return (
      <div>
        <Option onClick={() => addContent('button-popup')} title="Botón">
          <Img src="/images/contents/popupButton.jpg" alt="Botón emergente" />
        </Option>
        <Option onClick={() => addContent('text-popup')} title="Botón y texto lateral">
          <Img src="/images/contents/popupText.jpg" alt="Botón emergente con texto" />
        </Option>
        <Option onClick={() => addContent('image-popup')} title="Botón e imagen lateral">
          <Img src="/images/contents/popupImage.jpg" alt="Botón emergente con imagen" />
        </Option>
      </div>);
  }

  renderMultimediaComponents() {
    const { addContent } = this.props;
    return (
      <div>
        <Option onClick={() => addContent('video')} title="Video">
          <Img src="/images/contents/video.png" alt="Video" />
        </Option>
        <Option onClick={() => addContent('audio')} title="Audio">
          <Img src="/images/contents/audio.png" alt="Audio" />
        </Option>
        <Option onClick={() => addContent('resource')} title="Recurso">
          <Img src="/images/contents/download.png" alt="Recurso" />
        </Option>
        <Option onClick={() => addContent('collapse')} title="Botón en cascada">
          <Img src="/images/contents/collapse.png" alt="Botón en cascada" />
        </Option>
        <Option onClick={() => addContent('tabs')} title="Botón en etiqueta">
          <Img src="/images/contents/tabs.png" alt="Botón en etiqueta" />
        </Option>
      </div>);
  }

  renderAssimilationComponents() {
    const { addContent } = this.props;
    return (
      <div>
        <Option onClick={() => addContent('simple')} title="Respuesta Simple">
          <Img src="/images/contents/simple.png" alt="Respuesta Simple" />
        </Option>
        <Option onClick={() => addContent('multiple')} title="Respuesta Múltiple">
          <Img src="/images/contents/multiple.png" alt="Respuesta Múltiple" />
        </Option>
        <Option onClick={() => addContent('clozeSelect')} title="Cloze con selección">
          <Img src="/images/contents/selectionCloze.png" alt="Cloze con selección" />
        </Option>
        <Option onClick={() => addContent('cloze')} title="Cloze">
          <Img src="/images/contents/cloze.png" alt="Cloze" />
        </Option>
        <Option onClick={() => addContent('mixedQuestions')} title="Mixto">
          <Img src="/images/contents/mixed.jpg" alt="Mixed" />
        </Option>
      </div>
    );
  }

  render() {
    const { isOpen, width, menuWidth, close } = this.props;
    return (
      <StyledNavigation isOpen={isOpen} width={width} menuWidth={menuWidth}>
        <StyledHeader>
          <CloseButton name="times" onClick={close} />
        </StyledHeader>
        <StyledBody>
          {this.renderContent()}
        </StyledBody>
      </StyledNavigation>
    );
  }
}

SubMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  menuWidth: PropTypes.string,
  width: PropTypes.string,
  type: PropTypes.string.isRequired,
  addContent: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

SubMenu.defaultProps = {
  menuWidth: '14rem',
  width: '17.18rem',
};

export default SubMenu;
