import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { api } from 'config';
import Label from 'client/ui/Label';
import Input from 'client/ui/Input';
import MediumEditor from 'client/ui/MediumEditor';
import ButtonFa from 'client/ui/ButtonFa';
import { mediumEditorContentTypeText } from 'client/modules/dashboard/utils/medium-editor';
import UploadPictureMenu from './UploadPictureMenu';

const Editor = styled(MediumEditor)`
  &:focus {
    border: 2px solid #b0d6fe;
    box-shadow: 0px 0px 41px 0px #b0d6fe;
  }
`;

const StyledInput = styled(Input)`
  &:focus {
    border: 2px solid #b0d6fe;
    box-shadow: 0px 0px 20px 0px #b0d6fe;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

const StyledButtonFa = styled(ButtonFa)`
  display: inline-block;
  cursor: pointer;
  padding: 0.65em 0.60em;
  font-size: 1rem;
  background-color: transparent;
`;

const ButtonPopupItem = ({ id, title, buttonText, text, index, savePicture, onTitleChange,
  onTextChange, picture, previewMaxWidth, onButtonChange, movePicture, positionImage }) => (
    <div>
      <Label>Texto del botón</Label>
      <StyledInput
        type="text"
        value={buttonText}
        onChange={(value) => { onButtonChange(id, index, value); }}
        small
      />
      <Label>Título del popup</Label>
      <StyledInput
        type="text"
        value={title}
        onChange={(value) => { onTitleChange(id, index, value); }}
        small
      />
      {positionImage === 'up' ? (
        <div>
          <Wrapper>
            <StyledButtonFa
              fa="fa-arrow-down"
              transparent
              color="modalTitle"
              onClick={() => movePicture(id, index, 'down')}
            />
          </Wrapper>
          <UploadPictureMenu
            id={`id-${id}${index}`}
            image={picture && picture.length > 0 ? `${api.baseUrl}${picture}` : ''}
            onChange={(selected) => { savePicture(selected, id, index); }}
            previewMaxWidth={previewMaxWidth}
            required=""
          />
          <Label>Texto del popup</Label>
          <Editor
            id={id}
            text={text}
            onChange={(value) => { onTextChange(id, index, value); }}
            options={{
              toolbar: { buttons: mediumEditorContentTypeText },
              placeholder: { text: 'Añadir texto', hideOnClick: true },
            }}
          />
        </div>
      ) : (
          <div>
            <Label>Texto del popup</Label>
            <Editor
              id={id}
              text={text}
              onChange={(value) => { onTextChange(id, index, value); }}
              options={{
                toolbar: { buttons: mediumEditorContentTypeText },
                placeholder: { text: 'Añadir texto', hideOnClick: true },
              }}
            />
            <Wrapper>
              <StyledButtonFa
                fa="fa-arrow-up"
                transparent
                color="modalTitle"
                onClick={() => movePicture(id, index, 'up')}
              />
            </Wrapper>
            <UploadPictureMenu
              id={`id-${id}${index}`}
              image={picture && picture.length > 0 ? `${api.baseUrl}${picture}` : ''}
              onChange={(selected) => { savePicture(selected, id, index); }}
              previewMaxWidth={previewMaxWidth}
              required=""
            />
          </div>
        )}
    </div>
  );

ButtonPopupItem.propTypes = {
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  title: PropTypes.string,
  picture: PropTypes.string,
  movePicture: PropTypes.func,
  savePicture: PropTypes.func,
  onTitleChange: PropTypes.func,
  onTextChange: PropTypes.func.isRequired,
  previewMaxWidth: PropTypes.string,
  buttonText: PropTypes.string,
  onButtonChange: PropTypes.func,
};

ButtonPopupItem.defaultProps = {
  picture: null,
  previewMaxWidth: '150px',
  title: '',
  onTitleChange: null,
  onButtonChange: null,
  savePicture: null,
  buttonText: 'Botón Popup',
};

export default ButtonPopupItem;
