import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from 'client/ui/Button';
import Img from 'client/ui/Img';

const StyledItem = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  height: 80px;
  margin-bottom: .5rem;
  border: 1px solid ${props => props.theme.color.grayTable};
  border-radius: ${props => props.theme.radius};
  box-shadow: 0px 2px ${props => props.theme.color.grayTable};
`;

const NumberWrapper = styled.div`
  display: flex;
  height: 100%;
  vertical-align: middle;
`;

const Number = styled.div`
  font-size: 1.875rem;
  font-weight: bold;
  width: 50px;
  margin-right: 1rem;
  text-align: center;
  align-self: center;
`;

const UaColor = styled.div`
  width: 7px;
  background-color: ${props => props.color};
  margin-right: 1rem;
  height: 100%;
  z-index: 2;
`;

const TitleWrapper = styled.div`
  margin-right: 1rem;
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Buttons = styled.div`
  margin-right: .5rem;
  align-self: center;
  display: flex;
  flex-wrap: nowrap;
  a, button {
    margin-right: .5rem;
    font-size: 1rem;
    i {
      font-size: 1rem;
    }
  }
`;

const AccessButton = styled(Button)`
  width: 181px;
`;

const UfPicture = styled.div`
  width: 126px;
  min-width: 126px;
  height: 78px;
  overflow: hidden;
  border-top-left-radius: 0.24em;
  border-bottom-left-radius: 0.24em;
  margin-right: 1rem;
  text-align: center;
  img {
    width: 126px;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
`;

const ScreensCounter = styled.div`
  font-size: 0.75rem;
  color: ${props => props.theme.color.modalTitle};
  margin-bottom: .5rem;
`;

const Title = styled.div`
  color: ${props => props.theme.color.black};
  font-weight: bold;
  font-size: 1rem;
`;

const Item = ({ title, id, uf, index, screens, codeUf, picture, colorMf, colorUa }) => {
  const preview = uf ? '/viewUf/' : '/viewUa/';
  return (
    <StyledItem>
      {!uf
        ? <NumberWrapper><UaColor color={colorUa} /><Number>{index + 1}</Number></NumberWrapper>
        : <UfPicture><Img src={picture} /></UfPicture>
      }
      <TitleWrapper>
        <ScreensCounter>Pantallas {screens}</ScreensCounter>
        <Title>{codeUf} {title}</Title>
      </TitleWrapper>
      <Buttons>
        {!uf
          ? <AccessButton background={colorMf} to={`${preview}${id}`}>Ir a contenido</AccessButton>
          : <Button background={colorMf} to={`${preview}${id}`}>Acceder</Button>
        }
      </Buttons>
    </StyledItem>
  );
};

Item.propTypes = {
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  codeUf: PropTypes.string.isRequired,
  screens: PropTypes.number.isRequired,
  uf: PropTypes.bool.isRequired,
  picture: PropTypes.string,
  colorMf: PropTypes.string.isRequired,
  colorUa: PropTypes.string.isRequired,
};

Item.defaultProps = {
  picture: '',
};

export default Item;
