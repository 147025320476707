import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import buttonStyles from '../Button/buttonStyles';

const Label = styled.label`
  ${buttonStyles}
  text-transform: uppercase;
`;

const Input = styled.input`
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
`;

const FileUploadButton = ({ id, name, label, type, required, onChange }) => (
  <Label htmlFor={id} background="darkGray" color="white">
    {label}
    <Input
      id={id}
      name={name}
      type="file"
      accept={type}
      onChange={onChange}
      required={required}
    />
  </Label>
);

FileUploadButton.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.string,
  onChange: PropTypes.func,
};

FileUploadButton.defaultProps = {
  label: 'Subir imagen',
  name: 'file',
  type: '*/*',
  required: '',
  onChange: null,
};

export default FileUploadButton;
