import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Tabs, TabsPane, ContentTab } from 'client/ui/Tabs';
import TabsItem from './TabsItemView';

const StyledTabsPane = styled(TabsPane)`
  padding-top: 1rem;
  margin-bottom: 1rem;
  border: 1px solid ${props => props.theme.color.borderBg}
`;

const Wrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
`;

class TabsView extends Component {
  constructor() {
    super();
    this.state = {
      tab: 'tab-0',
    };
    this.handleTabClick = this.handleTabClick.bind(this);
  }

  handleTabClick(tab) {
    this.setState({ tab });
  }

  render() {
    const { items, id } = this.props;
    const { tab } = this.state;
    const width = `${100 / items.length}%`;
    return (
      <Wrapper>
        <Tabs
          tabsTitleMaxWidth={null}
          tabsBarBorder="none"
          selected={tab}
          component={ContentTab}
          onTabClick={currentTab => this.handleTabClick(currentTab)}
          componentProps={{ color: 'red', width }}
        >
          {items && items.map((item, index) => (
            <StyledTabsPane key={`tabs${index}-${id}`} name={`tab-${index}`} label={item.title}>
              <TabsItem
                text={item.text}
                picture={item.url}
              />
            </StyledTabsPane>
          ))}
        </Tabs>
      </Wrapper>
    );
  }
}

TabsView.propTypes = {
  id: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
};

TabsView.defaultProps = {};

export default TabsView;
