import styled from 'styled-components';

const CodeName = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-left: .5rem;
  margin-right: 1rem;
  width: 100%;
`;

export default CodeName;
