import { css } from 'styled-components';

const imgStyles = css`

  ${props => props.fix && css`
    min-width: 640px;
    @media screen and (min-width:0\0) {
   /* Estilos CSS para IE9, IE10, IE11 */
    min-width: 640px;
    }
  `}

  ${props => props.logo50 && css`
    width: auto;
    max-height: 50px;
    margin: auto;
    display: block;
  `}

  ${props => props.preview && css`
    width: 100%;
    object-fit: contain;
  `};
`;

export default imgStyles;
