import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import saveScormFile from 'client/modules/core/utils/downloadScorm';
import { getRoleModuleActions } from 'client/modules/dashboard-permissions/utils/modules-actions';
import { clearNotification, deleteModule, downloadScorm, save } from 'client/modules/dashboard-mfs/actions/mfs';
import List from '../components/Repository';
import fetchMyModules from '../actions/repository';

class ModulesContainer extends Component {
  constructor() {
    super();
    this.state = {
      mfs: [],
      modalIsOpen: false,
      validateModalIsOpen: false,
      moduleId: '',
      notFound: false,
      userPermissions: {},
      changedState: false,
    };
    this.hideModal = this.hideModal.bind(this);
    this.showModal = this.showModal.bind(this);
    this.showValidateModal = this.showValidateModal.bind(this);
    this.onValidateConfirm = this.onValidateConfirm.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.exportScorm = this.exportScorm.bind(this);
  }

  componentWillMount() {
    const { permissions } = this.props;
    const userPermissions = getRoleModuleActions(permissions, 'MODULES');
    this.setState({ userPermissions });
    this.loadModules();
  }

  componentWillReceiveProps(nextProps) {
    const { permissions } = nextProps;
    if (nextProps.notification.type === 'success') {
      this.loadModules();
    }
    const userPermissions = getRoleModuleActions(permissions, 'MODULES');
    this.setState({ userPermissions });
  }

  onConfirm() {
    const { moduleId } = this.state;
    const { remove } = this.props;
    this.hideModal();
    remove(moduleId);
  }

  onValidateConfirm() {
    const { moduleId } = this.state;
    const { saveMf } = this.props;
    this.setState({ changedState: true });
    this.hideModal();
    const data = {
      id: moduleId,
      status: 'VALIDATEDTEACHER',
    };
    saveMf(data);
  }

  loadModules() {
    fetchMyModules().then((mfsData) => {
      if (mfsData.length === 0) {
        this.setState({ notFound: true });
      }
      const userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
      const currentUserId = userInfo._id;
      const mfs = [];
      mfsData.forEach((module) => {
        const { _id, nameMf, centre, status, updatedAt, coAutor } = module;

        // Control para que el autor original no vea el mf clonado
        if (coAutor && coAutor !== currentUserId) {
          return false;
        }

        const id = _id;
        let parsedStatus;
        switch (status) {
          case 'DRAFT':
            parsedStatus = '<i style="width:10px;padding-right:12px;color:#a6a6a6;"  class="fas fa-circle"></i>Borrador';
            break;
          case 'VALIDATEDTEACHER':
            parsedStatus = '<i style="width:10px;padding-right:12px;color:#fbb03b;"  class="fas fa-circle"></i>Finalizado (docente)';
            break;
          case 'VALIDATED':
            parsedStatus = '<i style="width:10px;padding-right:12px;color:#7ac943;" class="fas fa-circle"></i>Validado (centro)';
            break;
          case 'PUBLICATED':
            parsedStatus = '<i style="width:10px;padding-right:12px;color:#29B6F6;" class="fas fa-circle"></i>Publicado';
            break;
          default:
            break;
        }
        const parsedCentre = centre && centre.name ? centre.name : 'Sin centro';
        const parsedUpdatedAt = moment(updatedAt).format('DD/MM/YYYY');
        const current = [
          id,
          nameMf,
          parsedCentre,
          parsedStatus,
          parsedUpdatedAt,
        ];
        mfs.push(current);
      });
      this.setState({ mfs });
    });
  }

  showModal(e) {
    const moduleId = e.currentTarget.getAttribute('data-id');
    if (moduleId) {
      this.setState({ modalIsOpen: true, moduleId });
    }
  }

  showValidateModal(e) {
    const moduleId = e.currentTarget.getAttribute('data-id');
    if (moduleId) {
      this.setState({ validateModalIsOpen: true, moduleId });
    }
  }

  exportScorm(e) {
    const { mfs } = this.state;
    const moduleId = e.currentTarget.getAttribute('data-id');
    const module = mfs.filter(mod => mod[0] === moduleId);
    downloadScorm(moduleId).then((response) => {
      saveScormFile(response, module[0][1]);
    });
  }

  hideModal() {
    this.setState({ modalIsOpen: false, validateModalIsOpen: false });
  }

  render() {
    const { mfs, modalIsOpen, notFound, userPermissions, validateModalIsOpen,
      changedState } = this.state;
    const { notification, clearMessages } = this.props;
    return (
      <List
        modules={mfs}
        notification={notification}
        clearMessages={clearMessages}
        modalIsOpen={modalIsOpen}
        validateModalIsOpen={validateModalIsOpen}
        showValidateModal={this.showValidateModal}
        afterNotification={this.afterNotification}
        changedState={changedState}
        showModal={this.showModal}
        hideModal={this.hideModal}
        onConfirm={this.onConfirm}
        onValidateConfirm={this.onValidateConfirm}
        notFound={notFound}
        exportScorm={this.exportScorm}
        userPermissions={userPermissions}
      />
    );
  }
}

ModulesContainer.propTypes = {
  clearMessages: PropTypes.func.isRequired,
  saveMf: PropTypes.func.isRequired,
  notification: PropTypes.objectOf(PropTypes.any).isRequired,
  remove: PropTypes.func.isRequired,
  permissions: PropTypes.arrayOf(PropTypes.object).isRequired,
};

ModulesContainer.defaultProps = {};

const mapStateToProps = (state) => {
  const { error, success, message } = state.mfs;
  const notification = {};

  if (error) {
    notification.type = 'error';
    notification.content = message;
  } else if (success) {
    notification.type = 'success';
    notification.content = message;
  }

  return {
    notification,
    permissions: state.permissions.userPermissions,
  };
};

const mapDispatchToProps = dispatch => ({
  clearMessages: () => { dispatch(clearNotification()); },
  saveMf: (data) => { dispatch(save(data)); },
  remove: (moduleId) => { dispatch(deleteModule(moduleId)); },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModulesContainer);
