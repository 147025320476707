/* eslint react/no-array-index-key: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { H2 } from 'client/ui/Headings';
import Label from 'client/ui/Label';
import Span from 'client/ui/Span';
import Input from 'client/ui/Input';
import Button from 'client/ui/Button';
import ButtonFa from 'client/ui/ButtonFa';
import ButtonGroup from 'client/ui/ButtonGroup';
import Divider from 'client/ui/Divider';
import MediumEditor from 'client/ui/MediumEditor';
import UploadPicture from 'client/ui/UploadPicture';
import FormWrapper from 'client/ui/FormWrapper';
import { mediumEditorBasic } from 'client/modules/dashboard/utils/medium-editor';

const Wrapper = styled.div`
  margin-bottom: 1rem;
`;

const StyledInfo = styled.div`
  font-size: 0.875rem;
  color: ${props => props.theme.color.addContentColor};
  margin-top: .5rem;
`;

const RemoveIcon = styled.div`
  color: red;
  position: absolute;
  top: 2px;
  right: 10px;
  button {
    text-transform: none;
  }
`;

const InputElement = styled.div`
  position: relative;
`;

const Form = ({ moduleToUpdate, submit, inputChange, addToCollection, removeFromCollection,
  changeItemOnCollection, handlePictureChange,
  handleMediumEditorChange, saveAndNext }) => {
  const { title, introduction, subtitles, objectives, coverPicture, haveUf } = moduleToUpdate;
  const nextTab = haveUf ? '#formativeUnits' : '#learningUnits';
  return (
    <FormWrapper>
      <H2>PORTADA MF</H2>
      <form onSubmit={(event) => { submit(event); }}>
        <Label>
          <Span spanForm>Título de MF</Span>
          <Input
            type="text"
            name="title"
            value={title}
            onChange={(event) => { inputChange(event); }}
            required
          />
        </Label>
        <Wrapper>
          {subtitles && subtitles.length === 1
            && (
              <InputElement>
                <Label>
                  <Span spanForm>Subtítulo de MF</Span>
                  <Input
                    type="text"
                    name="subtitle-0"
                    value={subtitles[0]}
                    onChange={(value) => { changeItemOnCollection('subtitles', 0, value); }}
                  />
                </Label>
              </InputElement>
            )
          }
          {subtitles && subtitles.length > 1 && subtitles.map((subtitle, index) => (
            <InputElement key={`subtitle ${index}`}>
              <Label>
                <Span spanForm>Subtítulo de MF</Span>
                <Input
                  type="text"
                  name={`subtitle-${index}`}
                  value={subtitle}
                  onChange={(value) => { changeItemOnCollection('subtitles', index, value); }}
                  required
                />
                {index > 0
                  && (
                    <RemoveIcon>
                      <ButtonFa
                        fa="fa-trash-alt"
                        transparent
                        color="red"
                        onClick={() => removeFromCollection('subtitles', index)}
                      >
                        Eliminar
                      </ButtonFa>
                    </RemoveIcon>)}
              </Label>
            </InputElement>
          ))}
          <Button onClick={() => addToCollection('subtitles')}>Añadir subtítulo</Button>
        </Wrapper>
        <Wrapper>
          <Label>
            <Span spanForm>Imagen de MF</Span>
          </Label>
          <UploadPicture
            image={coverPicture}
            onChange={handlePictureChange}
            required=""
            // required={coverPicture ? '' : 'required'}
          />
          <StyledInfo>
            Nota: Tamaño óptimo de la imagen, 1200x330px.
          </StyledInfo>
        </Wrapper>

        <Divider marginBottom="2rem" marginTop="1rem" />
        <H2>INTRODUCCIÓN Y OBJETIVOS DE MF</H2>
        <Label>
          <Span spanForm>Introducción MF</Span>
          <MediumEditor
            text={introduction}
            onChange={(value) => { handleMediumEditorChange(value, 'introduction'); }}
            options={{
              toolbar: { buttons: mediumEditorBasic },
              placeholder: false,
            }}
          />
        </Label>
        <Wrapper>
          {objectives && objectives.length === 1
            && (
              <InputElement>
                <Label>
                  <Span spanForm>Objetivos MF</Span>
                  <Input
                    type="text"
                    name="objective-0"
                    value={objectives[0]}
                    onChange={(value) => { changeItemOnCollection('objectives', 0, value); }}
                  />
                </Label>
              </InputElement>
            )
          }
          {objectives && objectives.length > 1 && objectives.map((objective, index) => (
            <InputElement key={`objective ${index}`}>
              <Label>
                <Span spanForm>Objetivos MF</Span>
                <Input
                  type="text"
                  name={`objective-${index}`}
                  value={objective}
                  onChange={(value) => { changeItemOnCollection('objectives', index, value); }}
                  required
                />
                {index > 0
                  && (
                    <RemoveIcon>
                      <ButtonFa
                        fa="fa-trash-alt"
                        transparent
                        color="red"
                        onClick={() => removeFromCollection('objectives', index)}
                      >
                        Eliminar
                      </ButtonFa>
                    </RemoveIcon>)}
              </Label>
            </InputElement>
          ))}
          <Button onClick={() => addToCollection('objectives')}>Añadir objetivo</Button>
        </Wrapper>
        <ButtonGroup align="right">
          <Button type="submit">Guardar</Button>
          <ButtonFa
            fa="fa-chevron-right"
            hollow
            borderColor="black"
            color="black"
            side="right"
            type="submit"
            onClick={() => { saveAndNext(nextTab); }}
          >
            Siguiente
          </ButtonFa>
        </ButtonGroup>
      </form>
    </FormWrapper>
  );
};

Form.propTypes = {
  moduleToUpdate: PropTypes.objectOf(PropTypes.any),
  inputChange: PropTypes.func.isRequired,
  addToCollection: PropTypes.func.isRequired,
  removeFromCollection: PropTypes.func.isRequired,
  changeItemOnCollection: PropTypes.func.isRequired,
  handlePictureChange: PropTypes.func.isRequired,
  handleMediumEditorChange: PropTypes.func.isRequired,
  saveAndNext: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
};

Form.defaultProps = {
  moduleToUpdate: {},
};

export default Form;
