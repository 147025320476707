import SET_PERMITIONS from '../constants/actionTypes';

function setPermissions(permissions) {
  return {
    type: SET_PERMITIONS,
    permissions,
  };
}

export default setPermissions;
