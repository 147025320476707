import { css } from 'styled-components';

const liStyles = css`

  ${props => props.marginTopBotton && css`
    margin: 1.5rem 0;
  `}
`;

export default liStyles;
