import React from 'react';
import styled from 'styled-components';
import { SortableHandle } from 'react-sortable-hoc';

const StyledDragHandle = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.color.white};
  background: ${props => props.theme.color.lightGray};
  border-top-right-radius: ${props => props.theme.radius};
  border-bottom-right-radius: ${props => props.theme.radius};
`;

const StyledDragIcon = styled.i`
  cursor: ns-resize;
`;

const DragHandle = SortableHandle(() => (
  <StyledDragHandle>
    <StyledDragIcon className="fa fa-bars fa-fw" aria-hidden="true" />
  </StyledDragHandle>
));

export default DragHandle;
