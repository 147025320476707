import React from 'react';
import PropTypes from 'prop-types';
import LiView from './LiView';

const UlView = ({ list, type, colorMf }) => (
  <div>
    {list.map((li, index) => (
      <LiView
        key={`li${index}`}
        text={li}
        type={type}
        index={index}
        colorMf={colorMf}
      />
    ))}
  </div>
);

UlView.propTypes = {
  list: PropTypes.arrayOf(PropTypes.string).isRequired,
  type: PropTypes.string.isRequired,
  colorMf: PropTypes.string.isRequired,
};

UlView.defaultProps = {};

export default UlView;
