import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const ContentWrapper = styled.div`
  padding-left: ${props => props.theme.outerMargin};
  padding-right: ${props => props.theme.outerMargin};
  max-width: ${props => props.theme.maxWidth};
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
  ${props => props.maxWidth && css`
    max-width: ${props.maxWidth};
  `};
  ${props => props.marginTop && css`
    margin-top: ${props.marginTop};
  `};
    ${props => props.lateralPadding !== '' && css`
    padding-left: ${props.lateralPadding};
    padding-right: ${props.lateralPadding};
  `};
`;

ContentWrapper.propTypes = {
  maxWidth: PropTypes.string,
  marginTop: PropTypes.string,
  lateralPadding: PropTypes.string,
};

ContentWrapper.defaultProps = {
  maxWidth: null,
  marginTop: '1rem',
  lateralPadding: '',
};

export default ContentWrapper;
