import { api } from 'config';
import {
  ROLE_SUCCESS,
  ROLE_ERROR,
  ROLE_CLEAR_NOTIFICATION,
} from '../constants/actionTypes';

function handleStateRoles(type, message) {
  return {
    type,
    message,
  };
}

function clearNotification() {
  return {
    type: ROLE_CLEAR_NOTIFICATION,
  };
}

function saveRole(data) {
  const token = localStorage.getItem('token') || null;
  const reqConfig = {
    method: 'POST',
    headers: Object.assign({}, {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
    body: JSON.stringify(data),
  };
  return (dispatch) => {
    fetch(`${api.baseUrl}/api/roles/`, reqConfig)
      .then((response) => {
        if (response.status >= 400) {
          dispatch(handleStateRoles(ROLE_ERROR, response.statusText));
        } else {
          dispatch(handleStateRoles(ROLE_SUCCESS, 'Guardado correctamente'));
        }
      })
      .catch(error => console.log(`Error: ${error}`));
  };
}

function updateRole(data, id) {
  const token = localStorage.getItem('token') || null;
  const reqConfig = {
    method: 'PUT',
    headers: Object.assign({}, {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
    body: JSON.stringify(data),
  };

  return (dispatch) => {
    fetch(`${api.baseUrl}/api/roles/${id}`, reqConfig)
      .then((response) => {
        if (response.status >= 400) {
          dispatch(handleStateRoles(ROLE_ERROR, response.statusText));
        } else {
          dispatch(handleStateRoles(ROLE_SUCCESS, 'Actualizado correctamente'));
        }
      })
      .catch(error => console.log(`Error: ${error}`));
  };
}

function save(data) {
  return (dispatch) => {
    if (!data) {
      return dispatch(handleStateRoles(ROLE_ERROR, 'Debes completar el formulario'));
    }
    if (data.id) {
      const { id } = data;
      delete data.id;
      dispatch(updateRole(data, id));
    } else {
      dispatch(saveRole(data));
    }
  };
}

function deleteRole(id) {
  const token = localStorage.getItem('token') || null;
  const reqConfig = {
    method: 'DELETE',
    headers: Object.assign({}, {
      Authorization: `Bearer ${token}`,
    }),
  };

  return (dispatch) => {
    fetch(`${api.baseUrl}/api/roles/${id}`, reqConfig)
      .then((response) => {
        if (response.status >= 400) {
          dispatch(handleStateRoles(ROLE_ERROR, response.statusText));
        } else {
          dispatch(handleStateRoles(ROLE_SUCCESS, 'Eliminado correctamente'));
        }
      })
      .catch(error => console.log(`Error: ${error}`));
  };
}

const fetchRolePromise = () => new Promise((resolve, reject) => {
  const token = localStorage.getItem('token') || null;
  const reqConfig = {
    method: 'GET',
    headers: Object.assign({}, {
      Authorization: `Bearer ${token}`,
    }),
  };
  fetch(`${api.baseUrl}/api/roles/`, reqConfig)
    .then((response) => {
      if (response.status >= 400) {
        return reject(response.statusText);
      }
      return resolve(response.json());
    });
});

async function fetchAllRoles() {
  try {
    const roleData = await fetchRolePromise();
    return roleData;
  } catch (error) {
    return error;
  }
}

export {
  fetchAllRoles,
  save,
  deleteRole,
  clearNotification,
};
