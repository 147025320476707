import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Input from 'client/ui/Input';
import Button from 'client/ui/Button';
import ButtonFa from 'client/ui/ButtonFa';
import IconFa from 'client/ui/IconFa';
import DragHandle from 'client/ui/DragHandle';
import Span from 'client/ui/Span';
import { savePage } from 'client/modules/dashboard-pages/actions/pages';
import ListPages from 'client/modules/dashboard-pages/containers/List';

const Item = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 1rem;
  border: 1px solid ${props => props.theme.color.grayTable};
  border-radius: ${props => props.theme.radius};
  background-color: white;
`;

/* El min-width: 0 corrige el bug cuando el texto es demasiado largo
y evita que se desplace el contenido fuera del contenedor */
const Column = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 1rem 0;
  width: 100%;
  min-width: 0;
  padding-right: 1rem;
`;

const StyledItemOptions = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledButtonFa = styled(ButtonFa)`
  display: inline-block;
  cursor: pointer;
  padding: 0 0.60em 0.65em;
  font-size: 1rem;
  background-color: transparent;
`;

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: no-wrap;
  height: 36px;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  button {
    margin-left: 1rem;
  }
`;

const ContentColor = styled.div`
  width: 7px;
  background-color: ${props => props.theme.color.greyContent};
  margin-right: 1rem;
  height: 100%;
  z-index: 2;
`;

const ContentIconsWrapper = styled.div`
  position: absolute;
  background-color: white;
  right: 5px;
  top: 95px;
  display: flex;
  justify-content: space-around;
  border: 1px solid grey;
  border-radius: 6px;
  box-shadow: 10px 10px 5px -6px rgba(0,0,0,0.75);
  z-index: 1000;
`;

const ContentIconWrapper = styled.div`
  padding: 1rem;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1rem;
  border: 1px solid white;
  z-index: 1000;
  &:hover {
    background-color: #EEEEEE;
    border: 1px solid #EEEEEE;
  }
  i {
    margin-bottom: .5rem;
    font-size: 1.5rem;
  }
`;

const FixedTitle = styled.div`
  width: 100%;
  text-align: left;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 2.1rem;
  padding-right: 1rem;
  white-space: nowrap;
`;

const NumberWrapper = styled.div`
  display: flex;
`;

const StyledIconFa = styled(IconFa)`
  margin-left: 1rem;
`;

const StyledSpan = styled(Span)`
  margin-left: 0.5rem;
`;

const StyledInput = styled(Input)`
  margin-bottom: 0;
  margin-left: 1.5rem;
`;

const EditionButton = styled(Button)`
  width: 182px;
`;

const StyledNumber = styled.div`
  font-size: 1.25rem;
`;

const ContentIcon = ({ icon, text, ...rest }) => (
  <ContentIconWrapper {...rest}>
    <IconFa regular name={icon} />
    <div>{text}</div>
  </ContentIconWrapper>
);

ContentIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

ContentIcon.defaultProps = {};

class TopicItem extends Component {
  constructor() {
    super();
    this.state = {
      showContents: false,
      newPageId: null,
      title: '',
      edition: true,
      propTitle: '',
    };
    this.handleShowContentTypes = this.handleShowContentTypes.bind(this);
    this.saveTopic = this.saveTopic.bind(this);
    this.newPage = this.newPage.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.toggleEdition = this.toggleEdition.bind(this);
  }

  componentWillMount() {
    const { title } = this.props;
    if (title === '') {
      this.setState({ edition: true });
    } else {
      this.setState({ title, propTitle: title, edition: false });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { title } = nextProps;
    if (title === '') {
      this.setState({ edition: true });
    } else {
      this.setState({ title, propTitle: title, edition: false });
    }
  }

  saveTopic() {
    const { id, save, pages, type } = this.props;
    const { title } = this.state;
    const data = {
      id,
      title,
    };
    if (pages.length === 1 && (type === 'evaluation' || type === 'page')) {
      const pageData = {
        id: pages[0],
        title,
      };
      savePage(pageData);
    }
    save(data);
  }

  toggleEdition() {
    const { edition, title } = this.state;
    const { id, save, pages, type } = this.props;
    if (edition && id !== '') {
      const data = {
        id,
        title,
      };
      if (pages.length === 1 && (type === 'evaluation' || type === 'page')) {
        const pageData = {
          id: pages[0],
          title,
        };
        savePage(pageData);
      }
      save(data);
    }
    this.setState({ edition: !edition });
  }

  newPage(type) {
    this.handleShowContentTypes();
    const { id, save } = this.props;
    const { title } = this.state;
    const data = {
      topic: id,
      title: '',
    };

    const topicData = {
      id,
      type,
    };

    if (type === 'page' || type === 'evaluation') {
      Object.assign(data, { type, title });
    }

    savePage(data);
    save(topicData);
  }

  handleShowContentTypes() {
    const { showContents } = this.state;
    this.setState({ showContents: !showContents });
  }

  handleInputChange(event) {
    const { target } = event;
    const { name, value } = target;
    this.setState({ [name]: value });
  }

  render() {
    const { onRemove, number, id, type, pages } = this.props;
    const { showContents, newPageId, edition, title, propTitle } = this.state;
    return (
      <Item>
        <div>
          <ContentColor />
        </div>
        <Column>
          <StyledItemOptions>
            <NumberWrapper>
              <StyledNumber>
                {number}
              </StyledNumber>
              {type === 'folder' && (
                <div>
                  <StyledIconFa
                    regular
                    name="folder-open"
                    color="modalTitle"
                  />
                  <StyledSpan color="modalTitle" weight="300">Carpeta</StyledSpan>
                </div>
              )}
              {type === 'page' && (
                <div>
                  <StyledIconFa
                    regular
                    name="copy"
                    color="modalTitle"
                  />
                  <StyledSpan color="modalTitle" weight="300">Página</StyledSpan>
                </div>
              )}
              {type === 'evaluation' && (
                <div>
                  <StyledIconFa
                    regular
                    name="check-square"
                    color="modalTitle"
                  />
                  <StyledSpan color="modalTitle" weight="300">Evaluación</StyledSpan>
                </div>
              )}
            </NumberWrapper>
            <div>
              <StyledButtonFa
                regular
                fa="fa-pencil-alt"
                transparent
                color="black"
                onClick={this.toggleEdition}
              />
              <StyledButtonFa
                fa="fa-trash-alt"
                transparent
                color="red"
                onClick={() => onRemove(id)}
              />
            </div>
          </StyledItemOptions>
          <InputWrapper>
            {edition
              ? (
                <StyledInput
                  type="text"
                  name="title"
                  value={title}
                  onChange={this.handleInputChange}
                  required
                />)
              : <FixedTitle>{title}</FixedTitle>
            }
            <div>
              {edition && (
                <Button
                  disabled={title === ''}
                  onClick={() => this.saveTopic()}
                >
                  Guardar
                </Button>)}
              {!edition && type === '' && propTitle !== '' && (
                <EditionButton
                  disabled={id === null}
                  onClick={() => this.handleShowContentTypes()}
                >
                  Añadir contenido
                </EditionButton>
              )}
              {!edition && (type === 'page' || type === 'evaluation') && propTitle !== '' && (
                <EditionButton
                  disabled={id === null}
                  to={`/dashboard/contents/${pages[0]}`}
                  onClick={() => this.handleShowContentTypes()}
                >
                  Editar contenido
                </EditionButton>
              )}
              {!edition && type === 'folder' && (
                <EditionButton
                  disabled
                >
                  Añadir contenido
                </EditionButton>
              )}
            </div>
          </InputWrapper>

          {type === 'folder' && (
            <ListPages topicId={id} newPageId={newPageId} modeEdit />
          )}

          {showContents && (
            <ContentIconsWrapper>
              <ContentIcon icon="file" text="Página" onClick={() => this.newPage('page')} />
              <ContentIcon icon="check-square" text="Evaluación" onClick={() => this.newPage('evaluation')} />
              <ContentIcon icon="folder-open" text="Carpeta" onClick={() => this.newPage('folder')} />
            </ContentIconsWrapper>)
          }
        </Column>
        <DragHandle />
      </Item>
    );
  }
}

TopicItem.propTypes = {
  id: PropTypes.string,
  number: PropTypes.number.isRequired,
  title: PropTypes.string,
  save: PropTypes.func.isRequired,
  pages: PropTypes.arrayOf(PropTypes.string).isRequired,
  onRemove: PropTypes.func.isRequired,
  type: PropTypes.string,
};

TopicItem.defaultProps = {
  id: null,
  title: '',
  type: '',
};

export default TopicItem;
