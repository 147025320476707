/* eslint max-len: 0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Span from 'client/ui/Span';
import ButtonFa from 'client/ui/ButtonFa';
import { H2 } from 'client/ui/Headings';
import SimpleQuestionView from './SimpleQuestionView';
import MultipleQuestionView from './MultipleQuestionView';
import ClozeQuestionView from './ClozeQuestionView';
import ClozeSelectView from './ClozeSelectView';

const Wrapper = styled.div`
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
  border-top: 0.5rem solid ${props => props.colorMf};
  border-left: 1px solid ${props => props.theme.color.borderBg};
  border-bottom: 1px solid ${props => props.theme.color.borderBg};
  border-right: 1px solid ${props => props.theme.color.borderBg};
`;

const StyledH2 = styled(H2)`
  padding-top: 1.5rem;
  padding-left: 2rem;
  font-size: 24px;
  color: ${props => props.theme.color.modalTitle};
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  font-weight: 900 !important;
  padding: 1rem 2rem 1rem 2rem;
`;

const QuestionWrapper = styled.div`
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3rem;
`;

const CounterSpan = styled(Span)`
  float: right;
`;

class QuestionsView extends Component {
  constructor() {
    super();
    this.state = {
      currentQuestionIndex: 0,
    };
    this.previous = this.previous.bind(this);
    this.next = this.next.bind(this);
  }

  previous() {
    const { currentQuestionIndex } = this.state;
    const prev = currentQuestionIndex - 1;
    if (prev >= 0) {
      this.setState({ currentQuestionIndex: prev });
    }
  }

  next() {
    const { items } = this.props;
    const { currentQuestionIndex } = this.state;
    const next = currentQuestionIndex + 1;

    if (next < items.length) {
      this.setState({ currentQuestionIndex: next });
    }
  }

  renderContent() {
    const { items, config } = this.props;
    const { currentQuestionIndex } = this.state;
    const item = items[currentQuestionIndex];
    if (!item) {
      return null;
    }
    switch (item.type) {
      case 'simple':
        if (config.messAnswers) {
          item.options = item.options.map(a => [Math.random(), a]).sort((a, b) => a[0] - b[0]).map(a => a[1]);
        }
        return (
          <div>
            <StyledH2>Seleccione la respuesta correcta:</StyledH2>
            <SimpleQuestionView item={item} />
          </div>
        );
      case 'multiple':
        if (config.messAnswers) {
          item.options = item.options.map(a => [Math.random(), a]).sort((a, b) => a[0] - b[0]).map(a => a[1]);
        }
        return (
          <div>
            <StyledH2>Seleccione la/las respuestas correctas:</StyledH2>
            <MultipleQuestionView item={item} />
          </div>
        );
      case 'cloze':
        return (
          <div>
            <StyledH2>Rellene el campo vacío:</StyledH2>
            <ClozeQuestionView item={item} />
          </div>
        );
      case 'clozeSelect':
        if (config.messAnswers) {
          item.options = item.options.map(a => [Math.random(), a]).sort((a, b) => a[0] - b[0]).map(a => a[1]);
        }
        return (
          <div>
            <StyledH2>Elija una opción:</StyledH2>
            <ClozeSelectView item={item} />
          </div>
        );
      default:
        return null;
    }
  }

  render() {
    const { items, colorMf } = this.props;
    const { currentQuestionIndex } = this.state;
    return (
      <QuestionWrapper>
        <Wrapper colorMf={colorMf}>
          <div>
            {this.renderContent()}
          </div>
          {colorMf
            && (
              <ButtonWrapper>
                <ButtonFa
                  fa="fa-chevron-left"
                  onClick={this.previous}
                  side="left"
                  background={colorMf}
                  disabled={currentQuestionIndex === 0}
                >
                  Atras
                </ButtonFa>
                <ButtonFa
                  fa="fa-chevron-right"
                  onClick={this.next}
                  side="right"
                  background={colorMf}
                  disabled={currentQuestionIndex === items.length - 1}
                >
                  Continuar
                </ButtonFa>
              </ButtonWrapper>
            )}
        </Wrapper>
        <CounterSpan color="modalTitle">{currentQuestionIndex + 1}/{items.length}</CounterSpan>
      </QuestionWrapper>
    );
  }
}

QuestionsView.propTypes = {
  colorMf: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  config: PropTypes.objectOf(PropTypes.any),
};

QuestionsView.defaultProps = {
  config: {},
};

export default QuestionsView;
