import { api } from 'config';

import {
  USER_SUCCESS,
  USER_ERROR,
  USER_CLEAR_NOTIFICATION,
} from '../constants/actionTypes';

function handleStateUser(type, message) {
  return {
    type,
    message,
  };
}

function clearNotification() {
  return {
    type: USER_CLEAR_NOTIFICATION,
  };
}

const fetchUserPromise = id => new Promise((resolve, reject) => {
  const token = localStorage.getItem('token') || null;
  const reqConfig = {
    method: 'GET',
    headers: Object.assign({}, {
      Authorization: `Bearer ${token}`,
    }),
  };
  fetch(`${api.baseUrl}/api/users/${id}`, reqConfig)
    .then((response) => {
      if (response.status >= 400) {
        return reject(response.statusText);
      }
      return resolve(response.json());
    });
});

async function fetchUser(id) {
  try {
    const userData = await fetchUserPromise(id);
    return userData;
  } catch (error) {
    return error;
  }
}

const fetchAllUsersPromise = () => new Promise((resolve, reject) => {
  const token = localStorage.getItem('token') || null;
  const reqConfig = {
    method: 'GET',
    headers: Object.assign({}, {
      Authorization: `Bearer ${token}`,
    }),
  };
  fetch(`${api.baseUrl}/api/users`, reqConfig)
    .then((response) => {
      if (response.status >= 400) {
        return reject(response.statusText);
      }
      return resolve(response.json());
    });
});

async function fetchAllUsers() {
  try {
    const newsData = await fetchAllUsersPromise();
    return newsData;
  } catch (error) {
    return error;
  }
}

const fetchAllPublicUsersPromise = () => new Promise((resolve, reject) => {
  const token = localStorage.getItem('token') || null;
  const reqConfig = {
    method: 'GET',
    headers: Object.assign({}, {
      Authorization: `Bearer ${token}`,
    }),
  };
  fetch(`${api.baseUrl}/api/users/public`, reqConfig)
    .then((response) => {
      if (response.status >= 400) {
        return reject(response.statusText);
      }
      return resolve(response.json());
    });
});

async function fetchAllPublicUsers() {
  try {
    const newsData = await fetchAllPublicUsersPromise();
    return newsData;
  } catch (error) {
    return error;
  }
}

function saveUser(data) {
  const token = localStorage.getItem('token') || null;
  const reqConfig = {
    method: 'POST',
    headers: Object.assign({}, {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
    body: JSON.stringify(data),
  };
  return (dispatch) => {
    fetch(`${api.baseUrl}/auth/register`, reqConfig)
      .then((response) => {
        if (response.status === 200) {
          dispatch(handleStateUser(USER_SUCCESS, 'Guardado correctamente'));
        }
        return response.json();
      })
      .then((saveUserResponse) => {
        if (Array.isArray(saveUserResponse) && saveUserResponse.length > 0) {
          dispatch(handleStateUser(USER_ERROR, saveUserResponse[0].msg));
        }
      })
      .catch(error => console.log(`Error: ${error}`));
  };
}

function updateUser(data, id) {
  const token = localStorage.getItem('token') || null;
  const reqConfig = {
    method: 'PUT',
    headers: Object.assign({}, {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
    body: JSON.stringify(data),
  };

  return (dispatch) => {
    fetch(`${api.baseUrl}/api/users/${id}`, reqConfig)
      .then((response) => {
        if (response.status === 200) {
          dispatch(handleStateUser(USER_SUCCESS, 'Actualizado correctamente'));
        }
        return response.json();
      })
      .then((saveUserResponse) => {
        if (Array.isArray(saveUserResponse) && saveUserResponse.length > 0) {
          dispatch(handleStateUser(USER_ERROR, saveUserResponse[0].msg));
        }
      })
      .catch(error => console.log(`Error: ${error}`));
  };
}

function save(data) {
  return (dispatch) => {
    if (!data) {
      return dispatch(handleStateUser(USER_ERROR, 'Debes completar el formulario'));
    }
    const { id } = data;
    delete data.id;
    if (id) {
      dispatch(updateUser(data, id));
    } else {
      dispatch(saveUser(data));
    }
  };
}

function deleteUser(id) {
  const token = localStorage.getItem('token') || null;
  const reqConfig = {
    method: 'DELETE',
    headers: Object.assign({}, {
      Authorization: `Bearer ${token}`,
    }),
  };

  return (dispatch) => {
    fetch(`${api.baseUrl}/api/users/${id}`, reqConfig)
      .then((response) => {
        if (response.status >= 400) {
          dispatch(handleStateUser(USER_ERROR, response.statusText));
        } else {
          dispatch(handleStateUser(USER_SUCCESS, 'Eliminado correctamente'));
        }
      })
      .catch(error => console.log(`Error: ${error}`));
  };
}

function changePassword(id, data) {
  const token = localStorage.getItem('token') || null;
  const reqConfig = {
    method: 'PUT',
    headers: Object.assign({}, {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
    body: JSON.stringify(data),
  };
  return (dispatch) => {
    fetch(`${api.baseUrl}/api/users/${id}/password`, reqConfig)
      .then((response) => {
        if (response.status === 200) {
          dispatch(handleStateUser(USER_SUCCESS, 'Contraseña actualizada correctamente'));
        } else {
          return response.json();
        }
      }).then((message) => {
        if (message) {
          dispatch(handleStateUser(USER_ERROR, message));
        }
      })
      .catch(error => console.log(`Error: ${error}`));
  };
}

export {
  clearNotification,
  fetchUser,
  fetchAllUsers,
  fetchAllPublicUsers,
  save,
  deleteUser,
  changePassword,
};
