import React from 'react';
import PropTypes from 'prop-types';
import { H2 } from 'client/ui/Headings';
import styled from 'styled-components';
import Input from 'client/ui/Input';
import ToggleSwitch from 'client/ui/ToggleSwitch';
import ContentWrapper from 'client/ui/ContentWrapper';

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

const ItemsWrapper = styled.div`
  width: 100%;
  max-width: 670px;
  margin-left: auto;
  margin-right: auto;
`;

const Title = styled.div``;

const NumberInput = styled(Input)`
  width: 80px;
  margin-bottom: 0;
`;

const EvaluationConfiguration = ({ requiredPoints, attempts, showFeedback, messQuestions,
  messAnswers, toggleSwitch, numberChange }) => (
    <ContentWrapper>
      <H2>Configuración</H2>
      <ItemsWrapper>
        <Item>
          <Title>Porcentaje para aprobar</Title>
          <NumberInput
            type="number"
            step="5"
            value={requiredPoints}
            onChange={event => numberChange('requiredPoints', event)}
            min="0"
            max="100"
          />
        </Item>
        <Item>
          <Title>Número de intentos</Title>
          <NumberInput
            type="number"
            step="1"
            min="1"
            value={attempts}
            onChange={event => numberChange('attempts', event)}
          />
        </Item>
        <Item>
          <Title>Mostrar feedback correcto</Title>
          <ToggleSwitch
            on={showFeedback}
            onClick={() => toggleSwitch('showFeedback')}
            text={showFeedback ? 'Si' : 'No'}
            reverse
          />
        </Item>
        <Item>
          <Title>Desordenar las preguntas</Title>
          <ToggleSwitch
            on={messQuestions}
            onClick={() => toggleSwitch('messQuestions')}
            text={messQuestions ? 'Si' : 'No'}
            reverse
          />
        </Item>
        <Item>
          <Title>Desordenar las respuestas</Title>
          <ToggleSwitch
            on={messAnswers}
            onClick={() => toggleSwitch('messAnswers')}
            text={messAnswers ? 'Si' : 'No'}
            reverse
          />
        </Item>
      </ItemsWrapper>
    </ContentWrapper>
);

EvaluationConfiguration.propTypes = {
  requiredPoints: PropTypes.number,
  attempts: PropTypes.number,
  showFeedback: PropTypes.bool,
  messQuestions: PropTypes.bool,
  messAnswers: PropTypes.bool,
  toggleSwitch: PropTypes.func.isRequired,
  numberChange: PropTypes.func.isRequired,
};

EvaluationConfiguration.defaultProps = {
  requiredPoints: 80,
  attempts: 1,
  showFeedback: false,
  messQuestions: false,
  messAnswers: false,
};

export default EvaluationConfiguration;
