import {
  ROLE_SUCCESS,
  ROLE_ERROR,
  ROLE_CLEAR_NOTIFICATION,
} from '../constants/actionTypes';

const initialState = {
  isFetching: false,
  success: false,
  error: false,
};

export default function roles(state = initialState, action) {
  switch (action.type) {
    case ROLE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        success: true,
        message: action.message,
      });

    case ROLE_ERROR:
      return Object.assign({}, state, {
        isFetching: false,
        error: true,
        message: action.message,
      });

    case ROLE_CLEAR_NOTIFICATION:
      return {
        ...state,
        error: null,
        success: null,
      };
    default:
      return state;
  }
}
