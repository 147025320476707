/* eslint react/no-array-index-key: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import Button from 'client/ui/Button';
import Notification from 'client/ui/Notification';
import ModalConfirm from 'client/ui/Modal';
import TopicItem from './TopicItem';
import TopicItemView from './TopicItemView';

const TopicsWrapper = styled.div`
  text-align: center;
`;

const AddButton = styled(Button)`
  margin-bottom: 1rem;
`;

const SortableItem = SortableElement(({ title, number, id, save, type, pages, addPage,
  onRemove }) => (
    <TopicItem
      title={title}
      number={number}
      id={id}
      save={save}
      type={type}
      pages={pages}
      addPage={addPage}
      onRemove={onRemove}
    />
));

const SortableList = SortableContainer(({ topics, addPage, showModal, save }) => (
  <div>
    {topics.map((tema, index) => (
      <SortableItem
        key={`Tema-${index}`}
        index={index}
        title={tema.title}
        number={index + 1}
        id={tema._id}
        save={save}
        type={tema.type}
        pages={tema.pages}
        addPage={addPage}
        onRemove={showModal}
      />
    ))}
  </div>));

const List = ({ save, notification, clearMessages, topics, modalIsOpen, hideModal, onConfirm,
  showModal, addTopic, addPage, modeEdit, onSortEnd, colorMf }) => {
  if (modeEdit) {
    return (
      <TopicsWrapper>
        <Notification
          message={notification}
          afterNotification={clearMessages}
        />
        <AddButton onClick={() => addTopic()}>Crear Nuevo</AddButton>
        {topics && topics.length > 0
          ? (
            <SortableList
              lockAxis="y"
              useDragHandle
              onSortEnd={onSortEnd}
              topics={topics}
              save={save}
              addPage={addPage}
              showModal={showModal}
            />)
          : <p>No hay ningún contenido creado</p>
        }
        <ModalConfirm
          isOpen={modalIsOpen}
          title="Eliminar"
          content="¿Desea eliminar el tema?"
          onCancel={hideModal}
          onConfirm={onConfirm}
        />
      </TopicsWrapper>
    );
  }
  return (
    <TopicsWrapper>
      {topics && topics.length > 0
        ? (
          <div> {topics.map((tema, index) => (
            <TopicItemView
              key={`Tema-${index}`}
              title={tema.title}
              number={index + 1}
              id={tema._id}
              type={tema.type}
              pages={tema.pages}
              colorMf={colorMf}
            />
          ))}
          </div>
        )
        : <p>No hay ningún contenido creado</p>
      }
    </TopicsWrapper>
  );
};

List.propTypes = {
  topics: PropTypes.arrayOf(PropTypes.any),
  clearMessages: PropTypes.func.isRequired,
  notification: PropTypes.objectOf(PropTypes.any).isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onSortEnd: PropTypes.func.isRequired,
  addTopic: PropTypes.func.isRequired,
  addPage: PropTypes.func.isRequired,
  modeEdit: PropTypes.bool.isRequired,
  save: PropTypes.func.isRequired,
  colorMf: PropTypes.string.isRequired,
};

List.defaultProps = {
  topics: [],
};

export default List;
