import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RandomId from 'short-unique-id';
import moment from 'moment';
import saveScormFile from 'client/modules/core/utils/downloadScorm';
import { getRoleModuleActions } from 'client/modules/dashboard-permissions/utils/modules-actions';
import { fetchCentres } from 'client/modules/dashboard-centres/actions/centres';
import { clearNotification, deleteModule, downloadScorm, save } from 'client/modules/dashboard-mfs/actions/mfs';
import { fetchAllPublicUsers } from 'client/modules/dashboard-users/actions/users';
import List from '../components/Repository';
import { fetchCentreModules, cloneModule } from '../actions/repository';

class ModulesContainer extends Component {
  constructor() {
    super();
    this.state = {
      mfs: [],
      modalIsOpen: false,
      validateModalIsOpen: false,
      cloneModalIsOpen: false,
      moduleId: '',
      notFound: false,
      userPermissions: {},
      newStatus: '',
      datatableKey: '1234',
      centre: null,
      colaborator: null,
      users: [],
      user: null,
    };
    this.hideModal = this.hideModal.bind(this);
    this.showModal = this.showModal.bind(this);
    this.showValidateModal = this.showValidateModal.bind(this);
    this.showCloneModal = this.showCloneModal.bind(this);
    this.cancelValidate = this.cancelValidate.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.exportScorm = this.exportScorm.bind(this);
    this.onValidateConfirm = this.onValidateConfirm.bind(this);
    this.onCloneConfirm = this.onCloneConfirm.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.loadUsers = this.loadUsers.bind(this);
  }

  componentWillMount() {
    const { permissions, centres, loadCentres } = this.props;
    const uid = new RandomId();
    const userPermissions = getRoleModuleActions(permissions, 'CENTREREPO');
    this.setState({ userPermissions, datatableKey: uid.randomUUID(12) });
    this.loadModules();
    this.loadUsers();
    if (!centres.length) {
      loadCentres();
    }
  }

  componentWillReceiveProps(nextProps) {
    const { permissions } = nextProps;
    if (nextProps.notification.type === 'success') {
      this.loadModules();
    }
    const userPermissions = getRoleModuleActions(permissions, 'CENTREREPO');
    this.setState({ userPermissions });
  }

  onConfirm() {
    const { moduleId } = this.state;
    const { remove } = this.props;
    this.hideModal();
    remove(moduleId);
  }

  onValidateConfirm() {
    const { moduleId, newStatus } = this.state;
    const { saveMf } = this.props;
    this.hideModal();
    const data = {
      id: moduleId,
      status: newStatus,
    };
    saveMf(data);
  }

  onCloneConfirm() {
    const { moduleId, user, centre } = this.state;
    const { cloneMf } = this.props;
    const data = {
      id: moduleId,
      coAutor: user.value,
      newCentre: centre.value,
    };
    this.hideModal();
    cloneMf(data);
  }

  handleSelectChange(name, value) {
    this.setState({ [name]: value });
  }

  loadModules() {
    fetchCentreModules().then((mfsData) => {
      if (mfsData.length === 0) {
        this.setState({ notFound: true });
      }
      const mfs = [];
      mfsData.forEach((module) => {
        const { _id, nameMf, centre, status, updatedAt, createdBy, coAutor } = module;
        const id = _id;
        let parsedStatus;
        switch (status) {
          case 'DRAFT':
            parsedStatus = '<i style="width:10px;padding-right:12px;color:#a6a6a6;"  class="fas fa-circle"></i>Borrador';
            break;
          case 'VALIDATEDTEACHER':
            parsedStatus = '<i style="width:10px;padding-right:12px;color:#fbb03b;"  class="fas fa-circle"></i>Finalizado (docente)';
            break;
          case 'VALIDATED':
            parsedStatus = '<i style="width:10px;padding-right:12px;color:#7ac943;" class="fas fa-circle"></i>Validado (centro)';
            break;
          case 'PUBLICATED':
            parsedStatus = '<i style="width:10px;padding-right:12px;color:#29B6F6;" class="fas fa-circle"></i>Publicado';
            break;
          default: break;
        }
        const parsedCentre = centre && centre.name ? centre.name : 'Sin centro';
        const parsedUpdatedAt = moment(updatedAt).format('DD/MM/YYYY');
        const parsedUsername = `${createdBy.firstName} ${createdBy.lastName}`;
        const parsedCoAutor = coAutor ? `${coAutor.firstName} ${coAutor.lastName}` : '';
        const current = [
          id,
          nameMf,
          parsedCentre,
          parsedUsername,
          parsedCoAutor,
          parsedStatus,
          parsedUpdatedAt,
        ];
        mfs.push(current);
      });
      this.setState({ mfs });
    });
  }

  loadUsers() {
    fetchAllPublicUsers().then((usersData) => {
      if (usersData.length === 0) {
        this.setState({ notFound: true });
      }
      const users = [];
      usersData.forEach((userData) => {
        const { _id, firstName, lastName } = userData;
        const id = _id;
        const name = `${firstName} ${lastName}`;
        const current = {
          value: id,
          label: name,
        };
        users.push(current);
      });
      this.setState({ users });
    });
  }

  showModal(e) {
    const moduleId = e.currentTarget.getAttribute('data-id');
    if (moduleId) {
      this.setState({ modalIsOpen: true, moduleId });
    }
  }

  showValidateModal(e) {
    const moduleId = e.currentTarget.getAttribute('data-id');
    const newStatus = e.currentTarget.selectedOptions[0].value;
    if (moduleId) {
      this.setState({ validateModalIsOpen: true, moduleId, newStatus });
    }
  }

  showCloneModal(e) {
    const moduleId = e.currentTarget.getAttribute('data-id');
    if (moduleId) {
      this.setState({ cloneModalIsOpen: true, moduleId });
    }
  }

  cancelValidate() {
    const uid = new RandomId();
    this.setState({ datatableKey: uid.randomUUID(12) });
    this.hideModal();
  }

  exportScorm(e) {
    const { mfs } = this.state;
    const moduleId = e.currentTarget.getAttribute('data-id');
    const module = mfs.filter(mod => mod[0] === moduleId);
    downloadScorm(moduleId).then((response) => {
      saveScormFile(response, module[0][1]);
    });
  }

  hideModal() {
    this.setState({
      modalIsOpen: false,
      validateModalIsOpen: false,
      cloneModalIsOpen: false,
    });
  }

  render() {
    const { mfs, modalIsOpen, notFound, userPermissions, validateModalIsOpen,
      datatableKey, cloneModalIsOpen, centre, colaborator, users, user } = this.state;
    const { notification, clearMessages, centres } = this.props;
    let parsedCentres = centres;
    const userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
    const userCentres = userInfo.centres;
    const isAdmin = userInfo.roles.find(rol => rol.rid === 'ADMIN');
    if (!isAdmin && centres.length) {
      parsedCentres = [];
      userCentres.forEach((curCentre) => {
        const centreData = centres.find(c => c._id === curCentre._id);
        if (centreData) {
          parsedCentres.push(centreData);
        }
      });
    }
    return (
      <List
        modules={mfs}
        notification={notification}
        clearMessages={clearMessages}
        modalIsOpen={modalIsOpen}
        showModal={this.showModal}
        showValidateModal={this.showValidateModal}
        showCloneModal={this.showCloneModal}
        hideModal={this.hideModal}
        onConfirm={this.onConfirm}
        notFound={notFound}
        exportScorm={this.exportScorm}
        userPermissions={userPermissions}
        validateModalIsOpen={validateModalIsOpen}
        onValidateConfirm={this.onValidateConfirm}
        cancelValidate={this.cancelValidate}
        cloneModalIsOpen={cloneModalIsOpen}
        onCloneConfirm={this.onCloneConfirm}
        datatableKey={datatableKey}
        centres={parsedCentres}
        centre={centre}
        colaborator={colaborator}
        handleSelectChange={this.handleSelectChange}
        users={users}
        user={user}
      />
    );
  }
}

ModulesContainer.propTypes = {
  clearMessages: PropTypes.func.isRequired,
  notification: PropTypes.objectOf(PropTypes.any).isRequired,
  saveMf: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  cloneMf: PropTypes.func.isRequired,
  loadCentres: PropTypes.func.isRequired,
  centres: PropTypes.arrayOf(PropTypes.any).isRequired,
  permissions: PropTypes.arrayOf(PropTypes.object).isRequired,
};

ModulesContainer.defaultProps = {};

const mapStateToProps = (state) => {
  const { error, success, message } = state.mfs;
  const notification = {};
  if (error) {
    notification.type = 'error';
    notification.content = message;
  } else if (success) {
    notification.type = 'success';
    notification.content = message;
  }

  return {
    notification,
    centres: state.centres.centres,
    permissions: state.permissions.userPermissions,
  };
};

const mapDispatchToProps = dispatch => ({
  clearMessages: () => { dispatch(clearNotification()); },
  saveMf: (data) => { dispatch(save(data)); },
  cloneMf: (data) => { dispatch(cloneModule(data)); },
  remove: (moduleId) => { dispatch(deleteModule(moduleId)); },
  loadCentres: () => { dispatch(fetchCentres()); },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModulesContainer);
