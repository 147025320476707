import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from 'client/ui/Button';
import IconFa from 'client/ui/IconFa';
import ListPages from 'client/modules/dashboard-pages/containers/List';

const Item = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 1rem;
  border: 1px solid black;
  border-radius: .3rem;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 1rem 0;
  width: 100%;
  min-width: 0;
  padding-right: 1rem;
`;

const NumberWrapper = styled.div`
  display: flex;
  height: 100%;
  vertical-align: middle;
`;

const Number = styled.div`
  font-size: 1.875rem;
  width: 50px;
  margin-right: 1rem;
  text-align: center;
  align-self: flex-start;
  padding-top: 1rem;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  button {
    margin-left: 1rem;
  }
`;

const ContentColor = styled.div`
  width: 7px;
  background-color: ${props => props.theme.color.greyContent};
  margin-right: 1rem;
  height: 100%;
  z-index: 2;
`;

const ContentIconWrapper = styled.div`
  padding: .5rem;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1rem;
  border: 1px solid white;
  z-index: 1000;
  &:hover {
    background-color: #EEEEEE;
    border: 1px solid #EEEEEE;
  }
  i {
    margin-bottom: .5rem;
    font-size: 1.5rem;
  }
`;

const FixedTitle = styled.div`
  flex-grow: 1;
  text-align: left;
  vertical-align: middle;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 1rem;
`;

const PagesWrapper = styled.div`
  margin-top: 1.5rem;
`;

const AccessButton = styled(Button)`
  width: 161px;
  min-width: 161px;
`;

const ContentIcon = ({ icon, text, ...rest }) => (
  <ContentIconWrapper {...rest}>
    <IconFa regular name={icon} />
    <div>{text}</div>
  </ContentIconWrapper>
);

ContentIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

ContentIcon.defaultProps = {};

class TopicItem extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const { number, id, type, pages, title, colorMf } = this.props;
    return (
      <Item>
        <div>
          <NumberWrapper><ContentColor /><Number>{number}</Number></NumberWrapper>
        </div>
        <Column>
          <TitleWrapper>
            <FixedTitle>{title}</FixedTitle>
            {type !== 'folder' && (
              <AccessButton
                background={colorMf}
                to={pages[0] && pages[0].length ? `/viewContent/${pages[0]}` : ''}
              >
                Ir a contenido
              </AccessButton>
            )}
          </TitleWrapper>
          {type === 'folder' && (
            <PagesWrapper>
              <ListPages topicId={id} modeEdit={false} colorMf={colorMf} />
            </PagesWrapper>
          )}
        </Column>
      </Item>
    );
  }
}

TopicItem.propTypes = {
  id: PropTypes.string,
  number: PropTypes.number.isRequired,
  colorMf: PropTypes.string.isRequired,
  title: PropTypes.string,
  pages: PropTypes.arrayOf(PropTypes.string).isRequired,
  type: PropTypes.string,
};

TopicItem.defaultProps = {
  id: null,
  title: '',
  type: '',
};

export default TopicItem;
