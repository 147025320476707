import React from 'react';
import PropTypes from 'prop-types';
import Plyr from 'react-plyr';

const Audio = ({ url, id }) => (
  <Plyr
    className={`react-plyr-${id}`}
    type="audio"
    url={url}
  />
);

Audio.propTypes = {
  url: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

Audio.defaultProps = {};

export default Audio;
