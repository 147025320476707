/* eslint react/no-multi-comp: 0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { debounce } from 'client/modules/core/utils/utils';
import RandomId from 'short-unique-id';
import Tree from 'react-d3-tree';

const TreeWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100%;
  position: absolute;
  padding: 1rem;
  min-width: 730px;
  overflow-x: auto;
`;

const NodeLabelWrapper = styled.div`
  padding: 1rem;
  background-color: #90A4AE;
  color: white;
  width: 200px;
  min-width: 200px;
  max-width: 200px;

  text-align: center;
  display: block;
  ${'' /* transform: translateX(-50%); */}
  border-radius: .5em;
  ${props => props.background && css`
    background-color: ${props.background};
  `}
`;

const NodeText = styled.div`
  font-size: 1rem;
  color: white;
`;

class NodeLabel extends React.PureComponent {
  render() {
    const uid = new RandomId();
    const { className, nodeData } = this.props;
    return (
      <NodeLabelWrapper className={className} background={nodeData.color}>
        {nodeData.items && nodeData.items.map(item => (
          <div
            key={uid.randomUUID(12)}
          >
            {item}
          </div>
        ))}
        {nodeData.name && <NodeText>{nodeData.name}</NodeText>}
      </NodeLabelWrapper>
    );
  }
}

NodeLabel.propTypes = {
  className: PropTypes.string,
  nodeData: PropTypes.objectOf(PropTypes.any),
};

NodeLabel.defaultProps = {
  className: '',
  nodeData: {},
};

class MfMindmap extends Component {
  constructor() {
    super();
    this.state = { translate: { x: 50, y: 50 } };
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', debounce(this.updateDimensions, 300));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions() {
    const width = window.innerWidth;
    // const height = window.innerHeight;
    this.setState({
      translate: {
        x: width / 2,
        y: 50,
      },
    });
  }

  render() {
    const { treeData } = this.props;
    const { translate } = this.state;
    return (
      <div>
        <TreeWrapper>
          <Tree
            data={treeData}
            translate={translate}
            pathFunc="elbow"
            orientation="vertical"
            foreignObject
            zoomable={false}
            separation={{ siblings: 1, nonSiblings: 1 }}
            nodeSvgShape={{
              shape: 'none',
              shapeProps: {
                width: 0,
                height: 0,
              },
            }}
            nodeSize={{ x: 300, y: 300 }}
            allowForeignObjects
            nodeLabelComponent={{
              render: <NodeLabel className="myLabelComponentInSvg" />,
              foreignObjectWrapper: {
                y: -40,
                x: -100,
              },
            }}
          />
        </TreeWrapper>
      </div>
    );
  }
}

MfMindmap.propTypes = {
  treeData: PropTypes.objectOf(PropTypes.any),
};

MfMindmap.defaultProps = {
  treeData: {},
};

export default MfMindmap;
