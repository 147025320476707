import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import StyledTabsBar from './StyledTabsBar';
import StyledTab from './StyledTab';
import TabsPane from './TabsPane';
import ContentTab from './ContentTab';

const StyledTabsWrapper = styled.div`
  ${''/* position: relative; */}
`;

const StyledTabs = styled.div`
  position: relative;
`;

const StyledTabWrapper = styled.div`
  ${props => props.tabsTitleMaxWidth && css`
    max-width: ${props.tabsTitleMaxWidth};
    padding-left: ${props.theme.outerMargin};
    padding-right: ${props.theme.outerMargin};
    margin-left: auto;
    margin-right: auto;
  `};
`;

/**
 * Componente para las Tabs
 */
class Tabs extends React.Component {
  constructor() {
    super();
    this.state = {};
    this.renderTabs = this.renderTabs.bind(this);
    this.renderTab = this.renderTab.bind(this);
    this.renderContent = this.renderContent.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentWillMount() {
    const { selected } = this.props;
    this.setState({ selected });
  }

  componentWillReceiveProps(nextProps) {
    const { selected } = nextProps;
    this.setState({ selected });
  }

  handleClick(event, name) {
    event.preventDefault();
    const { stateless, onTabClick } = this.props;
    const { selected } = this.state;
    if (name !== selected && onTabClick) {
      onTabClick(name);
    }
    if (!stateless) {
      this.setState({ selected: name });
    }
  }

  /**
   * Renderiza el componente indicado en TabsPane o en Tabs o usa el indicado en defaultProps
   * Además le pasa las props indicadas como componentProps en TabsPane o en Tabs al componente
   * @param  {TabsPane} child [description]
   */
  renderTab(child) {
    if (child && child.length !== 0) {
      const { name } = child.props;
      const { selected } = this.state;
      const Component = child.props.component || this.props.component;
      const componentProps = { ...this.props.componentProps, ...child.props.componentProps };
      return (
        <Component
          active={selected === name}
          onClick={event => this.handleClick(event, name)}
          {...componentProps}
        >
          {child.props.label}
        </Component>
      );
    }
    return false;
  }

  renderTabs() {
    const { children, tabsAligment, tabsBarBorder, tabsBarBorderColor,
      tabsTitleMaxWidth, ...rest } = this.props;
    return (
      <StyledTabs {...rest}>
        <StyledTabsBar
          textAlign={tabsAligment}
          borderBottom={tabsBarBorder}
          borderBottomColor={tabsBarBorderColor}
        >
          <StyledTabWrapper tabsTitleMaxWidth={tabsTitleMaxWidth}>
            {React.Children.map(children, (element, index) => (
              this.renderTab(element, index)
            ))}
          </StyledTabWrapper>
        </StyledTabsBar>
      </StyledTabs>
    );
  }

  /**
   * Renderiza el contenido de la pestaña activa según la propiedad name
   * En caso de no coincidir ninguno muestra el primero
   */
  renderContent() {
    // Convierte los children en array por si solo hay 1 children
    const children = React.Children.toArray(this.props.children);
    const { selected } = this.state;
    return (
      <div>
        {children.find(child => child.props.name === selected) || children[0]}
      </div>
    );
  }

  render() {
    return (
      <StyledTabsWrapper>
        {this.renderTabs()}
        {this.renderContent()}
      </StyledTabsWrapper>
    );
  }
}

Tabs.propTypes = {
  /** Handle state outside this component */
  stateless: PropTypes.bool,
  children: PropTypes.node.isRequired,
  selected: PropTypes.string,
  /** Main component for tabs */
  component: PropTypes.func,
  /** Main component props for tabs */
  componentProps: PropTypes.objectOf(PropTypes.any),
  onTabClick: PropTypes.func,
  tabsAligment: PropTypes.string,
  /** Divider that separates tabs and content */
  tabsBarBorder: PropTypes.string,
  tabsBarBorderColor: PropTypes.string,
  /**
   * Width of the shadow that is mostly visible on mobile devices when exists a lot of tabs
   * (tip: use a width equals to the gap/margin between tabs)
   */
  shadowWidth: PropTypes.string,
  tabsTitleMaxWidth: PropTypes.string,
};

Tabs.defaultProps = {
  stateless: false,
  selected: 0,
  component: StyledTab,
  componentProps: null,
  onTabClick: null,
  tabsAligment: 'initial',
  tabsBarBorder: '1px solid',
  tabsBarBorderColor: 'grayBlueDark',
  shadowWidth: '1rem',
  tabsTitleMaxWidth: '100%',
};

export {
  Tabs,
  StyledTab as Tab,
  TabsPane,
  ContentTab,
};
