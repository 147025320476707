/* eslint class-methods-use-this: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import Alert from 'react-s-alert';

class Notification extends React.Component {
  constructor() {
    super();
    this.showNotification = this.showNotification.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { message } = nextProps;
    if (message && Object.keys(message).length !== 0) {
      this.showNotification(message);
    }
  }

  // componentWillUnmount() {
  //   Alert.closeAll();
  // }

  showNotification(message) {
    const { onError, onSuccess, afterNotification } = this.props;
    const notificationConfig = { position: 'top-right', effect: 'slide', timeout: 3000 };

    if (message.type === 'error') {
      Alert.error(message.content, notificationConfig);
      if (onError) {
        onError();
      }
    } else if (message.type === 'success') {
      Alert.success(message.content, notificationConfig);
      if (onSuccess) {
        onSuccess();
      }
    }

    if (afterNotification) {
      afterNotification();
    }
  }

  render() {
    // const { limit } = this.props;

    return (
      <div className="notification">
        {/* sAlert */}
        <Alert stack={{ limit: 1 }} />
      </div>
    );
  }
}

Notification.propTypes = {
  message: PropTypes.objectOf(PropTypes.any),
  onError: PropTypes.func,
  onSuccess: PropTypes.func,
  afterNotification: PropTypes.func,
  // limit: PropTypes.number,
};

Notification.defaultProps = {
  message: null,
  onError: null,
  onSuccess: null,
  afterNotification: null,
  // limit: 3,
};

export default Notification;
