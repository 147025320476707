import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from 'client/ui/Button';
import Divider from 'client/ui/Divider';
import MixedQuestionMenu from './MixedQuestionMenu';
import SimpleQuestionItem from './SimpleQuestionItem';
import MultipleQuestionItem from './MultipleQuestionItem';
import ContentWrapperOptions from './ContentWrapperOptions';
import ClozeSelectItem from './ClozeSelectItem';
import ClozeItem from './ClozeItem';

const Wrapper = styled.div`
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    min-width: 172px;
  }
`;

const BottomWrapper = styled(Wrapper)`
  margin-top: 1rem;
`;

const StyledDivider = styled(Divider)`
  margin: 1rem 0;
`;

class MixedQuestions extends Component {
  constructor() {
    super();
    this.state = {
      menuVisible: false,
    };
    this.handleDisplayMenu = this.handleDisplayMenu.bind(this);
    this.addNewQuestion = this.addNewQuestion.bind(this);
    this.renderQuestion = this.renderQuestion.bind(this);
    this.onItemDelete = this.onItemDelete.bind(this);
    this.onItemMove = this.onItemMove.bind(this);
    this.wrapperQuestions = this.wrapperQuestions.bind(this);
  }

  onItemDelete(index) {
    const { contentId, removeQuestionItem } = this.props;
    removeQuestionItem(contentId, index);
  }

  onItemMove(index, direction) {
    const { contentId, moveQuestionItem } = this.props;
    moveQuestionItem(contentId, index, direction);
  }

  addNewQuestion(type) {
    const { addQuestion, contentId } = this.props;
    addQuestion(contentId, type);
    this.handleDisplayMenu();
  }

  handleDisplayMenu() {
    const { menuVisible } = this.state;
    this.setState({ menuVisible: !menuVisible });
  }

  wrapperQuestions() {
    const { items } = this.props;
    return items.map((item, index) => (
      <ContentWrapperOptions
        key={`Content_Wrapper_${item.id}`}
        id={index.toString()}
        remove={this.onItemDelete}
        moveContent={this.onItemMove}
        // No mostrar la linea divisoria en el ultimo elemento
        noBottomProps={index === (items.length - 1)}
        // @TODO: Pasar el tipo y ponerlo como absoluto en el caso de los botones
      >
        {this.renderQuestion(item)}
      </ContentWrapperOptions>
    ));
  }

  renderQuestion(item) {
    const { contentId, addAnswer, savePicture, onQuestionChange,
      optionCheckChange, optionTextChange, removeAnswer, sortQuestionOption,
      optionMultipleCheckChange, onClozeQuestionChange,
      forceClozeChange } = this.props;
    switch (item.type) {
      case 'simple':
        return (
          <SimpleQuestionItem
            contentId={contentId}
            questionId={item.id}
            question={item.question}
            options={item.options}
            picture={item.url}
            addAnswer={addAnswer}
            savePicture={savePicture}
            onQuestionChange={onQuestionChange}
            optionCheckChange={optionCheckChange}
            optionTextChange={optionTextChange}
            removeAnswer={removeAnswer}
            sortQuestionOption={sortQuestionOption}
          />
        );
      case 'multiple':
        return (
          <MultipleQuestionItem
            contentId={contentId}
            questionId={item.id}
            picture={item.url}
            question={item.question}
            options={item.options}
            addAnswer={addAnswer}
            savePicture={savePicture}
            onQuestionChange={onQuestionChange}
            optionMultipleCheckChange={optionMultipleCheckChange}
            optionTextChange={optionTextChange}
            sortQuestionOption={sortQuestionOption}
            removeAnswer={removeAnswer}
          />);
      case 'cloze':
        return (
          <ClozeItem
            contentId={contentId}
            questionId={item.id}
            question={item.question}
            options={item.options}
            addAnswer={addAnswer}
            onClozeQuestionChange={onClozeQuestionChange}
            optionTextChange={optionTextChange}
            removeAnswer={removeAnswer}
            forceClozeChange={forceClozeChange}
          />
        );
      case 'clozeSelect':
        return (
          <ClozeSelectItem
            contentId={contentId}
            questionId={item.id}
            question={item.question}
            options={item.options}
            addAnswer={addAnswer}
            sortQuestionOption={sortQuestionOption}
            onClozeQuestionChange={onClozeQuestionChange}
            optionCheckChange={optionCheckChange}
            optionTextChange={optionTextChange}
            removeAnswer={removeAnswer}
            forceClozeChange={forceClozeChange}
          />
        );
      default:
        return null;
    }
  }

  render() {
    const { items } = this.props;
    const { menuVisible } = this.state;
    return (
      <div>
        <MixedQuestionMenu
          isOpen={menuVisible}
          toggleNavigation={this.handleDisplayMenu}
          showSubMenu={this.handleDisplaySubMenu}
          addQuestion={this.addNewQuestion}
          close={this.handleDisplayMenu}
        />
        {items && items.length === 0 ? (
          <Wrapper>
            <Divider borderWidth="1px" borderColor="gray2" />
            <Button
              onClick={this.handleDisplayMenu}
            >
              Añadir Pregunta
            </Button>
            <Divider borderWidth="1px" borderColor="gray2" />
          </Wrapper>
        )
          : <StyledDivider borderWidth="1px" borderColor="gray2" />
        }
        {items.length > 0 && this.wrapperQuestions()}
        {items && items.length > 0 && (
          <BottomWrapper>
            <Divider borderWidth="1px" borderColor="gray2" />
            <Button
              onClick={this.handleDisplayMenu}
            >
              Añadir Pregunta
            </Button>
            <Divider borderWidth="1px" borderColor="gray2" />
          </BottomWrapper>
        )}
      </div>
    );
  }
}

MixedQuestions.propTypes = {
  contentId: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  addQuestion: PropTypes.func.isRequired,
  removeQuestionItem: PropTypes.func.isRequired,
  moveQuestionItem: PropTypes.func.isRequired,
  savePicture: PropTypes.func.isRequired,
  optionMultipleCheckChange: PropTypes.func.isRequired,
  onQuestionChange: PropTypes.func.isRequired,
  onClozeQuestionChange: PropTypes.func.isRequired,
  optionCheckChange: PropTypes.func.isRequired,
  optionTextChange: PropTypes.func.isRequired,
  addAnswer: PropTypes.func.isRequired,
  removeAnswer: PropTypes.func.isRequired,
  sortQuestionOption: PropTypes.func.isRequired,
  forceClozeChange: PropTypes.bool.isRequired,
};

MixedQuestions.defaultProps = {
  items: [],
};

export default MixedQuestions;
