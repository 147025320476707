import styled from 'styled-components';
import { getThemeColor } from '../style-utils';

const StyledTabsBar = styled.div`
  text-align: ${props => props.textAlign};
  border-bottom: ${props => props.borderBottom};
  border-bottom-color: ${props => getThemeColor(props.borderBottomColor)};

  white-space: nowrap;
  overflow: hidden;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export default StyledTabsBar;
