import React from 'react';
import PropTypes from 'prop-types';
import ContentWrapper from 'client/ui/ContentWrapper';
import DashboardItem from './DashboardItem';
import SectionTitle from './SectionTitle';

const Dashboard = ({ options }) => (
  <div>
    <SectionTitle />
    <ContentWrapper>
      {/* Grid de módulos disponibles para el usuario */}
      <div className="row">
        {options.map(option => (
          <div key={option.route} className="col-xs-6 col-sm-4 col-md-3">
            <DashboardItem
              route={option.route}
              name={option.name}
              icon={option.icon}
              color={option.color}
            />
          </div>
        ))}
      </div>
    </ContentWrapper>
  </div>
);

Dashboard.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      route: PropTypes.string,
      icon: PropTypes.string,
      color: PropTypes.string,
    }),
  ),
};

Dashboard.defaultProps = {
  options: [],
};

export default Dashboard;
