import styled from 'styled-components';

const Label = styled.label`
  display: flex;
  flex-direction: column;
  margin: 0;
  font-size: 0.875rem;
  line-height: 1.8;
  color: ${props => props.theme.color.black};
  > span {
    font-weight: bold;
  }
`;

export default Label;
