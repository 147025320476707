import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { H1 } from 'client/ui/Headings';
import Button from 'client/ui/Button';
import Notification from 'client/ui/Notification';
import ModalConfirm from 'client/ui/Modal';
import ContentWrapper from 'client/ui/ContentWrapper';
import SectionTitle from 'client/modules/dashboard/components/SectionTitle';
import RoleElements from './RoleElements';

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
`;

const Roles = ({ roles, notification, clearMessages, modalIsOpen, showModal,
  hideModal, onConfirm }) => (
    <div>
      <SectionTitle />
      <ContentWrapper>
        <H1>Roles</H1>
        <StyledHeader>
          <Button to="/dashboard/roles/add">Crear nuevo</Button>
        </StyledHeader>

        {/* Notificaciones */}
        <Notification
          message={notification}
          afterNotification={clearMessages}
        />
        <RoleElements list={roles} onRemove={showModal} />
        <ModalConfirm
          isOpen={modalIsOpen}
          title="Eliminar"
          content="¿Desea eliminar el rol?"
          onCancel={hideModal}
          onConfirm={onConfirm}
        />
      </ContentWrapper>
    </div>
);

Roles.propTypes = {
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  clearMessages: PropTypes.func.isRequired,
  notification: PropTypes.objectOf(PropTypes.any).isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

Roles.defaultProps = {
  roles: [],
};

export default Roles;
