import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import buttonStyles from './buttonStyles';

/**
 * Fix temporal para `Warning: Unknown props` cuando se estiliza un componente externo
 * @see https://github.com/styled-components/styled-components/issues/305
 */
const StyledLink = styled(({
  active, hollow, raised, transparent, background, color, borderColor, textTransform, ...rest
}) => (
  <Link {...rest} />
))`
  ${buttonStyles};
  text-decoration: none;
`;

export default StyledLink;
