import { css } from 'styled-components';

const thStyles = css`
  background-color: ${props => props.theme.color.gray};
  padding: .75rem;

  ${props => props.noBG && css`
    background-color:transparent;
  `}

  ${props => props.whiteBG && css`
    background-color:white;
  `}

  ${props => props.darkGray && css`
    background-color: ${props.theme.color.darkGray};
  `}

  ${props => props.whiteText && css`
    color: ${props.theme.color.white};
  `}

  ${props => props.color && css`
    background-color: ${props.color};
  `}

  ${props => props.alingLeft && css`
    text-align: left;
  `}
`;

export default thStyles;
