import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ModalConfirm from 'client/ui/Modal';
import Notification from 'client/ui/Notification';
import Label from 'client/ui/Label';
import Input from 'client/ui/Input';
import Button from 'client/ui/Button';
import ButtonGroup from 'client/ui/ButtonGroup';
import FormWrapper from 'client/ui/FormWrapper';
import styled from 'styled-components';

const Wrapper = styled.div`
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
`;

class ChangePassword extends Component {
  constructor() {
    super();
    this.state = {
      oldPassword: '',
      password: '',
      repeatPassword: '',
      modalIsOpen: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.onModalConfirm = this.onModalConfirm.bind(this);
    this.afterNotification = this.afterNotification.bind(this);
  }

  onModalConfirm() {
    this.hideModal();
    const { history } = this.props;
    history.replace('/dashboard');
  }

  handleInputChange(event) {
    const { target } = event;
    const { name, value } = target;
    this.setState({ [name]: value });
  }

  showModal() {
    this.setState({ modalIsOpen: true });
  }

  hideModal() {
    this.setState({ modalIsOpen: false });
  }

  afterNotification() {
    const { clearMessages, notification, history } = this.props;
    clearMessages();
    if (notification.type === 'success') {
      setTimeout(() => {
        history.replace('/');
      }, 1000);
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    const { oldPassword, password, repeatPassword } = this.state;
    const { userId, save } = this.props;
    const passwordData = {
      oldPassword,
      password,
      repeatPassword,
    };
    save(userId, passwordData);
  }

  render() {
    const { oldPassword, password, repeatPassword, modalIsOpen } = this.state;
    const { notification } = this.props;
    return (
      <FormWrapper>
        <Notification
          message={notification}
          afterNotification={this.afterNotification}
        />
        <Wrapper>
          <form onSubmit={this.handleSubmit}>
            {/* Contraseña actual */}
            <Label>
              <span>Contraseña actual</span>
              <Input
                type="password"
                name="oldPassword"
                value={oldPassword}
                onChange={this.handleInputChange}
                required
              />
            </Label>
            {/* Nueva contraseña */}
            <Label>
              <span>Nueva contraseña</span>
              <Input
                type="password"
                name="password"
                value={password}
                onChange={this.handleInputChange}
                required
              />
            </Label>
            <Label>
              <span>Repetir nueva contraseña</span>
              <Input
                type="password"
                name="repeatPassword"
                value={repeatPassword}
                onChange={this.handleInputChange}
                required
              />
            </Label>
            <ButtonGroup align="right">
              <Button background="#D7D5D8" onClick={() => this.showModal()}>Cancelar</Button>
              <Button type="submit">Guardar</Button>
            </ButtonGroup>
          </form>
          <ModalConfirm
            isOpen={modalIsOpen}
            title="Cancelar"
            content="¿Desea volver al dashboard?"
            onCancel={this.hideModal}
            onConfirm={this.onModalConfirm}
          />
        </Wrapper>
      </FormWrapper>
    );
  }
}

ChangePassword.propTypes = {
  userId: PropTypes.string.isRequired,
  save: PropTypes.func.isRequired,
  clearMessages: PropTypes.func.isRequired,
  notification: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

ChangePassword.defaultProps = {};

export default ChangePassword;
