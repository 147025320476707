import styled from 'styled-components';

const App = styled.div`
  width: 100%;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`;

export default App;
