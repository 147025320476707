const dashboardOptions = [
  {
    name: 'Roles',
    route: '/dashboard/roles',
    icon: 'check-square',
    module: 'ROLES',
    onlyFor: 'ADMIN',
  },
  {
    name: 'Permisos',
    route: '/dashboard/permissions',
    icon: 'lock-open',
    module: 'PERMISSIONS',
    onlyFor: 'ADMIN',
  },
  {
    name: 'Usuarios',
    route: '/dashboard/users',
    icon: 'users',
    module: 'USERS',
  },
  {
    name: 'Centros',
    route: '/dashboard/centres',
    icon: 'landmark',
    module: 'CENTRES',
  },
];

export default dashboardOptions;
