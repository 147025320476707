/* eslint no-undef: 0, import/prefer-default-export: 0 */

import { CALL_API } from 'redux-api-middleware';
import { api } from 'config';

import {
  MODULES_REQUEST,
  MODULES_SUCCESS,
  MODULES_ERROR,
} from '../constants/actionTypes';

function fetchModules() {
  const token = localStorage.getItem('token') || null;
  return {
    [CALL_API]: {
      types: [MODULES_REQUEST, MODULES_SUCCESS, MODULES_ERROR],
      endpoint: `${api.baseUrl}/api/modules/`,
      method: 'GET',
      headers: Object.assign({}, {
        Authorization: `Bearer ${token}`,
      }),
    },
  };
}

const fetchAllModulesPromise = () => new Promise((resolve, reject) => {
  const token = localStorage.getItem('token') || null;
  const reqConfig = {
    method: 'GET',
    headers: Object.assign({}, {
      Authorization: `Bearer ${token}`,
    }),
  };
  fetch(`${api.baseUrl}/api/modules/`, reqConfig)
    .then((response) => {
      if (response.status >= 400) {
        return reject(response.statusText);
      }
      return resolve(response.json());
    });
});


async function fetchAllModules() {
  try {
    const modulesData = await fetchAllModulesPromise();
    return modulesData;
  } catch (error) {
    return error;
  }
}

export {
  fetchModules,
  fetchAllModules,
};
