import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchAllModules, fetchModules } from 'client/modules/core/actions/modules';
import { fetchAllRoles, clearNotification, save } from 'client/modules/dashboard-roles/actions/roles';
import { fetchRoles } from 'client/modules/core/actions/roles';
import Permissions from '../components/Permissions';

class PermissionsContainer extends Component {
  constructor() {
    super();
    this.state = {
      rolePermission: [],
      roleId: '',
    };
    this.loadPermitions = this.loadPermitions.bind(this);
  }

  componentWillMount() {
    const { roles, loadRoles, modules, loadModules } = this.props;
    if (!roles.length) {
      loadRoles();
    }
    if (!modules.length) {
      loadModules();
    }
  }

  componentWillReceiveProps(nextProps) {
    /* Obtenemos los permisos del rol */
    if (nextProps.notification.type === 'success') {
      const { roleId } = this.state;
      this.loadPermitions(roleId);
    }
  }

  loadPermitions(roleId) {
    this.setState({ roleId });
    const rolePermission = [];
    fetchAllRoles().then((allRoles) => {
      fetchAllModules().then((allModules) => {
        const selectedRol = allRoles.find(rol => rol._id === roleId);
        allModules.forEach((module) => {
          const currenModule = { id: module._id, label: module.label };
          const rolpermissions = selectedRol.permissions;
          const currentModulePermission = rolpermissions.find(per => per.module._id === module._id);
          if (currentModulePermission) {
            const actions = {};
            module.actions.forEach((action) => {
              const havePermAction = currentModulePermission.actions.find(act => act === action);
              if (havePermAction) {
                actions[action] = true;
              } else {
                actions[action] = false;
              }
            });
            rolePermission.push({ module: currenModule, actions });
          } else {
            const actions = {};
            module.actions.forEach((action) => {
              actions[action] = false;
            });
            rolePermission.push({ module: currenModule, actions });
          }
          this.setState({ rolePermission });
        });
      });
    });
  }

  render() {
    const { rolePermission } = this.state;
    const { roles, clearMessages, notification, savePermissions, history } = this.props;
    const visibleRoles = roles.filter(rol => rol.rid !== 'ADMIN');
    const parsedRoles = visibleRoles.map(role => ({ value: role._id, label: role.label }));
    return (
      <Permissions
        roles={parsedRoles}
        rolePermission={rolePermission}
        clearMessages={clearMessages}
        loadRolePermitions={this.loadPermitions}
        notification={notification}
        saveRolePermitions={savePermissions}
        history={history}
      />
    );
  }
}

PermissionsContainer.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.object).isRequired,
  loadRoles: PropTypes.func.isRequired,
  modules: PropTypes.arrayOf(PropTypes.object).isRequired,
  loadModules: PropTypes.func.isRequired,
  clearMessages: PropTypes.func.isRequired,
  savePermissions: PropTypes.func.isRequired,
  notification: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

PermissionsContainer.defaultProps = {};

const mapStateToProps = (state) => {
  const { error, success, message } = state.roles;
  const notification = {};

  if (error) {
    notification.type = 'error';
    notification.content = message;
  } else if (success) {
    notification.type = 'success';
    notification.content = message;
  }

  return {
    notification,
    roles: state.common.roles,
    modules: state.common.modules,
  };
};

const mapDispatchToProps = dispatch => ({
  loadRoles: () => { dispatch(fetchRoles()); },
  loadModules: () => { dispatch(fetchModules()); },
  clearMessages: () => { dispatch(clearNotification()); },
  savePermissions: (permitions) => { dispatch(save(permitions)); },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PermissionsContainer);
