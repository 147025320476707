import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRoleModuleActions } from 'client/modules/dashboard-permissions/utils/modules-actions';
import List from '../components/List';
import { clearNotification, deleteCentre, fetchAllCentres } from '../actions/centres';

class CentersContainer extends Component {
  constructor() {
    super();
    this.state = {
      centres: [],
      modalIsOpen: false,
      centreId: '',
      notFound: false,
      userPermissions: {},
    };
    this.hideModal = this.hideModal.bind(this);
    this.showModal = this.showModal.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
  }

  componentWillMount() {
    const { permissions } = this.props;
    const userPermissions = getRoleModuleActions(permissions, 'CENTRES');
    this.setState({ userPermissions });
    this.loadCenters();
  }

  componentWillReceiveProps(nextProps) {
    const { permissions } = nextProps;
    if (nextProps.notification.type === 'success') {
      this.loadCenters();
    }
    const userPermissions = getRoleModuleActions(permissions, 'CENTRES');
    this.setState({ userPermissions });
  }

  onConfirm() {
    const { centreId } = this.state;
    const { remove } = this.props;
    this.hideModal();
    remove(centreId);
  }

  loadCenters() {
    fetchAllCentres().then((centresData) => {
      if (centresData.length === 0) {
        this.setState({ notFound: true });
      }
      const centres = [];
      centresData.forEach((centre) => {
        const { _id, name, location, address, colorMf, colorUf, colorUa } = centre;
        const id = _id;
        const current = [
          id,
          name,
          address,
          location,
          colorMf,
          colorUf,
          colorUa,
        ];
        centres.push(current);
      });
      this.setState({ centres });
    });
  }

  showModal(e) {
    const centreId = e.currentTarget.getAttribute('data-id');
    if (centreId) {
      this.setState({ modalIsOpen: true, centreId });
    }
  }

  hideModal() {
    this.setState({ modalIsOpen: false });
  }

  render() {
    const { centres, modalIsOpen, notFound, userPermissions } = this.state;
    const { notification, clearMessages } = this.props;
    return (
      <List
        centres={centres}
        notification={notification}
        clearMessages={clearMessages}
        modalIsOpen={modalIsOpen}
        showModal={this.showModal}
        hideModal={this.hideModal}
        onConfirm={this.onConfirm}
        notFound={notFound}
        userPermissions={userPermissions}
      />
    );
  }
}

CentersContainer.propTypes = {
  clearMessages: PropTypes.func.isRequired,
  notification: PropTypes.objectOf(PropTypes.any).isRequired,
  remove: PropTypes.func.isRequired,
  permissions: PropTypes.arrayOf(PropTypes.object).isRequired,
};

CentersContainer.defaultProps = {};

const mapStateToProps = (state) => {
  const { error, success, message } = state.centres;
  const notification = {};

  if (error) {
    notification.type = 'error';
    notification.content = message;
  } else if (success) {
    notification.type = 'success';
    notification.content = message;
  }

  return {
    notification,
    permissions: state.permissions.userPermissions,
  };
};

const mapDispatchToProps = dispatch => ({
  clearMessages: () => { dispatch(clearNotification()); },
  remove: (centreId) => { dispatch(deleteCentre(centreId)); },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CentersContainer);
