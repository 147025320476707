import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { api } from 'config';
import { connect } from 'react-redux';
import { fetchCentres } from 'client/modules/dashboard-centres/actions/centres';
import { getRoleModuleActions } from 'client/modules/dashboard-permissions/utils/modules-actions';
import { clearNotification as clearNotificationUa } from 'client/modules/dashboard-uas/actions/uas';
import uploadPicture from 'client/modules/core/utils/upload-mf-picture';
import { save, clearNotification, fetchModule } from '../actions/mfs';
import Wrapper from '../components/Wrapper';

class WrapperContainer extends Component {
  constructor() {
    super();
    this.state = {
      moduleToUpdate: {},
      createMode: true,
      pictureId: null,
      tab: '#generalDescription',
      isAdminCurrentUser: false,
    };
    this.loadData = this.loadData.bind(this);
    this.savePicture = this.savePicture.bind(this);
  }

  componentWillMount() {
    const { match, history, centres, loadCentres } = this.props;
    if (match.params.id) {
      const { id } = match.params;
      this.loadData(id);
      localStorage.setItem('mfId', id);
      this.setState({ createMode: false });
    }

    if (history.location && history.location.hash) {
      this.setState({ tab: history.location.hash });
    }

    if (!centres.length) {
      loadCentres();
    }
  }

  componentWillReceiveProps(nextProps) {
    /* Obtenemos los datos actualizados */
    if (nextProps.notification.type === 'success') {
      const { match } = this.props;
      if (match.params.id) {
        const { id } = match.params;
        this.loadData(id);
      }
    }
  }

  loadData(moduleId) {
    fetchModule(moduleId).then((moduleData) => {
      const userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
      const isAdmin = userInfo.roles.find(rol => rol.rid === 'ADMIN');
      const { history } = this.props;
      const { _id, centre, coverPicture, introduction, title, subtitles, objectives,
        bibliographicReferences, haveUf } = moduleData;

      const userCanViewCentre = isAdmin ? true : userInfo.centres.find(ce => ce._id === centre._id);
      const { permissions } = this.props;
      const repoPermissions = getRoleModuleActions(permissions, 'CENTREREPO');
      let isAdminCurrentUser = false;

      if (!repoPermissions.UPDATE || !userCanViewCentre) {
        history.replace('/withoutPermission');
      }

      if (isAdmin) {
        isAdminCurrentUser = true;
      }

      const moduleToUpdate = moduleData;
      let mfCentre = { value: '', label: '' };
      if (centre && centre._id) {
        mfCentre = { value: centre._id, label: centre.name };
      }
      const haveSubtitles = subtitles && subtitles.length ? subtitles : [''];
      const haveObjectives = objectives && objectives.length ? objectives : [''];
      let picture = null;
      if (bibliographicReferences.length === 0) {
        bibliographicReferences.push({ title: '', text: '' });
      }
      if (coverPicture) {
        picture = {
          id: coverPicture._id,
          src: `${api.baseUrl}${coverPicture.url.s}`,
        };
      }

      const proration = [];
      if (moduleData && moduleData.proration) {
        if (haveUf) {
          moduleData.proration.forEach((pro) => {
            const currentUf = moduleData.ufs.find(uf => uf._id === pro._id);
            if (currentUf) {
              Object.assign(pro, { title: currentUf.title });
              proration.push(pro);
            }
          });
        } else {
          moduleData.proration.forEach((pro) => {
            const currentUa = moduleData.uas.find(ua => ua._id === pro._id);
            if (currentUa) {
              Object.assign(pro, { title: currentUa.title });
              proration.push(pro);
            }
          });
        }
      }

      Object.assign(moduleToUpdate,
        {
          id: _id,
          centre: mfCentre,
          title: title || '',
          subtitles: haveSubtitles,
          introduction: introduction || '',
          objectives: haveObjectives,
          coverPicture: picture,
          bibliographicReferences,
          proration,
        });
      this.setState({ moduleToUpdate, isAdminCurrentUser });
    });
  }

  savePicture(picture, name) {
    const pictureId = uploadPicture({ picture: picture.file, description: `Portada del MF ${name}`, name });
    pictureId.then((response) => {
      if (response && response.image) {
        this.setState({ pictureId: response.image._id });
      }
    });
  }

  render() {
    const { saveForm, notification, clearMessages, history, centres, match, notificationUa,
      clearUaMessage } = this.props;
    const { moduleToUpdate, createMode, tab, pictureId, isAdminCurrentUser } = this.state;

    return (
      <Wrapper
        save={saveForm}
        notification={notification}
        notificationUa={notificationUa}
        clearMessages={clearMessages}
        clearUaMessage={clearUaMessage}
        moduleToUpdate={moduleToUpdate}
        history={history}
        createMode={createMode}
        centres={centres}
        pictureId={pictureId}
        savePicture={this.savePicture}
        isAdmin={isAdminCurrentUser}
        match={match}
        tab={tab}
      />
    );
  }
}

WrapperContainer.propTypes = {
  saveForm: PropTypes.func.isRequired,
  loadCentres: PropTypes.func.isRequired,
  centres: PropTypes.arrayOf(PropTypes.object).isRequired,
  clearMessages: PropTypes.func.isRequired,
  clearUaMessage: PropTypes.func.isRequired,
  notification: PropTypes.objectOf(PropTypes.any).isRequired,
  notificationUa: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  permissions: PropTypes.arrayOf(PropTypes.object).isRequired,
};

WrapperContainer.defaultProps = {};
const mapStateToProps = (state) => {
  const { error, success, message, id } = state.mfs;
  const notification = {};
  const notificationUa = {};

  if (error) {
    notification.type = 'error';
    notification.content = message;
  } else if (success) {
    notification.type = 'success';
    notification.content = message;
    notification.id = id;
  }

  if (state.uas.success) {
    notificationUa.type = 'success';
    notificationUa.content = state.uas.message;
  }

  return {
    notification,
    notificationUa,
    centres: state.centres.centres,
    permissions: state.permissions.userPermissions,
  };
};

const mapDispatchToProps = dispatch => ({
  saveForm: (data) => { dispatch(save(data)); },
  clearMessages: () => { dispatch(clearNotification()); },
  clearUaMessage: () => { dispatch(clearNotificationUa()); },
  loadCentres: () => { dispatch(fetchCentres()); },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WrapperContainer);
