/* eslint no-unused-expressions: 0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import theme from 'client/config/theme';
import { fetchModuleIndex } from 'client/modules/dashboard-mfs/actions/mfs';
import { fetchUfIndex } from 'client/modules/dashboard-ufs/actions/ufs';
import { fetchUaTopics } from 'client/modules/dashboard-topics/actions/topics';
import MfMindmap from '../components/MfMindmap';


async function loadUfs(id) {
  const data = await fetchUfIndex(id).then(ufData => ({
    _id: ufData._id,
    title: ufData.title,
    codeUf: ufData.codeUf,
    uas: ufData.uas,
  }));
  return data;
}

async function loadTopics(id) {
  const resp = { ua: id };
  const data = await fetchUaTopics(id).then((topics) => {
    const parsedData = topics.map(topic => ({ _id: topic._id, title: topic.title, ua: topic.ua }));
    return parsedData;
  });
  resp.topics = data;
  return resp;
}

class WrapperContainer extends Component {
  constructor() {
    super();
    this.state = {
      treeData: {},
    };
    this.loadData = this.loadData.bind(this);
  }

  componentWillMount() {
    const { mfId } = this.props;
    this.loadData(mfId);
  }

  loadData(moduleId) {
    fetchModuleIndex(moduleId).then((moduleData) => {
      const treeData = {};
      const { colorMf, colorUf, colorUa } = moduleData.centre;
      const colorContent = theme.color.greyContent;

      Object.assign(treeData, { name: moduleData.title, color: colorMf, children: [] });
      if (moduleData.haveUf) {
        const { ufs } = moduleData;
        Promise.all(ufs.map((uf) => {
          const ufData = loadUfs(uf._id);
          return ufData;
        })).then((responseUfs) => {
          const uas = [];
          responseUfs.forEach((uf) => {
            uf.uas.forEach((ua) => {
              uas.push(ua._id);
            });
          });
          Promise.all(uas.map(ua => loadTopics(ua))).then((allTopics) => {
            const parsedUas = [];
            responseUfs.forEach((uf) => {
              const node = {
                name: uf.title,
                color: colorUf,
              };
              const uaChildren = [];
              uf.uas.forEach((ua) => {
                const children = {};
                children.name = ua.title;
                children.color = colorUa;
                children.children = [];
                const filterTopic = allTopics.filter(topic => topic.ua === ua._id);
                if (filterTopic.length > 0) {
                  filterTopic.forEach((topic) => {
                    const parsedTitles = [];
                    topic.topics.forEach((currentTopic) => {
                      parsedTitles.push(currentTopic.title);
                    });
                    const parseObject = {
                      name: '',
                      items: parsedTitles,
                      color: colorContent,
                    };
                    children.children.push(parseObject);
                  });
                }
                uaChildren.push(children);
              });
              parsedUas.push(uaChildren);
              Object.assign(node, { children: uaChildren });
              treeData.children.push(node);
            });
            this.setState({ treeData });
          });
        });
      } else {
        const { uas } = moduleData;
        Promise.all(uas.map(ua => loadTopics(ua._id)))
          .then((respuesta) => {
            const parsedUas = [];
            uas.forEach((ua) => {
              const children = {};
              children.name = ua.title;
              children.color = colorUa;
              children.children = [];
              const filterTopic = respuesta.filter(topic => topic.ua === ua._id);
              if (filterTopic.length > 0) {
                filterTopic.forEach((topic) => {
                  const parsedTitles = [];
                  topic.topics.forEach((currentTopic) => {
                    parsedTitles.push(currentTopic.title);
                  });
                  const parseObject = {
                    name: '',
                    items: parsedTitles,
                    color: colorContent,
                  };
                  children.children.push(parseObject);
                });
              }
              parsedUas.push(children);
            });
            Object.assign(treeData, { children: parsedUas });
            this.setState({ treeData });
          });
      }
    });
  }

  render() {
    const { treeData } = this.state;
    return (
      <MfMindmap
        treeData={treeData}
      />
    );
  }
}

WrapperContainer.propTypes = {
  mfId: PropTypes.string.isRequired,
};

WrapperContainer.defaultProps = {};

export default WrapperContainer;
