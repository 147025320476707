const theme = {
  color: {
    // Brand
    primary: '#1F1410', // turquoise
    secondary: '#4caf50',
    alternate: '#fdca50',
    blueMfs: '#29B6F6',
    orangeUf: '#FF9100',
    greenUa: '#00BFA5',
    greyContent: '#90A4AE',
    redEvaluation: '#F84E66',

    // Basic
    white: '#FFFFFF',
    black: '#393838',
    gray: '#B0BEC5',
    darkGray: '#455A64',
    gray2: '#979797',
    grayLight: '#f3f3f2',
    grayTable: '#C0C0C0',
    modalTitle: '#4A4A4A',
    lightGray: '#D8D8D8',
    addContentColor: '#5A5F62',

    // More...
    yellow: '#fdca50',
    yellowLight: '#fdfdaf',
    yellowDark: '#8e750f',

    red: '#EB5C64',

    purple: '#9d72af',
    purpleDark: '#332050',

    turquoiseDark: '#1e91a1',
    turquoise: '#4ebbb7',
    turquoiseLight: '#cee9e9',

    grayBlueDark: '#354052',
    grayBlue: '#7F8FA4',

    grayLightClear: '#fbfbfb',

    // NavBar
    blueLight: '#356681',
    blueDark: '#193849',

    borderBg: '#D7D7D7',

    correctCheck: '#00C853',
    // Contextual
    // success: '',
    // info: '',
    // warning: '',
    // error: '',
  },

  font: {
    montserrat: 'montserrat, sans-serif',
    size: '1rem',
  },

  radius: '0.3em',
  oval: '1.5em',

  maxWidth: '1200px',
  outerMargin: '1.5rem',

  breakpoints: {
    xs: 0,
    xxs: 430, // 25em
    sm: 640, // 40em
    md: 1024, // 64em
    lg: 1200, // 75em
  },
};

export default theme;
