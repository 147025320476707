import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ChromePicker } from 'react-color';
import Label from 'client/ui/Label';
import Input from 'client/ui/Input';
import UploadPicture from 'client/ui/UploadPicture';
import Notification from 'client/ui/Notification';
import ModalConfirm from 'client/ui/Modal';
import Button from 'client/ui/Button';
import Span from 'client/ui/Span';
import ButtonGroup from 'client/ui/ButtonGroup';
import ContentWrapper from 'client/ui/ContentWrapper';
import { api } from 'config';
import { H2 } from 'client/ui/Headings';

const Wrapper = styled.div`
  margin-bottom: 1rem;
  &.middle {
    margin-bottom: .5rem;
  }
  &.double {
    margin-bottom: 2rem;
  }
`;

const StyledChromePicker = styled(ChromePicker)`
  position: absolute;
  bottom: 10px;
`;

const StyledChromePickerUf = StyledChromePicker.extend`
  left: 30px;
`;

const ColorWrapper = styled.div`
  width: 100%;
  max-width: 500px;
  display: flex;
  border: 1px solid black;
  border-radius: 1.3rem;
  height: 30px;
  overflow: hidden;
  ${props => props.noBorder && css`
    border: 0;
  `}
`;

const ColorItem = styled.div`
    width: 33.333%;
    background-color: ${props => props.bgColor};
    height: 100%;
    position: relative;
    text-align: center;
    vertical-align: center;
`;

const PickerWrapper = styled.div`
  position: relative;
  margin-top: 2rem;
`;

class Form extends Component {
  constructor() {
    super();
    this.state = {
      name: '',
      address: '',
      location: '',
      picture: null,
      colorMf: '#29B6F6',
      colorUf: '#FF9100',
      colorUa: '#00BFA5',
      modalIsOpen: false,
      mfColorSelectorVisible: false,
      ufColorSelectorVisible: false,
      uaColorSelectorVisible: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.afterNotification = this.afterNotification.bind(this);
    this.handlePictureChange = this.handlePictureChange.bind(this);
    this.handleModalAction = this.handleModalAction.bind(this);
    this.handleShowColorPicker = this.handleShowColorPicker.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { centreToUpdate, pictureId } = nextProps;
    if (centreToUpdate && centreToUpdate._id) {
      const { _id, name, address, location, colorMf, colorUf, colorUa, logo } = centreToUpdate;
      const id = _id;
      this.setState({
        id,
        name,
        address,
        location,
        colorMf,
        colorUf,
        colorUa,
      });
      if (logo) {
        const picture = {
          id: logo._id,
          src: `${api.baseUrl}${logo.url.s}`,
        };
        this.setState({ picture });
      }
    }
    if (pictureId) {
      const { picture } = this.state;
      picture.id = pictureId;
      this.setState({ picture });
    }
  }

  afterNotification() {
    const { notification, clearMessages, history } = this.props;
    clearMessages();
    if (notification.type === 'success') {
      setTimeout(() => {
        history.replace('/dashboard/centres');
      }, 1000);
    }
  }

  handleShowColorPicker(color) {
    const { mfColorSelectorVisible, ufColorSelectorVisible, uaColorSelectorVisible } = this.state;
    if (color === 'colorMf') {
      this.setState({
        mfColorSelectorVisible: !mfColorSelectorVisible,
        ufColorSelectorVisible: false,
        uaColorSelectorVisible: false,
      });
    } else if (color === 'colorUf') {
      this.setState({
        mfColorSelectorVisible: false,
        ufColorSelectorVisible: !ufColorSelectorVisible,
        uaColorSelectorVisible: false,
      });
    } else {
      this.setState({
        mfColorSelectorVisible: false,
        ufColorSelectorVisible: false,
        uaColorSelectorVisible: !uaColorSelectorVisible,
      });
    }
  }

  handlePictureChange(picture) {
    const { savePicture } = this.props;
    const { name } = this.state;
    this.setState({
      picture,
    });
    if (picture) {
      savePicture(picture, name);
    }
  }

  handleInputChange(event) {
    const { target } = event;
    const { name, value } = target;
    this.setState({ [name]: value });
  }

  handleChange(collection, color) {
    this.setState({ [collection]: color.hex });
  }

  showModal() {
    this.setState({ modalIsOpen: true });
  }

  hideModal() {
    this.setState({ modalIsOpen: false });
  }

  handleModalAction() {
    const { history } = this.props;
    history.replace('/dashboard/centres');
    this.hideModal();
  }

  handleSubmit(event) {
    event.preventDefault();
    const { save } = this.props;
    const { id, name, address, location, colorMf, colorUf, colorUa, picture } = this.state;
    const data = {
      name,
      address,
      location,
      colorMf,
      colorUf,
      colorUa,
    };

    if (id) {
      Object.assign(data, { id });
    }

    if (picture) {
      Object.assign(data, { logo: picture.id });
    }
    save(data);
  }

  render() {
    const { name, address, location, modalIsOpen, picture, colorMf, colorUf, colorUa,
      mfColorSelectorVisible, ufColorSelectorVisible, uaColorSelectorVisible } = this.state;
    const { notification, createMode } = this.props;
    return (
      <ContentWrapper>
        <H2>{createMode ? 'Creación de centro' : 'Edición de centro'}</H2>
        <Notification
          message={notification}
          afterNotification={this.afterNotification}
        />
        <form onSubmit={this.handleSubmit}>
          <Label>
            <Span spanForm>Nombre</Span>
            <Input
              type="text"
              name="name"
              value={name}
              onChange={this.handleInputChange}
              required
              maxLength="100"
            />
          </Label>
          <Label>
            <Span spanForm>Dirección</Span>
            <Input
              type="text"
              name="address"
              value={address}
              onChange={this.handleInputChange}
              required
              maxLength="100"
            />
          </Label>
          <Label>
            <Span spanForm>Localización</Span>
            <Input
              type="text"
              name="location"
              value={location}
              onChange={this.handleInputChange}
              required
              maxLength="100"
            />
          </Label>
          <Wrapper>
            <UploadPicture
              image={picture}
              onChange={this.handlePictureChange}
              required={picture ? '' : 'required'}
            />
          </Wrapper>
          <PickerWrapper>
            <p>Seleccione el color para:</p>
            {mfColorSelectorVisible && (
              <StyledChromePicker
                color={colorMf}
                onChangeComplete={value => this.handleChange('colorMf', value)}
                disableAlpha
              />)}
            {ufColorSelectorVisible && (
              <StyledChromePickerUf
                color={colorUf}
                onChangeComplete={value => this.handleChange('colorUf', value)}
                disableAlpha
              />)}
            {uaColorSelectorVisible && (
              <StyledChromePicker
                color={colorUa}
                onChangeComplete={value => this.handleChange('colorUa', value)}
                disableAlpha
              />)}
          </PickerWrapper>
          <ColorWrapper noBorder>
            <ColorItem>MF</ColorItem>
            <ColorItem>UF</ColorItem>
            <ColorItem>UA</ColorItem>
          </ColorWrapper>
          <ColorWrapper>
            <ColorItem bgColor={colorMf} onClick={() => this.handleShowColorPicker('colorMf')} />
            <ColorItem bgColor={colorUf} onClick={() => this.handleShowColorPicker('colorUf')} />
            <ColorItem bgColor={colorUa} onClick={() => this.handleShowColorPicker('colorUa')} />
          </ColorWrapper>
          <Wrapper>
            <ButtonGroup align="right">
              <Button
                onClick={() => this.showModal()}
                background="#6F7C7C"
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                background="grayBlueDark"
              >
                Guardar
              </Button>
            </ButtonGroup>
          </Wrapper>
        </form>

        <ModalConfirm
          isOpen={modalIsOpen}
          title="Cancelar"
          content="¿Desea cancelar, se perderán todos los datos no guardados?"
          onCancel={this.hideModal}
          onConfirm={this.handleModalAction}
        />
      </ContentWrapper>
    );
  }
}

Form.propTypes = {
  notification: PropTypes.objectOf(PropTypes.any).isRequired,
  centreToUpdate: PropTypes.objectOf(PropTypes.any),
  clearMessages: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  savePicture: PropTypes.func.isRequired,
  createMode: PropTypes.bool.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  pictureId: PropTypes.string,
};

Form.defaultProps = {
  centreToUpdate: {},
  pictureId: null,
};

export default Form;
