/* eslint react/no-array-index-key: 0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ModalConfirm from 'client/ui/Modal';
import RandomId from 'short-unique-id';
import Button from 'client/ui/Button';
import ButtonGroup from 'client/ui/ButtonGroup';
import PageItem from './PageItem';
import PageItemView from './PageItemView';

const PagesWrapper = styled.div`
  text-align: center;
`;

class List extends Component {
  constructor() {
    super();
    this.state = {
      pages: [],
    };
    this.addTempPage = this.addTempPage.bind(this);
  }

  componentWillMount() {
    const { pages } = this.props;
    this.setState({ pages });
  }

  componentWillReceiveProps(nextProps) {
    const { pages } = nextProps;
    this.setState({ pages });
  }

  addTempPage() {
    const { pages } = this.state;
    pages.push({ title: '' });
    this.setState({ pages });
  }

  render() {
    const { save, showModal, hideModal, onConfirm,
      modalIsOpen, addPage, modeEdit, colorMf } = this.props;
    const { pages } = this.state;
    const uid = new RandomId();
    if (modeEdit) {
      return (
        <PagesWrapper>
          {pages && pages.length > 0
            && (
              <div> {pages.map((page, index) => (
                <PageItem
                  key={`Page-${uid.randomUUID(12)}`}
                  title={page.title}
                  index={index}
                  type={page.type}
                  onRemove={showModal}
                  addPage={addPage}
                  save={save}
                  id={page._id}
                />
              ))}
              </div>
            )}
          <ButtonGroup align="left">
            <Button onClick={this.addTempPage}>Añadir</Button>
          </ButtonGroup>
          <ModalConfirm
            isOpen={modalIsOpen}
            title="Eliminar"
            content="¿Desea eliminar el tema?"
            onCancel={hideModal}
            onConfirm={onConfirm}
          />
        </PagesWrapper>
      );
    }
    return (
      <PagesWrapper>
        {pages && pages.length > 0
          && (
            <div> {pages.map((page, index) => (
              <PageItemView
                key={`Page-${page._id}`}
                title={page.title}
                index={index}
                colorMf={colorMf}
                id={page._id}
              />
            ))}
            </div>
          )}
      </PagesWrapper>
    );
  }
}

List.propTypes = {
  pages: PropTypes.arrayOf(PropTypes.any),
  showModal: PropTypes.func.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  addPage: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  modeEdit: PropTypes.bool.isRequired,
  colorMf: PropTypes.string.isRequired,
};

List.defaultProps = {
  pages: [],
};

export default List;
