import { api } from 'config';

const fetchMyModulesPromise = () => new Promise((resolve, reject) => {
  const token = localStorage.getItem('token') || null;
  const user = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
  const userId = user._id;
  const reqConfig = {
    method: 'GET',
    headers: Object.assign({}, {
      Authorization: `Bearer ${token}`,
    }),
  };
  fetch(`${api.baseUrl}/api/users/${userId}/mfs/`, reqConfig)
    .then((response) => {
      if (response.status >= 400) {
        return reject(response.statusText);
      }
      return resolve(response.json());
    });
});

async function fetchMyModules() {
  try {
    const documentData = await fetchMyModulesPromise();
    return documentData;
  } catch (error) {
    return error;
  }
}

export default fetchMyModules;
