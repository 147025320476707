import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from 'client/ui/Button';
import { H3 } from 'client/ui/Headings';
import { Tabs, TabsPane, ContentTab } from 'client/ui/Tabs';
import Menu from './ConfigurationMenu';
import TabsItem from './TabsItem';
import ContentWrapperOptions from './ContentWrapperOptions';

const WrapperTab = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
  border: 1px solid ${props => props.theme.color.grayTable};
  box-shadow: 2px ${props => props.theme.color.grayTable};
`;

const StyledButton = styled(Button)`
  margin-bottom: 1rem;
`;

const AddButton = styled(Button)`
  margin-bottom: 1rem;
  display: flex;
  margin-right: auto;
  margin-left: auto;
`;

const MenuTabsItem = styled.div`
  padding: 1rem 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid ${props => props.theme.color.grayTable};
  border-radius: ${props => props.theme.radius};
  box-shadow: 0px 2px ${props => props.theme.color.grayTable};
`;

// const StyledTabsPane = styled(TabsPane)`
//   padding-top: 1rem;
//   margin-bottom: 1rem;
//   padding: 1rem;
// `;

const StyledNoContentText = styled(H3)`
  text-align: center;
`;

class ContentTabs extends Component {
  constructor() {
    super();
    this.state = {
      configurationVisible: false,
      tab: 'tab-0',
    };
    this.handleDisplayMenu = this.handleDisplayMenu.bind(this);
    this.onItemDelete = this.onItemDelete.bind(this);
    this.onItemMove = this.onItemMove.bind(this);
    this.handleTabClick = this.handleTabClick.bind(this);
    this.close = this.close.bind(this);
  }

  onItemDelete(index) {
    const { id, removeTabsItem } = this.props;
    removeTabsItem(id, index);
  }

  onItemMove(index, direction) {
    const { id, moveTabsItem } = this.props;
    moveTabsItem(id, index, direction);
  }

  handleDisplayMenu() {
    const { configurationVisible } = this.state;
    this.setState({ configurationVisible: !configurationVisible });
  }

  close() {
    this.setState({ configurationVisible: false });
  }

  handleTabClick(tab) {
    this.setState({ tab });
  }

  render() {
    const { id, addItem, onTitleChange, onTextChange, items, savePicture } = this.props;
    const { configurationVisible, tab } = this.state;
    const width = `${100 / items.length}%`;
    return (
      <div>
        <Menu
          title="Editar botón en etiqueta"
          width="25rem"
          isOpen={configurationVisible}
          close={this.close}
        >
          <div>
            <AddButton
              onClick={() => addItem(id)}
            >
              Añadir item
            </AddButton>
            {items && items.map((item, index) => (
              <MenuTabsItem
                key={`Content_Wrapper_${index}`}
              >
                <ContentWrapperOptions
                  id={index.toString()}
                  remove={this.onItemDelete}
                  moveContent={this.onItemMove}
                  noBottomProps
                >
                  <TabsItem
                    key={`tabs${index}${id}`}
                    id={id}
                    index={index}
                    text={item.text}
                    title={item.title}
                    picture={item.url}
                    savePicture={savePicture}
                    onTitleChange={onTitleChange}
                    onTextChange={onTextChange}
                    previewMaxWidth="100px"
                  />
                </ContentWrapperOptions>
              </MenuTabsItem>
            ))}
          </div>
        </Menu>
        <StyledButton
          hollow
          color="black"
          onClick={() => this.handleDisplayMenu()}
        >
          Editar
        </StyledButton>
        {items.length === 0
          ? <StyledNoContentText>No hay items que mostrar</StyledNoContentText>
          : (
            <WrapperTab>
              <Tabs
                tabsTitleMaxWidth={null}
                tabsBarBorder="none"
                selected={tab}
                component={ContentTab}
                onTabClick={currentTab => this.handleTabClick(currentTab)}
                componentProps={{ color: 'red', width }}
              >
                {items && items.map((item, index) => (
                  <TabsPane key={`tabs${index}-${id}`} name={`tab-${index}`} label={item.title}>
                    <TabsItem
                      id={id}
                      index={index}
                      text={item.text}
                      title={item.title}
                      picture={item.url}
                      savePicture={savePicture}
                      onTitleChange={onTitleChange}
                      onTextChange={onTextChange}
                    />
                  </TabsPane>
                ))}
              </Tabs>
            </WrapperTab>
          )}
      </div>
    );
  }
}

ContentTabs.propTypes = {
  id: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  onTitleChange: PropTypes.func.isRequired,
  onTextChange: PropTypes.func.isRequired,
  savePicture: PropTypes.func.isRequired,
  addItem: PropTypes.func.isRequired,
  removeTabsItem: PropTypes.func.isRequired,
  moveTabsItem: PropTypes.func.isRequired,
};

ContentTabs.defaultProps = {};

export default ContentTabs;
