import { css } from 'styled-components';

const tableStyles = css`
  width:100%;
  border-collapse: collapse;
  border: 1px solid ${props => props.theme.color.grayTable};
  border-radius: ${props => props.theme.radius};
  box-shadow: 0px 2px ${props => props.theme.color.grayTable};
  td, th{
    padding-left:1rem;
    padding-right:1rem;
  }
  th{
    font-size: 16px;
    text-align: left;
  }
  td{
    font-size: 14px;
    ${''/* text-align: left; */}
  }

  ${props => props.borderColor && css`
    border: 1px solid ${props.borderColor};
  `}

  ${props => props.noBorder && css`
    border:0;
  `}

  ${props => props.overflow && css`
    overflow-y: hidden;
  `}

  ${props => props.normalBorder && css`
    th, Td {
      border: 1px solid black;
    }
  `}

  ${props => props.onlyBorderTr && css`
    tr {
      border: 1px solid black;
      border-collapse: collapse;
    }
  `}

  ${props => props.onlyBorderMainTr && css`
    Tr.main {
      border-top: 1px solid ${props.theme.color.grayTable};
      border-collapse: collapse;
    }
  `}

  ${props => props.whiteBG && css`
    background-color:white;
  `}

  ${props => props.paddingTextLeftRight && css`
    td{
      padding-left:1rem;
      padding-right:1rem;
    }
  `}

`;

export default tableStyles;
