import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PrivateLayout from 'client/modules/core/containers/PrivateLayoutRoute';
import Dashboard from 'client/modules/dashboard/containers/Dashboard';
import NotFoundPage from 'client/modules/core/components/NotFound';
import NoPermissions from 'client/modules/core/components/NoPermissions';
import Login from 'client/modules/accounts/containers/Login';
import CentreList from 'client/modules/dashboard-centres/containers/List';
import CentreForm from 'client/modules/dashboard-centres/containers/Form';
import UserList from 'client/modules/dashboard-users/containers/List';
import UserForm from 'client/modules/dashboard-users/containers/Form';
import RolesList from 'client/modules/dashboard-roles/containers/List';
import RolesForm from 'client/modules/dashboard-roles/containers/Form';
import Permissions from 'client/modules/dashboard-permissions/containers/Permissions';
import ChangePassword from 'client/modules/dashboard-users/containers/ChangePassword';
import MyRepositoryMFS from 'client/modules/my-mfs/containers/Repository';
import CenterRepositoryMFS from 'client/modules/center-repository/containers/Repository';
import PublicatedRepositoryMFS from 'client/modules/publicated-repository/containers/Repository';
import MfsDashboard from 'client/modules/dashboard-mfs/containers/Wrapper';
import MfsDashboardCenter from 'client/modules/dashboard-mfs/containers/WrapperCenter';
import UfsDashboard from 'client/modules/dashboard-ufs/containers/Wrapper';
import UasDashboard from 'client/modules/dashboard-uas/containers/Wrapper';
import ContentsDashboard from 'client/modules/dashboard-contents/containers/Wrapper';
import ViewMf from 'client/modules/view-mf/containers/Wrapper';
import ViewUa from 'client/modules/view-ua/containers/Wrapper';
import ViewUf from 'client/modules/view-uf/containers/Wrapper';
import ViewContent from 'client/modules/view-content/containers/Wrapper';
import ForgottenPassword from 'client/modules/accounts/components/ForgottenPassword';
import { userIsAuthenticated, userIsNotAuthenticated } from 'client/modules/accounts/utils/Utils';

const Routes = () => (
  <Switch>
    {/* Private routes  */}
    <Route exact path="/" component={userIsNotAuthenticated(Login)} />
    <Route exact path="/login" component={userIsNotAuthenticated(Login)} />
    <Route exact path="/forgottenPassword" component={ForgottenPassword} />
    <Route exact path="/withoutPermission" component={NoPermissions} />
    <PrivateLayout exact path="/dashboard" component={userIsAuthenticated(Dashboard)} module="DASHBOARD" action="READ" />
    <PrivateLayout exact path="/dashboard/centres" component={userIsAuthenticated(CentreList)} module="CENTRES" action="READ" />
    <PrivateLayout exact path="/dashboard/centres/add" component={userIsAuthenticated(CentreForm)} module="CENTRES" action="WRITE" />
    <PrivateLayout exact path="/dashboard/centres/edit/:id" component={userIsAuthenticated(CentreForm)} module="CENTRES" action="UPDATE" />
    <PrivateLayout exact path="/dashboard/users" component={userIsAuthenticated(UserList)} module="USERS" action="READ" />
    <PrivateLayout exact path="/dashboard/users/add" component={userIsAuthenticated(UserForm)} module="USERS" action="WRITE" />
    <PrivateLayout exact path="/dashboard/users/edit/:id" component={userIsAuthenticated(UserForm)} module="USERS" action="UPDATE" />
    <PrivateLayout exact path="/dashboard/roles" component={userIsAuthenticated(RolesList)} module="ROLES" action="READ" />
    <PrivateLayout exact path="/dashboard/roles/add" component={userIsAuthenticated(RolesForm)} module="ROLES" action="WRITE" />
    <PrivateLayout exact path="/dashboard/roles/edit/:id" component={userIsAuthenticated(RolesForm)} module="ROLES" action="UPDATE" />
    <PrivateLayout exact path="/dashboard/permissions" component={userIsAuthenticated(Permissions)} module="PERMISSIONS" action="READ" />
    <PrivateLayout exact path="/dashboard/changePassword" component={userIsAuthenticated(ChangePassword)} module="PASSWORD" action="RESET" />
    <PrivateLayout exact path="/dashboard/myMfs" component={userIsAuthenticated(MyRepositoryMFS)} module="MODULES" action="READ" />
    <PrivateLayout exact path="/dashboard/mfs" component={userIsAuthenticated(MfsDashboard)} module="MODULES" action="READ" />
    <PrivateLayout exact path="/dashboard/mfsAdmin/:id" component={userIsAuthenticated(MfsDashboardCenter)} module="CENTREREPO" action="READ" />
    <PrivateLayout exact path="/dashboard/mfs/:id" component={userIsAuthenticated(MfsDashboard)} module="MODULES" action="READ" />
    <PrivateLayout exact path="/viewMf/:id" component={userIsAuthenticated(ViewMf)} module="MODULES" action="READ" />
    <PrivateLayout exact path="/dashboard/ufs" component={userIsAuthenticated(UfsDashboard)} module="MODULES" action="READ" />
    <PrivateLayout exact path="/dashboard/ufs/:id" component={userIsAuthenticated(UfsDashboard)} module="MODULES" action="READ" />
    <PrivateLayout exact path="/viewUf/:id" component={userIsAuthenticated(ViewUf)} module="MODULES" action="READ" />
    <PrivateLayout exact path="/dashboard/uas/:type" component={userIsAuthenticated(UasDashboard)} module="MODULES" action="READ" />
    <PrivateLayout exact path="/dashboard/uas/edit/:id" component={userIsAuthenticated(UasDashboard)} module="MODULES" action="READ" />
    <PrivateLayout exact path="/viewUa/:id" component={userIsAuthenticated(ViewUa)} module="MODULES" action="READ" />
    <PrivateLayout exact path="/dashboard/contents" component={userIsAuthenticated(ContentsDashboard)} module="MODULES" action="READ" />
    <PrivateLayout exact path="/dashboard/contents/:id" component={userIsAuthenticated(ContentsDashboard)} module="MODULES" action="READ" />
    <PrivateLayout exact path="/viewContent/:id" component={userIsAuthenticated(ViewContent)} module="MODULES" action="READ" />
    <PrivateLayout exact path="/dashboard/centreRepository" component={userIsAuthenticated(CenterRepositoryMFS)} module="CENTREREPO" action="READ" />
    <PrivateLayout exact path="/publicated" component={userIsAuthenticated(PublicatedRepositoryMFS)} module="MODULES" action="READ" />

    <Route path="*" component={NotFoundPage} />
  </Switch>
);

export default Routes;
