import React from 'react';
import PropTypes from 'prop-types';
import { api } from 'config';
import styled from 'styled-components';
import Label from 'client/ui/Label';
import Audio from 'client/ui/Audio';
import FileUploadButton from 'client/ui/FileUploadButton';
import ContentText from './ContentText';

const AudioPreviewWrapper = styled.div`
  max-width: 500px;
  margin-left:auto;
  margin-right: auto;
  margin-bottom: 1rem;
`;

const UploadButton = styled.div`
  margin-bottom: 1rem;
`;

const AudioWrapper = ({ id, data, transcriptionChange, url, uploadAudio }) => (
  <div>
    <AudioPreviewWrapper>
      <Audio
        id={id}
        url={`${api.baseUrl}${url}`}
      />
    </AudioPreviewWrapper>
    <UploadButton>
      <FileUploadButton
        id={`uploadAudio-${id}`}
        label="Subir audio"
        onChange={(event) => { uploadAudio(event, id); }}
        required="required"
        type="audio/*"
      />
    </UploadButton>
    <Label>Transcripción</Label>
    <ContentText
      data={data}
      id={id}
      onChange={transcriptionChange}
      mediumType="mediumEditorBasic"
    />
  </div>
);

AudioWrapper.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.string.isRequired,
  transcriptionChange: PropTypes.func.isRequired,
  uploadAudio: PropTypes.func.isRequired,
  url: PropTypes.string,
};

AudioWrapper.defaultProps = {
  url: '',
};

export default AudioWrapper;
