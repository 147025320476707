import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import PictureView from './PictureView';
import TextView from './TextView';

const TextPictureWrapper = styled.div`
  width: 100%;
  min-height: 200px;
  display: flex;
  ${props => props.position.value === 'left' && css`
    flex-direction: row-reverse;
  `}
`;

const ContentWrapper = styled.div`
  width: 50%;
  padding: 1rem;
  padding-left: 0;
  ${props => props.width && css`
    width: ${props.width};
  `}
  p:first-child {
    margin-top: 0;
  }
  ${props => props.position.value === 'left' && css`
    margin-right: 1rem;
  `}
`;

const textPictureView = ({ data, picture, props }) => {
  const { position } = props;
  if (position.value === 'left') {
    return (
      <TextPictureWrapper className="animated bounceInLeft" position={position}>
        <ContentWrapper width="60%" position={position}>
          <TextView
            data={data}
          />
        </ContentWrapper>
        <ContentWrapper width="40%" position={position}>
          <PictureView
            key={`picture_${picture.id}`}
            picture={picture}
            props={props}
            noMargin
          />
        </ContentWrapper>
      </TextPictureWrapper>
    );
  }
  return (
    <TextPictureWrapper className="animated bounceInRight" position={position}>
      <ContentWrapper width="60%" position={position}>
        <TextView
          data={data}
        />
      </ContentWrapper>
      <ContentWrapper width="40%" position={position}>
        <PictureView
          key={`picture_${picture.id}`}
          picture={picture}
          props={props}
          noMargin
        />
      </ContentWrapper>
    </TextPictureWrapper>
  );
};
textPictureView.propTypes = {
  data: PropTypes.string.isRequired,
  picture: PropTypes.objectOf(PropTypes.any),
  props: PropTypes.objectOf(PropTypes.any).isRequired,
};

textPictureView.defaultProps = {
  picture: { src: '/images/contents/picture.jpg' },
};

export default textPictureView;
