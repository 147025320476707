import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Button from 'client/ui/Button';
import { H3 } from 'client/ui/Headings';
import Select from 'client/ui/Select';
import Popup from 'client/modules/view-content/components/ButtonPopupItemView';
import Menu from './ConfigurationMenu';
import ContentText from './ContentText';
import ButtonPopupItem from './ButtonPopupItem';
import ContentWrapperOptions from './ContentWrapperOptions';

const TextPictureWrapper = styled.div`
  width: 100%;
  min-height: 200px;
  display: flex;
  ${props => props.position.value === 'left' && css`
    flex-direction: row-reverse;
    @media (max-width: 650px) {
      flex-direction: column !important;
    }
  `}
  @media (max-width: 650px) {
    flex-direction: column-reverse;
  }
`;

const ContentWrapper = styled.div`
  width: 50%;
  padding: 1rem 1rem 0;
  ${props => props.width && css`
    width: ${props.width};
  `}
`;

const AddButton = styled(Button)`
  margin-bottom: 1rem;
  display: flex;
  margin-left: auto;
  margin-right: auto;
`;

const StyledNoContentText = styled(H3)`
  text-align: center;
`;

const StyledPopupPane = styled.div`
  padding-top: 1rem;
  margin-top: 8px;
  margin-bottom: 8px;
`;
const StyledButton = styled(Button)`
  margin-bottom: 1rem;
`;

const MenuPopupItem = styled.div`
  padding: 1rem 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid ${props => props.theme.color.grayTable};
  border-radius: ${props => props.theme.radius};
  box-shadow: 0px 2px ${props => props.theme.color.grayTable};
`;

class ContentTextPopup extends Component {
  constructor() {
    super();
    this.state = {
      configurationVisible: false,
    };
    this.onItemDelete = this.onItemDelete.bind(this);
    this.onItemMove = this.onItemMove.bind(this);
    this.handleDisplayMenu = this.handleDisplayMenu.bind(this);
    this.onPictureMove = this.onPictureMove.bind(this);
    this.close = this.close.bind(this);
  }

  onItemDelete(index) {
    const { id, removePopupItem } = this.props;
    removePopupItem(id, index);
  }

  onItemMove(index, direction) {
    const { id, movePopupItem } = this.props;
    movePopupItem(id, index, direction);
  }

  onPictureMove(id, index, direction) {
    const { onImageMove } = this.props;
    onImageMove(id, index, direction)
  }

  handleDisplayMenu() {
    const { configurationVisible } = this.state;
    this.setState({ configurationVisible: !configurationVisible });
  }

  close() {
    this.setState({ configurationVisible: false });
  }

  render() {
    const { id, addItem, onTitleChange, onTextChange, items, savePicture, data, onChange,
      changePosition, props, positions, onButtonChange } = this.props;
    const { position } = props;
    const { configurationVisible } = this.state;
    return (
      <div>
        <Menu
          title="Editar botón popup"
          width="25rem"
          isOpen={configurationVisible}
          close={this.close}
        >
          <AddButton
            onClick={() => addItem(id)}
          >
            Añadir Popup
          </AddButton>
          {items.length === 0
            ? <StyledNoContentText>No hay items que mostrar</StyledNoContentText>
            : (
              <div>
                {items && items.map((item, index) => (
                  <MenuPopupItem>
                    <ContentWrapperOptions
                      id={index.toString()}
                      remove={this.onItemDelete}
                      moveContent={this.onItemMove}
                      noBottomProps
                    >
                      <ButtonPopupItem
                        key={`popups${index}-${id}`}
                        id={id}
                        index={index}
                        buttonText={item.buttonText}
                        text={item.text}
                        title={item.title}
                        positionImage={item.positionImage}
                        movePicture={this.onPictureMove}
                        picture={item.url}
                        savePicture={savePicture}
                        onTitleChange={onTitleChange}
                        onTextChange={onTextChange}
                        onButtonChange={onButtonChange}
                      />
                    </ContentWrapperOptions>
                  </MenuPopupItem>
                ))}
              </div>
            )}
        </Menu>
        <StyledButton
          hollow
          color="black"
          onClick={() => this.handleDisplayMenu()}
        >
          Editar
        </StyledButton>
        <Select
          name="selectedRole"
          options={positions}
          value={position}
          onChange={(value) => { changePosition(id, value); }}
          placeholder="Seleccione"
          noResultsText="No se ha encontrado coincidencias"
          valueKey="id"
          labelKey="text"
          autoFocus
          small
        />

        <TextPictureWrapper position={position}>
          <ContentWrapper width="60%">
            <ContentText
              data={data}
              id={id}
              onChange={onChange}
            />
          </ContentWrapper>
          <ContentWrapper width="40%">
            {items && items.map((item, index) => (
              <StyledPopupPane>
                <Popup
                  key={`popups${index}-${id}`}
                  buttonText={item.buttonText}
                  title={item.title}
                  text={item.text}
                  positionImage={item.positionImage}
                  picture={item.url}
                  colorMf="#455A64"
                />
              </StyledPopupPane>
            ))}
          </ContentWrapper>
        </TextPictureWrapper>
      </div>
    );
  }
}

ContentTextPopup.propTypes = {
  id: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  onTitleChange: PropTypes.func.isRequired,
  onTextChange: PropTypes.func.isRequired,
  savePicture: PropTypes.func.isRequired,
  addItem: PropTypes.func.isRequired,
  removePopupItem: PropTypes.func.isRequired,
  movePopupItem: PropTypes.func.isRequired,
  data: PropTypes.string.isRequired,
  props: PropTypes.objectOf(PropTypes.any),
  changePosition: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  positions: PropTypes.arrayOf(PropTypes.any).isRequired,
  position: PropTypes.objectOf(PropTypes.any),
  onButtonChange: PropTypes.func.isRequired,
};

ContentTextPopup.defaultProps = {
  props: {},
  position: {},
};

export default ContentTextPopup;
