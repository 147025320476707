/* eslint import/prefer-default-export: 0 */

import { TOOGLE_NAVIGATION } from '../constants/actionTypes';

export function toogleNavigation(id, open) {
  return {
    type: TOOGLE_NAVIGATION,
    id,
    open,
  };
}
