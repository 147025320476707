import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ContentWrapper from 'client/ui/ContentWrapper';
import Li from 'client/ui/Li';

const StyledUl = styled.ul`
  list-style-type: none;
  padding-left: 0;
  li {
    margin-bottom: 1rem;
  }
`;

const StyledLi = styled(Li)`
  display: flex;
  color: ${props => props.theme.color.black};
  &:before {
    content: "• ";
    color: ${props => props.color};
    margin-right: 10px;
  }
`;

const CustomDiv = styled.div`
  line-height: 22px;
`;

const GeneralDescription = ({ moduleToView }) => {
  const { codeCert, codeMf, nameCert, nameMf, centre, colorMf } = moduleToView;
  return (
    <ContentWrapper marginTop="2rem">
      <StyledUl>
        <StyledLi color={colorMf} className="animated fadeIn">
          <CustomDiv>
            <b>Código del Módulo</b><div className="animated fadeIn delay-1s">{codeMf}</div>
          </CustomDiv>
        </StyledLi>
        <StyledLi color={colorMf} className="animated fadeIn">
          <CustomDiv>
            <b>Nombre del Módulo Formativo</b><div className="animated fadeIn delay-1s">{nameMf}</div>
          </CustomDiv>
        </StyledLi>
        <StyledLi color={colorMf} className="animated fadeIn">
          <CustomDiv>
            <b>Código Certificado</b><div className="animated fadeIn delay-1s">{codeCert}</div>
          </CustomDiv>
        </StyledLi>
        <StyledLi color={colorMf} className="animated fadeIn">
          <CustomDiv>
            <b>Nombre del Certificado</b><div className="animated fadeIn delay-1s">{nameCert}</div>
          </CustomDiv>
        </StyledLi>
        <StyledLi color={colorMf} className="animated fadeIn">
          <div>
            <b>Centro</b><div className="animated fadeIn delay-1s">{centre && centre.label}</div>
          </div>
        </StyledLi>
      </StyledUl>
    </ContentWrapper>
  );
};

GeneralDescription.propTypes = {
  moduleToView: PropTypes.objectOf(PropTypes.any).isRequired,
};

GeneralDescription.defaultProps = {};

export default GeneralDescription;
