/* eslint react/no-array-index-key: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Collapse from 'client/ui/CollapsableMenu';
import { NavLink } from 'react-router-dom';
import IconFa from 'client/ui/IconFa';

const StyledNavLink = styled(NavLink)`
  display: block;
  position: relative;
  padding: 0.5rem;
  color: #000;
  background-color: white;
  text-decoration: none;

  &:hover {
    background: #EEEEEE;
  }

  &:active,
  &.active {
    color: #000;
    font-weight: bold;
    background-color: rgb(220,220,220);
  }
`;

const CustomNavLink = StyledNavLink.extend`
padding: 1rem;
  color: black;
  background: #fff;
  border-bottom: 1px solid #D6D6D6;
  cursor: pointer;
`;

const StyledCollapse = styled(Collapse)`
  border-bottom: 1px solid #D6D6D6;
`;

const FaWrapper = styled.div`
  font-size: .4rem;
  align-self: center;
  margin-right: .5rem;
`;

const NavLinkWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Wrapper = styled.div`
  border: 1px solid #D6D6D6;
`;

const MenuCollapse = ({ isOpen, items, onLinkClick }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <Wrapper>
      {items.map((item, index) => (
        <div key={`item-${index}`}>
          {item.options && item.options.length > 0
            ? (
              <StyledCollapse isOpen={item.isOpen} key={item.name} title={item.name} id={item.id}>
                {item.options.map(option => (
                  <StyledNavLink key={option.name} exact to={option.path} onClick={onLinkClick}>
                    <NavLinkWrapper>
                      <FaWrapper><IconFa name="circle" /></FaWrapper>{option.name}
                    </NavLinkWrapper>
                  </StyledNavLink>
                ))}
              </StyledCollapse>
            )
            : (
              <div>
                {item.click
                  ? (
                    <CustomNavLink key={item.name} exact to={item.path} onClick={item.click}>
                      <NavLinkWrapper>
                        {item.name}
                      </NavLinkWrapper>
                    </CustomNavLink>)
                  : (
                    <CustomNavLink key={item.name} exact to={item.path} onClick={onLinkClick}>
                      <NavLinkWrapper>
                        {item.name}
                      </NavLinkWrapper>
                    </CustomNavLink>)}
              </div>)}
        </div>
      ))}

    </Wrapper>
  );
};

MenuCollapse.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  onLinkClick: PropTypes.func.isRequired,
};

MenuCollapse.defaultProps = {};

export default MenuCollapse;
