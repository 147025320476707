import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const MainContentWrapper = styled.div`
  flex: 1 0 auto;
  min-height: 1px;
  width: 100%;
`;

const MainContent = ({ children, noMaxWidth }) => (
  <MainContentWrapper
    noMaxWidth={noMaxWidth}
  >
    {children}
  </MainContentWrapper>
);

MainContent.propTypes = {
  children: PropTypes.node.isRequired,
  noMaxWidth: PropTypes.bool.isRequired,
};

MainContent.defaultProps = {};

export default MainContent;
