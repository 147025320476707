import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getThemeColor } from '../style-utils';
import Button from '../Button';

const StyledFa = styled.i`
  color: ${props => getThemeColor(props.faColor)};
`;

/**
 * Renders any Button component with Font Awesome icon
 */
const ButtonFa = ({ children, fa, side, faColor, ...rest }) => {
  const icon = (
    <StyledFa
      faColor={faColor}
      className={`fas ${fa}`}
      aria-hidden="true"
    />
  );

  return (
    <Button {...rest}>
      {side === 'left'
        ? <span>{icon} {children}</span>
        : <span>{children} {icon}</span>
      }
    </Button>
  );
};

ButtonFa.propTypes = {
  /** Font Awesome icon name */
  fa: PropTypes.string.isRequired,
  children: PropTypes.node,
  side: PropTypes.oneOf(['left', 'right']),
  faColor: PropTypes.string,
};

ButtonFa.defaultProps = {
  children: null,
  side: 'left',
  faColor: 'inherit',
};

export default ButtonFa;
