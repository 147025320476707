import styled, { css } from 'styled-components';
import { getThemeColor } from '../style-utils';

const StyledTab = styled.button`
  display: inline-block;
  padding: 0.5em 1em;
  font-size: inherit;
  line-height: 1;
  background: transparent;
  border: 0;
  border-radius: 0;
  outline: 0;
  cursor: pointer;
  -webkit-appearance: none;

  ${''/* Tab activa */}
  ${props => props.active && props.color && css`
    border-bottom: 3px solid ${getThemeColor(props.color)};
  `};
`;

export default StyledTab;
