import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import IconFa from 'client/ui/IconFa';

/**
 * 1. Hace que el alto sea el mismo que el del contenedor y no mayor.
 *    Comentar la propiedad si no se desea este comportamiento.
 */
const StyledNavigation = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  width: ${props => props.width};
  min-height: 100%;
  background-color: ${props => props.theme.color.white};
  border-right: 1px solid ${props => props.theme.color.gray};
  z-index: 99;
  transform: translateX(${props => (props.isOpen ? '0' : '-150%')});
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  height: 100vh; ${''/* 1 */}
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 0;
  text-align: center;
`;

const CloseButton = styled(IconFa)`
  margin-right: 1rem;
  cursor: pointer;
`;

const TitleWrapper = styled.div`
  padding-left: 1rem;
`;

/**
 * 1. Hace que aparezca scroll cuando el contenido sea mayor al tamaño del contenedor.
 *    Comentar la propiedad si no se desea este comportamiento.
 */
const StyledBody = styled.div`
  flex: 1;
  overflow: auto; ${''/* 1 */}
  padding: 1rem 1.5rem 1rem 1rem;
`;


class Menu extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const { isOpen, width, children, close, title } = this.props;
    return (
      <StyledNavigation isOpen={isOpen} width={width}>
        <StyledHeader>
          <TitleWrapper>
            {title}
          </TitleWrapper>
          <CloseButton name="times" onClick={close} />
        </StyledHeader>
        <StyledBody>
          {children}
        </StyledBody>
      </StyledNavigation>
    );
  }
}

Menu.propTypes = {
  close: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  width: PropTypes.string,
  title: PropTypes.string.isRequired,
};

Menu.defaultProps = {
  width: '14rem',
};

export default Menu;
