import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import IconFa from 'client/ui/IconFa';
import Img from 'client/ui/Img';
import Span from 'client/ui/Span';

/**
 * 1. Hace que el alto sea el mismo que el del contenedor y no mayor.
 *    Comentar la propiedad si no se desea este comportamiento.
 */
const StyledNavigation = styled.nav`
  position: fixed;
  top: 0;
  width: ${props => props.width};
  min-height: 100%;
  background-color: ${props => props.theme.color.darkGray};
  border-right: 1px solid ${props => props.theme.color.darkGray};
  z-index: 110;
  transform: translateX(${props => (props.isOpen ? 0 : '-100%')});
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  height: 100vh; ${''/* 1 */}
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 0;
  text-align: center;
`;

const StyledSpan = styled(Span)`
  font-weight: bold;
  margin-left: 1rem;
`;

const CloseButton = styled(IconFa)`
  color: white;
  margin-right: 1.5rem;
  cursor: pointer;
`;

/**
 * 1. Hace que aparezca scroll cuando el contenido sea mayor al tamaño del contenedor.
 *    Comentar la propiedad si no se desea este comportamiento.
 */
const StyledBody = styled.div`
  flex: 1;
  overflow: auto; ${''/* 1 */}
`;

const OptionTitle = styled.div`
  font-weight: bold;
  color: ${props => props.theme.color.white};
  margin-bottom: .5rem;
`;

const OptionWrapper = styled.div`
  cursor: pointer;
  margin: 1rem 2.65rem 2rem 1rem;

  &:hover{
    opacity: 0.80;
  }
`;

const OptionContent = styled.div`
  background-color: white;
  height: 125px;
  overflow: hidden;
`;

const Option = ({ title, onClick, children }) => (
  <OptionWrapper
    role="button"
    onClick={onClick}
    tabIndex={0}
  >
    <OptionTitle>{title}</OptionTitle>
    <OptionContent>
      {children}
    </OptionContent>
  </OptionWrapper>
);

Option.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

Option.defaultProps = {};

class EvaluationMenu extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const { isOpen, width, close, addContent } = this.props;
    return (
      <StyledNavigation isOpen={isOpen} width={width}>
        <StyledHeader>
          <StyledSpan color="white">Evaluación</StyledSpan>
          <CloseButton name="times" onClick={close} />
        </StyledHeader>
        <StyledBody>
          <Option onClick={() => addContent('simple')} title="Respuesta Simple">
            <Img src="/images/contents/simple.png" alt="Respuesta Simple" />
          </Option>
          <Option onClick={() => addContent('multiple')} title="Respuesta Múltiple">
            <Img src="/images/contents/multiple.png" alt="Respuesta Múltiple" />
          </Option>
          <Option onClick={() => addContent('clozeSelect')} title="Cloze con selección">
            <Img src="/images/contents/selectionCloze.png" alt="Cloze con selección" />
          </Option>
          <Option onClick={() => addContent('cloze')} title="Cloze">
            <Img src="/images/contents/cloze.png" alt="Cloze" />
          </Option>
          <Option onClick={() => addContent('mixedQuestions')} title="Mixto">
            <Img src="/images/contents/mixed.jpg" alt="Mixed" />
          </Option>
        </StyledBody>
      </StyledNavigation>
    );
  }
}

EvaluationMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  width: PropTypes.string,
  addContent: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

EvaluationMenu.defaultProps = {
  width: '17.18rem',
};

export default EvaluationMenu;
