import React from 'react';
import PropTypes from 'prop-types';
import Button from 'client/ui/Button';
import { api } from 'config';
import styled from 'styled-components';
import Img from 'client/ui/Img';
import TextTruncate from 'react-text-truncate';
import TextView from './TextView';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 720px;
`;

const FileIconWrapper = styled.div`
  width: 50px;
  margin-bottom: .5rem;
`;

const FileName = styled.div`
  font-size: 0.875rem;
  padding: 0 0.5rem;
  color: #666666;
  margin-bottom: .5rem;
  text-align: center;
`;

const UploadWrapper = styled.div`
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 1.5rem;
`;

const ResourceView = ({ url, data, name, colorMf }) => (
  <Wrapper className="animated flipInX">
    <TextView data={data} />
    {url && url.length > 5
      && (
        <UploadWrapper>
          <FileIconWrapper>
            <Img src="/images/contents/fill.png" />
          </FileIconWrapper>
          <FileName>
            <TextTruncate
              line={2}
              truncateText="…"
              text={name}
            />
          </FileName>
          {colorMf !== ''
          && <Button background={colorMf} href={`${api.baseUrl}${url}`} download={name}>Descargar</Button>}
        </UploadWrapper>)}
  </Wrapper>
);


ResourceView.propTypes = {
  url: PropTypes.string,
  data: PropTypes.string.isRequired,
  name: PropTypes.string,
  colorMf: PropTypes.string.isRequired,
};

ResourceView.defaultProps = {
  url: '',
  name: '',
};

export default ResourceView;
