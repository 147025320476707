import React from 'react';
import PropTypes from 'prop-types';
import Label from 'client/ui/Label';
import Input from 'client/ui/Input';
import { api } from 'config';
import styled from 'styled-components';
import MediumEditor from 'client/ui/MediumEditor';
import { mediumEditorBasic } from 'client/modules/dashboard/utils/medium-editor';
import UploadPictureMenu from './UploadPictureMenu';

const Editor = styled(MediumEditor)`
  &:focus {
    border: 2px solid #b0d6fe;
    box-shadow: 0px 0px 41px 0px #b0d6fe;
  }
`;

const StyledInput = styled(Input)`
  &:focus {
    border: 2px solid #b0d6fe;
    box-shadow: 0px 0px 20px 0px #b0d6fe;
  }
`;

const Div = styled.div`
  padding: 1rem;
  &:focus-within {
    background-color: #f3f3f2;
    width: 100%;
    height: 100%;
  }
`;

const TabsItem = ({ id, title, text, onTitleChange, onTextChange, index, picture,
  savePicture, previewMaxWidth }) => (
    <Div>
      <Label>Título</Label>
      <StyledInput
        type="text"
        value={title}
        onChange={(value) => { onTitleChange(id, index, value); }}
        small
      />
      <UploadPictureMenu
        id={`id-${id}${index}`}
        image={picture && picture.length > 0 ? `${api.baseUrl}${picture}` : ''}
        onChange={(selected) => { savePicture(selected, id, index); }}
        previewMaxWidth={previewMaxWidth}
        required=""
      />
      <Label>Texto</Label>
      <Editor
        id={id}
        text={text}
        onChange={(value) => { onTextChange(id, index, value); }}
        options={{
          toolbar: { buttons: mediumEditorBasic },
          placeholder: { text: 'Añadir texto', hideOnClick: true },
        }}
      />
    </Div>
);

TabsItem.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  picture: PropTypes.string,
  onTitleChange: PropTypes.func.isRequired,
  onTextChange: PropTypes.func.isRequired,
  savePicture: PropTypes.func.isRequired,
  previewMaxWidth: PropTypes.string,
};

TabsItem.defaultProps = {
  picture: '',
  previewMaxWidth: '150px',
};

export default TabsItem;
