/* eslint no-restricted-globals: 0, react/destructuring-assignment: 0,
react/no-access-state-in-setstate: 0, react/no-array-index-key: 0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import theme from 'client/config/theme';
import Divider from 'client/ui/Divider';
import Button from 'client/ui/Button';
import IconFa from 'client/ui/IconFa';
import CodeName from 'client/ui/CodeName';
import { Tabs, TabsPane, Tab } from 'client/ui/Tabs';
import SectionCircle from 'client/ui/SectionCircle';
import { H2 } from 'client/ui/Headings';
import ContentWrapper from 'client/ui/ContentWrapper';
import { redirectTo } from 'index';
import TextView from './TextView';
import PictureView from './PictureView';
import TextPictureView from './TextPictureView';
import TextWithTitle from './TextWithTitle';
import TitleView from './TitleView';
import ListView from './ListView';
import LinkView from './LinkView';
import CollapseView from './CollapseView';
import VideoView from './VideoView';
import AudioView from './AudioView';
import ResourceView from './ResourceView';
import TabsView from './TabsView';
import ButtonPopupView from './ButtonPopupView';
import TextPopupView from './TextPopupView';
import ImagePopupView from './ImagePopupView';
import GalleryView from './GalleryView';
import QuestionsView from './QuestionsView';

const CircleText = styled.div`
  font-size: 21px;
  font-weight: bold;
  color: white;
  position: absolute;
  top: 10px;
  right: 14px;
`;

const Header = styled.div`
  margin-bottom: .5rem;
`;

const SectionTitle = styled.div`
  text-align: center;
  background-color: ${props => props.theme.color.greyContent};
  color: white;
  padding: .5rem;
  h2 {
    margin-bottom: 0;
  }
`;

const StyledH2 = styled(H2)`
  color: ${props => props.theme.color.white};
`;

const Section = ContentWrapper.extend`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;
`;

const StyledSeparation = styled.div`
  width: 100%;
  min-width: 0;
  margin-right: 1rem;
`;

const MfName = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: .25rem;
`;

const DividerWrapper = styled.div`
  position: relative;
`;

const MfsDivider = Divider.extend`
  position: absolute;
  top: 0;
  left: 0;
`;

const UfsDivider = MfsDivider.extend`
  left: 25%;
`;
const UasDivider = MfsDivider.extend`
  left: 50%;
  &.mf {
    left: 33.3333%;
  }
`;

const ContentDivider = MfsDivider.extend`
  left: 75%;
  &.mf {
    left: 66.6666%;
  }
`;

const NoResult = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
`;

const StyledReturn = styled.span`
  margin-right: .7rem;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
`;

const PrevFa = styled(IconFa)`
  margin-right: .5rem;
  ${props => props.color && css`
    color: ${props.color};
  `}
  ${props => props.doubleMargin && css`
    margin-right: .8rem;
  `}
`;

const ContentDivWrapper = styled.div`
  margin-bottom: 2rem;
`;

const renderContent = (content, colorMf) => {
  switch (content.type) {
    case 'text':
      return (
        <TextView
          key={`Content_${content.id}`}
          data={content.data}
          id={content.id}
        />);
    case 'picture':
      return (
        <PictureView
          key={`Content_${content.id}`}
          id={content.id}
          picture={content.picture}
          props={content.props}
        />
      );
    case 'textPicture':
      return (
        <TextPictureView
          key={`Content_${content.id}`}
          id={content.id}
          data={content.data}
          props={content.props}
          picture={content.picture}
        />
      );
    case 'textTitle':
      return (
        <TextWithTitle
          key={`Content_${content.id}`}
          id={content.id}
          data={content.data}
          title={content.title}
        />
      );
    case 'title':
      return (
        <TitleView
          key={`Content_${content.id}`}
          id={content.id}
          title={content.title}
        />
      );
    case 'ul':
      return (
        <ListView
          key={`Content_${content.id}`}
          id={content.id}
          list={content.list}
          type="ul"
          colorMf={colorMf}
        />
      );
    case 'ol':
      return (
        <ListView
          key={`Content_${content.id}`}
          id={content.id}
          list={content.list}
          type="ol"
          colorMf={colorMf}
        />
      );
    case 'link':
      return (
        <LinkView
          key={`Content_${content.id}`}
          id={content.id}
          data={content.data}
          url={content.url}
          colorMf={colorMf}
        />
      );
    case 'collapse':
      return (
        <CollapseView
          key={`Content_${content.id}`}
          id={content.id}
          items={content.items}
          colorMf={colorMf}
        />
      );
    case 'video':
      return (
        <VideoView
          key={`Content_${content.id}`}
          id={content.id}
          data={content.data}
          url={content.url}
        />
      );
    case 'audio':
      return (
        <AudioView
          key={`Content_${content.id}`}
          id={content.id}
          data={content.data}
          url={content.url}
        />
      );
    case 'resource':
      return (
        <ResourceView
          key={`Content_${content.id}`}
          id={content.id}
          data={content.data}
          name={content.props ? content.props.name : ''}
          url={content.url}
          colorMf={colorMf}
        />
      );
    case 'tabs':
      return (
        <TabsView
          key={`Content_${content.id}`}
          id={content.id}
          items={content.items}
        />
      );
    case 'button-popup':
      return (
        <ButtonPopupView
          key={`Content_${content.id}`}
          id={content.id}
          items={content.items}
          colorMf={colorMf}
        />
      );
    case 'text-popup':
      return (
        <TextPopupView
          key={`Content_${content.id}`}
          id={content.id}
          items={content.items}
          data={content.data}
          props={content.props}
          colorMf={colorMf}
        />
      );
    case 'image-popup':
      return (
        <ImagePopupView
          key={`Content_${content.id}`}
          id={content.id}
          items={content.items}
          props={content.props}
          picture={content.picture}
          colorMf={colorMf}
        />
      );
    case 'gallery':
      return (
        <GalleryView
          id={content.id}
          items={content.items}
          color={colorMf}
        />
      );
    case 'simple':
    case 'multiple':
    case 'clozeSelect':
    case 'cloze':
    case 'mixedQuestions':
      return (
        <QuestionsView
          key={`Content_${content.id}`}
          id={content.id}
          items={content.items}
          colorMf={colorMf}
        />
      );
    default:
      return null;
  }
};

const PrevLink = data => (
  <StyledReturn onClick={() => redirectTo(`/viewUa/${data.uaId}`)}>
    <PrevFa name="chevron-left" doubleMargin />
    <PrevFa name="circle" color={data.colorUa} />
    <span>UA</span>
  </StyledReturn>
);

class Wrapper extends Component {
  constructor() {
    super();
    this.state = {};
    this.changeToEdit = this.changeToEdit.bind(this);
    this.wrapperContents = this.wrapperContents.bind(this);
  }

  changeToEdit() {
    const { history, match, tab } = this.props;
    const { id } = match.params;
    const path = { ...location, hash: tab };
    path.name = `/dashboard/contents/${id}`;
    path.pathname = `/dashboard/contents/${id}`;
    history.replace(path);
  }

  wrapperContents() {
    const { contents, colorMf } = this.props;
    return (
      <div>
        {contents.map(content => (
          <ContentDivWrapper key={content.id}>
            {renderContent(content, colorMf)}
            <Divider borderWidth="1px" borderColor="gray2" borderStyle="dotted" marginTop="1.5rem" />
          </ContentDivWrapper>
        ))}
      </div>
    );
  }

  render() {
    const { tab, title, notFound, uaId, contents, colorMf, colorUf, colorUa, haveUf } = this.props;
    return (
      <div>
        {haveUf
          ? (
            <DividerWrapper>
              <Divider borderWidth="2px" borderColor="gray2" />
              <MfsDivider width="25%" borderWidth="2px" borderColor={colorMf} />
              <UfsDivider width="25%" borderWidth="2px" borderColor={colorUf} />
              <UasDivider width="25%" borderWidth="2px" borderColor={colorUa} />
              <ContentDivider width="25%" borderWidth="2px" borderColor="greyContent" />
            </DividerWrapper>
          )
          : (
            <DividerWrapper>
              <Divider borderWidth="2px" borderColor="gray2" />
              <MfsDivider width="33.3333%" borderWidth="2px" borderColor={colorMf} />
              <UasDivider className="mf" width="33.3333%" borderWidth="2px" borderColor={colorUa} />
              <ContentDivider className="mf" width="33.3333%" borderWidth="2px" borderColor="greyContent" />
            </DividerWrapper>
          )}
        <Header>
          <Section>
            <MfName>
              <SectionCircle size="42px" background="greyContent">
                <CircleText>C</CircleText>
              </SectionCircle>
            </MfName>
            <StyledSeparation>
              <CodeName>
                { title !== ''
                  ? <div>{title}</div>
                  : <div>Contenido</div>
                }
              </CodeName>
            </StyledSeparation>
            <Button
              hollow
              color="black"
              borderColor="black"
              onClick={() => this.changeToEdit()}
            >
              Editar
            </Button>
          </Section>
          <SectionTitle>
            <StyledH2>CONTENIDOS</StyledH2>
          </SectionTitle>
        </Header>
        <Tabs
          tabsBarBorderColor="gray2"
          selected={tab}
          component={Tab}
          componentProps={{ color: colorMf }}
          tabsTitleMaxWidth={theme.maxWidth}
        >
          <TabsPane name="#ua" label="UA" component={PrevLink} componentProps={{ uaId, colorUa }}>
            <div />
          </TabsPane>
          <TabsPane
            name="#contents"
            label="Contenidos"
          >
            <ContentWrapper marginTop="2rem" lateralPadding="2.5rem">
              {contents.length > 0 && this.wrapperContents()}
              {notFound && contents.length === 0
                && <NoResult><H2>No se han cargado contenidos en esta página.</H2></NoResult>}
              {!notFound && contents.length === 0
                && <NoResult><H2>Cargando contenidos</H2></NoResult>
              }
            </ContentWrapper>
          </TabsPane>
        </Tabs>
      </div>
    );
  }
}

Wrapper.propTypes = {
  uaId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  contents: PropTypes.arrayOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  notFound: PropTypes.bool.isRequired,
  tab: PropTypes.string.isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  colorMf: PropTypes.string.isRequired,
  colorUf: PropTypes.string.isRequired,
  colorUa: PropTypes.string.isRequired,
  haveUf: PropTypes.bool.isRequired,
};

Wrapper.defaultProps = {
  contents: [],
};

export default Wrapper;
