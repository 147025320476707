import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from 'client/ui/Button';
import Divider from 'client/ui/Divider';
import ClozeSelectItem from './ClozeSelectItem';
import ContentWrapperOptions from './ContentWrapperOptions';

const Wrapper = styled.div`
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    min-width: 172px;
  }
`;

const QuestionWrapper = styled.div`
  padding-top: 1.5rem;
  margin-bottom: 1.5rem;
`;

class ClozeSelect extends Component {
  constructor() {
    super();
    this.state = {};
    this.onItemDelete = this.onItemDelete.bind(this);
    this.onItemMove = this.onItemMove.bind(this);
  }

  onItemDelete(index) {
    const { contentId, removeQuestionItem } = this.props;
    removeQuestionItem(contentId, index);
  }

  onItemMove(index, direction) {
    const { contentId, moveQuestionItem } = this.props;
    moveQuestionItem(contentId, index, direction);
  }

  render() {
    const { contentId, items, onClozeQuestionChange, optionCheckChange, optionTextChange, addAnswer,
      addClozeSelect, removeAnswer, sortQuestionOption, forceClozeChange } = this.props;
    return (
      <div>
        {items && items.length === 0 ? (
          <Wrapper>
            <Divider borderWidth="1px" borderColor="gray2" />
            <Button
              onClick={() => addClozeSelect(contentId)}
            >
              Añadir Pregunta
            </Button>
            <Divider borderWidth="1px" borderColor="gray2" />
          </Wrapper>
        )
          : <Divider borderWidth="1px" borderColor="gray2" />
        }
        {items && items.map((item, index) => (
          <QuestionWrapper key={item.id}>
            <ContentWrapperOptions
              id={index.toString()}
              remove={this.onItemDelete}
              moveContent={this.onItemMove}
              noBottomProps
            >
              <ClozeSelectItem
                contentId={contentId}
                questionId={item.id}
                question={item.question}
                options={item.options}
                addAnswer={addAnswer}
                sortQuestionOption={sortQuestionOption}
                onClozeQuestionChange={onClozeQuestionChange}
                optionCheckChange={optionCheckChange}
                optionTextChange={optionTextChange}
                removeAnswer={removeAnswer}
                forceClozeChange={forceClozeChange}
              />
            </ContentWrapperOptions>
          </QuestionWrapper>
        ))}
        {items && items.length > 0 && (
          <Wrapper>
            <Divider borderWidth="1px" borderColor="gray2" />
            <Button
              onClick={() => addClozeSelect(contentId)}
            >
              Añadir Pregunta
            </Button>
            <Divider borderWidth="1px" borderColor="gray2" />
          </Wrapper>
        )}
      </div>
    );
  }
}

ClozeSelect.propTypes = {
  contentId: PropTypes.string.isRequired,
  onClozeQuestionChange: PropTypes.func.isRequired,
  optionCheckChange: PropTypes.func.isRequired,
  optionTextChange: PropTypes.func.isRequired,
  addAnswer: PropTypes.func.isRequired,
  addClozeSelect: PropTypes.func.isRequired,
  removeAnswer: PropTypes.func.isRequired,
  moveQuestionItem: PropTypes.func.isRequired,
  removeQuestionItem: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  sortQuestionOption: PropTypes.func.isRequired,
  forceClozeChange: PropTypes.bool.isRequired,
};

ClozeSelect.defaultProps = {};

export default ClozeSelect;
