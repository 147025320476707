export const ROLES_REQUEST = 'ROLES_REQUEST';
export const ROLES_SUCCESS = 'ROLES_SUCCESS';
export const ROLES_ERROR = 'ROLES_ERROR';
export const PERMISSIONS_REQUEST = 'PERMISSIONS_REQUEST';
export const PERMISSIONS_SUCCESS = 'PERMISSIONS_SUCCESS';
export const PERMISSIONS_ERROR = 'PERMISSIONS_ERROR';
export const MODULES_REQUEST = 'MODULES_REQUEST';
export const MODULES_SUCCESS = 'MODULES_SUCCESS';
export const MODULES_ERROR = 'MODULES_ERROR';
export const TOOGLE_NAVIGATION = 'TOOGLE_NAVIGATION';
