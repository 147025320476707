import React from 'react';
import PropTypes from 'prop-types';
import { api } from 'config';
import styled, { css } from 'styled-components';
import Radio from 'client/ui/Radio';
import Span from 'client/ui/Span';
import IconFa from 'client/ui/IconFa';
import Img from 'client/ui/Img';

const StyledImagePreview = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 400px;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  img {
    display: block;
    margin: auto;
    width: auto;
    max-width: 100%;
  }
`;

const StyledSpan = styled.div`
  line-height: 27px;
  padding: 0 2.5rem;
  font-size: 20px;
  font-weight: bold;
`;

const RadioWrapper = styled.div`
  margin: 0.5rem 2.5rem;
  display: flex;
  flex-direction: row;
  padding: 0.5rem 0;
  padding-right: 1rem;
  ${props => props.correct === true && css`
    border: 1px solid #93CA40;
  `}
`;

const AnswerWrapper = styled.div`
  display: flex;
  width: 100%;
  min-width: 0;
  align-items: center;
`;

const AnswerSpan = styled(Span)`
  width: 100%;
  min-width: 0;
`;

const StyledRadio = styled(Radio)`
  margin-right: 1rem;
  margin-left: 1.5rem;
  align-items: center;
  width: 13px;
`;

const CorrectAnswerIcon = styled(IconFa)`
  color: ${props => props.theme.color.correctCheck};
  padding-left: 1rem;
`;

const SimpleQuestionView = ({ item }) => (
  <div>
    { item.url && (
      <StyledImagePreview>
        <Img src={`${api.baseUrl}${item.url}`} alt="imagen" />
      </StyledImagePreview>
    )}
    <StyledSpan>
      <Span color="modalTitle">{item.question}</Span>
    </StyledSpan>
    {item.options.map(option => (
      <RadioWrapper
        key={option.id}
        correct={option.correctAnswer}
      >
        <StyledRadio
          name={`radio-${option.id}`}
          value={option.text}
          checked={option.correctAnswer}
          readOnly
        />
        <AnswerWrapper>
          <AnswerSpan>{option.text}</AnswerSpan>
          {option.correctAnswer === true && (
            <CorrectAnswerIcon
              name="check-circle"
              solid
            />
          )}
        </AnswerWrapper>
      </RadioWrapper>
    ))}
  </div>
);

SimpleQuestionView.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
};

SimpleQuestionView.defaultProps = {};

export default SimpleQuestionView;
