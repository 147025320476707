import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import MediumEditor from 'client/ui/MediumEditor';
import { mediumEditorContentTypeText, mediumEditorBasic } from 'client/modules/dashboard/utils/medium-editor';

const TextWrapper = styled.div`
  width: 100%;
  ${props => props.margin > 0 && css`
    margin: ${props.margin};
  `}
`;

const StyledEditor = styled(MediumEditor)`
  margin-bottom: 0;
`;

const Text = ({ data, onChange, id, margin, mediumType, placeHolder }) => (
  <TextWrapper margin={margin}>
    {mediumType === 'mediumEditorBasic' ? (
      <StyledEditor
        text={data}
        onChange={(value) => { onChange(id, value); }}
        options={{
          toolbar: { buttons: mediumEditorBasic },
          placeholder: { text: placeHolder, hideOnClick: true },
        }}
      />
    )
      : (
        <StyledEditor
          text={data}
          onChange={(value) => { onChange(id, value); }}
          options={{
            toolbar: { buttons: mediumEditorContentTypeText },
            placeholder: { text: 'Añadir texto', hideOnClick: true },
          }}
        />
      )}
  </TextWrapper>
);

Text.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  margin: PropTypes.string,
  mediumType: PropTypes.string,
  placeHolder: PropTypes.string,
};

Text.defaultProps = {
  margin: '0',
  mediumType: 'mediumEditorContentTypeText',
  placeHolder: 'Añadir texto',
};

export default Text;
