/* eslint react/no-array-index-key: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ContentWrapper from 'client/ui/ContentWrapper';
import { H2 } from 'client/ui/Headings';
import Li from 'client/ui/Li';

const StyledUl = styled.ul`
  list-style-type: none;
  padding-left: 0;
  li {
    margin-bottom: .6rem;
  }
`;

const StyledLi = styled(Li)`
  display: flex;
  color: ${props => props.theme.color.black};
  &:before {
    content: "• ";
    color: ${props => props.color};
    margin-right: 10px;
  }
`;

const DangerousDiv = styled.div`
  margin-bottom: 1.5rem;
  line-height: 23px;
  color: ${props => props.theme.color.modalTitle};
`;

const GeneralData = ({ ufToView }) => {
  const { subtitles, introduction, objectives, colorMf } = ufToView;
  return (
    <ContentWrapper marginTop="2rem">
      {/* <H2>Codigo UF</H2>
      <DangerousDiv>{codeUf}</DangerousDiv>
      <H2>Título</H2>
      <DangerousDiv>{title}</DangerousDiv> */}

      {subtitles && subtitles.length === 1 && subtitles[0] !== '' && (
        <div>
          <H2 className="animated fadeIn">Subtítulos</H2>
          <StyledUl className="animated fadeIn delay-1s">
            <StyledLi color={colorMf} key="obj-0">{subtitles[0]}</StyledLi>
          </StyledUl>
        </div>
      )}

      {subtitles && subtitles.length > 1 && (
        <div>
          <H2 className="animated fadeIn">Subtítulos</H2>
          <StyledUl>
            {subtitles.map((subtitle, index) => {
              if (subtitle !== '') {
                return <StyledLi className="animated fadeIn delay-1s" color={colorMf} key={`obj-${index}`}>{subtitle}</StyledLi>;
              }
              return null;
            })}
          </StyledUl>
        </div>
      )}
      <H2 className="animated fadeIn">Introducción</H2>
      <DangerousDiv className="animated fadeIn delay-1s" dangerouslySetInnerHTML={{ __html: introduction }} />

      {objectives && objectives.length === 1 && objectives[0] !== '' && (
        <div>
          <H2 className="animated fadeIn">Objetivos</H2>
          <StyledUl className="animated fadeIn delay-1s">
            <StyledLi color={colorMf} key="obj-0">{objectives[0]}</StyledLi>
          </StyledUl>
        </div>
      )}

      {objectives && objectives.length > 1 && (
        <div>
          <H2 className="animated fadeIn">Objetivos</H2>
          <StyledUl className="animated fadeIn delay-1s">
            {objectives.map((objective, index) => {
              if (objective !== '') {
                return <StyledLi color={colorMf} key={`obj-${index}`}>{objective}</StyledLi>;
              }
              return null;
            })}
          </StyledUl>
        </div>
      )}
    </ContentWrapper>
  );
};

GeneralData.propTypes = {
  ufToView: PropTypes.objectOf(PropTypes.any).isRequired,
};

GeneralData.defaultProps = {};

export default GeneralData;
