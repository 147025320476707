import { connect } from 'react-redux';
import login from '../actions/login';
import Login from '../components/Login';

const mapStateToProps = state => ({
  errorMessage: state.auth.errorMessage || null,
});

const mapDispatchToProps = dispatch => ({
  onSubmit: (email, password) => {
    dispatch(login({ email, password }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Login);
