/* eslint no-unused-expressions: 0 */

import { injectGlobal } from 'styled-components';
import theme from './theme';

export default function () {
  injectGlobal`
    ${''/*
      Resetting box-sizing to border-box
    */}
    html {
      box-sizing: border-box;
      background-color: white;
    }

    ${''/*
      Make all elements inherit from the parent box-sizing
      This make it easier to change the box-sizing in plugins or other components
      that leverage other behavior
      See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
    */}
    *,
    *::before,
    *::after {
      box-sizing: inherit;
    }

    body {
      margin: 0;
      font-family: ${theme.font.montserrat};
    }

    img {
      width: 100%;
      height: auto;
      vertical-align: middle;
      border-style: none;
    }
    @media print
    {
        .no-print, .no-print *
        {
            display: none !important;
        }
    }
  `;
}
