import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Notification from 'client/ui/Notification';
import Label from 'client/ui/Label';
import Select from 'client/ui/Select';
import Button from 'client/ui/Button';
import Modal from 'client/ui/Modal';
import ButtonGroup from 'client/ui/ButtonGroup';
import ContentWrapper from 'client/ui/ContentWrapper';
import { H1, H2 } from 'client/ui/Headings';
import PermissionGrid from './PermissionGrid';

const PermissionsWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  margin-bottom: 1rem;
`;

const Loading = styled.div`
  padding: 1rem;

`;

const PermissionsContainer = ContentWrapper.extend`
  /* Ocultamos la X del select*/
  .Select-clear-zone {
    display: none !important;
  }
`;

const SectionTitle = styled.div`
  text-align: center;
  background-color: #CFD8DC;
  color: ${props => props.theme.color.black};
  padding: .5rem;
  text-transform: uppercase;
  h2 {
    margin-bottom: 0;
  }
`;

class Permissions extends Component {
  constructor() {
    super();
    this.state = {
      selectedRole: '',
      currentRolePermissions: [],
      modalIsOpen: false,
    };
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleModalAction = this.handleModalAction.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { rolePermission } = nextProps;
    const { selectedRole } = this.state;
    if (selectedRole && rolePermission.length > 0) {
      this.setState({ currentRolePermissions: rolePermission });
    }
  }

  handleSelectChange(name, value) {
    const { loadRolePermitions } = this.props;
    loadRolePermitions(value.value);
    this.setState({ [name]: value });
  }

  handleCheckboxChange(module, event) {
    const { currentRolePermissions } = this.state;
    const index = currentRolePermissions.findIndex(r => r.module.label === module);

    if (index > -1) {
      const { name } = event.target;
      const value = event.target.checked;
      const role = currentRolePermissions[index];
      role.actions[name] = value;
      currentRolePermissions[index] = role;
      this.setState({ currentRolePermissions });
    }
  }

  showModal() {
    this.setState({
      modalIsOpen: true,
    });
  }

  hideModal() {
    this.setState({ modalIsOpen: false });
  }

  handleModalAction() {
    const { history } = this.props;
    history.replace('/dashboard');
    this.hideModal();
  }

  handleSubmit(event) {
    event.preventDefault();
    const { currentRolePermissions, selectedRole } = this.state;
    const { saveRolePermitions } = this.props;
    const permitions = [];
    currentRolePermissions.forEach((rol) => {
      const newActions = [];
      Object.keys(rol.actions).forEach((action) => {
        if (rol.actions[action]) {
          newActions.push(action);
        }
      });
      permitions.push({ module: rol.module.id, actions: newActions });
    });

    const filteredPermitions = [];
    permitions.forEach((permission) => {
      if (permission.actions.length > 0) {
        filteredPermitions.push(permission);
      }
    });
    saveRolePermitions({ id: selectedRole.value, permissions: filteredPermitions });
  }

  render() {
    const { selectedRole, currentRolePermissions, modalIsOpen } = this.state;
    const { roles, notification, clearMessages } = this.props;
    return (
      <div>
        <SectionTitle><H2>Panel de administración Atlas</H2></SectionTitle>
        <PermissionsContainer>
          <Notification
            message={notification}
            afterNotification={clearMessages}
          />
          <H1>Permisos</H1>
          <Label>
            <span>Permisos del rol</span>
            <Select
              name="selectedRole"
              options={roles}
              value={selectedRole}
              onChange={(value) => { this.handleSelectChange('selectedRole', value); }}
              placeholder="Selecciona un rol"
              noResultsText="No se ha encontrado coincidencias"
              valueKey="id"
              labelKey="text"
              autoFocus
              small
            />
          </Label>
          {selectedRole
            && (
              <div>
                {currentRolePermissions.length > 0
                  ? (
                    <div>
                      <form onSubmit={this.handleSubmit}>
                        <p><strong>Tabla de permisos del rol</strong></p>
                        <PermissionsWrapper>
                          {currentRolePermissions.map(role => (
                            <PermissionGrid
                              key={role.module.label}
                              name={role.module.label}
                              actions={role.actions}
                              onChange={this.handleCheckboxChange}
                            />
                          ))}
                        </PermissionsWrapper>
                        <ButtonGroup align="right">
                          <Button
                            background="#6F7C7C"
                            onClick={() => this.showModal()}
                          >
                            Cancelar
                          </Button>
                          <Button type="submit">Guardar</Button>
                        </ButtonGroup>
                      </form>
                    </div>
                  )
                  : <Loading><H2>Cargando los permisos...</H2></Loading>
                }
              </div>
            )
          }
          <Modal
            isOpen={modalIsOpen}
            title="Cancelar"
            content="Si pulsa aceptar no se guardarán los cambios, ¿está seguro que desea continuar?"
            onCancel={this.hideModal}
            onConfirm={this.handleModalAction}
          />
        </PermissionsContainer>
      </div>
    );
  }
}

Permissions.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.object).isRequired,
  notification: PropTypes.objectOf(PropTypes.any).isRequired,
  clearMessages: PropTypes.func.isRequired,
  rolePermission: PropTypes.arrayOf(PropTypes.object).isRequired,
  loadRolePermitions: PropTypes.func.isRequired,
  saveRolePermitions: PropTypes.func.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

Permissions.defaultProps = {};

export default Permissions;
