/* eslint no-unused-expressions: 0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import theme from 'client/config/theme';
import { fetchUfIndex } from 'client/modules/dashboard-ufs/actions/ufs';
import { fetchUaTopics } from 'client/modules/dashboard-topics/actions/topics';
import MfMindmap from 'client/modules/view-mf/components/MfMindmap';

async function loadTopics(id) {
  const resp = { ua: id };
  const data = await fetchUaTopics(id).then((topics) => {
    const parsedData = topics.map(topic => ({ _id: topic._id, title: topic.title, ua: topic.ua }));
    return parsedData;
  });
  resp.topics = data;
  return resp;
}

class WrapperContainer extends Component {
  constructor() {
    super();
    this.state = {
      treeData: {},
    };
    this.loadData = this.loadData.bind(this);
  }

  componentWillMount() {
    const { ufId } = this.props;
    this.loadData(ufId);
  }

  loadData() {
    const { ufId, colorUf, colorUa } = this.props;
    const colorContent = theme.color.greyContent;
    fetchUfIndex(ufId).then((ufData) => {
      const treeData = { name: ufData.title, color: colorUf };
      const { uas } = ufData;
      Promise.all(uas.map(ua => loadTopics(ua._id)))
        .then((respuesta) => {
          const parsedUas = [];
          uas.forEach((ua) => {
            const children = {};
            children.name = ua.title;
            children.color = colorUa;
            children.children = [];
            const filterTopic = respuesta.filter(topic => topic.ua === ua._id);
            if (filterTopic.length > 0) {
              filterTopic.forEach((topic) => {
                const parsedTitles = [];
                topic.topics.forEach((currentTopic) => {
                  parsedTitles.push(currentTopic.title);
                });
                const parseObject = {
                  name: '',
                  items: parsedTitles,
                  color: colorContent,
                };
                children.children.push(parseObject);
              });
            }
            parsedUas.push(children);
          });
          Object.assign(treeData, { children: parsedUas });
          this.setState({ treeData });
        });
    });
  }

  render() {
    const { treeData } = this.state;
    return (
      <MfMindmap
        treeData={treeData}
      />
    );
  }
}

WrapperContainer.propTypes = {
  ufId: PropTypes.string.isRequired,
  colorUf: PropTypes.string.isRequired,
  colorUa: PropTypes.string.isRequired,
};

WrapperContainer.defaultProps = {};

export default WrapperContainer;
