import SET_PERMITIONS from '../constants/actionTypes';

const initialState = {
  userPermissions: [],
};

export default function roles(state = initialState, action) {
  switch (action.type) {
    case SET_PERMITIONS:
      return {
        ...state,
        userPermissions: action.permissions,
      };
    default:
      return state;
  }
}
