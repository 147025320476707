/* eslint no-restricted-globals: 0, react/destructuring-assignment: 0,
react/no-access-state-in-setstate: 0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { redirectTo } from 'index';
import RandomId from 'short-unique-id';
import theme from 'client/config/theme';
import Divider from 'client/ui/Divider';
import Alert from 'react-s-alert';
import Button from 'client/ui/Button';
import ButtonGroup from 'client/ui/ButtonGroup';
import IconFa from 'client/ui/IconFa';
import { Tabs, TabsPane, Tab } from 'client/ui/Tabs';
import Circle from 'client/ui/Circle';
import ModalConfirm from 'client/ui/Modal';
import SectionCircle from 'client/ui/SectionCircle';
import { isValidUrl } from 'client/modules/core/utils/utils';
import { H2 } from 'client/ui/Headings';
import ContentWrapper from 'client/ui/ContentWrapper';
import CodeName from 'client/ui/CodeName';
import { arrayMove } from 'react-sortable-hoc';
import ContentVideo from './ContentVideo';
import ContentAudio from './ContentAudio';
import ContentText from './ContentText';
import ContentResource from './ContentResource';
import ContentPicture from './ContentPicture';
import ContentTextPicture from './ContentTextPicture';
import ContentTextWithTitle from './ContentTextWithTitle';
import ContentTitle from './ContentTitle';
import ContentList from './ContentList';
import { Menu } from './Menu';
import ContentWrapperOptions from './ContentWrapperOptions';
import ContentLink from './ContentLink';
import ContentCollapse from './ContentCollapse';
import ContentTabs from './ContentTabs';
import ContentButtonPopup from './ContentButtonPopup';
import ContentTextPopup from './ContentTextPopup';
import ContentImagePopup from './ContentImagePopup';
import ContentGallery from './ContentGallery';
import ContentSimpleQuestion from './ContentSimpleQuestion';
import ContentMultipleQuestion from './ContentMultipleQuestion';
import ClozeSelect from './ClozeSelect';
import Cloze from './Cloze';
import MixedQuestions from './MixedQuestions';

const notificationConfig = { position: 'top-right', effect: 'slide', timeout: 3000 };

const CircleText = styled.div`
  font-size: 21px;
  font-weight: bold;
  color: white;
  position: absolute;
  top: 10px;
  right: 14px;
`;

const StyledH2 = styled(H2)`
  color: ${props => props.theme.color.white};
`;

const Header = styled.div`
  margin-bottom: .5rem;
`;

const SectionTitle = styled.div`
  text-align: center;
  background-color: ${props => props.theme.color.greyContent};
  color: white;
  padding: .5rem;
  h2 {
    margin-bottom: 0;
  }
`;

const Section = ContentWrapper.extend`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;
  justify-content: space-between;
`;

const StyledSeparation = styled.div`
  width: 100%;
  min-width: 0;
  margin-right: 1rem;
`;

const MfName = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: .25rem;
`;

const DividerWrapper = styled.div`
  position: relative;
`;

const MfsDivider = Divider.extend`
  position: absolute;
  top: 0;
  left: 0;
`;

const UfsDivider = MfsDivider.extend`
  left: 25%;
`;

const UasDivider = MfsDivider.extend`
  left: 50%;
  &.mf {
    left: 33.3333%;
  }
`;

const ContentDivider = MfsDivider.extend`
  left: 75%;
  &.mf {
    left: 66.6666%;
  }
`;

const NoContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AddButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;

  hr {
    position: absolute;
    left: 0;
    top: 26px;
    border: 0;
    border-top: dotted 1px;
  }
`;

const CircleButton = styled(Circle)`
  position: relative;
  z-index: 5;
  cursor: pointer;
  i {
    font-size: 2rem;
    color:white;
    position: absolute;
    top: 10px;
    left: 11px;
  }
  &:hover {
    background-color: #374850;
  }
`;

const InfoText = styled.div`
  margin-bottom: 1rem;
  text-align: center;
`;

const StyledReturn = styled.span`
  margin-right: .7rem;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
`;

const PrevFa = styled(IconFa)`
  margin-right: .5rem;
  ${props => props.color && css`
    color: ${props.theme.color[props.color]};
  `}
  ${props => props.doubleMargin && css`
    margin-right: .8rem;
  `}
`;

const StyledButtonGroup = styled(ButtonGroup)`
  margin: 2rem 0;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 0;
`;

const ButtonsWrapper = styled.div`
  min-width: 245px;
  width: 245px;
`;

const PrevLink = data => (
  <StyledReturn onClick={() => redirectTo(`/dashboard/uas/edit/${data.uaId}`)}>
    <PrevFa name="chevron-left" doubleMargin />
    <PrevFa name="circle" color="greenUa" />
    <span>UA</span>
  </StyledReturn>
);

const CloseButton = styled(Button)`
  margin-right: 0.5rem;
`;

class Wrapper extends Component {
  constructor() {
    super();
    this.state = {
      menuVisible: false,
      contents: [],
      forceClozeChange: false,
    };
    this.handleSaveAllContents = this.handleSaveAllContents.bind(this);
    this.handleMediumEditorChange = this.handleMediumEditorChange.bind(this);
    this.handlePositionChange = this.handlePositionChange.bind(this);
    this.handleImagePopupPosition = this.handleImagePopupPosition.bind(this);
    this.handlePictureChange = this.handlePictureChange.bind(this);
    this.handlePictureTextChange = this.handlePictureTextChange.bind(this);
    this.handleTextTitleChange = this.handleTextTitleChange.bind(this);
    this.handleExternLinkChange = this.handleExternLinkChange.bind(this);
    this.handleCollapseTitleChange = this.handleCollapseTitleChange.bind(this);
    this.handleCollapseTextChange = this.handleCollapseTextChange.bind(this);
    this.handleItemRemove = this.handleItemRemove.bind(this);
    this.handleLiChange = this.handleLiChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.handleDisplayMenu = this.handleDisplayMenu.bind(this);
    this.handleVideoChange = this.handleVideoChange.bind(this);
    this.wrapperContents = this.wrapperContents.bind(this);
    this.changeToPreview = this.changeToPreview.bind(this);
    this.renderContent = this.renderContent.bind(this);
    this.renderAddButton = this.renderAddButton.bind(this);
    this.handleLiRemove = this.handleLiRemove.bind(this);
    this.addContent = this.addContent.bind(this);
    this.addLi = this.addLi.bind(this);
    this.addItem = this.addItem.bind(this);
    this.onClose = this.onClose.bind(this);
    this.handleTabsTitleChange = this.handleTabsTitleChange.bind(this);
    this.handleTabsTextChange = this.handleTabsTextChange.bind(this);
    this.addGalleryItem = this.addGalleryItem.bind(this);
    this.handleGalleryTextChange = this.handleGalleryTextChange.bind(this);
    this.addAnswer = this.addAnswer.bind(this);
    this.onQuestionChange = this.onQuestionChange.bind(this);
    this.onClozeQuestionChange = this.onClozeQuestionChange.bind(this);
    this.onQuestionTextChange = this.onQuestionTextChange.bind(this);
    this.optionCheckChange = this.optionCheckChange.bind(this);
    this.optionTextChange = this.optionTextChange.bind(this);
    this.addSimpleQuestion = this.addSimpleQuestion.bind(this);
    this.addMultipleQuestion = this.addMultipleQuestion.bind(this);
    this.addCloze = this.addCloze.bind(this);
    this.addClozeSelect = this.addClozeSelect.bind(this);
    this.removeAnswer = this.removeAnswer.bind(this);
    this.optionMultipleCheckChange = this.optionMultipleCheckChange.bind(this);
    this.sortQuestionOption = this.sortQuestionOption.bind(this);
    this.addQuestionToMixedContent = this.addQuestionToMixedContent.bind(this);
    this.handlePopupButtonTextChange = this.handlePopupButtonTextChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { contents } = nextProps;
    this.setState({ contents });
  }

  onClose() {
    const { uaId } = this.props;
    this.handleSaveAllContents(`/dashboard/uas/edit/${uaId}`);
  }

  onQuestionChange(contentId, questionId, event) {
    const { contents } = this.state;
    const { value } = event.target;
    const index = contents.findIndex(content => content.id === contentId);
    if (index > -1) {
      const currentQuestionIndex = contents[index].items
        .findIndex(question => question.id === questionId);
      if (currentQuestionIndex > -1) {
        contents[index].items[currentQuestionIndex].question = value;
        this.setState({ contents });
      }
    }
  }

  onClozeQuestionChange(contentId, questionId, value) {
    const { contents } = this.state;
    let forceClozeChange = false;
    const index = contents.findIndex(content => content.id === contentId);
    if (index > -1) {
      const currentQuestionIndex = contents[index].items
        .findIndex(question => question.id === questionId);
      if (currentQuestionIndex > -1) {
        const currentQuestion = contents[index].items[currentQuestionIndex];
        const count = (value.match(/<b>/g) || []).length;
        let parseString = value;
        let correctAnswer = '';

        let correctAnswerIndex = value.indexOf('<b>') + 3;
        let correctAnswerLastIndex = value.indexOf('</b>');

        if (correctAnswerIndex > 2) {
          correctAnswer = value.substring(correctAnswerIndex, correctAnswerLastIndex);
        }

        if (count > 1) {
          const firstIndex = value.indexOf('<b>');
          const currentIndex = currentQuestion.question.indexOf('<b>');
          forceClozeChange = true;
          if (currentIndex > firstIndex) {
            // Si currentIndex es mayor la respuesta correcta sigue siendo la primera
            const inicio = value.lastIndexOf('<b>');
            const fin = value.lastIndexOf('</b>');
            correctAnswer = value.substring(correctAnswerIndex, correctAnswerLastIndex);
            parseString = value.substring(0, inicio) + value.substring(inicio + 3, fin)
              + value.substring(fin + 4, value.length);
          } else {
            const inicio = value.indexOf('<b>');
            const fin = value.indexOf('</b>');
            correctAnswerIndex = value.lastIndexOf('<b>') + 3;
            correctAnswerLastIndex = value.lastIndexOf('</b>');
            correctAnswer = value.substring(correctAnswerIndex, correctAnswerLastIndex);
            parseString = value.substring(0, inicio) + value.substring(inicio + 3, fin)
              + value.substring(fin + 4, value.length);
          }
        }

        correctAnswer = correctAnswer.trim();
        contents[index].items[currentQuestionIndex].question = parseString;
        if (currentQuestion.type === 'clozeSelect') {
          const defaultIndex = currentQuestion.options.findIndex(op => op.default === true);
          contents[index].items[currentQuestionIndex].options[defaultIndex].text = correctAnswer;
        } else {
          contents[index].items[currentQuestionIndex].options[0].text = correctAnswer;
        }
        this.setState({ contents, forceClozeChange });
      }
    }
  }

  onQuestionTextChange(contentId, questionId, event) {
    const { contents } = this.state;
    const { value } = event.target;
    const index = contents.findIndex(content => content.id === contentId);
    if (index > -1) {
      const currentQuestionIndex = contents[index].items
        .findIndex(question => question.id === questionId);
      if (currentQuestionIndex > -1) {
        const emptyValue = '<p><br></p>';
        contents[index].items[currentQuestionIndex].question = value !== emptyValue ? value.trim() : '';
        this.setState({ contents });
      }
    }
  }

  optionCheckChange(contentId, questionId, optionId) {
    const { contents } = this.state;
    const index = contents.findIndex(content => content.id === contentId);
    if (index > -1) {
      const contentIndex = contents[index].items.findIndex(content => content.id === questionId);
      if (contentIndex > -1) {
        const currentIndex = contents[index].items[contentIndex].options
          .findIndex(opt => opt.id === optionId);
        if (currentIndex > -1) {
          contents[index].items[contentIndex].options.forEach((option, curr) => {
            contents[index].items[contentIndex].options[curr].correctAnswer = false;
          });
          contents[index].items[contentIndex].options[currentIndex].correctAnswer = true;
          this.setState({ contents });
        }
      }
    }
  }

  sortQuestionOption(contentId, questionId, oldIndex, newIndex) {
    const { contents } = this.state;
    const index = contents.findIndex(content => content.id === contentId);
    if (index > -1) {
      const contentIndex = contents[index].items.findIndex(content => content.id === questionId);
      if (contentIndex > -1) {
        const currentOrder = contents[index].items[contentIndex].options;
        const newOrder = arrayMove(currentOrder, oldIndex, newIndex);
        contents[index].items[contentIndex].options = newOrder;
        this.setState({ contents });
      }
    }
  }

  optionMultipleCheckChange(contentId, questionId, optionId) {
    const { contents } = this.state;
    const index = contents.findIndex(content => content.id === contentId);
    if (index > -1) {
      const contentIndex = contents[index].items.findIndex(content => content.id === questionId);
      if (contentIndex > -1) {
        const currentIndex = contents[index].items[contentIndex].options
          .findIndex(opt => opt.id === optionId);
        if (currentIndex > -1) {
          const checked = contents[index].items[contentIndex].options[currentIndex].correctAnswer;
          contents[index].items[contentIndex].options[currentIndex].correctAnswer = !checked;
          this.setState({ contents });
        }
      }
    }
  }

  optionTextChange(contentId, questionId, optionId, event) {
    const { contents } = this.state;
    const { value } = event.target;
    const index = contents.findIndex(content => content.id === contentId);
    if (index > -1) {
      const contentIndex = contents[index].items.findIndex(content => content.id === questionId);
      if (contentIndex > -1) {
        const currentIndex = contents[index].items[contentIndex].options
          .findIndex(opt => opt.id === optionId);
        if (currentIndex > -1) {
          contents[index].items[contentIndex].options[currentIndex].text = value;
          this.setState({ contents });
        }
      }
    }
  }

  changeToPreview() {
    const { match } = this.props;
    const { id } = match.params;
    this.handleSaveAllContents(`/viewContent/${id}`);
  }

  handleCollapseTitleChange(id, index, event) {
    const { contents } = this.state;
    const { target } = event;
    const { value } = target;
    const position = contents.findIndex(content => content.id === id);
    if (position > -1) {
      contents[position].items[index].title = value;
      this.setState({ contents });
    }
  }

  handlePopupButtonTextChange(id, index, event) {
    const { contents } = this.state;
    const { target } = event;
    const { value } = target;
    const position = contents.findIndex(content => content.id === id);
    if (position > -1) {
      contents[position].items[index].buttonText = value;
      this.setState({ contents });
    }
  }

  handleTabsTitleChange(id, index, event) {
    const { contents } = this.state;
    const { target } = event;
    const { value } = target;
    const position = contents.findIndex(content => content.id === id);
    if (position > -1) {
      contents[position].items[index].title = value;
      this.setState({ contents });
    }
  }

  handleCollapseTextChange(id, index, value) {
    const { contents } = this.state;
    const position = contents.findIndex(content => content.id === id);
    if (position > -1) {
      const emptyValue = '<p><br></p>';
      contents[position].items[index].text = value !== emptyValue ? value.trim() : '';
      this.setState({ contents });
    }
  }

  handleTabsTextChange(id, index, value) {
    const { contents } = this.state;
    const position = contents.findIndex(content => content.id === id);
    if (position > -1) {
      const emptyValue = '<p><br></p>';
      contents[position].items[index].text = value !== emptyValue ? value.trim() : '';
      this.setState({ contents });
    }
  }

  handleItemRemove(id, index) {
    const { contents } = this.state;
    const position = contents.findIndex(content => content.id === id);
    if (position > -1) {
      const current = contents[position].items;
      current.splice(index, 1);
      contents[position].items = current;
      this.setState({ contents });
    }
  }

  removeAnswer(contentId, questionId, optionId) {
    const { contents } = this.state;
    const index = contents.findIndex(content => content.id === contentId);
    if (index > -1) {
      const currentQuestionIndex = contents[index].items
        .findIndex(question => question.id === questionId);
      if (currentQuestionIndex > -1) {
        const currentIndex = contents[index].items[currentQuestionIndex].options
          .findIndex(opt => opt.id === optionId);
        if (currentIndex > -1) {
          const { options } = contents[index].items[currentQuestionIndex];
          options.splice(currentIndex, 1);
          this.setState({ contents });
        }
      }
    }
  }

  handleExternLinkChange(id, event) {
    const { contents } = this.state;
    const { target } = event;
    const { value } = target;
    const index = contents.findIndex(content => content.id === id);
    if (index > -1) {
      contents[index].url = value;
      this.setState({ contents });
    }
  }

  handleInputChange(event) {
    const { target } = event;
    const { name, value } = target;
    this.setState({ [name]: value });
  }

  handlePictureTextChange(id, event) {
    const { contents } = this.state;
    const { target } = event;
    const { value } = target;
    const index = contents.findIndex(content => content.id === id);
    if (index > -1) {
      contents[index].props.text = value;
      this.setState({ contents });
    }
  }

  handleTextTitleChange(id, event) {
    const { contents } = this.state;
    const { target } = event;
    const { value } = target;
    const index = contents.findIndex(content => content.id === id);
    if (index > -1) {
      contents[index].title = value;
      this.setState({ contents });
    }
  }

  handleTitleChange(id, event) {
    const { contents } = this.state;
    const { target } = event;
    const { value } = target;
    const index = contents.findIndex(content => content.id === id);
    if (index > -1) {
      contents[index].title = value;
      this.setState({ contents });
    }
  }

  handleVideoChange(id, value) {
    const { contents } = this.state;
    const index = contents.findIndex(content => content.id === id);
    if (index > -1) {
      contents[index].url = value;
      this.setState({ contents });
    }
  }

  handleLiChange(id, value, index) {
    const { contents } = this.state;
    const position = contents.findIndex(content => content.id === id);
    if (position > -1) {
      const emptyValue = '<p><br></p>';
      contents[position].list[index] = value !== emptyValue ? value.trim() : '';
      this.setState({ contents });
    }
  }

  handleLiRemove(id, index) {
    const { contents } = this.state;
    const position = contents.findIndex(content => content.id === id);
    if (position > -1) {
      const current = contents[position].list;
      current.splice(index, 1);
      contents[position].list = current;
      this.setState({ contents });
    }
  }

  handleMediumEditorChange(id, value) {
    const { contents } = this.state;
    const index = contents.findIndex(content => content.id === id);
    if (index > -1) {
      const emptyValue = '<p><br></p>';
      contents[index].data = value !== emptyValue ? value.trim() : '';
      this.setState({ contents });
    }
  }

  handlePositionChange(id, value) {
    const { contents } = this.state;
    const index = contents.findIndex(content => content.id === id);
    if (index > -1) {
      contents[index].props.position = value;
      this.setState({ contents });
    }
  }

  handleImagePopupPosition (id, item, value) {
    const { contents } = this.state;
    const index = contents.findIndex(content => content.id === id);
    if (index > -1) {
      contents[index].items[item].positionImage = value;
      console.log(contents[index], item)
      this.setState({ contents });
    }
  }

  addLi(id) {
    const { contents } = this.state;
    const index = contents.findIndex(content => content.id === id);
    if (index > -1) {
      contents[index].list.push('');
      this.setState({ contents });
    }
  }

  addGalleryItem(id) {
    const { contents } = this.state;
    const index = contents.findIndex(content => content.id === id);
    if (index > -1) {
      contents[index].items.push({ text: '' });
      this.setState({ contents });
    }
  }

  handleGalleryTextChange(id, index, value) {
    const { contents } = this.state;
    const position = contents.findIndex(content => content.id === id);
    if (position > -1) {
      const emptyValue = '<p><br></p>';
      contents[position].items[index].text = value !== emptyValue ? value.trim() : '';
      this.setState({ contents });
    }
  }

  addItem(id) {
    const { contents } = this.state;
    const index = contents.findIndex(content => content.id === id);
    if (index > -1) {
      var item = contents[index].items.length + 1
      contents[index].items.push({ buttonText: 'Botón Popup ' + item, title: 'Título', text: '', positionImage: 'up' });
      this.setState({ contents });
    }
  }

  addSimpleQuestion(contentId) {
    const { contents } = this.state;
    const uid = new RandomId();
    const index = contents.findIndex(content => content.id === contentId);
    if (index > -1) {
      contents[index].items.push({ id: uid.randomUUID(12),
        question: '',
        type: 'simple',
        options: [{ id: uid.randomUUID(12), text: '', correctAnswer: false },
          { id: uid.randomUUID(12), text: '', correctAnswer: false }] });
      this.setState({ contents });
    }
  }

  addMultipleQuestion(contentId) {
    const { contents } = this.state;
    const uid = new RandomId();
    const index = contents.findIndex(content => content.id === contentId);
    if (index > -1) {
      contents[index].items.push({ id: uid.randomUUID(12),
        question: '',
        type: 'multiple',
        options: [{ id: uid.randomUUID(12), text: '', correctAnswer: false },
          { id: uid.randomUUID(12), text: '', correctAnswer: false }] });
      this.setState({ contents });
    }
  }

  addClozeSelect(contentId) {
    const { contents } = this.state;
    const uid = new RandomId();
    const index = contents.findIndex(content => content.id === contentId);
    if (index > -1) {
      contents[index].items.push({ id: uid.randomUUID(12),
        question: '',
        type: 'clozeSelect',
        options: [{ id: uid.randomUUID(12), text: '', correctAnswer: true, default: true },
          { id: uid.randomUUID(12), text: '', correctAnswer: false }] });
      this.setState({ contents });
    }
  }

  addCloze(contentId) {
    const { contents } = this.state;
    const uid = new RandomId();
    const index = contents.findIndex(content => content.id === contentId);
    if (index > -1) {
      contents[index].items.push({ id: uid.randomUUID(12),
        question: '',
        type: 'cloze',
        options: [{ id: uid.randomUUID(12), text: '' },
          { id: uid.randomUUID(12), text: '' }] });
      this.setState({ contents });
    }
  }

  addAnswer(contentId, questionId) {
    const { contents } = this.state;
    const uid = new RandomId();
    const index = contents.findIndex(content => content.id === contentId);
    if (index > -1) {
      const currentQuestionIndex = contents[index].items
        .findIndex(question => question.id === questionId);
      if (currentQuestionIndex > -1) {
        contents[index].items[currentQuestionIndex].options.push({ id: uid.randomUUID(12), text: '', correctAnswer: false });
        this.setState({ contents });
      }
    }
  }

  addQuestionToMixedContent(contentId, type) {
    const { contents } = this.state;
    const index = contents.findIndex(content => content.id === contentId);
    const question = '';
    if (index > -1) {
      const uid = new RandomId();
      if (type === 'simple' || type === 'multiple') {
        contents[index].items.push({ id: uid.randomUUID(12),
          question,
          type,
          options: [{ id: uid.randomUUID(12), text: '', correctAnswer: false },
            { id: uid.randomUUID(12), text: '', correctAnswer: false }] });
      } else if (type === 'cloze') {
        contents[index].items.push({ id: uid.randomUUID(12),
          question,
          type,
          options: [{ id: uid.randomUUID(12), text: '' },
            { id: uid.randomUUID(12), text: '' }] });
      } else if (type === 'clozeSelect') {
        contents[index].items.push({ id: uid.randomUUID(12),
          question,
          type,
          options: [{ id: uid.randomUUID(12), text: '', correctAnswer: true, default: true },
            { id: uid.randomUUID(12), text: '', correctAnswer: false }] });
      }
      this.setState({ contents });
    }
  }

  handleSaveAllContents(callback) {
    const { save, history, reorderPageContents, pageId } = this.props;
    const { contents } = this.state;

    Promise.all(contents.map((content) => {
      const parsedContent = {
        id: content.id,
        data: content.data,
        page: content.page,
        props: content.props,
        type: content.type,
      };

      if ((content.type === 'textPicture' || content.type === 'picture' || content.type === 'image-popup')
      && content.picture && content.picture.pictureId) {
        Object.assign(parsedContent, { picture: content.picture.pictureId });
      }

      if ((content.type === 'title' || content.type === 'textTitle')
      && content.title) {
        Object.assign(parsedContent, { title: content.title });
      }

      if (content.type === 'ul' || content.type === 'ol') {
        Object.assign(parsedContent, { list: content.list });
      }

      if (content.type === 'link') {
        if (isValidUrl(content.url)) {
          Object.assign(parsedContent, { url: content.url });
        } else {
          let parsedUrl = content.url;
          if (parsedUrl.indexOf('http') < 0) {
            parsedUrl = `http://${content.url}`;
          }
          Object.assign(parsedContent, { url: parsedUrl });
        }
      }

      if (content.type === 'collapse' || content.type === 'tabs') {
        const filterItems = content.items.map(item => (
          {
            title: item.title,
            url: item.url,
            text: item.text,
          }
        ));
        Object.assign(parsedContent, { items: filterItems });
      }

      if (content.type === 'button-popup' || content.type === 'text-popup') {
        const filterItems = content.items.map(item => (
          {
            buttonText: item.buttonText,
            title: item.title,
            url: item.url,
            text: item.text,
            positionImage: item.positionImage
          }
        ));
        Object.assign(parsedContent, { items: filterItems });
      }

      if (content.type === 'image-popup') {
        const filterItems = content.items.map(item => (
          {
            buttonText: item.buttonText,
            title: item.title,
            url: item.url,
            text: item.text,
            positionImage: item.positionImage
          }
        ));
        Object.assign(parsedContent, { items: filterItems });
      }

      if (content.type === 'video' || content.type === 'audio' || content.type === 'resource') {
        Object.assign(parsedContent, { url: content.url });
      }

      if (content.type === 'gallery') {
        const filterItems = content.items.map(item => ({
          pictureId: item.pictureId,
          url: item.url,
          text: item.text,
        }));
        Object.assign(parsedContent, { items: filterItems });
      }

      if (content.type === 'simple' || content.type === 'multiple' || content.type === 'clozeSelect'
        || content.type === 'cloze' || content.type === 'mixedQuestions') {
        Object.assign(parsedContent, { items: content.items });
      }

      return save(parsedContent).then(response => response);
    })).then(() => {
      const contentsOrdered = contents.map(content => content.id);
      const newContentOrder = {
        id: pageId,
        contents: contentsOrdered,
      };
      reorderPageContents(newContentOrder).then(() => {
        if (callback) {
          history.push(callback);
        } else {
          Alert.success('Guardado correctamente', notificationConfig);
        }
      });
    });
  }

  addContent(type) {
    this.handleDisplayMenu();
    const { top } = this.state;
    const { pageId, contents, save, reorderPageContents } = this.props;
    const uid = new RandomId();
    const content = {
      page: pageId,
      type,
    };
    switch (type) {
      case 'text':
        Object.assign(content, {
          data: '',
        });
        break;
      case 'picture':
        Object.assign(content, {
          props: { text: '' },
        });
        break;
      case 'textPicture':
        Object.assign(content, {
          data: '',
          props: { position: { value: 'left', label: 'Izquierda' } },
        });
        break;
      case 'textTitle':
        Object.assign(content, {
          data: '',
          title: '',
        });
        break;
      case 'title':
        Object.assign(content, {
          title: '',
        });
        break;
      case 'ul':
      case 'ol':
        Object.assign(content, {
          list: [''],
        });
        break;
      case 'link':
        Object.assign(content, {
          url: '',
          data: '',
        });
        break;
      case 'collapse':
      case 'tabs':
        Object.assign(content, {
          items: [{ title: 'Título 1', text: '' },
            { title: 'Título 2', text: '' }],
        });
        break;
      case 'button-popup':
        Object.assign(content, {
          items: [{ buttonText: 'Saber más', title: 'Título', text: '', positionImage: 'up' }],
        });
        break;
      case 'text-popup':
        Object.assign(content, {
          items: [{ buttonText: 'Saber más', title: 'Título', text: '', positionImage: 'up' }],
        });
        Object.assign(content, {
          data: '',
          props: { position: { value: 'left', label: 'Izquierda' } },
        });
        break;
      case 'image-popup':
        Object.assign(content, {
          items: [{ buttonText: 'Saber más', title: 'Título', text: '', positionImage: 'up' }],
        });
        Object.assign(content, {
          props: { position: { value: 'left', label: 'Izquierda' } },
        });
        break;
      case 'video':
      case 'audio':
      case 'resource':
        Object.assign(content, {
          url: '',
          data: '',
        });
        break;
      case 'gallery':
        Object.assign(content, {
          items: [{ text: '' },
            { text: '' }],
        });
        break;
      case 'simple':
      case 'multiple':
        Object.assign(content, {
          items: [{ id: uid.randomUUID(12),
            question: '',
            type,
            options: [{ id: uid.randomUUID(12), text: '', correctAnswer: false },
              { id: uid.randomUUID(12), text: '', correctAnswer: false }] }],
        });
        break;
      case 'clozeSelect':
        Object.assign(content, {
          items: [{ id: uid.randomUUID(12),
            question: '',
            type,
            options: [{ id: uid.randomUUID(12), text: '', correctAnswer: true, default: true },
              { id: uid.randomUUID(12), text: '', correctAnswer: false }] }],
        });
        break;
      case 'cloze':
        Object.assign(content, {
          items: [{ id: uid.randomUUID(12),
            question: '',
            type,
            options: [{ id: uid.randomUUID(12), text: '' },
              { id: uid.randomUUID(12), text: '' }] }],
        });
        break;
      default:
        break;
    }

    const newContent = save(content);
    console.log(content)
    newContent.then((contentData) => {
      const currents = contents;
      const parsedNewContent = contentData;
      parsedNewContent.id = contentData._id;
      parsedNewContent.page = contentData.page._id;
      delete parsedNewContent.updatedAt;
      delete parsedNewContent.deletedAt;
      delete parsedNewContent.createdAt;
      delete parsedNewContent._id;
      if (top) {
        currents.unshift(contentData);
        const contentsOrdered = currents.map(c => c.id);
        const newContentOrder = {
          id: pageId,
          contents: contentsOrdered,
        };
        reorderPageContents(newContentOrder);
        this.setState({ contents: currents });
      } else {
        currents.push(contentData);
        this.setState({ contents: currents });
        // @See: https://stackoverflow.com/questions/49253988/automatically-scroll-to-rendered-element-after-setstate-in-react-js
        const element = document.getElementById('bottomOfContents');
        element.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    });
  }

  handlePictureChange(id, picture) {
    const { savePicture } = this.props;
    const { contents } = this.state;
    const index = contents.findIndex(content => content.id === id);
    const currents = contents;
    if (index > -1) {
      currents[index].picture = picture;
      this.setState({ contents: currents });
    }

    if (picture) {
      const props = {
        content: id,
        text: currents[index].props.text,
      };
      savePicture(picture, props);
    }
  }

  handleDisplayMenu(top) {
    const { menuVisible } = this.state;
    this.setState({ menuVisible: !menuVisible, top });
  }

  wrapperContents() {
    const { contents, moveContent, removeContent } = this.props;
    return (
      <div>
        {contents.map(content => (
          <ContentWrapperOptions
            key={`Content_Wrapper_${content.id}`}
            id={content.id}
            remove={removeContent}
            moveContent={moveContent}
            // @TODO: Pasar el tipo y ponerlo como absoluto en el caso de los botones
          >
            {this.renderContent(content)}
          </ContentWrapperOptions>
        ))}
        <AddButtonWrapper>
          <CircleButton size="50px" background="addContentColor" onClick={() => this.handleDisplayMenu(false)}>
            <IconFa name="plus" color="white" />
          </CircleButton>
          <Divider borderWidth="1px" borderColor="gray2" />
        </AddButtonWrapper>
      </div>
    );
  }

  renderContent(content) {
    const { positions, removeItem, moveItem, uploadContentFile, saveItemsPicture,
      saveQuestionPicture } = this.props;
    const { forceClozeChange } = this.state;
    switch (content.type) {
      case 'text':
        return (
          <ContentText
            key={`Content_${content.id}`}
            data={content.data}
            id={content.id}
            onChange={this.handleMediumEditorChange}
          />);
      case 'picture':
        return (
          <ContentPicture
            key={`Content_${content.id}`}
            id={content.id}
            props={content.props}
            changePicture={this.handlePictureChange}
            changePictureText={this.handlePictureTextChange}
            picture={content.picture}
            required="required"
          />
        );
      case 'textPicture':
        return (
          <ContentTextPicture
            key={`Content_${content.id}`}
            id={content.id}
            data={content.data}
            props={content.props}
            changePicture={this.handlePictureChange}
            changePosition={this.handlePositionChange}
            changePictureText={this.handlePictureTextChange}
            onChange={this.handleMediumEditorChange}
            positions={positions}
            picture={content.picture}
          />
        );
      case 'textTitle':
        return (
          <ContentTextWithTitle
            key={`Content_${content.id}`}
            id={content.id}
            data={content.data}
            title={content.title}
            onChange={this.handleMediumEditorChange}
            titleChange={this.handleTextTitleChange}
          />
        );
      case 'title':
        return (
          <ContentTitle
            key={`Content_${content.id}`}
            id={content.id}
            title={content.title}
            titleChange={this.handleTitleChange}
          />
        );
      case 'ul':
        return (
          <ContentList
            key={`Content_${content.id}`}
            id={content.id}
            list={content.list}
            onLiChange={this.handleLiChange}
            addLi={this.addLi}
            type="ul"
            handleLiRemove={this.handleLiRemove}
          />
        );
      case 'ol':
        return (
          <ContentList
            key={`Content_${content.id}`}
            id={content.id}
            list={content.list}
            onLiChange={this.handleLiChange}
            addLi={this.addLi}
            type="ol"
            handleLiRemove={this.handleLiRemove}
          />
        );
      case 'link':
        return (
          <ContentLink
            key={`Content_${content.id}`}
            id={content.id}
            data={content.data}
            url={content.url}
            onChange={this.handleMediumEditorChange}
            changeUrl={this.handleExternLinkChange}
          />
        );
      case 'collapse':
        return (
          <ContentCollapse
            key={`Content_${content.id}`}
            id={content.id}
            items={content.items}
            savePicture={saveItemsPicture}
            onTitleChange={this.handleCollapseTitleChange}
            onTextChange={this.handleCollapseTextChange}
            addItem={this.addItem}
            removeCollapseItem={removeItem}
            moveCollapseItem={moveItem}
          />
        );
      case 'tabs':
        return (
          <ContentTabs
            key={`Content_${content.id}`}
            id={content.id}
            items={content.items}
            savePicture={saveItemsPicture}
            onTitleChange={this.handleTabsTitleChange}
            onTextChange={this.handleTabsTextChange}
            addItem={this.addItem}
            removeTabsItem={removeItem}
            moveTabsItem={moveItem}
          />
        );
      case 'video':
        return (
          <ContentVideo
            key={`Content_${content.id}`}
            id={content.id}
            data={content.data}
            url={content.url}
            transcriptionChange={this.handleMediumEditorChange}
            onChange={this.handleVideoChange}
          />
        );
      case 'audio':
        return (
          <ContentAudio
            key={`Content_${content.id}`}
            id={content.id}
            data={content.data}
            url={content.url}
            uploadAudio={uploadContentFile}
            transcriptionChange={this.handleMediumEditorChange}
          />
        );
      case 'resource':
        return (
          <ContentResource
            key={`Content_${content.id}`}
            id={content.id}
            data={content.data}
            properties={content.props}
            url={content.url}
            uploadFile={uploadContentFile}
            descriptionChange={this.handleMediumEditorChange}
          />
        );
      case 'button-popup':
        return (
          <ContentButtonPopup
            key={`Content_${content.id}`}
            id={content.id}
            items={content.items}
            savePicture={saveItemsPicture}
            onImageMove={this.handleImagePopupPosition}
            onTitleChange={this.handleCollapseTitleChange}
            onTextChange={this.handleCollapseTextChange}
            onButtonChange={this.handlePopupButtonTextChange}
            addItem={this.addItem}
            removePopupItem={removeItem}
            movePopupItem={moveItem}
          />
        );
      case 'text-popup':
        return (
          <ContentTextPopup
            key={`Content_${content.id}`}
            id={content.id}
            items={content.items}
            onImageMove={this.handleImagePopupPosition}
            savePicture={saveItemsPicture}
            onTitleChange={this.handleCollapseTitleChange}
            onTextChange={this.handleCollapseTextChange}
            onButtonChange={this.handlePopupButtonTextChange}
            addItem={this.addItem}
            removePopupItem={removeItem}
            movePopupItem={moveItem}
            data={content.data}
            props={content.props}
            changePosition={this.handlePositionChange}
            onChange={this.handleMediumEditorChange}
            positions={positions}
          />
        );
      case 'image-popup':
        return (
          <ContentImagePopup
            key={`Content_${content.id}`}
            id={content.id}
            items={content.items}
            savePicture={saveItemsPicture}
            onImageMove={this.handleImagePopupPosition}
            onTitleChange={this.handleCollapseTitleChange}
            onTextChange={this.handleCollapseTextChange}
            onButtonChange={this.handlePopupButtonTextChange}
            addItem={this.addItem}
            removePopupItem={removeItem}
            movePopupItem={moveItem}
            props={content.props}
            changePosition={this.handlePositionChange}
            onChange={this.handleMediumEditorChange}
            positions={positions}
            changePicture={this.handlePictureChange}
            changePictureText={this.handlePictureTextChange}
            picture={content.picture}
          />
        );
      case 'gallery':
        return (
          <ContentGallery
            key={`Content_${content.id}`}
            id={content.id}
            items={content.items}
            savePicture={saveItemsPicture}
            onTextChange={this.handleGalleryTextChange}
            addGalleryItem={this.addGalleryItem}
            removeGalleryItem={removeItem}
            moveGalleryItem={moveItem}
          />
        );
      case 'simple':
        return (
          <ContentSimpleQuestion
            key={`Content_${content.id}`}
            contentId={content.id}
            items={content.items}
            addAnswer={this.addAnswer}
            addSimpleQuestion={this.addSimpleQuestion}
            savePicture={saveQuestionPicture}
            onQuestionChange={this.onQuestionChange}
            optionCheckChange={this.optionCheckChange}
            optionTextChange={this.optionTextChange}
            onButtonChange={this.handlePopupButtonTextChange}
            removeAnswer={this.removeAnswer}
            removeQuestionItem={removeItem}
            sortQuestionOption={this.sortQuestionOption}
            moveQuestionItem={moveItem}
          />
        );
      case 'multiple':
        return (
          <ContentMultipleQuestion
            key={`Content_${content.id}`}
            contentId={content.id}
            items={content.items}
            addAnswer={this.addAnswer}
            addMultipleQuestion={this.addMultipleQuestion}
            savePicture={saveQuestionPicture}
            onQuestionChange={this.onQuestionChange}
            optionMultipleCheckChange={this.optionMultipleCheckChange}
            optionTextChange={this.optionTextChange}
            removeAnswer={this.removeAnswer}
            removeQuestionItem={removeItem}
            sortQuestionOption={this.sortQuestionOption}
            moveQuestionItem={moveItem}
          />
        );
      case 'clozeSelect':
        return (
          <ClozeSelect
            key={`Content_${content.id}`}
            contentId={content.id}
            items={content.items}
            addAnswer={this.addAnswer}
            addClozeSelect={this.addClozeSelect}
            onClozeQuestionChange={this.onClozeQuestionChange}
            setCorrectAnswer={this.setCorrectAnswerClozeSelection}
            optionCheckChange={this.optionCheckChange}
            optionTextChange={this.optionTextChange}
            removeAnswer={this.removeAnswer}
            removeQuestionItem={removeItem}
            sortQuestionOption={this.sortQuestionOption}
            moveQuestionItem={moveItem}
            forceClozeChange={forceClozeChange}
          />
        );
      case 'cloze':
        return (
          <Cloze
            key={`Content_${content.id}`}
            contentId={content.id}
            items={content.items}
            addAnswer={this.addAnswer}
            addCloze={this.addCloze}
            onClozeQuestionChange={this.onClozeQuestionChange}
            setCorrectAnswer={this.setCorrectAnswer}
            optionTextChange={this.optionTextChange}
            removeAnswer={this.removeAnswer}
            removeQuestionItem={removeItem}
            sortQuestionOption={this.sortQuestionOption}
            moveQuestionItem={moveItem}
            forceClozeChange={forceClozeChange}
          />
        );
      case 'mixedQuestions':
        return (
          <MixedQuestions
            key={`Content_${content.id}`}
            contentId={content.id}
            items={content.items}
            addQuestion={this.addQuestionToMixedContent}
            addAnswer={this.addAnswer}
            removeQuestionItem={removeItem}
            moveQuestionItem={moveItem}
            savePicture={saveQuestionPicture}
            onQuestionChange={this.onQuestionChange}
            onClozeQuestionChange={this.onClozeQuestionChange}
            optionCheckChange={this.optionCheckChange}
            optionTextChange={this.optionTextChange}
            removeAnswer={this.removeAnswer}
            sortQuestionOption={this.sortQuestionOption}
            optionMultipleCheckChange={this.optionMultipleCheckChange}
            forceClozeChange={forceClozeChange}
          />
        );
      default:
        return null;
    }
  }

  renderAddButton() {
    return (
      <AddButtonWrapper>
        <CircleButton size="50px" background="addContentColor" onClick={() => this.handleDisplayMenu()}>
          <IconFa name="plus" color="white" />
        </CircleButton>
        <Divider borderWidth="1px" borderColor="gray2" />
      </AddButtonWrapper>
    );
  }

  render() {
    const { tab, title, uaId, modalIsOpen, hideModal, onConfirm, haveUf } = this.props;
    const { contents, menuVisible } = this.state;
    return (
      <div>
        {haveUf
          ? (
            <DividerWrapper>
              <Divider borderWidth="2px" borderColor="gray2" />
              <MfsDivider width="25%" borderWidth="2px" borderColor="blueMfs" />
              <UfsDivider width="25%" borderWidth="2px" borderColor="orangeUf" />
              <UasDivider width="25%" borderWidth="2px" borderColor="greenUa" />
              <ContentDivider width="25%" borderWidth="2px" borderColor="greyContent" />
            </DividerWrapper>
          )
          : (
            <DividerWrapper>
              <Divider borderWidth="2px" borderColor="gray2" />
              <MfsDivider width="33.3333%" borderWidth="2px" borderColor="blueMfs" />
              <UasDivider className="mf" width="33.3333%" borderWidth="2px" borderColor="greenUa" />
              <ContentDivider className="mf" width="33.3333%" borderWidth="2px" borderColor="greyContent" />
            </DividerWrapper>
          )}
        <Menu
          isOpen={menuVisible}
          // stickyAt={1800}
          toggleNavigation={this.handleDisplayMenu}
          showSubMenu={this.handleDisplaySubMenu}
          addContent={this.addContent}
        />
        <Header>
          <Section>
            <TitleWrapper>
              <MfName>
                <SectionCircle size="42px" background="greyContent">
                  <CircleText>C</CircleText>
                </SectionCircle>
              </MfName>
              <StyledSeparation>
                <CodeName>
                  { title !== '' && <div>{title}</div> }
                </CodeName>
              </StyledSeparation>
            </TitleWrapper>
            <ButtonsWrapper>
              <CloseButton
                onClick={() => this.onClose()}
              >
                Cerrar
              </CloseButton>
              <Button
                hollow
                color="black"
                borderColor="black"
                onClick={() => this.changeToPreview()}
              >
                Previsualizar
              </Button>
            </ButtonsWrapper>
          </Section>
          <SectionTitle><StyledH2>CONTENIDOS</StyledH2></SectionTitle>
        </Header>
        <Tabs
          tabsBarBorderColor="gray2"
          selected={tab}
          component={Tab}
          componentProps={{ color: 'greyContent' }}
          tabsTitleMaxWidth={theme.maxWidth}
        >
          <TabsPane name="#ua" label="UA" component={PrevLink} componentProps={{ uaId }}>
            <div />
          </TabsPane>
          <TabsPane
            name="#contents"
            label="Contenidos"
          >
            <ContentWrapper>
              <Alert stack={{ limit: 1 }} />
              <NoContent>
                <InfoText>
                  Haz clic en + para agregar un bloque de contenido.
                </InfoText>
                <AddButtonWrapper>
                  <CircleButton size="50px" background="addContentColor" onClick={() => this.handleDisplayMenu(true)}>
                    <IconFa name="plus" color="white" />
                  </CircleButton>
                  <Divider borderWidth="1px" borderColor="gray2" />
                </AddButtonWrapper>
              </NoContent>
              {contents.length > 0 && this.wrapperContents()}
              {contents.length > 0 && (
                <StyledButtonGroup align="right">
                  <Button onClick={() => this.handleSaveAllContents()}>Guardar</Button>
                </StyledButtonGroup>
              )}
              <div id="bottomOfContents" />
            </ContentWrapper>
          </TabsPane>
        </Tabs>
        <ModalConfirm
          isOpen={modalIsOpen}
          title="Eliminar"
          content="¿Desea eliminar el contenido?"
          onCancel={hideModal}
          onConfirm={onConfirm}
        />
      </div>
    );
  }
}

Wrapper.propTypes = {
  save: PropTypes.func.isRequired,
  removeContent: PropTypes.func.isRequired,
  savePicture: PropTypes.func.isRequired,
  uploadContentFile: PropTypes.func.isRequired,
  moveContent: PropTypes.func.isRequired,
  reorderPageContents: PropTypes.func.isRequired,
  pageId: PropTypes.string.isRequired,
  uaId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  contents: PropTypes.arrayOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  tab: PropTypes.string.isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  positions: PropTypes.arrayOf(PropTypes.any).isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  removeItem: PropTypes.func.isRequired,
  moveItem: PropTypes.func.isRequired,
  saveItemsPicture: PropTypes.func.isRequired,
  haveUf: PropTypes.bool.isRequired,
  saveQuestionPicture: PropTypes.func.isRequired,
};

Wrapper.defaultProps = {
  contents: [],
};

export default Wrapper;
