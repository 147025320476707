import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Input from 'client/ui/Input';
import Button from 'client/ui/Button';
import IconFa from 'client/ui/IconFa';
import ButtonFa from 'client/ui/ButtonFa';

const Item = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  padding-left: 1rem;
  position: relative;
`;

const FixedTitle = styled.div`
  width: 100%;
  text-align: left;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 0.5625rem;
  padding-right: 1rem;
  white-space: nowrap;
`;

const StyledItemOptions = styled.div`
  display: flex;
  justify-content: space-between;
  align-self: flex-end;
`;

const StyledButtonFa = styled(ButtonFa)`
  display: inline-block;
  cursor: pointer;
  padding: 0.65em 0.60em;
  font-size: 1rem;
  background-color: transparent;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 36px;
  button, a {
    margin-left: 1rem;
  }
  input {
    margin-bottom: 0;
  }
`;

const EditionButton = styled(Button)`
  width: 182px;
`;

const ContentIconsWrapper = styled.div`
  position: absolute;
  background-color: white;
  right: 5px;
  top: 95px;
  display: flex;
  justify-content: space-around;
  border: 1px solid grey;
  border-radius: 6px;
  box-shadow: 10px 10px 5px -6px rgba(0,0,0,0.75);
  z-index: 1000;
`;

const ContentIconWrapper = styled.div`
  padding: 1rem;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1rem;
  border: 1px solid white;
  z-index: 1000;
  &:hover {
    background-color: #EEEEEE;
    border: 1px solid #EEEEEE;
  }
  i {
    margin-bottom: .5rem;
    font-size: 1.5rem;
  }
`;

const ContentIcon = ({ icon, text, ...rest }) => (
  <ContentIconWrapper {...rest}>
    <IconFa regular name={icon} />
    <div>{text}</div>
  </ContentIconWrapper>
);

ContentIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

ContentIcon.defaultProps = {};

class PageItem extends Component {
  constructor() {
    super();
    this.state = {
      title: '',
      edition: true,
      showContents: false,
    };
    this.handleShowContentTypes = this.handleShowContentTypes.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.toggleEdition = this.toggleEdition.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.updatePage = this.updatePage.bind(this);
  }

  componentWillMount() {
    const { title, type } = this.props;
    if (title === '') {
      this.setState({ edition: true, type });
    } else {
      this.setState({ title, edition: false, type });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { title, type } = nextProps;
    if (title === '') {
      this.setState({ edition: true, type });
    } else {
      this.setState({ title, edition: false, type });
    }
  }

  toggleEdition() {
    const { edition } = this.state;
    if (edition) {
      const { id, save } = this.props;
      const { title } = this.state;
      if (id !== '') {
        const data = {
          id,
          title,
        };
        save(data);
      }
    }
    this.setState({ edition: !edition });
  }

  updatePage(type) {
    this.handleShowContentTypes();
    const { id, addPage } = this.props;
    const { title } = this.state;
    const data = {
      type,
      title,
    };

    if (id !== '') {
      Object.assign(data, { id });
    }
    addPage(data);
  }

  handleInputChange(event) {
    const { target } = event;
    const { name, value } = target;
    this.setState({ [name]: value });
  }

  handleShowContentTypes() {
    const { showContents } = this.state;
    this.setState({ showContents: !showContents });
  }

  handleSubmit() {
    const { save, id } = this.props;
    const { title, type } = this.state;
    const data = {
      title,
      type,
    };

    if (id) {
      Object.assign(data, { id });
    }

    save(data);
    this.toggleEdition();
  }

  render() {
    const { onRemove, id } = this.props;
    const { edition, title, showContents, type } = this.state;
    return (
      <Item>
        <StyledItemOptions>
          <div>
            <StyledButtonFa
              regular
              fa="fa-pencil-alt"
              transparent
              color="black"
              onClick={this.toggleEdition}
            />
            <StyledButtonFa
              fa="fa-trash-alt"
              transparent
              color="red"
              onClick={() => onRemove(id)}
            />
          </div>
        </StyledItemOptions>
        <InputWrapper>
          {edition
            ? (
              <Input
                type="text"
                name="title"
                value={title}
                onChange={this.handleInputChange}
                required
              />)
            : <FixedTitle>{title}</FixedTitle>
          }
          <div>
            {edition && id === '' && (
              <Button
                disabled={title === ''}
                onClick={() => this.toggleEdition()}
              >
                Guardar
              </Button>
            )}
            {edition && id !== '' && (
              <Button
                disabled={title === ''}
                onClick={() => this.handleSubmit()}
              >
                Guardar
              </Button>
            )}
            {!edition && type === '' && (
              <EditionButton
                disabled={id === null}
                onClick={() => this.handleShowContentTypes()}
              >
                Añadir contenido
              </EditionButton>
            )}
            {!edition && (type === 'page' || type === 'evaluation') && (
              <EditionButton
                to={`/dashboard/contents/${id}`}
              >
                Editar contenido
              </EditionButton>
            )}
          </div>
          {showContents && (
            <ContentIconsWrapper>
              <ContentIcon icon="file" text="Página" onClick={() => this.updatePage('page')} />
              <ContentIcon icon="check-square" text="Evaluación" onClick={() => this.updatePage('evaluation')} />
            </ContentIconsWrapper>)
          }
        </InputWrapper>
      </Item>
    );
  }
}

PageItem.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  save: PropTypes.func.isRequired,
  addPage: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  type: PropTypes.string,
};

PageItem.defaultProps = {
  id: '',
  type: '',
  title: '',
};

export default PageItem;
