import React from 'react';
import PropTypes from 'prop-types';
import Label from 'client/ui/Label';
import { api } from 'config';
import MediumEditor from 'client/ui/MediumEditor';
import { mediumEditorBasic } from 'client/modules/dashboard/utils/medium-editor';
import UploadPictureGallery from './UploadPictureGallery';

const GalleryItem = ({ id, text, onTextChange, index, picture,
  savePicture, previewMaxWidth }) => (
    <div>
      <UploadPictureGallery
        id={`id-${id}${index}`}
        image={picture && picture.length > 0 ? `${api.baseUrl}${picture}` : '/images/contents/picture.jpg'}
        onChange={(selected) => { savePicture(selected, id, index); }}
        previewMaxWidth={previewMaxWidth}
        required=""
      />
      <Label>Texto</Label>
      <MediumEditor
        id={id}
        text={text}
        onChange={(value) => { onTextChange(id, index, value); }}
        options={{
          toolbar: { buttons: mediumEditorBasic },
          placeholder: { text: 'Añadir texto', hideOnClick: true },
        }}
      />
    </div>
);

GalleryItem.propTypes = {
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  picture: PropTypes.string,
  onTextChange: PropTypes.func.isRequired,
  savePicture: PropTypes.func.isRequired,
  previewMaxWidth: PropTypes.string,
};

GalleryItem.defaultProps = {
  picture: '',
  previewMaxWidth: '150px',
};

export default GalleryItem;
