import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import RandomId from 'short-unique-id';
import Input from 'client/ui/Input';
import Button from 'client/ui/Button';
import Label from 'client/ui/Label';
import ButtonFa from 'client/ui/ButtonFa';
import MediumEditor from 'client/ui/MediumEditor';
import { mediumSelection } from 'client/modules/dashboard/utils/medium-editor';

const StyledOption = styled.div`
  display: flex;
  align-items: baseline;
  padding: 0.563rem 0;
  margin: 0.5rem 0;
  border: 1px solid ${props => props.theme.color.grayTable};
  border-radius: ${props => props.theme.radius};
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.5);
`;

const StyledInput = styled(Input)`
  border: 1px solid ${props => props.theme.color.gray};
  margin-bottom: 0;
  margin-left: 1rem;
`;

const ReadOnlyInput = styled(StyledInput)`
  margin-right: 1rem;
  margin-top: .375rem;
  margin-bottom: .375rem;
`;

const StyledButtonFa = styled(ButtonFa)`
  display: inline-block;
  cursor: pointer;
  padding: 0.875em 1em;
  font-size: 1rem;
  background-color: transparent;
`;

const CenterButton = styled.div`
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

const StyledMedium = styled(MediumEditor)`
  b {
    background-color: #4A90E2;
  }
`;

const StyledInfo = styled.div`
  margin-bottom: 1rem;
  font-size: 0.875rem;
  color: ${props => props.theme.color.addContentColor};
`;

class ClozeItem extends Component {
  constructor() {
    super();
    this.state = { questionUid: null };
  }

  componentWillMount() {
    const uid = new RandomId();
    this.setState({ questionUid: uid.randomUUID(12) });
  }

  componentWillReceiveProps(nextProps) {
    const { forceClozeChange } = nextProps;
    if (forceClozeChange) {
      const uid = new RandomId();
      this.setState({ questionUid: uid.randomUUID(12) });
    }
  }

  render() {
    const { question, contentId, questionId, options, onClozeQuestionChange, addAnswer,
      optionTextChange, removeAnswer } = this.props;
    const { questionUid } = this.state;

    return (
      <div>
        <StyledMedium
          key={questionUid}
          minHeight="1rem"
          text={question}
          onChange={(value) => { onClozeQuestionChange(contentId, questionId, value); }}
          options={{
            toolbar: { buttons: mediumSelection },
            placeholder: { text: 'Añadir texto', hideOnClick: true },
          }}
        />
        <StyledInfo>
          Nota: Añada una frase y seleccione la palabra correcta.
        </StyledInfo>
        <Label>Respuesta válida</Label>
        <StyledOption>
          <ReadOnlyInput
            type="text"
            name="text"
            value={options[0].text}
            readOnly
          />
        </StyledOption>

        <CenterButton>
          <Button onClick={() => addAnswer(contentId, questionId)}>
            Añadir Otra Respuesta Válida
          </Button>
        </CenterButton>

        <Label>Otras respuestas válidas</Label>
        {options.map((option, index) => {
          if (index > 0) {
            return (
              <StyledOption key={option.id}>
                <StyledInput
                  type="text"
                  name="text"
                  value={option.text}
                  onChange={(value) => { optionTextChange(contentId, questionId, option.id, value); }}
                  placeholder="Añadir otra respuesta válida"
                />
                <StyledButtonFa
                  fa="fa-trash-alt"
                  transparent
                  onClick={() => removeAnswer(contentId, questionId, option.id)}
                  color="black"
                />
              </StyledOption>
            );
          }
          return null;
        })}
      </div>
    );
  }
}

ClozeItem.propTypes = {
  question: PropTypes.string.isRequired,
  contentId: PropTypes.string.isRequired,
  addAnswer: PropTypes.func.isRequired,
  questionId: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  optionTextChange: PropTypes.func.isRequired,
  onClozeQuestionChange: PropTypes.func.isRequired,
  removeAnswer: PropTypes.func.isRequired,
  forceClozeChange: PropTypes.bool.isRequired,
};

ClozeItem.defaultProps = {};

export default ClozeItem;
