import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import logout from 'client/modules/accounts/actions/logout';
import canDo from 'client/modules/core/utils/permissionsUtils';
import MenuCollapse from '../components/MenuCollapse';

const MenuCollapseContainer = ({ opened, onLinkClick, location, isOpen, logoutAction,
  permissions }) => {
  const userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;

  let roles = [];

  if (userInfo) {
    roles = userInfo.roles.map(rol => rol.rid);
  }
  const items = [];

  roles.forEach((rol) => {
    if (rol === 'ADMIN') {
      items.push({
        isOpen: false,
        id: 1,
        name: 'Panel de Administración',
        path: '/dashboard',
      });
    }
  });

  if (canDo(permissions, 'MODULES', 'READ')) {
    items.push({
      isOpen: false,
      id: 2,
      name: 'Mis Módulos Formativos',
      path: '/dashboard/myMfs',
    });
  }

  if (canDo(permissions, 'CENTREREPO', 'READ')) {
    items.push({
      isOpen: false,
      id: 3,
      name: 'Módulos Formativos de los centros',
      path: '/dashboard/centreRepository',
    });
  }

  items.push({
    isOpen: false,
    id: 4,
    name: 'Módulos Formativos publicados',
    path: '/publicated',
  });

  if (canDo(permissions, 'PASSWORD', 'RESET')) {
    items.push({
      isOpen: false,
      id: 5,
      name: 'Cambiar contraseña',
      path: '/dashboard/changePassword',
    });
  }

  items.push({
    isOpen: false,
    id: 6,
    name: 'Cerrar sesion',
    path: '/',
    click: logoutAction,
  });

  items.forEach((item, index) => {
    if (item.id === opened) {
      items[index].isOpen = true;
    } else {
      items[index].isOpen = false;
    }
  });

  return (
    <MenuCollapse
      items={items}
      onLinkClick={onLinkClick}
      location={location}
      isOpen={isOpen}
    />
  );
};

MenuCollapseContainer.propTypes = {
  opened: PropTypes.number,
  onLinkClick: PropTypes.func.isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  isOpen: PropTypes.bool.isRequired,
  permissions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  logoutAction: PropTypes.func.isRequired,
};

MenuCollapseContainer.defaultProps = {
  opened: null,
};

const mapStateToProps = state => ({
  opened: state.common.opened,
  permissions: state.permissions.userPermissions,
});

const mapDispatchToProps = dispatch => ({
  logoutAction: () => { dispatch(logout()); },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MenuCollapseContainer);
