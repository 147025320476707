import React from 'react';
import PropTypes from 'prop-types';
import Collapse from 'client/ui/Collapse';
import CollapseItem from './CollapseItemView';

const CollapseView = ({ items, id }) => (
  <div>
    {items && items.map((item, index) => (
      <Collapse
        key={`collapse${index}${id}`}
        title={item.title}
        headerBg="grayLight"
        borderColor="borderBg"
        colorText="modalTitle"
        borderBottomColor="borderBg"
        fontWeight="bold"
      >
        <CollapseItem
          key={`collapse${index}-${id}`}
          text={item.text}
          picture={item.url}
        />
      </Collapse>
    ))}
  </div>
);

CollapseView.propTypes = {
  id: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
};

CollapseView.defaultProps = {};

export default CollapseView;
