import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { H4 } from 'client/ui/Headings';
import Video from 'client/ui/Video';
import Img from 'client/ui/Img';
import { getVideoService, parseVideoId } from 'client/modules/core/utils/parse-video';
import TextView from './TextView';

const VideoWrapper = styled.div`
  margin-left:auto;
  margin-right: auto;
  margin-bottom: 2rem;
`;

const VideoView = ({ url, data }) => {
  const service = getVideoService(url);
  const videoId = parseVideoId(url);
  return (
    <div>
      <VideoWrapper>
        {url && url.length > 5 && service && service !== '' && videoId !== false
          ? <div><Video service={service} id={videoId} /></div>
          : <Img src="/images/contents/video.jpg" />
        }
      </VideoWrapper>
      {data.length > 0
        && (
          <div>
            <H4>Transcripción</H4>
            <TextView data={data} />
          </div>
        )}
    </div>
  );
};

VideoView.propTypes = {
  url: PropTypes.string,
  data: PropTypes.string.isRequired,
};

VideoView.defaultProps = {
  url: '',
};

export default VideoView;
