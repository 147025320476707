import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { api } from 'config';
import { fetchPage } from 'client/modules/dashboard-pages/actions/pages';
import { fetchUa } from 'client/modules/dashboard-uas/actions/uas';
import { fetchUf } from 'client/modules/dashboard-ufs/actions/ufs';
import { fetchCentre } from 'client/modules/dashboard-centres/actions/centres';
import { fetchPageContents } from 'client/modules/dashboard-contents/actions/contents';
import Wrapper from '../components/Wrapper';
import EvaluationWrapper from '../components/EvaluationWrapper';

class WrapperContainer extends Component {
  constructor() {
    super();
    this.state = {
      contents: [],
      title: '',
      tab: '#contents',
      pageId: null,
      uaId: '',
      notFound: false,
      colorMf: '',
      colorUf: '',
      colorUa: '',
      haveUf: false,
      typePage: 'page',
      config: {},
    };
    this.loadPageContents = this.loadPageContents.bind(this);
  }

  componentWillMount() {
    const { match, history } = this.props;
    if (match.params.id) {
      const { id } = match.params;
      this.setState({ pageId: id });
      this.loadPageContents(id);
    }
    if (history.location && history.location.hash) {
      this.setState({ tab: history.location.hash });
    }
  }

  loadPageContents(pageId) {
    fetchPage(pageId).then((page) => {
      const typePage = page.type;
      let config = {};
      if (page.config) {
        config = page.config;
      }
      fetchUa(page.topic.ua).then((ua) => {
        if (ua.uf) {
          fetchUf(ua.uf._id).then((uf) => {
            const { mf } = uf;
            const { centre } = mf;
            const { colorMf, colorUf, colorUa } = centre;
            this.setState({ colorMf, colorUf, colorUa, haveUf: true });
          });
        } else if (ua.mf) {
          fetchCentre(ua.mf.centre).then((centreData) => {
            const { colorMf, colorUf, colorUa } = centreData;
            this.setState({ colorMf, colorUf, colorUa, haveUf: false });
          });
        }
      });
      fetchPageContents(pageId).then((contents) => {
        const parsed = [];
        contents.forEach((content) => {
          let picture = null;
          const current = content;
          current.id = content._id;
          delete current.updatedAt;
          delete current.deletedAt;
          delete current.createdAt;
          delete current._id;
          delete current.__v;
          if (content.picture && content.picture._id) {
            picture = {
              id: content.picture._id,
              src: `${api.baseUrl}${content.picture.url.l}`,
            };
            current.picture = picture;
          }
          parsed.push(current);
        });
        if (contents.length === 0) {
          this.setState({ contents,
            config,
            title: page.title,
            uaId: page.topic.ua,
            typePage,
            notFound: true });
        } else {
          this.setState({ contents: parsed,
            config,
            title: page.title,
            typePage,
            uaId: page.topic.ua });
        }
      });
    });
  }

  render() {
    const { history, match } = this.props;
    const { contents, tab, pageId, title, uaId, notFound, colorMf, colorUf,
      colorUa, haveUf, typePage, config } = this.state;
    if (typePage === 'evaluation') {
      return (
        <EvaluationWrapper
          contents={contents}
          history={history}
          notFound={notFound}
          title={title}
          uaId={uaId}
          pageId={pageId}
          match={match}
          tab="#evaluacion"
          colorMf={colorMf}
          colorUf={colorUf}
          colorUa={colorUa}
          haveUf={haveUf}
          typePage={typePage}
          config={config}
        />
      );
    }
    return (
      <Wrapper
        contents={contents}
        history={history}
        notFound={notFound}
        title={title}
        uaId={uaId}
        pageId={pageId}
        match={match}
        tab={tab}
        colorMf={colorMf}
        colorUf={colorUf}
        colorUa={colorUa}
        haveUf={haveUf}
        typePage={typePage}
      />
    );
  }
}

WrapperContainer.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

WrapperContainer.defaultProps = {};

export default WrapperContainer;
