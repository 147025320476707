/* eslint no-restricted-globals: 0, react/destructuring-assignment: 0,
react/no-access-state-in-setstate: 0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import deepEqual from 'fast-deep-equal';
import Notification from 'client/ui/Notification';
import theme from 'client/config/theme';
import Modal from 'client/ui/Modal';
import Divider from 'client/ui/Divider';
import Button from 'client/ui/Button';
import ButtonFa from 'client/ui/ButtonFa';
import IconFa from 'client/ui/IconFa';
import CodeName from 'client/ui/CodeName';
import { Tabs, TabsPane, Tab } from 'client/ui/Tabs';
import SectionCircle from 'client/ui/SectionCircle';
import { H2 } from 'client/ui/Headings';
import ContentWrapper from 'client/ui/ContentWrapper';
import List from 'client/modules/dashboard-mfs/containers/List';
import Proration from 'client/modules/dashboard-uas/components/Proration';
import { redirectTo } from 'index';
import GeneralData from './GeneralData';
import BibliographicReferences from './BibliographicReferences';


const CircleText = styled.div`
  font-size: 21px;
  font-weight: bold;
  color: white;
  position: absolute;
  top: 11px;
  right: 7px;
`;

const StyledH2 = styled(H2)`
  color: ${props => props.theme.color.white};
`;

const Header = styled.div`
  margin-bottom: .5rem;
`;

const SectionTitle = styled.div`
  text-align: center;
  background-color: ${props => props.theme.color.orangeUf};
  color: white;
  padding: .5rem;
  h2 {
    margin-bottom: 0;
  }
`;

const StyledSeparation = styled.div`
  width: 100%;
  min-width: 0;
  margin-right: 1rem;
`;

const Section = ContentWrapper.extend`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;
`;

const MfName = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: .25rem;
`;

const DividerWrapper = styled.div`
  position: relative;
`;

const MfsDivider = Divider.extend`
  position: absolute;
  top: 0;
  left: 0;
`;

const UfsDivider = MfsDivider.extend`
  left: 25%;
`;

const StyledReturn = styled.span`
  margin-right: .7rem;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
`;

const PrevFa = styled(IconFa)`
  margin-right: .5rem;
  ${props => props.color && css`
    color: ${props.theme.color[props.color]};
  `}
  ${props => props.doubleMargin && css`
    margin-right: .8rem;
  `}
`;

const BackButtonWrapper = styled.div`
  max-width: ${props => props.theme.maxWidth};
  margin-left: auto;
  margin-right: auto;
  padding-left: ${props => props.theme.outerMargin};
  padding-right: ${props => props.theme.outerMargin};
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 0;
`;

const PrevLink = data => (
  <StyledReturn onClick={() => redirectTo(`/dashboard/mfs/${data.mfId}#formativeUnits`)}>
    <PrevFa name="chevron-left" doubleMargin />
    <PrevFa name="circle" color="blueMfs" />
    <span>MF</span>
  </StyledReturn>
);

class Wrapper extends Component {
  constructor() {
    super();
    this.state = {
      id: null,
      codeUf: '',
      title: '',
      subtitles: [''],
      objectives: [''],
      introduction: '',
      coverPicture: null,
      bibliographicReferences: [],
      modalIsOpen: false,
      previewModalIsOpen: false,
      nextTab: null,
      mfId: null,
      toPreview: false,
    };
    this.handleBibliographicEditorChange = this.handleBibliographicEditorChange.bind(this);
    this.handleChangeItemOnCollection = this.handleChangeItemOnCollection.bind(this);
    this.handleReferenceTitleChange = this.handleReferenceTitleChange.bind(this);
    this.handleMediumEditorChange = this.handleMediumEditorChange.bind(this);
    this.removeFromCollection = this.removeFromCollection.bind(this);
    this.handlePictureChange = this.handlePictureChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.cancelPreviewModal = this.cancelPreviewModal.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.afterNotification = this.afterNotification.bind(this);
    this.showPreviewModal = this.showPreviewModal.bind(this);
    this.addToCollection = this.addToCollection.bind(this);
    this.handleChangeTab = this.handleChangeTab.bind(this);
    this.changeToPreview = this.changeToPreview.bind(this);
    this.handleTabClick = this.handleTabClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.confirmModal = this.confirmModal.bind(this);
    this.saveAndNext = this.saveAndNext.bind(this);
    this.cancelModal = this.cancelModal.bind(this);
    this.isFormDirty = this.isFormDirty.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  componentWillMount() {
    const { mfId } = this.props;
    this.setState({ mfId });
  }

  componentWillReceiveProps(nextProps) {
    const { ufToUpdate, pictureId } = nextProps;
    if (ufToUpdate && ufToUpdate.id && !pictureId) {
      const { id, bibliographicReferences, codeUf, title, subtitles, introduction,
        objectives, coverPicture, mfId } = ufToUpdate;
      this.setState({
        id,
        bibliographicReferences,
        codeUf,
        title,
        subtitles,
        introduction,
        objectives,
        coverPicture,
        mfId,
      });
    }
    if (pictureId) {
      const { coverPicture } = this.state;
      coverPicture.id = pictureId;
      this.setState({ coverPicture });
    }
  }

  isFormDirty() {
    const { ufToUpdate } = this.props;
    const { bibliographicReferences, codeUf, title, subtitles, introduction, objectives,
      coverPicture } = ufToUpdate;

    const initialData = {
      bibliographicReferences,
      codeUf,
      title,
      subtitles,
      introduction,
      objectives,
    };

    if (coverPicture) {
      Object.assign(initialData, {
        coverPicture: {
          id: coverPicture.id,
        },
      });
    }

    const currentData = {
      bibliographicReferences: this.state.bibliographicReferences,
      codeUf: this.state.codeUf,
      title: this.state.title,
      subtitles: this.state.subtitles,
      introduction: this.state.introduction,
      objectives: this.state.objectives,
    };

    if (this.state.coverPicture) {
      Object.assign(currentData, {
        coverPicture: {
          id: this.state.coverPicture.id,
        },
      });
    }

    return !deepEqual(initialData, currentData);
  }

  afterNotification() {
    const { nextTab, toPreview } = this.state;
    const { clearMessages, history, match, notification } = this.props;
    clearMessages();
    if (!match.params.id && notification.id) {
      setTimeout(() => {
        history.replace(`/dashboard/ufs/${notification.id}`);
      }, 1000);
    }

    if (notification.id && toPreview) {
      const { tab } = this.props;
      const { id } = match.params;
      this.setState({ toPreview: false });
      const path = { ...location, hash: tab };
      path.name = `/viewUf/${id}`;
      path.pathname = `/viewUf/${id}`;
      history.replace(path);
    }

    if (notification.id && nextTab && !toPreview) {
      setTimeout(() => {
        this.handleChangeTab(nextTab);
      }, 1000);
    }
  }

  handleMediumEditorChange(value, name) {
    const emptyValue = '<p><br></p>';
    this.setState({
      [name]: value !== emptyValue ? value.trim() : '',
    });
  }

  showModal() {
    this.setState({ modalIsOpen: true });
  }

  showPreviewModal() {
    this.setState({ previewModalIsOpen: true });
  }

  hideModal() {
    this.setState({ modalIsOpen: false, previewModalIsOpen: false });
  }

  confirmModal() {
    this.hideModal();
    this.handleSubmit();
  }

  cancelModal() {
    const { nextTab } = this.state;
    this.hideModal();
    this.handleChangeTab(nextTab);
  }

  cancelPreviewModal() {
    this.hideModal();
    const { history, match, tab } = this.props;
    const { id } = match.params;
    const path = { ...location, hash: tab };
    path.name = `/viewUf/${id}`;
    path.pathname = `/viewUf/${id}`;
    history.replace(path);
  }

  // changeToPreview() {
  //   const { history, match, tab } = this.props;
  //   const { id } = match.params;
  //   const path = { ...location, hash: tab };
  //   path.name = `/viewUf/${id}`;
  //   path.pathname = `/viewUf/${id}`;
  //   history.replace(path);
  // }

  changeToPreview() {
    const { history, match, tab } = this.props;
    const { id } = match.params;
    const path = { ...location, hash: tab };
    if (this.isFormDirty()) {
      this.setState({ toPreview: true });
      this.showPreviewModal();
    } else {
      path.name = `/viewUf/${id}`;
      path.pathname = `/viewUf/${id}`;
      history.replace(path);
    }
  }

  handleBibliographicEditorChange(index, value) {
    const { bibliographicReferences } = this.state;
    const emptyValue = '<p><br></p>';
    bibliographicReferences[index].text = value !== emptyValue ? value.trim() : '';
    this.setState({ bibliographicReferences });
  }

  handleReferenceTitleChange(index, event) {
    const { bibliographicReferences } = this.state;
    const { target } = event;
    const { value } = target;
    bibliographicReferences[index].title = value;
    this.setState({ bibliographicReferences });
  }

  handleInputChange(event) {
    const { target } = event;
    const { name, value } = target;
    this.setState({ [name]: value });
  }

  addToCollection(collection) {
    const current = this.state[collection];
    if (collection === 'bibliographicReferences') {
      current.push({ title: '', text: '' });
    } else {
      current.push('');
    }
    this.setState({ [collection]: current });
  }

  removeFromCollection(collection, index) {
    const current = this.state[collection];
    current.splice(index, 1);
    this.setState({ [collection]: current });
  }

  handleChangeItemOnCollection(collection, index, event) {
    const current = this.state[collection];
    const { target } = event;
    const { value } = target;
    current[index] = value;
    this.setState({ [collection]: current });
  }

  handleTabClick(tab) {
    if (this.isFormDirty()) {
      this.setState({ nextTab: tab });
      this.showModal();
    } else {
      this.handleChangeTab(tab);
    }
  }

  saveAndNext(tab) {
    this.setState({ nextTab: tab });
  }

  handleChangeTab(tab) {
    const { history } = this.props;
    const path = { ...location, hash: tab };
    history.replace(path);
  }

  handlePictureChange(coverPicture) {
    const { savePicture } = this.props;
    const { title } = this.state;
    this.setState({
      coverPicture,
    });
    if (coverPicture) {
      savePicture(coverPicture, title);
    }
  }

  handleSelectChange(name, value) {
    this.setState({ [name]: value });
  }

  handleSubmit(event) {
    if (event) {
      event.preventDefault();
    }
    const { save, tab } = this.props;
    const { id, bibliographicReferences, codeUf, title, introduction, objectives, subtitles,
      coverPicture, mfId } = this.state;
    if (tab === '#BibliographicReferences') {
      const data = {
        id,
        bibliographicReferences,
        mfId,
      };
      save(data);
    } else if (tab === '#generalData') {
      const data = {
        id,
        codeUf,
        title,
        introduction,
        subtitles,
        objectives,
        mfId,
      };
      if (coverPicture) {
        Object.assign(data, { coverPicture: coverPicture.id });
      }
      save(data);
    }
  }

  render() {
    const { notification, tab, createMode, savePicture, mfId, notificationUa,
      clearUaMessage, save } = this.props;
    const { id, bibliographicReferences, codeUf, title, introduction, subtitles, objectives,
      coverPicture, modalIsOpen, previewModalIsOpen } = this.state;
    const savedCodeUf = this.props.ufToUpdate.codeUf;
    const savedTitle = this.props.ufToUpdate.title;
    const { proration } = this.props.ufToUpdate;
    const { uas } = this.props.ufToUpdate;
    return (
      <div>
        <DividerWrapper>
          <Divider borderWidth="2px" borderColor="grayLight" />
          <MfsDivider width="25%" borderWidth="2px" borderColor="blueMfs" />
          <UfsDivider width="25%" borderWidth="2px" borderColor="orangeUf" />
        </DividerWrapper>
        <Header>
          <Section>
            <TitleWrapper>
              <MfName>
                <SectionCircle size="42px" background="orangeUf">
                  <CircleText>UF</CircleText>
                </SectionCircle>
              </MfName>
              <StyledSeparation>
                <CodeName>
                  { savedCodeUf !== '' && savedTitle !== ''
                    ? <div>{savedCodeUf} {savedTitle}</div>
                    : <div>UF</div>
                  }
                </CodeName>
              </StyledSeparation>
            </TitleWrapper>

            {!createMode
              && (
                <Button
                  hollow
                  color="black"
                  borderColor="black"
                  onClick={() => this.changeToPreview()}
                >
                  Previsualizar
                </Button>)}
          </Section>
          <SectionTitle><StyledH2>UNIDAD FORMATIVA</StyledH2></SectionTitle>
        </Header>
        <Notification
          message={notification}
          afterNotification={this.afterNotification}
        />

        <Notification
          message={notificationUa}
          afterNotification={clearUaMessage}
        />

        <Modal
          isOpen={modalIsOpen}
          title="Aviso"
          content="Ha realizado cambios, ¿desea guardar los cambios antes de continuar?"
          onCancel={this.cancelModal}
          onConfirm={this.confirmModal}
        />
        <Modal
          isOpen={previewModalIsOpen}
          title="Aviso"
          content="Ha realizado cambios, ¿desea guardar los cambios antes de previsualizar?"
          onCancel={this.cancelPreviewModal}
          onConfirm={this.confirmModal}
        />
        <Tabs
          tabsBarBorderColor="gray2"
          selected={tab}
          component={Tab}
          componentProps={{ color: 'orangeUf' }}
          onTabClick={currentTab => this.handleTabClick(currentTab)}
          tabsTitleMaxWidth={theme.maxWidth}
        >
          <TabsPane name="#mf" label="Descripción" component={PrevLink} componentProps={{ mfId }}>
            <div />
          </TabsPane>
          <TabsPane
            name="#generalData"
            label="Datos generales"
          >
            <GeneralData
              ufToUpdate={{ codeUf, title, introduction, subtitles, objectives, coverPicture }}
              inputChange={this.handleInputChange}
              addToCollection={this.addToCollection}
              removeFromCollection={this.removeFromCollection}
              handleChangeItemOnCollection={this.handleChangeItemOnCollection}
              handlePictureChange={this.handlePictureChange}
              handleMediumEditorChange={this.handleMediumEditorChange}
              createMode={createMode}
              savePicture={savePicture}
              saveAndNext={this.saveAndNext}
              submit={this.handleSubmit}
            />
          </TabsPane>

          {!createMode
            && (
              <TabsPane
                name="#learningUnits"
                label="Unidades de Aprendizaje"
              >
                <List haveUf={false} items={uas} typeMf={false} modeEdit />
                <BackButtonWrapper>
                  <ButtonFa
                    fa="fa-chevron-left"
                    side="left"
                    onClick={() => redirectTo(`/dashboard/mfs/${mfId}#formativeUnits`)}
                  >
                    Volver a MF
                  </ButtonFa>
                </BackButtonWrapper>
              </TabsPane>)}
          {!createMode
            && (
              <TabsPane
                name="#BibliographicReferences"
                label="Referencias Bibliográficas"
              >
                <BibliographicReferences
                  ufToUpdate={{ id, bibliographicReferences }}
                  titleChange={this.handleReferenceTitleChange}
                  mediumEditorChange={this.handleBibliographicEditorChange}
                  addReference={this.addToCollection}
                  removeReference={this.removeFromCollection}
                  submit={this.handleSubmit}
                />
              </TabsPane>)}
          {!createMode
            && (
            <TabsPane
              name="#evaluationConfiguration"
              label="Configuración de la evaluación"
            >
              <ContentWrapper>
                {id && (
                  <Proration id={id} proration={proration} save={save} />
                )}
              </ContentWrapper>
            </TabsPane>)}
        </Tabs>
      </div>
    );
  }
}

Wrapper.propTypes = {
  save: PropTypes.func.isRequired,
  savePicture: PropTypes.func.isRequired,
  pictureId: PropTypes.string,
  notification: PropTypes.objectOf(PropTypes.any).isRequired,
  notificationUa: PropTypes.objectOf(PropTypes.any).isRequired,
  clearMessages: PropTypes.func.isRequired,
  clearUaMessage: PropTypes.func.isRequired,
  createMode: PropTypes.bool.isRequired,
  ufToUpdate: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  tab: PropTypes.string.isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  mfId: PropTypes.string.isRequired,
};

Wrapper.defaultProps = {
  ufToUpdate: {},
  pictureId: null,
};

export default Wrapper;
