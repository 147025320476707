import { api } from 'config';
import {
  UF_SUCCESS,
  UF_ERROR,
  UF_CLEAR_NOTIFICATION,
} from '../constants/actionTypes';

function handleStateUf(type, message, id) {
  return {
    type,
    message,
    id,
  };
}

function clearNotification() {
  return {
    type: UF_CLEAR_NOTIFICATION,
  };
}

function updateUf(data, id) {
  const token = localStorage.getItem('token') || null;
  const reqConfig = {
    method: 'PUT',
    headers: Object.assign({}, {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
    body: JSON.stringify(data),
  };

  return (dispatch) => {
    fetch(`${api.baseUrl}/api/ufs/${id}`, reqConfig)
      .then((response) => {
        if (response.status >= 400) {
          dispatch(handleStateUf(UF_ERROR, response.statusText));
        }
        return response.json();
      }).then((ev) => {
        dispatch(handleStateUf(UF_SUCCESS, 'Actualizada correctamente', ev._id));
      })
      .catch(error => console.log(`Error: ${error}`));
  };
}

function saveUf(data) {
  const token = localStorage.getItem('token') || null;
  const reqConfig = {
    method: 'POST',
    headers: Object.assign({}, {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
    body: JSON.stringify(data),
  };
  return (dispatch) => {
    fetch(`${api.baseUrl}/api/mfs/${data.mfId}/ufs/`, reqConfig)
      .then((response) => {
        if (response.status >= 400) {
          dispatch(handleStateUf(UF_ERROR, response.statusText));
        }
        return response.json();
      }).then((doc) => {
        dispatch(handleStateUf(UF_SUCCESS, 'Guardada correctamente', doc._id));
      })
      .catch(error => console.log(`Error: ${error}`));
  };
}

function save(data) {
  return (dispatch) => {
    if (!data) {
      return dispatch(handleStateUf(UF_ERROR, 'Debes completar el formulario de módulos'));
    }
    if (data.id) {
      const { id } = data;
      delete data.id;
      dispatch(updateUf(data, id));
    } else {
      dispatch(saveUf(data));
    }
  };
}

const fetchUfPromise = id => new Promise((resolve, reject) => {
  const token = localStorage.getItem('token') || null;
  const reqConfig = {
    method: 'GET',
    headers: Object.assign({}, {
      Authorization: `Bearer ${token}`,
    }),
  };
  fetch(`${api.baseUrl}/api/ufs/${id}`, reqConfig)
    .then((response) => {
      if (response.status >= 400) {
        return reject(response.statusText);
      }
      return resolve(response.json());
    });
});

async function fetchUf(id) {
  try {
    const ufData = await fetchUfPromise(id);
    return ufData;
  } catch (error) {
    return error;
  }
}

const fetchUfIndexPromise = id => new Promise((resolve, reject) => {
  const token = localStorage.getItem('token') || null;
  const reqConfig = {
    method: 'GET',
    headers: Object.assign({}, {
      Authorization: `Bearer ${token}`,
    }),
  };
  fetch(`${api.baseUrl}/api/ufs/${id}/index`, reqConfig)
    .then((response) => {
      if (response.status >= 400) {
        return reject(response.statusText);
      }
      return resolve(response.json());
    });
});

async function fetchUfIndex(id) {
  try {
    const ufData = await fetchUfIndexPromise(id);
    return ufData;
  } catch (error) {
    return error;
  }
}

const fetchAllUfsPromise = () => new Promise((resolve, reject) => {
  const token = localStorage.getItem('token') || null;
  const reqConfig = {
    method: 'GET',
    headers: Object.assign({}, {
      Authorization: `Bearer ${token}`,
    }),
  };
  fetch(`${api.baseUrl}/api/ufs/`, reqConfig)
    .then((response) => {
      if (response.status >= 400) {
        return reject(response.statusText);
      }
      return resolve(response.json());
    });
});

async function fetchAllUfs() {
  try {
    const ufData = await fetchAllUfsPromise();
    return ufData;
  } catch (error) {
    return error;
  }
}

function deleteUf(id) {
  const token = localStorage.getItem('token') || null;
  const reqConfig = {
    method: 'DELETE',
    headers: Object.assign({}, {
      Authorization: `Bearer ${token}`,
    }),
  };
  return (dispatch) => {
    fetch(`${api.baseUrl}/api/ufs/${id}`, reqConfig)
      .then((response) => {
        if (response.status >= 400) {
          dispatch(handleStateUf(UF_ERROR, response.statusText));
        } else {
          dispatch(handleStateUf(UF_SUCCESS, 'Eliminada la unidad formativa correctamente'));
        }
      })
      .catch(error => console.log(`Error: ${error}`));
  };
}

const fetchUfUasPromise = id => new Promise((resolve, reject) => {
  const token = localStorage.getItem('token') || null;
  const reqConfig = {
    method: 'GET',
    headers: Object.assign({}, {
      Authorization: `Bearer ${token}`,
    }),
  };
  fetch(`${api.baseUrl}/api/ufs/${id}/uas`, reqConfig)
    .then((response) => {
      if (response.status >= 400) {
        return reject(response.statusText);
      }
      return resolve(response.json());
    });
});

async function fetchUfUas(id) {
  try {
    const uasData = await fetchUfUasPromise(id);
    return uasData;
  } catch (error) {
    return error;
  }
}

const downloadScormPromise = ufId => new Promise((resolve, reject) => {
  const token = localStorage.getItem('token') || null;
  const reqConfig = {
    method: 'GET',
    headers: Object.assign({}, {
      Authorization: `Bearer ${token}`,
    }),
  };
  fetch(`${api.baseUrl}/api/scorm/ufdownload/${ufId}`, reqConfig)
    .then((response) => {
      if (response.status >= 400) {
        return reject(response.statusText);
      }
      return resolve(response.blob());
    });
});

async function downloadScorm(ufId) {
  try {
    const scormData = await downloadScormPromise(ufId);
    return scormData;
  } catch (error) {
    return error;
  }
}

const ufScreenCounterPromise = id => new Promise((resolve, reject) => {
  const token = localStorage.getItem('token') || null;
  const reqConfig = {
    method: 'GET',
    headers: Object.assign({}, {
      Authorization: `Bearer ${token}`,
    }),
  };
  fetch(`${api.baseUrl}/api/ufs/${id}/uasContentsScreenCounter`, reqConfig)
    .then((response) => {
      if (response.status >= 400) {
        return reject(response.statusText);
      }
      return resolve(response.json());
    });
});

async function ufScreenCounter(id) {
  try {
    const screensCounter = await ufScreenCounterPromise(id);
    return screensCounter;
  } catch (error) {
    return error;
  }
}

export {
  fetchAllUfs,
  fetchUf,
  fetchUfIndex,
  fetchUfUas,
  downloadScorm,
  clearNotification,
  ufScreenCounter,
  deleteUf,
  save,
};
