import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from 'client/ui/Button';

const Item = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  padding-left: 1rem;
  position: relative;
`;

const Title = styled.div`
  flex-grow: 1;
  text-align: left;
  align-self: center;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 1rem;
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const AccessButton = styled(Button)`
  width: 161px;
  min-width: 161px;
`;

const PageItemView = ({ id, title, index, colorMf }) => (
  <Item>
    <ContentWrapper>
      <Title>{index + 1}. {title}</Title>
      <AccessButton
        background={colorMf}
        to={`/viewContent/${id}`}
      >
        Ir a contenido
      </AccessButton>
    </ContentWrapper>
  </Item>
);

PageItemView.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  colorMf: PropTypes.string.isRequired,
};

PageItemView.defaultProps = {};

export default PageItemView;
