/* eslint no-unused-expressions: 0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { fetchModuleIndex } from 'client/modules/dashboard-mfs/actions/mfs';
import { fetchUfIndex } from 'client/modules/dashboard-ufs/actions/ufs';
import { fetchUaTopics } from 'client/modules/dashboard-topics/actions/topics';
import MfIndex from '../components/MfIndex';


async function loadUfs(id) {
  const data = await fetchUfIndex(id).then(ufData => ({
    _id: ufData._id,
    title: ufData.title,
    codeUf: ufData.codeUf,
    uas: ufData.uas,
  }));
  return data;
}

async function loadTopics(id) {
  const resp = { ua: id };
  const data = await fetchUaTopics(id).then((topics) => {
    const parsedData = topics.map(topic => ({ _id: topic._id, title: topic.title, ua: topic.ua }));
    return parsedData;
  });
  resp.topics = data;
  return resp;
}

class WrapperContainer extends Component {
  constructor() {
    super();
    this.state = {
      moduleIndex: [],
      haveUf: false,
    };
    this.loadData = this.loadData.bind(this);
  }

  componentWillMount() {
    const { mfId } = this.props;
    this.loadData(mfId);
  }

  loadData(moduleId) {
    fetchModuleIndex(moduleId).then((moduleData) => {
      const indice = {};
      indice.haveUf = moduleData.haveUf;
      if (moduleData.haveUf) {
        const { ufs } = moduleData;
        Promise.all(ufs.map((uf) => {
          const ufData = loadUfs(uf._id);
          return ufData;
        })).then((responseUfs) => {
          const responses = responseUfs.map((responseUf) => {
            const { uas } = responseUf;
            const parseUf = {
              ufTitle: responseUf.title,
              codeUf: responseUf.codeUf,
              id: responseUf._id,
            };
            const parsedUas = uas.map(ua => ({
              _id: ua._id,
              uaTitle: ua.title,
            }));
            Object.assign(parseUf, { uas: parsedUas });
            return parseUf;
          });
          Promise.all(
            responses.map(response => Promise.all(response.uas.map(ua => loadTopics(ua._id)))
              .then(respuestaFinal => respuestaFinal)),
          ).then((allTopics) => {
            const parsedData = [];
            responses.forEach((response) => {
              const data = {
                ufTitle: response.ufTitle,
                ufId: response.id,
                codeUf: response.codeUf,
              };
              const uas = [];
              response.uas.forEach((ua) => {
                const currentUa = { title: ua.uaTitle, id: ua._id };
                allTopics.forEach((topics) => {
                  const filterTopics = topics.filter(topic => topic.ua === ua._id);
                  if (filterTopics.length > 0) {
                    filterTopics.forEach((filterTopic) => {
                      const parsedTitles = filterTopic.topics.map(topic => topic.title);
                      Object.assign(currentUa, { topics: parsedTitles });
                    });
                  }
                });
                uas.push(currentUa);
              });
              data.uas = uas;
              parsedData.push(data);
            });
            this.setState({ moduleIndex: parsedData, haveUf: moduleData.haveUf });
          });
        });
      } else {
        const { uas } = moduleData;
        Promise.all(uas.map(ua => loadTopics(ua._id)))
          .then((respuesta) => {
            const parsedUas = [];
            uas.forEach((ua) => {
              const currentUa = { title: ua.title, id: ua._id };
              const filterTopic = respuesta.filter(topic => topic.ua === ua._id);
              if (filterTopic.length > 0) {
                filterTopic.forEach((topic) => {
                  const parsedTitles = [];
                  topic.topics.forEach((currentTopic) => {
                    parsedTitles.push(currentTopic.title);
                  });
                  currentUa.topics = parsedTitles;
                });
                parsedUas.push(currentUa);
              }
            });
            this.setState({ moduleIndex: parsedUas, haveUf: moduleData.haveUf });
          });
      }
    });
  }


  render() {
    const { moduleIndex, haveUf } = this.state;
    const { mfId, colorMf } = this.props;
    return (
      <MfIndex
        moduleIndex={moduleIndex}
        colorMf={colorMf}
        haveUf={haveUf}
        mfId={mfId}
      />
    );
  //  return null;
  }
}

WrapperContainer.propTypes = {
  mfId: PropTypes.string.isRequired,
  colorMf: PropTypes.string.isRequired,
};

WrapperContainer.defaultProps = {};

export default WrapperContainer;
