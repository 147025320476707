import { css } from 'styled-components';

const tdStyles = css`
  padding: 0 0.5rem;
  vertical-align: top;

  ${props => props.titleStyle && css`
    ${''/* background-color: ${props.theme.color.gray}; */}
    text-align: center;
    font-weight: bold;
  `}

  ${props => props.paddingNone && css`
    padding: 0;
  `}

  ${props => props.goldTitle && css`
    background-color: ${props.theme.color.gray};
    text-align: center;
    font-weight: bold;
    color: ${props.theme.color.yellowDark};
  `}

  ${props => props.nowrap && css`
    white-space: nowrap;
  `}

  ${props => props.noBG && css`
    background-color:transparent;
  `}

  ${props => props.whiteBG && css`
    background-color:white;
  `}
  ${props => props.grayLightBG && css`
    background-color:${props.theme.color.grayTable};
  `}

  ${props => props.yellowBG && css`
    background-color: ${props.theme.color.yellowLight};
  `}

  ${props => props.redBG && css`
    background-color:red;
  `}

  ${props => props.blueDarkBG && css`
    background-color: ${props.theme.color.blueDark};
  `}

  ${props => props.whiteText && css`
    color: ${props.theme.color.white};
  `}

  ${props => props.center && css`
    text-align:center;
  `}
  ${props => props.bold && css`
    font-weight: bold;
  `}

  ${props => props.maxWidth && css`
    max-width: ${props.maxWidth};
  `};

`;

export default tdStyles;
