/* eslint jsx-a11y/label-has-for: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledRadio = styled.label`
  display: inherit;
  align-items: center;
  font-size: 1rem;
  line-height: normal;
`;

const Radio = ({ name, value, children, checked, onChange, ...rest }) => (
  <StyledRadio>
    <input
      type="radio"
      name={name}
      value={value}
      checked={checked}
      onChange={onChange}
      {...rest}
    />
    {children}
  </StyledRadio>
);

Radio.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  children: PropTypes.node,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

Radio.defaultProps = {
  checked: false,
  onChange: null,
  children: null,
};

export default Radio;
