import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { getThemeColor } from '../style-utils';


const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  ${props => props.reverse === true && css`
    flex-direction: row-reverse;
    span {
      margin-right: .5rem;
    }
  `}
`;

const StyledSwitch = styled.div`
  border: 1px solid #ccc;
  width: 50px;
  height: 28px;
  border-radius: 15px;
  cursor: pointer;
  background: ${props => (props.on ? getThemeColor('primary') : '#E9EDF1')};
  display: inline-block;
`;

const Toggle = styled.div`
  border: 1px solid ${props => props.theme.color.white};
  box-shadow: 1px 1px 1px #ccc;
  width: 20px;
  height: 20px;
  left: 3px;
  top: 3px;
  border-radius: 12px;
  background: ${props => props.theme.color.white};
  position: relative;
  transition: left .2s ease-in-out;
  left: ${props => (props.on ? '24px' : '')}
`;

const StyledText = styled.span`
  margin-left: .5rem;
  color: ${props => props.theme.color.modalTitle};
`;

const ToggleSwitch = ({ on, onClick, text, reverse }) => (
  <StyledWrapper reverse={reverse}>
    <StyledSwitch onClick={onClick} on={on}>
      <Toggle on={on} />
    </StyledSwitch>
    <StyledText>{text}</StyledText>
  </StyledWrapper>
);

ToggleSwitch.propTypes = {
  on: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  reverse: PropTypes.bool,
};

ToggleSwitch.defaultProps = {
  reverse: false,
};

export default ToggleSwitch;
