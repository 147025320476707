import {
  UA_REQUEST,
  UA_SUCCESS,
  UA_ERROR,
  UA_CLEAR_NOTIFICATION,
} from '../constants/actionTypes';

const initialState = {
  isFetching: false,
  success: false,
  error: false,
  id: '',
};

export default function uas(state = initialState, action) {
  switch (action.type) {
    case UA_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      });

    case UA_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        success: true,
        message: action.message,
        id: action.id,
      });

    case UA_ERROR:
      return Object.assign({}, state, {
        isFetching: false,
      });

    case UA_CLEAR_NOTIFICATION:
      return {
        ...state,
        error: null,
        success: null,
      };
    default:
      return state;
  }
}
