/* eslint no-restricted-globals: 0, react/destructuring-assignment: 0,
react/no-access-state-in-setstate: 0, react/no-array-index-key: 0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import theme from 'client/config/theme';
import Divider from 'client/ui/Divider';
import Button from 'client/ui/Button';
import ButtonFa from 'client/ui/ButtonFa';
import CodeName from 'client/ui/CodeName';
import ButtonGroup from 'client/ui/ButtonGroup';
import IconFa from 'client/ui/IconFa';
import { Tabs, TabsPane, Tab } from 'client/ui/Tabs';
import SectionCircle from 'client/ui/SectionCircle';
import { H2 } from 'client/ui/Headings';
import { redirectTo } from 'index';
import ContentWrapper from 'client/ui/ContentWrapper';
import TopicList from 'client/modules/dashboard-topics/containers/topics';
import ViewProration from './ViewProration';

const CircleText = styled.div`
  font-size: 21px;
  font-weight: bold;
  color: white;
  position: absolute;
  top: 11px;
  right: 7px;
`;

const Header = styled.div`
  margin-bottom: .5rem;
`;

const Section = ContentWrapper.extend`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;
`;

const MfName = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: .25rem;
`;

const StyledSeparation = styled.div`
  width: 100%;
  min-width: 0;
  margin-right: 1rem;
`;

const DividerWrapper = styled.div`
  position: relative;
`;

const MfsDivider = Divider.extend`
  position: absolute;
  top: 0;
  left: 0;
`;

const UfsDivider = MfsDivider.extend`
  left: 25%;
`;
const UasDivider = MfsDivider.extend`
  left: 50%;
`;

const StyledReturn = styled.span`
  margin-right: .7rem;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
`;

const PrevFa = styled(IconFa)`
  margin-right: .5rem;
  ${props => props.color && css`
    color: ${props.color};
  `}
  ${props => props.doubleMargin && css`
    margin-right: .8rem;
  `}
`;

const PrevLink = (data) => {
  if (data.ufId !== null) {
    return (
      <StyledReturn onClick={() => redirectTo(`/viewUf/${data.ufId}#learningUnits`)}>
        <PrevFa name="chevron-left" doubleMargin />
        <PrevFa name="circle" color={data.colorUf} />
        <span>UF</span>
      </StyledReturn>
    );
  }
  if (data.mfId !== null) {
    return (
      <StyledReturn onClick={() => redirectTo(`/viewMf/${data.mfId}#learningUnits`)}>
        <PrevFa name="chevron-left" doubleMargin />
        <PrevFa name="circle" color={data.colorMf} />
        <span>MF</span>
      </StyledReturn>
    );
  }
  return null;
};

class Wrapper extends Component {
  constructor() {
    super();
    this.state = {};
    this.changeToEdit = this.changeToEdit.bind(this);
    this.handleTabClick = this.handleTabClick.bind(this);
    this.handleChangeTab = this.handleChangeTab.bind(this);
  }

  handleTabClick(tab) {
    this.handleChangeTab(tab);
  }

  handleChangeTab(tab) {
    const { history } = this.props;
    const path = { ...location, hash: tab };
    history.replace(path);
  }

  changeToEdit() {
    const { history, match, tab } = this.props;
    const { id } = match.params;
    const path = { ...location, hash: tab };
    path.name = `/dashboard/uas/edit/${id}`;
    path.pathname = `/dashboard/uas/edit/${id}`;
    history.replace(path);
  }

  render() {
    const { tab, mfId, ufId, uaToView } = this.props;
    const { id, title, colorMf, colorUf, colorUa, proration } = uaToView;
    return (
      <div>
        <DividerWrapper>
          <Divider borderWidth="2px" borderColor="grayLight" />
          <MfsDivider width="25%" borderWidth="2px" borderColor={colorMf} />
          <UfsDivider width="25%" borderWidth="2px" borderColor={colorUf} />
          <UasDivider width="25%" borderWidth="2px" borderColor={colorUa} />
        </DividerWrapper>
        <Header>
          <Section>
            <MfName>
              <SectionCircle size="42px" background={colorUa}>
                <CircleText>UA</CircleText>
              </SectionCircle>
            </MfName>
            <StyledSeparation>
              <CodeName>
                { title }
              </CodeName>
            </StyledSeparation>
            <Button
              hollow
              color="black"
              borderColor="black"
              onClick={() => this.changeToEdit()}
            >
              Editar
            </Button>
          </Section>
        </Header>
        <Divider
          borderWidth="2px"
          borderColor="grayLight"
          marginBottom="0.5rem"
          marginTop="1rem"
        />
        <Tabs
          tabsBarBorderColor="gray2"
          selected={tab}
          component={Tab}
          componentProps={{ color: colorMf }}
          onTabClick={currentTab => this.handleTabClick(currentTab)}
          tabsTitleMaxWidth={theme.maxWidth}
        >
          <TabsPane name="#mf" label="Descripción" component={PrevLink} componentProps={{ mfId, ufId, colorMf, colorUf }}>
            <div />
          </TabsPane>
          <TabsPane
            name="#unidad"
            label="Unidad de Aprendizaje"
          >
            <ContentWrapper>
              <H2>CONTENIDOS DE LA UNIDAD DE APRENDIZAJE</H2>
              <TopicList uaId={id} modeEdit={false} colorMf={colorMf} />
              <ButtonGroup align="left">
                {ufId !== null
                  && (
                  <ButtonFa
                    fa="fa-chevron-left"
                    side="left"
                    background={colorMf}
                    onClick={() => redirectTo(`/viewUf/${ufId}#learningUnits`)}
                  >
                    Volver a UF
                  </ButtonFa>)}
                {mfId !== null
                  && (
                  <ButtonFa
                    fa="fa-chevron-left"
                    side="left"
                    background={colorMf}
                    onClick={() => redirectTo(`/viewMf/${mfId}#learningUnits`)}
                  >
                    Volver a MF
                  </ButtonFa>)}
              </ButtonGroup>
            </ContentWrapper>
          </TabsPane>
          <TabsPane
            name="#evaluationConfiguration"
            label="Configuración de la evaluación"
          >
            <ContentWrapper>
              {id && (
                <ViewProration proration={proration} color={colorMf} />
              )}
            </ContentWrapper>
          </TabsPane>
        </Tabs>
      </div>
    );
  }
}

Wrapper.propTypes = {
  uaToView: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  tab: PropTypes.string.isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  mfId: PropTypes.string,
  ufId: PropTypes.string,
};

Wrapper.defaultProps = {
  uaToView: {},
  mfId: null,
  ufId: null,
};

export default Wrapper;
