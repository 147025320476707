/* eslint no-undef: 0 */
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';

const userIsAuthenticated = connectedRouterRedirect({
  // The url to redirect user to if they fail
  redirectPath: '/login',
  authenticatedSelector: state => state.auth.isAuthenticated,
  // A nice display name for this check
  wrapperDisplayName: 'UserIsAuthenticated',
});

const locationHelper = locationHelperBuilder({});

const redirectionHelper = () => {
  const authToken = localStorage.getItem('token');
  let redirection = '/dashboard/myMfs';

  if (authToken !== null) {
    const userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
    redirection = '/dashboard/myMfs';
    if (userInfo.roles.find(rol => rol.rid === 'ADMINISTRADORCENTRO')) {
      redirection = '/dashboard/centreRepository';
    }
    if (userInfo.roles.find(rol => rol.rid === 'ADMIN')) {
      redirection = '/dashboard';
    }
  }

  return redirection;
};

const userIsNotAuthenticated = connectedRouterRedirect({
  // This sends the user either to the query param route if we have one,
  // or to the landing page if none is specified and the user is already logged in
  redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || redirectionHelper(),
  // This prevents us from adding the query parameter when we send the user away from the login page
  allowRedirectBack: false,
  // If selector is true, wrapper will not redirect
  // So if there is no user data, then we show the page
  authenticatedSelector: state => state.auth.isAuthenticated === false,
  // A nice display name for this check
  wrapperDisplayName: 'UserIsNotAuthenticated',
});

export {
  userIsAuthenticated,
  userIsNotAuthenticated,
};
