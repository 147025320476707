import React from 'react';
import styled from 'styled-components';
import { H2 } from 'client/ui/Headings';

const StyledSectionTitle = styled.div`
  text-align: center;
  background-color: #CFD8DC;
  color: ${props => props.theme.color.black};
  padding: .5rem;
  text-transform: uppercase;
  margin-bottom: 2rem;
  h2 {
    margin-bottom: 0;
  }
`;

const SectionTitle = () => (
  <StyledSectionTitle><H2>Panel de administración Atlas</H2></StyledSectionTitle>
);

SectionTitle.propTypes = {};

SectionTitle.defaultProps = {};

export default SectionTitle;
