import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Input from 'client/ui/Input';

const TitleInput = styled(Input)`
  font-size: 1.375rem;
  margin-bottom: 0;
`;

const Title = ({ title, titleChange, id, ...rest }) => (
  <div {...rest}>
    <TitleInput
      placeholder="Título"
      value={title}
      onChange={(value) => { titleChange(id, value); }}
    />
  </div>
);

Title.propTypes = {
  id: PropTypes.string.isRequired,
  titleChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

Title.defaultProps = {};

export default Title;
