import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Alert from 'react-s-alert';
import Datatables from 'client/ui/Datatables';
import { H1, H2 } from 'client/ui/Headings';
import Notification from 'client/ui/Notification';
import ContentWrapper from 'client/ui/ContentWrapper';
import ModalConfirm from 'client/ui/Modal';
import Select from 'client/ui/Select';
import Label from 'client/ui/Label';

const notificationConfig = { position: 'top-right', effect: 'slide', timeout: 3000 };

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
`;

const StyledH1 = H1.extend`
  margin-bottom: 0;
`;

const NoResult = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
`;

const CloneSelect = styled(Select)`
  margin-bottom: 1rem;
`;

const CloneComponent = ({ parsedcentres, centre, handleSelectChange, users, user }) => (
  <div>
    <Label>
      <span>Centro</span>
      <CloneSelect
        isSearchable
        key="centreSelection"
        name="centre"
        options={parsedcentres}
        value={centre}
        onChange={(value) => { handleSelectChange('centre', value); }}
        valueKey="value"
        labelKey="label"
        placeholder="Seleccione centro"
        noResultsText="No se ha encontrado coincidencias"
        required
        medium
      />
    </Label>
    <Label>
      <span>Usuario</span>
      <CloneSelect
        isSearchable
        key="userSelection"
        name="user"
        options={users}
        value={user}
        onChange={(value) => { handleSelectChange('user', value); }}
        valueKey="value"
        labelKey="label"
        placeholder="Seleccione un usuario"
        noResultsText="No se ha encontrado coincidencias"
        required
        medium
      />
    </Label>
  </div>
);

CloneComponent.propTypes = {
  parsedcentres: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  users: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  centre: PropTypes.objectOf(PropTypes.any),
  user: PropTypes.objectOf(PropTypes.any),
  handleSelectChange: PropTypes.func.isRequired,
};

CloneComponent.defaultProps = {
  centre: {},
  user: {},
};

class Modules extends Component {
  constructor() {
    super();
    this.state = {};
    this.cloneReady = this.cloneReady.bind(this);
  }

  cloneReady() {
    const { user, centre, onCloneConfirm } = this.props;

    if (!centre) {
      Alert.error('Debe seleccionar un centro', notificationConfig);
      return false;
    }

    if (!user) {
      Alert.error('Debe seleccionar un usuario', notificationConfig);
      return false;
    }

    onCloneConfirm();
    return false;
  }

  render() {
    const { modules, notification, clearMessages, modalIsOpen, centres,
      hideModal, onConfirm, showModal, notFound, userPermissions, exportScorm,
      showValidateModal, validateModalIsOpen, onValidateConfirm, cancelValidate,
      datatableKey, cloneModalIsOpen, showCloneModal, centre,
      handleSelectChange, users, user } = this.props;

    const parsedcentres = centres.length ? centres.map(c => ({ value: c._id, label: c.name })) : [];
    const buttons = [];

    Object.keys(userPermissions).forEach((per) => {
      switch (per) {
        case 'READ':
          buttons.push({
            type: 'link',
            icon: 'fa-eye',
            classButton: 'basic',
            routeName: '/viewMf/',
            target: 'target="_blank"',
            tooltip: 'Leer',
            module: 'CENTREREPO',
          });
          break;
        case 'UPDATE':
          buttons.push({
            type: 'link',
            icon: 'fa-pencil-alt',
            classButton: 'basic',
            routeName: '/dashboard/mfsAdmin/',
            tooltip: 'Editar',
            module: 'CENTREREPO',
          });
          break;
        case 'EXPORT':
          buttons.push({
            type: 'button',
            icon: 'fas fa-download',
            classButton: 'basic',
            action: exportScorm,
            actionName: 'export',
            tooltip: 'Descargar scorm',
            module: 'CENTREREPO',
          });
          break;
        case 'DELETE':
          buttons.push({
            type: 'button',
            icon: 'fa-trash-alt',
            classButton: 'red',
            action: showModal,
            actionName: 'delete-module',
            tooltip: 'Eliminar',
            module: 'CENTREREPO',
          });
          break;
        case 'CLONE':
          buttons.push({
            type: 'button',
            icon: 'fas fa-copy',
            action: showCloneModal,
            actionName: 'clone-module',
            tooltip: 'Clonar',
            module: 'CENTREREPO',
          });
          break;
        case 'VALIDATE':
          buttons.push({
            type: 'selector',
            action: showValidateModal,
            actionName: 'validate-module',
            tooltip: 'Validar',
            module: 'CENTREREPO',
          });
          break;
        default: break;
      }
    });
    return (
      <ContentWrapper lateralPadding="2rem" maxWidth="100%">
        <StyledHeader>
          <StyledH1>Módulos de los centros</StyledH1>
        </StyledHeader>
        {/* Notificaciones */}
        <Notification
          message={notification}
          afterNotification={clearMessages}
        />
        {modules.length > 0
          ? (
            <div>
              <Datatables
                id="newsTable"
                key={datatableKey}
                headers={[
                  { name: 'Nombre' },
                  { name: 'Centro' },
                  { name: 'Autor' },
                  { name: 'Co-autor' },
                  { name: 'Estado' },
                  { name: 'Fecha modificación' },
                ]}
                rows={modules}
                buttons={buttons}
                config={{
                  dom: 'Bfrtip',
                  buttons: [
                    {
                      extend: 'excelHtml5',
                      title: 'Lista de modulos de los centros',
                      className: '',
                      exportOptions: { columns: ':visible' },
                    },
                    {
                      extend: 'colvis',
                      className: '',
                      collectionLayout: 'fixed',
                    },
                  ],
                  order: [[4, 'desc']],
                  columns: [
                    null,
                    { width: '20%' },
                    { width: '10%' },
                    { width: '105px' },
                    { width: '105px' },
                    { width: '10%' },
                    { width: '102px' },
                  ],
                }}
              />
            </div>)
          : (
            <NoResult>
              {notFound
                ? <H2>No se ha creado ningún módulo formativo en el centro</H2>
                : <H2>Cargando módulos formativos</H2>
              }
            </NoResult>
          )
        }

        <ModalConfirm
          isOpen={modalIsOpen}
          title="Eliminar"
          content="¿Desea eliminar el Módulo Formativo?"
          onCancel={hideModal}
          onConfirm={onConfirm}
        />

        <ModalConfirm
          isOpen={validateModalIsOpen}
          title="Cambiar estado MF"
          content="¿Desea cambiar el estado del Módulo Formativo?"
          onCancel={cancelValidate}
          onConfirm={onValidateConfirm}
        />

        <ModalConfirm
          isOpen={cloneModalIsOpen}
          title="Clonar MF"
          content={(
            <CloneComponent
              centre={centre}
              user={user}
              users={users}
              parsedcentres={parsedcentres}
              handleSelectChange={handleSelectChange}
            />)}
          onCancel={hideModal}
          onConfirm={this.cloneReady}
        />

      </ContentWrapper>
    );
  }
}

Modules.propTypes = {
  modules: PropTypes.arrayOf(PropTypes.array).isRequired,
  clearMessages: PropTypes.func.isRequired,
  notification: PropTypes.objectOf(PropTypes.any).isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  cancelValidate: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  notFound: PropTypes.bool.isRequired,
  exportScorm: PropTypes.func.isRequired,
  showValidateModal: PropTypes.func.isRequired,
  showCloneModal: PropTypes.func.isRequired,
  validateModalIsOpen: PropTypes.bool.isRequired,
  cloneModalIsOpen: PropTypes.bool.isRequired,
  onCloneConfirm: PropTypes.func.isRequired,
  onValidateConfirm: PropTypes.func.isRequired,
  userPermissions: PropTypes.objectOf(PropTypes.any).isRequired,
  datatableKey: PropTypes.string.isRequired,
  centres: PropTypes.arrayOf(PropTypes.any).isRequired,
  centre: PropTypes.objectOf(PropTypes.any),
  handleSelectChange: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  user: PropTypes.objectOf(PropTypes.any),
};

Modules.defaultProps = {
  centre: null,
  user: null,
};

export default Modules;
