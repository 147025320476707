import { api } from 'config';

function updatePage(data, id) {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem('token') || null;
    const reqConfig = {
      method: 'PUT',
      headers: Object.assign({}, {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
      body: JSON.stringify(data),
    };
    fetch(`${api.baseUrl}/api/pages/${id}`, reqConfig)
      .then((response) => {
        if (response.status >= 400) {
          return reject(response.statusText);
        }
        return resolve(response.json());
      });
  });
}

function createPage(data) {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem('token') || null;
    const reqConfig = {
      method: 'POST',
      headers: Object.assign({}, {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
      body: JSON.stringify(data),
    };
    fetch(`${api.baseUrl}/api/topics/${data.topic}/pages/`, reqConfig)
      .then((response) => {
        if (response.status >= 400) {
          return reject(response.statusText);
        }
        return resolve(response.json());
      });
  });
}

async function savePage(data) {
  try {
    if (data.id) {
      const { id } = data;
      delete data.id;
      const pageData = await updatePage(data, id);
      return pageData;
    }
    const pageData = await createPage(data);
    return pageData;
  } catch (error) {
    return error;
  }
}

const fetchPagePromise = id => new Promise((resolve, reject) => {
  const token = localStorage.getItem('token') || null;
  const reqConfig = {
    method: 'GET',
    headers: Object.assign({}, {
      Authorization: `Bearer ${token}`,
    }),
  };
  fetch(`${api.baseUrl}/api/pages/${id}`, reqConfig)
    .then((response) => {
      if (response.status >= 400) {
        return reject(response.statusText);
      }
      return resolve(response.json());
    });
});

async function fetchPage(id) {
  try {
    const pageData = await fetchPagePromise(id);
    return pageData;
  } catch (error) {
    return error;
  }
}

const fetchTopicPagesPromise = id => new Promise((resolve, reject) => {
  const token = localStorage.getItem('token') || null;
  const reqConfig = {
    method: 'GET',
    headers: Object.assign({}, {
      Authorization: `Bearer ${token}`,
    }),
  };
  fetch(`${api.baseUrl}/api/topics/${id}/pages/`, reqConfig)
    .then((response) => {
      if (response.status >= 400) {
        return reject(response.statusText);
      }
      return resolve(response.json());
    });
});

async function fetchTopicPages(topicId) {
  try {
    const pageData = await fetchTopicPagesPromise(topicId);
    return pageData;
  } catch (error) {
    return error;
  }
}

function removePagePromise(id) {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem('token') || null;
    const reqConfig = {
      method: 'DELETE',
      headers: Object.assign({}, {
        Authorization: `Bearer ${token}`,
      }),
    };
    fetch(`${api.baseUrl}/api/pages/${id}`, reqConfig)
      .then((response) => {
        if (response.status >= 400) {
          return reject(response.statusText);
        }
        return resolve(response.json());
      });
  });
}

async function deletePage(pageId) {
  try {
    const page = await removePagePromise(pageId);
    return page;
  } catch (error) {
    return error;
  }
}

export {
  fetchPage,
  fetchTopicPages,
  deletePage,
  savePage,
};
