import {
  CENTRE_REQUEST,
  CENTRE_SUCCESS,
  CENTRE_ERROR,
  CENTRES_REQUEST,
  CENTRES_SUCCESS,
  CENTRES_ERROR,
  CENTRE_CLEAR_NOTIFICATION,
} from '../constants/actionTypes';

const initialState = {
  isFetching: false,
  success: false,
  error: false,
  centres: [],
};

export default function centres(state = initialState, action) {
  switch (action.type) {
    case CENTRE_REQUEST:
    case CENTRES_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      });

    case CENTRE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        success: true,
        message: action.message,
      });

    case CENTRES_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        centres: action.payload,
      });

    case CENTRES_ERROR:
    case CENTRE_ERROR:
      return Object.assign({}, state, {
        isFetching: false,
      });

    case CENTRE_CLEAR_NOTIFICATION:
      return {
        ...state,
        error: null,
        success: null,
      };
    default:
      return state;
  }
}
