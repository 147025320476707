/* eslint no-undef: 0, import/prefer-default-export: 0 */
import { CALL_API } from 'redux-api-middleware';
import { api } from 'config';

import {
  ROLES_REQUEST,
  ROLES_SUCCESS,
  ROLES_ERROR,
} from '../constants/actionTypes';

export function fetchRoles() {
  const token = localStorage.getItem('token') || null;
  return {
    [CALL_API]: {
      types: [ROLES_REQUEST, ROLES_SUCCESS, ROLES_ERROR],
      endpoint: `${api.baseUrl}/api/roles/`,
      method: 'GET',
      headers: Object.assign({}, {
        Authorization: `Bearer ${token}`,
      }),
    },
  };
}
