import {
  ROLES_REQUEST,
  ROLES_SUCCESS,
  ROLES_ERROR,
  PERMISSIONS_REQUEST,
  PERMISSIONS_SUCCESS,
  PERMISSIONS_ERROR,
  MODULES_REQUEST,
  MODULES_SUCCESS,
  MODULES_ERROR,
  TOOGLE_NAVIGATION,
} from '../constants/actionTypes';

const initialState = {
  isFetching: false,
  roles: [],
  permissions: [],
  modules: [],
  opened: null,
};

export default function common(state = initialState, action) {
  switch (action.type) {
    case ROLES_REQUEST:
    case PERMISSIONS_REQUEST:
    case MODULES_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case ROLES_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        roles: action.payload,
      });
    case PERMISSIONS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        permissions: action.payload,
      });
    case MODULES_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        modules: action.payload,
      });
    case ROLES_ERROR:
    case PERMISSIONS_ERROR:
    case MODULES_ERROR:
      return Object.assign({}, state, {
        isFetching: false,
      });
    case TOOGLE_NAVIGATION: {
      // const newArray = state.opened;
      // newArray[action.id - 1] = action.open;
      return Object.assign({}, state, {
        opened: action.id,
      });
    }
    default:
      return state;
  }
}
