import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { H2 } from 'client/ui/Headings';
import Button from 'client/ui/Button';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import ContentWrapper from 'client/ui/ContentWrapper';
import Notification from 'client/ui/Notification';
import ModalConfirm from 'client/ui/Modal';
import ListItem from './ListItem';
import ListItemView from './ListItemView';

const ListWrapper = styled.div`
  margin-top: 1rem;
`;

const ButtonWrapper = styled.div`
  text-align: center;
`;

const NoResult = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
`;

const SortableItem = SortableElement(({ title, codeUf, id, uf, onRemove,
  userPermissions, exportScorm, number, picture, screens }) => (
    <ListItem
      id={id}
      index={number + 1}
      title={title}
      codeUf={codeUf}
      screens={screens}
      onRemove={onRemove}
      uf={uf}
      picture={picture}
      canEdit={userPermissions.UPDATE}
      canDelete={userPermissions.DELETE}
      canExport={userPermissions.EXPORT}
      exportScorm={exportScorm}
    />
));

const SortableList = SortableContainer(({ items, showModal, exportScorm,
  userPermissions, uf, colorMf }) => (
    <div>
      {items.map((item, index) => (
        <SortableItem
          key={`elemento-${index}`}
          id={item._id}
          uf={uf}
          title={item.title}
          screens={item.screens}
          codeUf={item.codeUf ? item.codeUf : ''}
          picture={item.coverPicture ? item.coverPicture.src : ''}
          index={index}
          number={index}
          onRemove={showModal}
          userPermissions={userPermissions}
          exportScorm={exportScorm}
          colorMf={colorMf}
        />
      ))}
    </div>
));

const List = ({ uf, items, notification, clearMessages, modalIsOpen,
  hideModal, onConfirm, showModal, userPermissions, type, exportScorm, modeEdit, notFound,
  onSortEnd, colorMf, colorUf, colorUa }) => {
  const link = uf ? '/dashboard/ufs/' : `/dashboard/uas/${type}`;
  if (modeEdit) {
    return (
      <ContentWrapper marginTop="2rem">
        <Notification
          message={notification}
          afterNotification={clearMessages}
        />
        <H2>PANEL DE UNIDADES {uf ? ' FORMATIVAS' : ' DE APRENDIZAJE'}</H2>
        <ButtonWrapper>
          <Button to={link}>Añadir unidad {uf ? ' FORMATIVA' : ' DE APRENDIZAJE'}</Button>
        </ButtonWrapper>
        <ListWrapper>
          {items.length > 0
            && (
              <SortableList
                lockAxis="y"
                useDragHandle
                uf={uf}
                onSortEnd={onSortEnd}
                items={items}
                showModal={showModal}
                onRemove={showModal}
                userPermissions={userPermissions}
                exportScorm={exportScorm}
                colorMf={colorMf}
              />)}
        </ListWrapper>
        <ModalConfirm
          isOpen={modalIsOpen}
          title="Eliminar"
          content="¿Desea eliminar"
          onCancel={hideModal}
          onConfirm={onConfirm}
        />
      </ContentWrapper>
    );
  }

  return (
    <ContentWrapper marginTop="2rem">
      <ListWrapper>
        {items.length > 0
          && (
            <div>{items.map((item, index) => (
              <ListItemView
                key={`List-${item._id}`}
                title={item.title}
                screens={item.screens}
                codeUf={item.codeUf ? item.codeUf : ''}
                picture={item.coverPicture ? item.coverPicture.src : ''}
                index={index}
                id={item._id}
                colorMf={colorMf}
                colorUf={colorUf}
                colorUa={colorUa}
                uf={uf}
              />
            ))}
            </div>)}
        <NoResult>
          {notFound && <H2>No hay nada que mostrar</H2> }
        </NoResult>
      </ListWrapper>
    </ContentWrapper>
  );
};

List.propTypes = {
  uf: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  type: PropTypes.string.isRequired,
  clearMessages: PropTypes.func.isRequired,
  notification: PropTypes.objectOf(PropTypes.any).isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onSortEnd: PropTypes.func.isRequired,
  exportScorm: PropTypes.func.isRequired,
  modeEdit: PropTypes.bool.isRequired,
  userPermissions: PropTypes.objectOf(PropTypes.any).isRequired,
  notFound: PropTypes.bool.isRequired,
  colorMf: PropTypes.string.isRequired,
  colorUf: PropTypes.string.isRequired,
  colorUa: PropTypes.string.isRequired,
};

List.defaultProps = {};

export default List;
