/* eslint react/no-array-index-key: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { H2 } from 'client/ui/Headings';
import Label from 'client/ui/Label';
import Span from 'client/ui/Span';
import Input from 'client/ui/Input';
import Button from 'client/ui/Button';
import ButtonFa from 'client/ui/ButtonFa';
import ButtonGroup from 'client/ui/ButtonGroup';
import MediumEditor from 'client/ui/MediumEditor';
import FormWrapper from 'client/ui/FormWrapper';
import { mediumEditorMedium } from 'client/modules/dashboard/utils/medium-editor';

const Wrapper = styled.div`
  margin-bottom: 1rem;
`;

const RemoveIcon = styled.div`
  color: red;
  position: absolute;
  top: 2px;
  right: 10px;
  button {
    text-transform: none;
  }
`;
const ReferenceWrapper = styled.div`
  position: relative;
`;

const Form = ({ moduleToUpdate, submit, titleChange, addReference, removeReference,
  mediumEditorChange }) => {
  const { bibliographicReferences } = moduleToUpdate;
  return (
    <FormWrapper>
      <H2>REFERENCIAS BIBLIOGRÁFICAS</H2>
      <form onSubmit={submit}>
        <Wrapper>
          {bibliographicReferences && bibliographicReferences.length === 1
            && bibliographicReferences.map((reference, index) => (
              <ReferenceWrapper key={`reference ${index}`}>
                <Label>
                  <Span spanForm>Título</Span>
                  <Input
                    type="text"
                    name={`reference-${index}`}
                    value={reference.title}
                    onChange={(value) => { titleChange(index, value); }}
                  />
                </Label>
                <Label>
                  <Span spanForm>Texto</Span>
                  <MediumEditor
                    text={reference.text}
                    onChange={(value) => { mediumEditorChange(index, value, 'introduction'); }}
                    options={{
                      toolbar: { buttons: mediumEditorMedium },
                      placeholder: false,
                    }}
                  />
                </Label>
              </ReferenceWrapper>
            ))
          }
          {bibliographicReferences && bibliographicReferences.length > 1
            && bibliographicReferences.map((reference, index) => (
              <ReferenceWrapper key={`reference ${index}`}>
                <Label>
                  <Span spanForm>Título</Span>
                  <Input
                    type="text"
                    name={`reference-${index}`}
                    value={reference.title}
                    onChange={(value) => { titleChange(index, value); }}
                    required
                  />
                  <RemoveIcon>
                    <ButtonFa
                      fa="fa-trash-alt"
                      transparent
                      color="red"
                      onClick={() => removeReference('bibliographicReferences', index)}
                    >
                      Eliminar
                    </ButtonFa>
                  </RemoveIcon>
                </Label>
                <Label>
                  <Span spanForm>Texto</Span>
                  <MediumEditor
                    text={reference.text}
                    onChange={(value) => { mediumEditorChange(index, value, 'introduction'); }}
                    options={{
                      toolbar: { buttons: mediumEditorMedium },
                      placeholder: false,
                    }}
                  />
                </Label>
              </ReferenceWrapper>
            ))}
          <Button onClick={() => addReference('bibliographicReferences')}>Añadir</Button>
        </Wrapper>
        <ButtonGroup align="right">
          <Button type="submit">Guardar</Button>
        </ButtonGroup>
      </form>
    </FormWrapper>
  );
};

Form.propTypes = {
  moduleToUpdate: PropTypes.objectOf(PropTypes.any).isRequired,
  titleChange: PropTypes.func.isRequired,
  addReference: PropTypes.func.isRequired,
  removeReference: PropTypes.func.isRequired,
  mediumEditorChange: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
};

Form.defaultProps = {};

export default Form;
