/* eslint react/no-unused-prop-types: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import StyledA from './StyledA';
import StyledLink from './StyledLink';
import StyledButton from './StyledButton';

/**
 * Renders an A if href is specified, renders a Link if to is specified, otherwise renders a Button
 */
const Button = ({ to, href, type, ...rest }) => {
  if (to) {
    return <StyledLink to={to} {...rest} />;
  }
  if (href) {
    return <StyledA href={href} {...rest} />;
  }
  return <StyledButton type={type} {...rest} />;
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  type: PropTypes.string,
  active: PropTypes.bool,
  hollow: PropTypes.bool,
  raised: PropTypes.bool,
  transparent: PropTypes.bool,
  background: PropTypes.string,
  color: PropTypes.string,
  borderColor: PropTypes.string,
  textTransform: PropTypes.string,
};

Button.defaultProps = {
  href: null,
  to: null,
  type: 'button',
  active: false,
  hollow: false,
  raised: false,
  transparent: false,
  background: 'darkGray',
  color: 'white',
  borderColor: null,
  textTransform: 'uppercase',
};

export default Button;
