import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Fieldset from 'client/ui/Fieldset';
import Label from 'client/ui/Label';
import Input from 'client/ui/Input';
import { H4 } from 'client/ui/Headings';
import Button from 'client/ui/Button';
import ButtonGroup from 'client/ui/ButtonGroup';
import ModalConfirm from 'client/ui/Modal';
import Notification from 'client/ui/Notification';

const Wrapper = styled.div`
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
`;

class Form extends Component {
  constructor() {
    super();

    this.state = {
      id: '',
      label: '',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.afterNotification = this.afterNotification.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { roleToUpdate } = nextProps;
    if (roleToUpdate && roleToUpdate._id) {
      const { _id, label } = roleToUpdate;
      const id = _id;
      this.setState({
        id,
        label,
      });
    }
  }

  handleInputChange(event) {
    const { target } = event;
    const { name, value } = target;
    this.setState({ [name]: value });
  }

  handleSubmit(event) {
    event.preventDefault();
    const { id, label } = this.state;
    const { save, createMode } = this.props;
    const rid = label.toUpperCase().replace(/\s/g, '');
    const data = {
      rid,
      label,
    };

    if (!createMode) {
      Object.assign(data, { id });
    }
    save(data);
  }

  afterNotification() {
    const { notification, clearNotification, history } = this.props;
    clearNotification();
    if (notification.type === 'success') {
      setTimeout(() => {
        history.replace('/dashboard/roles');
      }, 1000);
    }
  }

  render() {
    const { label } = this.state;
    const { createMode, notification, showModal, hideModal, onConfirm,
      modalIsOpen, modalMessage } = this.props;
    return (
      <Wrapper>
        <Notification
          message={notification}
          afterNotification={this.afterNotification}
        />
        {createMode
          ? <H4>Creación de rol</H4>
          : <H4>Edición de rol</H4>
        }
        <form onSubmit={this.handleSubmit}>
          <Fieldset>
            {/* Nombre */}
            <Label>
              <span>Nombre</span>
              <Input
                type="text"
                name="label"
                value={label}
                onChange={this.handleInputChange}
                required
              />
            </Label>
          </Fieldset>
          <ButtonGroup align="right">
            <Button background="#D7D5D8" onClick={() => showModal()}>Cancelar</Button>
            <Button type="submit">Guardar</Button>
          </ButtonGroup>
        </form>
        <ModalConfirm
          isOpen={modalIsOpen}
          title="Cancelar"
          content={modalMessage}
          onCancel={hideModal}
          onConfirm={onConfirm}
        />
      </Wrapper>
    );
  }
}

Form.propTypes = {
  roleToUpdate: PropTypes.objectOf(PropTypes.any),
  createMode: PropTypes.bool.isRequired,
  save: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  clearNotification: PropTypes.func.isRequired,
  notification: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  modalMessage: PropTypes.string.isRequired,
};

Form.defaultProps = {
  roleToUpdate: {},
};

export default Form;
