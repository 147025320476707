/* eslint no-unused-expressions: 0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { fetchUfIndex } from 'client/modules/dashboard-ufs/actions/ufs';
import { fetchUaTopics } from 'client/modules/dashboard-topics/actions/topics';
import UfIndex from '../components/UfIndex';

async function loadTopics(id) {
  const resp = { ua: id };
  const data = await fetchUaTopics(id).then((topics) => {
    const parsedData = topics.map(topic => ({ _id: topic._id, title: topic.title, ua: topic.ua }));
    return parsedData;
  });
  resp.topics = data;
  return resp;
}

class WrapperContainer extends Component {
  constructor() {
    super();
    this.state = {
      ufIndex: [],
    };
    this.loadData = this.loadData.bind(this);
  }

  componentWillMount() {
    const { ufId } = this.props;
    this.loadData(ufId);
  }

  loadData() {
    const { ufId } = this.props;
    fetchUfIndex(ufId).then((ufData) => {
      const { uas } = ufData;
      Promise.all(uas.map(ua => loadTopics(ua._id)))
        .then((respuesta) => {
          const parsedUas = [];
          uas.forEach((ua) => {
            const currentUa = { title: ua.title, id: ua._id };
            const filterTopic = respuesta.filter(topic => topic.ua === ua._id);
            if (filterTopic.length > 0) {
              filterTopic.forEach((topic) => {
                const parsedTitles = [];
                topic.topics.forEach((currentTopic) => {
                  parsedTitles.push(currentTopic.title);
                });
                currentUa.topics = parsedTitles;
              });
              parsedUas.push(currentUa);
            }
          });
          this.setState({ ufIndex: parsedUas });
        });
    });
  }

  render() {
    const { ufIndex } = this.state;
    const { ufId, colorMf } = this.props;
    return (
      <UfIndex
        ufIndex={ufIndex}
        colorMf={colorMf}
        ufId={ufId}
      />
    );
  }
}

WrapperContainer.propTypes = {
  ufId: PropTypes.string.isRequired,
  colorMf: PropTypes.string.isRequired,
};

WrapperContainer.defaultProps = {};

export default WrapperContainer;
