import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { api } from 'config';
import { connect } from 'react-redux';
import saveScormFile from 'client/modules/core/utils/downloadScorm';
import { arrayMove } from 'react-sortable-hoc';
import { getRoleModuleActions } from 'client/modules/dashboard-permissions/utils/modules-actions';
import { deleteUf, clearNotification, fetchUfUas, downloadScorm, save as saveUnit, ufScreenCounter } from 'client/modules/dashboard-ufs/actions/ufs';
import { deleteUa, uaScreenCounter } from 'client/modules/dashboard-uas/actions/uas';
import { fetchModuleUfs, fetchModuleUas, save } from '../actions/mfs';
import List from '../components/List';

class ListContainer extends Component {
  constructor() {
    super();
    this.state = {
      items: [],
      modalIsOpen: false,
      itemId: '',
      userPermissions: {},
      notFound: false,
      type: '',
    };
    this.loadUfs = this.loadUfs.bind(this);
    this.loadUas = this.loadUas.bind(this);
    this.loadUfUas = this.loadUfUas.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.showModal = this.showModal.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.exportScorm = this.exportScorm.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
  }

  componentWillMount() {
    const { haveUf, permissions, typeMf } = this.props;
    const userPermissions = getRoleModuleActions(permissions, 'MODULES');
    this.setState({ userPermissions });
    if (haveUf && typeMf) {
      this.loadUfs();
    } else if (typeMf) {
      this.loadUas();
    } else {
      this.loadUfUas();
    }
  }

  componentWillReceiveProps(nextProps) {
    const { haveUf, permissions, typeMf } = nextProps;
    const userPermissions = getRoleModuleActions(permissions, 'CENTRES');
    this.setState({ userPermissions });
    if (haveUf && typeMf) {
      this.loadUfs();
    } else if (typeMf) {
      this.loadUas();
    } else {
      this.loadUfUas();
    }
  }

  onConfirm() {
    const { itemId } = this.state;
    const { removeUf, removeUa, haveUf } = this.props;
    this.hideModal();
    if (haveUf) {
      removeUf(itemId);
    } else {
      removeUa(itemId);
    }
  }

  onSortEnd({ oldIndex, newIndex }) {
    const { items } = this.state;
    const { haveUf, typeMf, saveMf, saveUf } = this.props;
    const mfId = localStorage.getItem('mfId');
    const ufId = localStorage.getItem('ufId');
    const newOrder = arrayMove(items, oldIndex, newIndex);
    const newOrderIds = newOrder.map(item => item._id);
    this.setState({ items: newOrder });

    if (haveUf && typeMf) {
      const data = {
        id: mfId,
        ufs: newOrderIds,
      };
      saveMf(data);
    } else if (typeMf) {
      const data = {
        id: mfId,
        uas: newOrderIds,
      };
      saveMf(data);
    } else {
      const data = {
        id: ufId,
        uas: newOrderIds,
      };
      saveUf(data);
    }
  }

  showModal(itemId) {
    if (itemId) {
      this.setState({ modalIsOpen: true, itemId });
    }
  }

  hideModal() {
    this.setState({ modalIsOpen: false });
  }

  loadUfs() {
    const mfId = localStorage.getItem('mfId');
    fetchModuleUfs(mfId).then((ufsData) => {
      if (ufsData.length === 0) {
        this.setState({ notFound: true });
      }

      Promise.all(ufsData.map((uf) => {
        const screensCounter = ufScreenCounter(uf._id);
        return screensCounter;
      })).then((responseUfs) => {
        const items = ufsData.map((uf) => {
          const screens = responseUfs.find((screen => screen.id === uf._id));
          if (screens) {
            Object.assign(uf, { screens: screens.counter });
          }
          const current = uf;
          if (uf.coverPicture && uf.coverPicture.url) {
            current.coverPicture.src = `${api.baseUrl}${uf.coverPicture.url.s}`;
          } else {
            current.coverPicture = {};
            current.coverPicture.src = '/images/contents/picture.jpg';
          }
          return current;
        });
        this.setState({ items });
      });
    });
  }

  loadUas() {
    const mfId = localStorage.getItem('mfId');
    fetchModuleUas(mfId).then((uasData) => {
      if (uasData.length === 0) {
        this.setState({ notFound: true });
      }

      Promise.all(uasData.map((ua) => {
        const screensCounter = uaScreenCounter(ua._id);
        return screensCounter;
      })).then((responseUas) => {
        const items = uasData.map((ua) => {
          const screens = responseUas.find((screen => screen.id === ua._id));
          if (screens) {
            Object.assign(ua, { screens: screens.counter });
          }
          return ua;
        });
        this.setState({ items, type: 'mfs' });
      });
    });
  }

  loadUfUas() {
    const ufId = localStorage.getItem('ufId');
    fetchUfUas(ufId).then((uasData) => {
      if (uasData.length === 0) {
        this.setState({ notFound: true });
      }
      Promise.all(uasData.map((ua) => {
        const screensCounter = uaScreenCounter(ua._id);
        return screensCounter;
      })).then((responseUas) => {
        const items = uasData.map((ua) => {
          const screens = responseUas.find((screen => screen.id === ua._id));
          if (screens) {
            Object.assign(ua, { screens: screens.counter });
          }
          return ua;
        });
        this.setState({ items, type: 'ufs' });
      });
    });
  }

  exportScorm(ufId) {
    const { haveUf } = this.props;
    const { items } = this.state;
    if (haveUf) {
      const uf = items.filter(item => item._id === ufId);
      downloadScorm(ufId).then((response) => {
        saveScormFile(response, uf[0].title);
      });
    }
  }

  render() {
    const { notification, clearMessages, haveUf, modeEdit, colorMf, colorUf, colorUa } = this.props;
    const { items, modalIsOpen, userPermissions, type, notFound } = this.state;
    return (
      <List
        notification={notification}
        clearMessages={clearMessages}
        modalIsOpen={modalIsOpen}
        showModal={this.showModal}
        hideModal={this.hideModal}
        onConfirm={this.onConfirm}
        onSortEnd={this.onSortEnd}
        userPermissions={userPermissions}
        exportScorm={this.exportScorm}
        type={type}
        uf={haveUf}
        items={items}
        modeEdit={modeEdit}
        notFound={notFound}
        colorMf={colorMf}
        colorUf={colorUf}
        colorUa={colorUa}
      />
    );
  }
}

ListContainer.propTypes = {
  clearMessages: PropTypes.func.isRequired,
  removeUf: PropTypes.func.isRequired,
  removeUa: PropTypes.func.isRequired,
  notification: PropTypes.objectOf(PropTypes.any).isRequired,
  permissions: PropTypes.arrayOf(PropTypes.object).isRequired,
  haveUf: PropTypes.bool.isRequired,
  typeMf: PropTypes.bool.isRequired,
  modeEdit: PropTypes.bool.isRequired,
  saveMf: PropTypes.func.isRequired,
  saveUf: PropTypes.func.isRequired,
  colorMf: PropTypes.string,
  colorUf: PropTypes.string,
  colorUa: PropTypes.string,
};

ListContainer.defaultProps = {
  colorMf: '',
  colorUf: '',
  colorUa: '',
};

const mapStateToProps = (state) => {
  const { error, success, message, id } = state.ufs;
  const notification = {};

  if (error) {
    notification.type = 'error';
    notification.content = message;
  } else if (success) {
    notification.type = 'success';
    notification.content = message;
    notification.id = id;
  }

  return {
    notification,
    permissions: state.permissions.userPermissions,
  };
};

const mapDispatchToProps = dispatch => ({
  clearMessages: () => { dispatch(clearNotification()); },
  removeUf: (itemId) => { dispatch(deleteUf(itemId)); },
  removeUa: (itemId) => { dispatch(deleteUa(itemId)); },
  saveMf: (data) => { dispatch(save(data)); },
  saveUf: (data) => { dispatch(saveUnit(data)); },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ListContainer);
