import styled, { css } from 'styled-components';

const StyledTab = styled.div`
  display: inline-block;
  padding: 1em;
  font-size: inherit;
  line-height: 1;
  background: white;
  border: 1px solid ${props => props.theme.color.grayTable};
  border-radius: 0;
  outline: 0;
  cursor: pointer;
  color: ${props => props.theme.color.gray};
  -webkit-appearance: none;

  ${''/* Tab activa */}
  ${props => props.active && props.color && css`
    background: ${props.theme.color.grayLight};
    color: ${props.theme.color.modalTitle}
    border-bottom: 1px solid ${props.theme.color.grayTable};
  `};
  ${props => props.width && css`
    width: ${props.width};
    font-weight: bold;
  `};
`;

export default StyledTab;
