/* eslint no-param-reassign: 0 max-len: 0 */
import { combineReducers } from 'redux';
import common from 'client/modules/core/reducers/common';
import auth from 'client/modules/accounts/reducers/auth';
import centres from 'client/modules/dashboard-centres/reducers/centres';
import users from 'client/modules/dashboard-users/reducers/users';
import roles from 'client/modules/dashboard-roles/reducers/roles';
import permissions from 'client/modules/dashboard-permissions/reducers/permissions';
import mfs from 'client/modules/dashboard-mfs/reducers/modules';
import ufs from 'client/modules/dashboard-ufs/reducers/ufs';
import uas from 'client/modules/dashboard-uas/reducers/uas';
import topics from 'client/modules/dashboard-topics/reducers/topics';
import { LOGOUT } from 'client/modules/accounts/constants/actionTypes';

/**
 * Devuelve un objeto cuyos valores son diferentes funciones reducer en una única función reducer
 * que puedes enviar a createStore.
 * El reducer resultante llama a cada reducer interno y une sus resultados en un único objeto de estado.
 * La forma del objeto de estado es igual a las claves enviadas a reducers.
 * @see http://redux.js.org/docs/api/combineReducers.html
 */
const appReducer = combineReducers({
  // routing: routerReducer,
  common,
  auth,
  centres,
  permissions,
  users,
  roles,
  mfs,
  ufs,
  uas,
  topics,
});

/**
 * Si el tipo de acción es 'LOGOUT' resetea el Store al estado inicial,
 * en otro caso le pasa el state y el action al appReducer
 * @see http://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
 */

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
