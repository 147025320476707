import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

/**
 * Contenedor para un grupo de botones
 * Nota: uso los selectores `a` y `button` en vez de `StyledA`, `StyledLink`, `StyledButton`
 * ya que los selectores de componente no se integrarán hasta la versión 2...
 * @see https://github.com/styled-components/styled-components/issues/142
 */
const ButtonGroup = styled.div`
  text-align: ${props => props.align};

  ${props => ['left', 'center'].includes(props.align) && css`
    a:not(:last-child),
    button:not(:last-child) {
      margin-right: 0.5rem;
    }
  `};

  ${props => props.align === 'right' && css`
    a:not(:first-child),
    button:not(:first-child) {
      margin-left: 0.5rem;
    }
  `};
`;

ButtonGroup.propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'right']),
};

ButtonGroup.defaultProps = {
  align: 'left',
};

export default ButtonGroup;
