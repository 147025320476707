import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { api } from 'config';
import Button from 'client/ui/Button';
import Modal from 'client/ui/Popup';
import Img from 'client/ui/Img';
import { H3 } from 'client/ui/Headings';

const Wrapper = styled.div`
  display: flex;
  @media (max-width: 650px) {
    justify-content: center;
  }
`;

const DangerousDiv = styled.div`
  line-height: 23px;
  color: ${props => props.theme.color.black};
`;

const StyledImagePreview = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 640px;
  width: 100%;
  img {
    display: block;
    margin: auto;
    width: auto;
    max-width: 100%;
  }
`;

const StyledH3 = styled(H3)`
  line-height: 35px;
  color: ${props => props.theme.color.black};
`;

const StyledModal = styled(Modal)`
  margin-top: 1rem;
  margin-bottom: 1rem;
  max-height: 80vh;
  width: 100%;
  max-width: 77vh;
  overflow-y: auto;
`;

const StyledButton = styled(Button)`
  margin-left: 10px;
  margin-right: 10px;
  min-width: 176px;
`;

class ButtonPopupItemView extends Component {
  constructor() {
    super();
    this.state = {
      modalIsOpen: false,
    };
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
  }

  onConfirm() {
    this.hideModal();
  }

  showModal() {
    this.setState({
      modalIsOpen: true,
    });
  }

  hideModal() {
    this.setState({ modalIsOpen: false });
  }

  render() {
    const { title, text, picture, buttonText, positionImage, colorMf } = this.props;
    const { modalIsOpen } = this.state;
    return (
      <Wrapper>
        {colorMf
          && (
            <StyledButton
              onClick={this.showModal}
              background={colorMf}
            >
              {buttonText}
            </StyledButton>
          )}
        <StyledModal
          isOpen={modalIsOpen}
          content={
            (
              <div>
                <StyledH3>{title}</StyledH3>
                {positionImage === 'up' ? (
                  <div>
                    {picture && (
                      <StyledImagePreview>
                        <Img src={`${api.baseUrl}${picture}`} alt="imagen" />
                      </StyledImagePreview>
                    )}
                    <DangerousDiv dangerouslySetInnerHTML={{ __html: text }} />
                  </div>
                ) : (
                    <div>
                      <DangerousDiv dangerouslySetInnerHTML={{ __html: text }} />
                      {picture && (
                        <StyledImagePreview>
                          <Img src={`${api.baseUrl}${picture}`} alt="imagen" />
                        </StyledImagePreview>
                      )}
                    </div>
                  )}
              </div>
            )
          }
          confirmText="Cerrar"
          onConfirm={this.onConfirm}
          onCancel={this.hideModal}
        />
      </Wrapper>
    );
  }
}

ButtonPopupItemView.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string.isRequired,
  picture: PropTypes.string,
  buttonText: PropTypes.string,
  colorMf: PropTypes.string.isRequired,
};

ButtonPopupItemView.defaultProps = {
  picture: '',
  title: '',
  buttonText: 'Botón Popup',
};

export default ButtonPopupItemView;
