import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledIconFa = styled.span`
  color: inherit;
`;

const IconFa = ({ name, regular, ...rest }) => {
  let fa = 'fas'; // Solid
  if (regular) {
    fa = 'far'; // Regular
  }
  return (
    <StyledIconFa {...rest}>
      <i className={`${fa} fa-${name}`} aria-hidden="true" />
    </StyledIconFa>
  );
};

IconFa.propTypes = {
  name: PropTypes.string.isRequired,
  regular: PropTypes.bool,
};

IconFa.defaultProps = {
  regular: false,
};

export default IconFa;
