import logout from 'client/modules/accounts/actions/logout';
/* @TODO: contemplar el error por vencimiento de token de sesion */
/**
 * API error handling
 * @see https://github.com/agraboso/redux-api-middleware/issues/74
 */
export default store => next => (action) => {
  // Checks to see if the action has a payload and if the payload is an ApiError
  if (action && action.payload && action.payload.name && action.payload.name === 'ApiError') {
    if (action.payload.status === 401) {
      store.dispatch(logout());
    }
  }
  return next(action);
};
