import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Carrousel from 'client/modules/dashboard-contents/components/Carrousel';

const SliderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
`;

const GalleryView = ({ items, color }) => (
  <div>
    <SliderWrapper>
      <Carrousel elements={items} color={color} />
    </SliderWrapper>
  </div>
);

GalleryView.propTypes = {
  items: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  color: PropTypes.string.isRequired,
};

GalleryView.defaultProps = {};

export default GalleryView;
