import React from 'react';
import PropTypes from 'prop-types';
import Plyr from 'react-plyr';

const Video = ({ id, service }) => (
  <Plyr
    provider={service}
    videoId={id}
  />
);

Video.propTypes = {
  id: PropTypes.string,
  service: PropTypes.string,
};

Video.defaultProps = {
  id: '',
  service: '',
};

export default Video;
