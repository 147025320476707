/* eslint react/no-array-index-key: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { redirectTo } from 'index';
import styled, { css } from 'styled-components';
import ContentWrapper from 'client/ui/ContentWrapper';
import Li from 'client/ui/Li';

const StyledUl = styled.ul`
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 1.5rem;
  li {
    margin-bottom: 1rem;
  }
  ${props => props.paddingLeft && css`
    padding-left: 20px;
  `}
`;

const ContentUl = StyledUl.extend`
  li {
    margin-bottom: .5rem !important;
  }
`;

const StyledLi = styled(Li)`
  display: flex;
  color: ${props => props.theme.color.black};
  &:before {
    content: "• ";
    color: ${props => props.color};
    margin-right: 10px;
  }
  animation-delay: 1.5s;
`;

const CustomUl = styled.ul`
  margin: 0;
  margin-bottom: .5rem;
  font-size: 1.125rem;
  li {
    margin-bottom: 1rem;
  }
`;

const UlExtended = styled(CustomUl)`
  font-size: 1rem;
`;

const CustomLi = styled.li`
  font-size: 0.875rem;
  cursor: pointer;
  margin-bottom: .5rem;
  animation-delay: 1s;
`;

const LiExtended = styled(CustomLi)`
  font-size: 1.125rem;
`;

const BasicLi = styled.li`
  cursor: pointer;
  div {
    display: inline-block;
  }
`;

const Upper = styled.div`
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 1rem;
  margin-top: 1rem;
`;


const MfIndex = ({ moduleIndex, haveUf, mfId, colorMf }) => (
  <ContentWrapper marginTop="2rem">
    <CustomUl>
      <BasicLi
        className="animated fadeIn"
        key={mfId}
        onClick={() => redirectTo(`/viewMf/${mfId}#generalData`)}
      >
        Objetivos y resumen
      </BasicLi>
      <li className="animated fadeIn">Mapa conceptual</li>
    </CustomUl>
    {haveUf
      ? (
        <div>
          {moduleIndex && moduleIndex.length > 0 && (
            <UlExtended>
              {moduleIndex.map((module, index) => (
                <div key={`${module.ufId}${index}`}>
                  <LiExtended
                    className="animated fadeIn"
                    key={`obj-${module.ufId}${index}`}
                    onClick={() => redirectTo(`/viewUf/${module.ufId}`)}
                  >
                    {`${module.codeUf} ${module.ufTitle}`}
                  </LiExtended>
                  <ol>
                    {module.uas.map(ua => (
                      <div key={`div-${ua.id}`}>
                        <CustomLi
                          className="animated fadeIn"
                          key={`obj-${ua.id}`}
                          onClick={() => redirectTo(`/viewUa/${ua.id}`)}
                        >
                          <Upper>
                            {ua.title}
                          </Upper>
                        </CustomLi>
                        <ContentUl>
                          {ua.topics.map((topic, index) => (
                            <StyledLi className="animated fadeIn" color={colorMf} key={`obj-${topic}${index}`}>{topic}</StyledLi>
                          ))}
                        </ContentUl>
                      </div>
                    ))}
                  </ol>
                </div>
              ))}
            </UlExtended>
          )}
        </div>)
      : (
        <UlExtended>
          {moduleIndex.map(ua => (
            <div key={`div-${ua.id}`}>
              <LiExtended
                className="animated fadeIn"
                key={`obj-${ua.id}`}
                onClick={() => redirectTo(`/viewUa/${ua.id}`)}
              >
                {ua.title}
              </LiExtended>
              <StyledUl>
                {ua.topics.map((topic, index) => (
                  <StyledLi className="animated fadeIn" color={colorMf} key={`obj-${topic}${index}`}>{topic}</StyledLi>
                ))}
              </StyledUl>
            </div>
          ))}
        </UlExtended>
      )}
    {!haveUf
      && (
        <CustomUl>
          <BasicLi
            className="animated fadeIn"
          >
            <div
              key={mfId}
              onClick={() => redirectTo(`/viewMf/${mfId}#BibliographicReferences`)}
              onKeyDown={() => redirectTo(`/viewMf/${mfId}#BibliographicReferences`)}
              role="button"
              tabIndex={0}
            >
              Referencias Bibliográficas
            </div>
          </BasicLi>
        </CustomUl>
      )}
  </ContentWrapper>
);

MfIndex.propTypes = {
  moduleIndex: PropTypes.arrayOf(PropTypes.any).isRequired,
  haveUf: PropTypes.bool.isRequired,
  mfId: PropTypes.string.isRequired,
  colorMf: PropTypes.string.isRequired,
};

MfIndex.defaultProps = {};

export default MfIndex;
