import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import ButtonFa from 'client/ui/ButtonFa';

const StyledRolesList = styled.div`
  background-color: ${props => props.theme.color.white};
  border: 1px solid ${props => props.theme.color.gray};
`;

const StyledItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 0.5rem;
  border-bottom: 1px solid ${props => props.theme.color.gray};
  &:last-child {
    border-bottom: 0;
  }
  :nth-child(even) {
    background-color: #F1F0F0;
  }
`;

const StyledName = styled.div`
  flex-grow: 1;
  text-transform: capitalize;
  padding: 0.65em 0.60em;
`;

const StyledActions = styled.div`
  text-align: right;
`;

const StyledButtonFa = styled(ButtonFa)`
  font-size: 1rem;
  padding: 0.65em 0.60em;
`;

function canBeEdited(role) {
  const { rolName } = role;
  return !['ROOT', 'ADMIN', 'ADMINISTRADORCENTRO'].includes(rolName);
}

const RolesList = ({ list, onRemove }) => (
  <StyledRolesList>
    {list.map(item => (
      <StyledItem key={item.id}>
        <StyledName>{item.text}</StyledName>
        {canBeEdited(item) ? (
          <StyledActions>
            <Link to={`/dashboard/roles/edit/${item.id}`}>
              <StyledButtonFa
                fa="fa-pencil-alt"
                transparent
                color="#455A64"
              />
            </Link>
            <StyledButtonFa
              fa="fa-trash-alt"
              transparent
              color="red"
              onClick={() => onRemove(item.id)}
            />
          </StyledActions>)
          : null
        }
      </StyledItem>
    ))}
  </StyledRolesList>
);

RolesList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.string,
    }),
  ),
  onRemove: PropTypes.func,
};

RolesList.defaultProps = {
  list: [],
  onRemove: null,
};

export default RolesList;
