import React from 'react';
import PropTypes from 'prop-types';
import Select from 'client/ui/Select';
import styled, { css } from 'styled-components';
import ContentText from './ContentText';
import ContentPicture from './ContentPicture';

const TextPictureWrapper = styled.div`
  width: 100%;
  min-height: 200px;
  display: flex;
  ${props => props.position.value === 'left' && css`
    flex-direction: row-reverse;
  `}
`;

const ContentWrapper = styled.div`
  width: 50%;
  padding: 1rem 1rem 0;
  ${props => props.width && css`
    width: ${props.width};
  `}
`;

const TextPicture = ({ id, picture, data, changePicture, onChange, changePosition, props,
  positions, changePictureText }) => {
  const { position } = props;
  return (
    <div>
      <Select
        name="selectedRole"
        options={positions}
        value={position}
        onChange={(value) => { changePosition(id, value); }}
        placeholder="Seleccione"
        noResultsText="No se ha encontrado coincidencias"
        valueKey="id"
        labelKey="text"
        autoFocus
        small
      />

      <TextPictureWrapper position={position}>
        <ContentWrapper width="60%">
          <ContentText
            data={data}
            id={id}
            onChange={onChange}
          />
        </ContentWrapper>
        <ContentWrapper width="40%">
          <ContentPicture
            key={`picture_${id}`}
            id={id}
            changePicture={changePicture}
            changePictureText={changePictureText}
            props={props}
            picture={picture}
          />
        </ContentWrapper>
      </TextPictureWrapper>
    </div>
  );
};

TextPicture.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.string.isRequired,
  picture: PropTypes.objectOf(PropTypes.any),
  props: PropTypes.objectOf(PropTypes.any),
  changePicture: PropTypes.func.isRequired,
  changePictureText: PropTypes.func.isRequired,
  changePosition: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  positions: PropTypes.arrayOf(PropTypes.any).isRequired,
  position: PropTypes.objectOf(PropTypes.any),
};

TextPicture.defaultProps = {
  props: {},
  picture: { src: '/images/contents/picture.jpg' },
  position: {},
};

export default TextPicture;
