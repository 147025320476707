import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from 'client/ui/Button';
import { H3 } from 'client/ui/Headings';
import Popup from 'client/modules/view-content/components/ButtonPopupItemView';
import Menu from './ConfigurationMenu';
import ButtonPopupItem from './ButtonPopupItem';
import ContentWrapperOptions from './ContentWrapperOptions';

const AddButton = styled(Button)`
  margin-bottom: 1rem;
  display: flex;
  margin-left: auto;
  margin-right: auto;
`;

const StyledNoContentText = styled(H3)`
  text-align: center;
`;

const StyledPopupPane = styled.div`
  padding-top: 1rem;
  margin-bottom: 1rem;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-flow: row wrap;
  align-content: flex-end;
  justify-content: center;
`;

const StyledButton = styled(Button)`
  margin-bottom: 1rem;
`;

const MenuPopupItem = styled.div`
  padding: 1rem 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid ${props => props.theme.color.grayTable};
  border-radius: ${props => props.theme.radius};
  box-shadow: 0px 2px ${props => props.theme.color.grayTable};
`;

class ContentButtonPopup extends Component {
  constructor() {
    super();
    this.state = {
      configurationVisible: false,
    };
    this.onItemDelete = this.onItemDelete.bind(this);
    this.onItemMove = this.onItemMove.bind(this);
    this.onPictureMove = this.onPictureMove.bind(this);
    this.handleDisplayMenu = this.handleDisplayMenu.bind(this);
    this.close = this.close.bind(this);
  }

  onItemDelete(index) {
    const { id, removePopupItem } = this.props;
    removePopupItem(id, index);
  }

  onItemMove(index, direction) {
    const { id, movePopupItem } = this.props;
    movePopupItem(id, index, direction);
  }

  onPictureMove(id, index, direction) {
    const { onImageMove } = this.props;
    onImageMove(id, index, direction)
  }

  handleDisplayMenu() {
    const { configurationVisible } = this.state;
    this.setState({ configurationVisible: !configurationVisible });
  }

  close() {
    this.setState({ configurationVisible: false });
  }

  render() {
    const { id, addItem, onTitleChange, onTextChange, items, savePicture, 
      onButtonChange } = this.props;
    const { configurationVisible } = this.state;
    return (
      <div>
        <Menu
          title="Editar botón popup"
          width="25rem"
          isOpen={configurationVisible}
          close={this.close}
        >
          <AddButton
            onClick={() => addItem(id)}
          >
            Añadir Popup
          </AddButton>
          {items.length === 0
            ? <StyledNoContentText>No hay items que mostrar</StyledNoContentText>
            : (
              <div>
                {items && items.map((item, index) => (
                  <MenuPopupItem>
                    <ContentWrapperOptions
                      id={index.toString()}
                      remove={this.onItemDelete}
                      moveContent={this.onItemMove}
                      noBottomProps
                    >
                      <ButtonPopupItem
                        key={`popups${index}-${id}`}
                        id={id}
                        index={index}
                        buttonText={item.buttonText}
                        text={item.text}
                        title={item.title}
                        picture={item.url}
                        movePicture={this.onPictureMove}
                        positionImage={item.positionImage}
                        savePicture={savePicture}
                        onTitleChange={onTitleChange}
                        onTextChange={onTextChange}
                        onButtonChange={onButtonChange}
                      />
                    </ContentWrapperOptions>
                  </MenuPopupItem>
                ))}
              </div>
            )}
        </Menu>
        <StyledButton
          hollow
          color="black"
          onClick={() => this.handleDisplayMenu()}
        >
          Editar
        </StyledButton>
        <Wrapper>
          {items && items.map((item, index) => (
            <StyledPopupPane>
              <Popup
                key={`popups${index}-${id}`}
                title={item.title}
                text={item.text}
                picture={item.url}
                buttonText={item.buttonText}
                positionImage={item.positionImage}
                colorMf="#455A64"
              />
            </StyledPopupPane>
          ))}
        </Wrapper>
      </div>
    );
  }
}

ContentButtonPopup.propTypes = {
  id: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  onTitleChange: PropTypes.func.isRequired,
  onTextChange: PropTypes.func.isRequired,
  onButtonChange: PropTypes.func.isRequired,
  savePicture: PropTypes.func.isRequired,
  addItem: PropTypes.func.isRequired,
  removePopupItem: PropTypes.func.isRequired,
  movePopupItem: PropTypes.func.isRequired,
};

ContentButtonPopup.defaultProps = {};

export default ContentButtonPopup;
