import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ContentText from './ContentText';
import ContentTitle from './ContentTitle';


const StyledTitle = styled(ContentTitle)`
  margin-bottom: 1rem;
`;

const TextTitle = ({ title, titleChange, data, onChange, id }) => (
  <div>
    <StyledTitle
      id={id}
      title={title}
      titleChange={titleChange}
    />
    <ContentText
      data={data}
      id={id}
      onChange={onChange}
    />
  </div>
);

TextTitle.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  titleChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

TextTitle.defaultProps = {
};

export default TextTitle;
