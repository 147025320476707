import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { H2 } from 'client/ui/Headings';
import Label from 'client/ui/Label';
import Span from 'client/ui/Span';
import Input from 'client/ui/Input';
import Button from 'client/ui/Button';
import ButtonFa from 'client/ui/ButtonFa';
import ButtonGroup from 'client/ui/ButtonGroup';
import Select from 'client/ui/Select';
import FormWrapper from 'client/ui/FormWrapper';
import ToggleSwitch from 'client/ui/ToggleSwitch';

const ButtonsWrapper = styled.div`
  margin-bottom: 2rem;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

const Form = ({ moduleToUpdate, centres, handleUfOrNotUf, inputChange, handleSelectChange,
  handleSubmit, saveAndNext }) => {
  const { codeMf, codeCert, nameMf, nameCert, centre, haveUf } = moduleToUpdate;
  const parsedcentres = centres.length ? centres.map(c => ({ value: c._id, label: c.name })) : [];
  return (
    <FormWrapper>
      <H2>DESCRIPCIÓN GENERAL DEL MÓDULO FORMATIVO</H2>
      <form onSubmit={(event) => { handleSubmit(event); }}>
        <Label>
          <Span spanForm>Código Módulo</Span>
          <Input
            type="text"
            name="codeMf"
            value={codeMf}
            onChange={(event) => { inputChange(event); }}
            required
            small
          />
        </Label>
        <Label>
          <Span spanForm>Nombre del Módulo formativo</Span>
          <Input
            type="text"
            name="nameMf"
            value={nameMf}
            onChange={(event) => { inputChange(event); }}
            required
          />
        </Label>
        <Label>
          <Span spanForm>Código Certificado</Span>
          <Input
            type="text"
            name="codeCert"
            value={codeCert}
            onChange={(event) => { inputChange(event); }}
            required
            small
          />
        </Label>
        <Label>
          <Span spanForm>Nombre del Certificado</Span>
          <Input
            type="text"
            name="nameCert"
            value={nameCert}
            onChange={(event) => { inputChange(event); }}
            required
          />
        </Label>
        <Label>
          <span>Centro</span>
          <Select
            isSearchable
            name="centre"
            options={parsedcentres}
            value={centre}
            onChange={(value) => { handleSelectChange('centre', value); }}
            valueKey="value"
            labelKey="label"
            placeholder="Seleccione centro"
            noResultsText="No se ha encontrado coincidencias"
            required
            medium
          />
        </Label>
        <ButtonsWrapper>
          <p>Contiene Unidades Formativas</p>
          <ToggleSwitch
            on={haveUf || false}
            onClick={() => handleUfOrNotUf()}
            text={haveUf ? 'Con UF' : 'Sin UF'}
          />
        </ButtonsWrapper>
        <ButtonGroup align="right">
          <Button type="submit">Guardar</Button>
          <ButtonFa
            fa="fa-chevron-right"
            hollow
            borderColor="black"
            color="black"
            side="right"
            type="submit"
            onClick={() => { saveAndNext('#generalData'); }}
          >
            Siguiente
          </ButtonFa>
        </ButtonGroup>
      </form>
    </FormWrapper>
  );
};

Form.propTypes = {
  moduleToUpdate: PropTypes.objectOf(PropTypes.any),
  inputChange: PropTypes.func.isRequired,
  handleUfOrNotUf: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleSelectChange: PropTypes.func.isRequired,
  saveAndNext: PropTypes.func.isRequired,
  centres: PropTypes.arrayOf(PropTypes.object).isRequired,
};

Form.defaultProps = {
  moduleToUpdate: {},
};

export default Form;
