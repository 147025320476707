import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import logout from 'client/modules/accounts/actions/logout';
import setPermissions from 'client/modules/dashboard-permissions/actions/permissions';
import { fetchAllModules, fetchModules } from 'client/modules/core/actions/modules';
import PrivateLayoutRoute from '../components/PrivateLayoutRoute';
import { getUsername } from '../selectors/User';

class PrivateLayoutRouteContainer extends Component {
  constructor() {
    super();
    this.state = {};
    this.loadUserPermissions = this.loadUserPermissions.bind(this);
    this.loadAdminModules = this.loadAdminModules.bind(this);
  }

  componentWillMount() {
    const { modules, loadModules, userRoles, userPermissions } = this.props;
    if (!modules.length) {
      loadModules();
    }

    if (!userPermissions.length > 0) {
      if (userRoles.find(rol => rol.rid === 'ADMIN')) {
        this.loadAdminModules();
      } else {
        this.loadUserPermissions();
      }
    }
  }

  loadUserPermissions() {
    const userCurrentModules = [];
    const { setUserPermissions, userRoles } = this.props;
    userRoles.forEach((rol) => {
      rol.permissions.forEach((module) => {
        const actions = {};
        module.actions.forEach((act) => {
          Object.assign(actions, { [act]: true });
        });
        userCurrentModules.push({ module: module.module.label, actions });
      });
    });
    const userModules = [];
    userCurrentModules.forEach((cur) => {
      const index = userModules.findIndex(mod => mod.module === cur.module);
      if (index > -1) {
        const currentActions = userModules[index].actions;
        const nuevasActions = cur.actions;
        const newActions = Object.assign({}, currentActions, nuevasActions);
        userModules[index].actions = newActions;
      } else {
        userModules.push(cur);
      }
    });
    setUserPermissions(userModules);
  }

  loadAdminModules() {
    const { setUserPermissions } = this.props;
    fetchAllModules().then((modulesData) => {
      if (modulesData.length > 0) {
        const permissions = [];
        modulesData.forEach((module) => {
          const actions = {};
          module.actions.forEach((action) => {
            actions[action] = true;
          });
          permissions.push({ module: module.label, actions });
        });
        setUserPermissions(permissions);
      }
    });
    this.setState({});
  }

  render() {
    const { component, userPermissions, action, module, userRoles, ...rest } = this.props;
    if (userPermissions.length === 0) {
      const authToken = localStorage.getItem('token');
      if (authToken) {
        return false;
      }
    }
    if (userRoles.length > 0) {
      if (userRoles.find(rol => rol === 'ADMIN')) {
        return (
          <PrivateLayoutRoute component={component} {...rest} />
        );
      }
      if (userPermissions.length > 0) {
        // Pasamos a mayusculas el nombre del modulo
        const moduleUpperCase = module.toUpperCase();
        const canAccessModule = userPermissions.find(per => per.module === moduleUpperCase);
        if (canAccessModule) {
          const userAction = Object.keys(canAccessModule.actions).filter(act => act === action);
          if (userAction) {
            return (<PrivateLayoutRoute component={component} {...rest} />);
          }
          /* El usuario no tiene permisos para realizar esa acción */
          return (
            <PrivateLayoutRoute component={
              () => <h2>No tiene permisos para realizar la acción</h2>}
            />
          );
        }
        /* El usuario no puede acceder al modulo */
        return (<PrivateLayoutRoute component={() => <h2>Acceso denegado</h2>} />);
      }
    }
    return (
      <PrivateLayoutRoute component={component} {...rest} />
    );
  }
}

PrivateLayoutRouteContainer.propTypes = {
  component: PropTypes.func.isRequired,
  action: PropTypes.string.isRequired,
  module: PropTypes.string.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.object),
  userPermissions: PropTypes.arrayOf(PropTypes.object).isRequired,
  modules: PropTypes.arrayOf(PropTypes.object).isRequired,
  setUserPermissions: PropTypes.func.isRequired,
  loadModules: PropTypes.func.isRequired,
};

PrivateLayoutRouteContainer.defaultProps = {
  userRoles: [],
};

const mapStateToProps = state => ({
  userName: getUsername(state),
  userRoles: state.auth.userInfo.roles,
  userPermissions: state.permissions.userPermissions,
  modules: state.common.modules,
});

const mapDispatchToProps = dispatch => ({
  setUserPermissions: (permissions) => { dispatch(setPermissions(permissions)); },
  loadModules: () => { dispatch(fetchModules()); },
  logout: () => { dispatch(logout()); },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PrivateLayoutRouteContainer);
