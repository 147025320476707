/* eslint no-undef: 0 */
import { redirectTo } from 'index';
import { LOGOUT } from '../constants/actionTypes';

function logout() {
  /* Destruimos las localstorage */
  localStorage.removeItem('token');
  localStorage.removeItem('token-expire');
  localStorage.removeItem('mode');
  localStorage.removeItem('userInfo');
  localStorage.removeItem('mfId');
  localStorage.removeItem('ufId');
  redirectTo('/');
  return {
    type: LOGOUT,
  };
}


export default logout;
