import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { api } from 'config';
import { arrayMove } from 'react-sortable-hoc';
import uploadPicture from 'client/modules/core/utils/upload-content-picture';
import uploadFile from 'client/modules/core/utils/upload-file';
import { fetchUa } from 'client/modules/dashboard-uas/actions/uas';
import { fetchPage, savePage } from 'client/modules/dashboard-pages/actions/pages';
import { fetchPageContents, saveContent, deleteContent } from '../actions/contents';
import Wrapper from '../components/Wrapper';
import EvaluationWrapper from '../components/EvaluationWrapper';

const positions = [
  { value: 'left', label: 'Izquierda' },
  { value: 'right', label: 'Derecha' },
];

class WrapperContainer extends Component {
  constructor() {
    super();
    this.state = {
      contents: [],
      contentId: null,
      title: '',
      tab: '#contents',
      pageId: null,
      uaId: '',
      notFound: false,
      modalIsOpen: false,
      haveUf: false,
      type: 'page',
      config: {},
    };
    this.loadPageContents = this.loadPageContents.bind(this);
    this.saveItemsPicture = this.saveItemsPicture.bind(this);
    this.saveQuestionPicture = this.saveQuestionPicture.bind(this);
    this.removeContent = this.removeContent.bind(this);
    this.moveContent = this.moveContent.bind(this);
    this.savePicture = this.savePicture.bind(this);
    this.uploadContentFile = this.uploadContentFile.bind(this);
    this.changeFile = this.changeFile.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.moveItem = this.moveItem.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.showModal = this.showModal.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
  }

  componentWillMount() {
    const { match, history } = this.props;
    if (match.params.id) {
      const { id } = match.params;
      this.setState({ pageId: id });
      this.loadPageContents(id);
    }
    if (history.location && history.location.hash) {
      this.setState({ tab: history.location.hash });
    }
  }

  onConfirm() {
    this.hideModal();
    this.removeContent();
  }

  loadPageContents(pageId) {
    fetchPage(pageId).then((page) => {
      if (!page.type) {
        page.type = 'page';
      }
      // Fix evaluation config
      let config = {};
      if (!page.config) {
        config = {
          requiredPoints: 50,
          attempts: 1,
          showFeedback: true,
          messQuestions: false,
          messAnswers: false,
        };
      }
      if (page.config) {
        let { requiredPoints, attempts, showFeedback, messQuestions, messAnswers } = page.config;
        if (requiredPoints === undefined) {
          requiredPoints = 80;
        }
        if (attempts === undefined) {
          attempts = 1;
        }
        if (showFeedback === undefined) {
          showFeedback = false;
        }
        if (messQuestions === undefined) {
          messQuestions = false;
        }
        if (messAnswers === undefined) {
          messAnswers = false;
        }

        config = {
          requiredPoints,
          attempts,
          showFeedback,
          messQuestions,
          messAnswers,
        };
      }

      fetchUa(page.topic.ua).then((uaData) => {
        if (uaData.uf) {
          this.setState({ haveUf: true });
        }
        if (uaData.mf) {
          this.setState({ haveUf: false });
        }
      });
      fetchPageContents(pageId).then((contents) => {
        const parsed = [];
        contents.forEach((content) => {
          let picture = null;
          const current = content;
          current.id = content._id;
          delete current.updatedAt;
          delete current.deletedAt;
          delete current.createdAt;
          delete current._id;
          delete current.__v;
          if (content.picture && content.picture._id) {
            picture = {
              id: content.picture._id,
              src: `${api.baseUrl}${content.picture.url.l}`,
            };
            current.picture = picture;
          }
          parsed.push(current);
        });
        if (contents.length === 0) {
          this.setState({ contents, type: page.type, title: page.title, uaId: page.topic.ua, notFound: true, config });
        } else {
          this.setState({ contents: parsed, type: page.type, title: page.title, uaId: page.topic.ua, config });
        }
      });
    });
  }

  savePicture(picture, props) {
    const { contents } = this.state;
    const pictureId = uploadPicture({ picture: picture.file, props });
    pictureId.then((response) => {
      if (response && response.image) {
        const index = contents.findIndex(content => content.id === props.content);
        if (index > -1) {
          const currents = contents;
          currents[index].picture.pictureId = response.image._id;
          this.setState({ contents: currents });
        }
      }
    });
  }

  uploadContentFile(file, props) {
    const { contents } = this.state;
    const { content } = props;
    const uploadedAudio = uploadFile({ file, props });
    uploadedAudio.then((response) => {
      if (response && response.resource) {
        const index = contents.findIndex(cont => cont.id === content);
        if (index > -1) {
          const currents = contents;
          currents[index].url = `${response.resource.url}`;
          currents[index].props = props;
          this.setState({ contents: currents });
        }
      }
    });
  }

  changeFile(event, id) {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const props = {
        content: id,
        name: file.name,
      };
      this.uploadContentFile(file, props);
    }
  }

  saveItemsPicture(picture, id, index) {
    const { contents } = this.state;
    const props = {
      content: id,
    };
    const position = contents.findIndex(content => content.id === id);
    const currents = contents;
    currents[position].items[index].picture = picture;
    if (position > -1) {
      if (picture && picture.file) {
        const pictureId = uploadPicture({ picture: picture.file, props });
        pictureId.then((response) => {
          if (response && response.image) {
            currents[position].items[index].picture.pictureId = response.image._id;
            currents[position].items[index].url = response.image.url.l;
            currents[position].items[index].content = id;
            this.setState({ contents: currents });
          }
        });
      } else {
        currents[position].items[index].url = '';
        this.setState({ contents: currents });
      }
    }
  }

  saveGalleryPicture(picture, id, index) {
    const { contents } = this.state;
    const props = {
      content: id,
    };
    const position = contents.findIndex(content => content.id === id);
    const currents = contents;
    currents[position].items[index].picture = picture;
    if (position > -1) {
      if (picture && picture.file) {
        const pictureId = uploadPicture({ picture: picture.file, props });
        pictureId.then((response) => {
          if (response && response.image) {
            currents[position].items[index].pictureId = response.image._id;
            currents[position].items[index].url = response.image.url.l;
            currents[position].items[index].content = id;
            this.setState({ contents: currents });
          }
        });
      } else {
        this.setState({ contents: currents });
      }
    }
  }

  saveQuestionPicture(picture, contentId, questionId) {
    const { contents } = this.state;
    const props = {
      content: contentId,
    };
    const position = contents.findIndex(content => content.id === contentId);
    const currents = contents;
    if (position > -1) {
      const currentQuestionIndex = currents[position].items
        .findIndex(question => question.id === questionId);
      if (currentQuestionIndex > -1) {
        if (picture && picture.file) {
          const pictureId = uploadPicture({ picture: picture.file, props });
          pictureId.then((response) => {
            if (response && response.image) {
              currents[position].items[currentQuestionIndex].url = response.image.url.l;
              this.setState({ contents: currents });
            }
          });
        } else {
          currents[position].items[currentQuestionIndex].url = '';
          this.setState({ contents: currents });
        }
      }
    }
  }

  showModal(contentId) {
    if (contentId) {
      this.setState({ modalIsOpen: true, contentId });
    }
  }

  hideModal() {
    this.setState({ modalIsOpen: false, contentId: null });
  }

  moveContent(id, direction) {
    const { contents } = this.state;
    const index = contents.findIndex(content => content.id === id);
    if (index > -1) {
      const lastPosition = contents.length - 1;
      if (direction === 'down' && index < lastPosition) {
        const newOrder = arrayMove(contents, index, index + 1);
        this.setState({ contents: newOrder });
      } else if (direction === 'up' && index > 0) {
        const newOrder = arrayMove(contents, index, index - 1);
        this.setState({ contents: newOrder });
      }
    }
  }

  moveItem(id, index, direction) {
    const { contents } = this.state;
    const contentIndex = contents.findIndex(content => content.id === id);
    if (contentIndex > -1) {
      const currents = contents;
      const currentItems = contents[contentIndex].items;
      const lastPosition = currentItems.length - 1;
      // const next = index + 1;
      const prev = index - 1;
      if (direction === 'down' && index < lastPosition) {
        const newOrder = arrayMove(currentItems, index, prev + 2);
        currents[contentIndex].items = newOrder;
        this.setState({ contents: currents });
      } else if (direction === 'up' && prev >= 0) {
        const newOrder = arrayMove(currentItems, index, prev);
        currents[contentIndex].items = newOrder;
        this.setState({ contents: currents });
      }
    }
  }

  removeContent() {
    const { contents, contentId } = this.state;
    const position = contents.findIndex(content => content.id === contentId);
    if (position > -1) {
      const currents = contents;
      currents.splice(position, 1);
      this.setState({ contents: currents });
      deleteContent(contentId);
    }
  }

  removeItem(id, index) {
    const { contents } = this.state;
    const position = contents.findIndex(content => content.id === id);
    if (position > -1) {
      const currents = contents;
      const currentItems = contents[position].items;
      currentItems.splice(index, 1);
      currents[position].items = currentItems;
      this.setState({ contents: currents });
    }
  }

  render() {
    const { history, match } = this.props;
    const { contents, tab, pageId, title, uaId, notFound, modalIsOpen, haveUf,
      type, config } = this.state;
    if (type === 'evaluation') {
      return (
        <EvaluationWrapper
          save={saveContent}
          savePage={savePage}
          contents={contents}
          history={history}
          title={title}
          uaId={uaId}
          pageId={pageId}
          match={match}
          tab="#evaluacion"
          modalIsOpen={modalIsOpen}
          hideModal={this.hideModal}
          onConfirm={this.onConfirm}
          saveQuestionPicture={this.saveQuestionPicture}
          removeContent={this.showModal}
          moveContent={this.moveContent}
          haveUf={haveUf}
          removeItem={this.removeItem}
          moveItem={this.moveItem}
          config={config}
        />
      );
    }

    return (
      <Wrapper
        save={saveContent}
        contents={contents}
        removeContent={this.showModal}
        history={history}
        notFound={notFound}
        positions={positions}
        moveContent={this.moveContent}
        reorderPageContents={savePage}
        modalIsOpen={modalIsOpen}
        hideModal={this.hideModal}
        onConfirm={this.onConfirm}
        title={title}
        uaId={uaId}
        savePicture={this.savePicture}
        uploadContentFile={this.changeFile}
        saveItemsPicture={this.saveItemsPicture}
        saveQuestionPicture={this.saveQuestionPicture}
        pageId={pageId}
        match={match}
        tab={tab}
        removeItem={this.removeItem}
        moveItem={this.moveItem}
        haveUf={haveUf}
      />
    );
  }
}

WrapperContainer.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

WrapperContainer.defaultProps = {};

export default WrapperContainer;
