const dataTablesI18n = (() => {
  const translations = [];
  translations.en = {
    sEmptyTable: 'No data available in table',
    sInfo: 'Showing _START_ to _END_ of _TOTAL_ entries',
    sInfoEmpty: 'Showing 0 to 0 of 0 entries',
    sInfoFiltered: '(filtered from _MAX_ total entries)',
    sInfoPostFix: '',
    sInfoThousands: ',',
    sLengthMenu: 'Show _MENU_ entries',
    sLoadingRecords: 'Loading...',
    sProcessing: 'Processing...',
    sSearch: 'Search:',
    sZeroRecords: 'No matching records found',
    oPaginate: {
      sFirst: 'First',
      sLast: 'Last',
      sNext: 'Next',
      sPrevious: 'Previous',
    },
    oAria: {
      sSortAscending: ': activate to sort column ascending',
      sSortDescending: ': activate to sort column descending',
    },
    buttons: {
      colvis: 'Show/Hidden Columns',
    },
  };

  translations.es = {
    sProcessing: 'Procesando...',
    sLengthMenu: 'Mostrar _MENU_ registros',
    sZeroRecords: 'No se encontraron resultados',
    sEmptyTable: 'Ningún dato disponible en esta tabla',
    sInfo: 'Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros',
    sInfoEmpty: 'Mostrando registros del 0 al 0 de un total de 0 registros',
    sInfoFiltered: '(filtrado de un total de _MAX_ registros)',
    sInfoPostFix: '',
    sSearch: 'Buscar:',
    sUrl: '',
    sInfoThousands: ',',
    sLoadingRecords: 'Cargando...',
    oPaginate: {
      sFirst: 'Primero',
      sLast: 'Último',
      sNext: 'Siguiente',
      sPrevious: 'Anterior',
    },
    oAria: {
      sSortAscending: ': Activar para ordenar la columna de manera ascendente',
      sSortDescending: ': Activar para ordenar la columna de manera descendente',
    },
    buttons: {
      colvis: 'Mostrar/Ocultar columnas',
      Copy: 'Copiar',
      copyTitle: 'Copiar',
      copyKeys: 'Pulse <i>ctrl</i> o <i>\u2318</i> + <i>C</i> para copiar el contenido de la tabla al portapapeles del sistema. <br><br>Para cancelar, haga click sobre este mensaje o pulse en escape',
    },
  };
  /**
* Devuelve el idioma para el datatable
*
* @param  {String} lang
* @return {String} - HTML
*/
  const getTranslation = (lang) => {
    const language = lang !== 'undefined' ? lang : 'en';
    return translations[language];
  };

  return {
    getTranslation,
  };
})();

export default dataTablesI18n;
