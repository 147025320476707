import styled, { css } from 'styled-components';
import Select from 'react-select';

const StyledSelect = styled(Select)`
  .Select-control {
    margin: 0 0 1rem;
    background-color: ${props => props.theme.color.white};

    ${props => props.maxWidth && css`
      max-width: ${props.maxWidth}
    `};
    ${props => props.disabled && css`
      cursor: not-allowed;
    `};
  }
  ${props => props.medium && css`
    width: 100%;
    max-width: 500px;
  `};
  ${props => props.small && css`
    width: 100%;
    max-width: 300px;
  `};
  .Select-value,
  .Select-placeholder {
    font-size: 1rem;
    color: ${props => props.theme.color.black};
  }
`;

StyledSelect.displayName = 'Select';

export default StyledSelect;
