import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_ERROR } from '../constants/actionTypes';

const initialState = {
  isFetching: false,
  isAuthenticated: false,
  userInfo: {},
};

/**
 * Reducer para el auth.
 * El estado inicial establece la autenticación basado en el token del localStorage
 * @param  {Object} [state=initialState]  - Estado de la app
 * @param  {Object} action                - Acción a realizar
 * @return {Object}                       - Siguiente estado de la app
 */
export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isFetching: true,
        isAuthenticated: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: true,
        userInfo: action.userInfo,
        errorMessage: '',
      };
    case LOGIN_ERROR:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
        errorMessage: action.message,
      };
    // NOTA: no se contempla el caso de LOGOUT porque se controla en el rootReducer
    default:
      return state;
  }
}
