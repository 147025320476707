import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import dashboardOptions from '../utils/dashboard-options';
import Dashboard from '../components/Dashboard';

class DashboardContainer extends Component {
  constructor() {
    super();
    this.state = {};
    this.userDashboardOptions = this.userDashboardOptions.bind(this);
  }

  componentWillMount() {
    const { userRoles } = this.props;
    if (userRoles.find(rol => rol.rid === 'ADMIN')) {
      this.userDashboardOptions({ allModules: true });
    } else {
      this.userDashboardOptions({ allModules: false });
    }
  }

  userDashboardOptions({ allModules }) {
    const { userPermissions } = this.props;
    const options = [];

    if (allModules) {
      dashboardOptions.forEach(({ module, onlyFor, ...rest }) => {
        options.push({ ...rest });
      });
    } else {
      dashboardOptions.forEach(({ module, onlyFor, ...rest }) => {
        const accessModule = userPermissions.find(mod => mod.module === module);
        // Mostramos en el dashboard todos los modulos en los que el usuario
        // tenga permisos de lectura
        if (accessModule && accessModule.actions.READ) {
          options.push({ ...rest });
        }
      });
    }
    const data = {
      options,
    };
    this.setState({ data });
  }

  render() {
    const { data } = this.state;
    return (
      <Dashboard
        {...data}
      />
    );
  }
}

DashboardContainer.propTypes = {
  userRoles: PropTypes.arrayOf(PropTypes.object).isRequired,
  userPermissions: PropTypes.arrayOf(PropTypes.object).isRequired,
};

DashboardContainer.defaultProps = {};

const mapStateToProps = state => ({
  userRoles: state.auth.userInfo.roles,
  userPermissions: state.permissions.userPermissions,
});

export default connect(
  mapStateToProps,
  null,
)(DashboardContainer);
