import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Datatables from 'client/ui/Datatables';
import { H1, H2 } from 'client/ui/Headings';
import Button from 'client/ui/Button';
import Notification from 'client/ui/Notification';
import ContentWrapper from 'client/ui/ContentWrapper';
import ModalConfirm from 'client/ui/Modal';

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
`;

const StyledH1 = H1.extend`
  margin-bottom: 0;
`;

const NoResult = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
`;

const Modules = ({ modules, notification, clearMessages, modalIsOpen,
  hideModal, onConfirm, showModal, notFound, userPermissions, exportScorm,
  validateModalIsOpen, onValidateConfirm, showValidateModal }) => {
  const buttons = [];
  let canWrite = false;

  Object.keys(userPermissions).forEach((per) => {
    switch (per) {
      case 'READ':
        buttons.push({
          type: 'link',
          icon: 'fa-eye',
          classButton: 'basic',
          routeName: '/viewMf/',
          target: 'target="_blank"',
          module: 'MODULES',
          tooltip: 'Leer',
        });
        break;
      case 'WRITE':
        canWrite = true;
        break;
      case 'UPDATE':
        buttons.push({
          type: 'link',
          icon: 'fa-pencil-alt',
          classButton: 'basic',
          routeName: '/dashboard/mfs/',
          actionName: 'update',
          enabledStatus: 'DRAFT',
          module: 'MODULES',
          tooltip: 'Editar',
        });
        break;
      case 'EXPORT':
        buttons.push({
          type: 'button',
          icon: 'fas fa-download',
          classButton: 'basic',
          action: exportScorm,
          actionName: 'export',
          enabledStatus: 'VALIDATED',
          module: 'MODULES',
          tooltip: 'Descargar scorm',
        });
        break;
      case 'DELETE':
        buttons.push({
          type: 'button',
          icon: 'fa-trash-alt',
          classButton: 'red',
          action: showModal,
          actionName: 'delete-module',
          enabledStatus: 'DRAFT',
          module: 'MODULES',
          tooltip: 'Eliminar',
        });
        break;
      case 'VALIDATE':
        buttons.push({
          type: 'button-text',
          text: 'Validar',
          classButton: 'basic',
          action: showValidateModal,
          actionName: 'validate',
          enabledStatus: 'DRAFT',
          module: 'MODULES',
          tooltip: 'Validar MF',
        });
        break;
      default: break;
    }
  });

  return (
    <ContentWrapper lateralPadding="2rem" maxWidth="100%">
      <StyledHeader>
        <StyledH1>Mis módulos</StyledH1>
        {canWrite
          && <Button to="/dashboard/mfs/">Crear nuevo</Button>
        }
      </StyledHeader>
      {/* Notificaciones */}
      <Notification
        message={notification}
        afterNotification={clearMessages}
      />
      {modules.length > 0
        ? (
          <div>
            <Datatables
              id="newsTable"
              headers={[
                { name: 'Nombre' },
                { name: 'Centro' },
                { name: 'Estado' },
                { name: 'Fecha modificación' },
              ]}
              rows={modules}
              buttons={buttons}
              config={{
                dom: 'Bfrtip',
                buttons: [
                  {
                    extend: 'excelHtml5',
                    title: 'Lista de modulos',
                    className: '',
                    exportOptions: { columns: ':visible' },
                  },
                  {
                    extend: 'colvis',
                    className: '',
                    collectionLayout: 'fixed',
                  },
                ],
                order: [[3, 'desc']],
                columns: [
                  null,
                  { width: '20%' },
                  { width: '105px' },
                  { width: '10%' },
                  { width: '102px' },
                ],
              }}
            />
          </div>)
        : (
          <NoResult>
            {notFound
              ? <H2>No ha creado ningún módulo formativo</H2>
              : <H2>Cargando módulos formativos</H2>
            }
          </NoResult>
        )
      }

      <ModalConfirm
        isOpen={modalIsOpen}
        title="Eliminar"
        content="¿Desea eliminar el Módulo Formativo?"
        onCancel={hideModal}
        onConfirm={onConfirm}
      />

      <ModalConfirm
        isOpen={validateModalIsOpen}
        title="Validar MF"
        content="¿Desea validar el Módulo Formativo? Una vez hecha esta acción no podrá realizar cambios"
        onCancel={hideModal}
        onConfirm={onValidateConfirm}
      />
    </ContentWrapper>
  );
};

Modules.propTypes = {
  modules: PropTypes.arrayOf(PropTypes.array).isRequired,
  clearMessages: PropTypes.func.isRequired,
  notification: PropTypes.objectOf(PropTypes.any).isRequired,
  validateModalIsOpen: PropTypes.bool.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  showValidateModal: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onValidateConfirm: PropTypes.func.isRequired,
  notFound: PropTypes.bool.isRequired,
  exportScorm: PropTypes.func.isRequired,
  userPermissions: PropTypes.objectOf(PropTypes.any).isRequired,
};

Modules.defaultProps = {};

export default Modules;
