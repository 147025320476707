import { api } from 'config';
import {
  MODULE_SUCCESS,
  MODULE_ERROR,
  MODULE_CLEAR_NOTIFICATION,
} from '../constants/actionTypes';

function handleStateModule(type, message, id) {
  return {
    type,
    message,
    id,
  };
}

function clearNotification() {
  return {
    type: MODULE_CLEAR_NOTIFICATION,
  };
}

function updateModule(data, id) {
  const token = localStorage.getItem('token') || null;
  const reqConfig = {
    method: 'PUT',
    headers: Object.assign({}, {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
    body: JSON.stringify(data),
  };

  return (dispatch) => {
    fetch(`${api.baseUrl}/api/mfs/${id}`, reqConfig)
      .then((response) => {
        if (response.status >= 400) {
          dispatch(handleStateModule(MODULE_ERROR, response.statusText));
        }
        return response.json();
      }).then((ev) => {
        dispatch(handleStateModule(MODULE_SUCCESS, 'Actualizado correctamente', ev._id));
      })
      .catch(error => console.log(`Error: ${error}`));
  };
}

function saveModule(data) {
  const token = localStorage.getItem('token') || null;
  const reqConfig = {
    method: 'POST',
    headers: Object.assign({}, {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
    body: JSON.stringify(data),
  };

  return (dispatch) => {
    fetch(`${api.baseUrl}/api/mfs/`, reqConfig)
      .then((response) => {
        if (response.status >= 400) {
          dispatch(handleStateModule(MODULE_ERROR, response.statusText));
        }
        return response.json();
      }).then((doc) => {
        dispatch(handleStateModule(MODULE_SUCCESS, 'Guardado correctamente', doc._id));
      })
      .catch(error => console.log(`Error: ${error}`));
  };
}

function save(data) {
  return (dispatch) => {
    if (!data) {
      return dispatch(handleStateModule(MODULE_ERROR, 'Debes completar el formulario de módulos'));
    }
    if (data.id) {
      const { id } = data;
      delete data.id;
      dispatch(updateModule(data, id));
    } else {
      dispatch(saveModule(data));
    }
  };
}

const fetchModulePromise = id => new Promise((resolve, reject) => {
  const token = localStorage.getItem('token') || null;
  const reqConfig = {
    method: 'GET',
    headers: Object.assign({}, {
      Authorization: `Bearer ${token}`,
    }),
  };
  fetch(`${api.baseUrl}/api/mfs/${id}`, reqConfig)
    .then((response) => {
      if (response.status >= 400) {
        return reject(response.statusText);
      }
      return resolve(response.json());
    });
});

async function fetchModule(id) {
  try {
    const documentData = await fetchModulePromise(id);
    return documentData;
  } catch (error) {
    return error;
  }
}

const fetchModuleIndexPromise = id => new Promise((resolve, reject) => {
  const token = localStorage.getItem('token') || null;
  const reqConfig = {
    method: 'GET',
    headers: Object.assign({}, {
      Authorization: `Bearer ${token}`,
    }),
  };
  fetch(`${api.baseUrl}/api/mfs/${id}/index`, reqConfig)
    .then((response) => {
      if (response.status >= 400) {
        return reject(response.statusText);
      }
      return resolve(response.json());
    });
});

async function fetchModuleIndex(id) {
  try {
    const documentData = await fetchModuleIndexPromise(id);
    return documentData;
  } catch (error) {
    return error;
  }
}

const fetchModuleUfsPromise = id => new Promise((resolve, reject) => {
  const token = localStorage.getItem('token') || null;
  const reqConfig = {
    method: 'GET',
    headers: Object.assign({}, {
      Authorization: `Bearer ${token}`,
    }),
  };
  fetch(`${api.baseUrl}/api/mfs/${id}/ufs`, reqConfig)
    .then((response) => {
      if (response.status >= 400) {
        return reject(response.statusText);
      }
      return resolve(response.json());
    });
});

async function fetchModuleUfs(id) {
  try {
    const ufsData = await fetchModuleUfsPromise(id);
    return ufsData;
  } catch (error) {
    return error;
  }
}

const fetchModuleUasPromise = id => new Promise((resolve, reject) => {
  const token = localStorage.getItem('token') || null;
  const reqConfig = {
    method: 'GET',
    headers: Object.assign({}, {
      Authorization: `Bearer ${token}`,
    }),
  };
  fetch(`${api.baseUrl}/api/mfs/${id}/uas`, reqConfig)
    .then((response) => {
      if (response.status >= 400) {
        return reject(response.statusText);
      }
      return resolve(response.json());
    });
});

async function fetchModuleUas(id) {
  try {
    const uasData = await fetchModuleUasPromise(id);
    return uasData;
  } catch (error) {
    return error;
  }
}

const fetchAllModulesPromise = () => new Promise((resolve, reject) => {
  const token = localStorage.getItem('token') || null;
  const reqConfig = {
    method: 'GET',
    headers: Object.assign({}, {
      Authorization: `Bearer ${token}`,
    }),
  };
  fetch(`${api.baseUrl}/api/mfs/`, reqConfig)
    .then((response) => {
      if (response.status >= 400) {
        return reject(response.statusText);
      }
      return resolve(response.json());
    });
});

async function fetchAllModules() {
  try {
    const documentData = await fetchAllModulesPromise();
    return documentData;
  } catch (error) {
    return error;
  }
}

function deleteModule(id) {
  const token = localStorage.getItem('token') || null;
  const reqConfig = {
    method: 'DELETE',
    headers: Object.assign({}, {
      Authorization: `Bearer ${token}`,
    }),
  };
  return (dispatch) => {
    fetch(`${api.baseUrl}/api/mfs/${id}`, reqConfig)
      .then((response) => {
        if (response.status >= 400) {
          dispatch(handleStateModule(MODULE_ERROR, response.statusText));
        } else {
          dispatch(handleStateModule(MODULE_SUCCESS, 'Eliminado el módulo correctamente'));
        }
      })
      .catch(error => console.log(`Error: ${error}`));
  };
}

const downloadScormPromise = mfId => new Promise((resolve, reject) => {
  const token = localStorage.getItem('token') || null;
  const reqConfig = {
    method: 'GET',
    headers: Object.assign({}, {
      Authorization: `Bearer ${token}`,
    }),
  };
  fetch(`${api.baseUrl}/api/scorm/mfdownload/${mfId}`, reqConfig)
    .then((response) => {
      if (response.status >= 400) {
        return reject(response.statusText);
      }
      return resolve(response.blob());
    });
});

async function downloadScorm(mfId) {
  try {
    const scormData = await downloadScormPromise(mfId);
    return scormData;
  } catch (error) {
    return error;
  }
}

export {
  handleStateModule,
  fetchAllModules,
  fetchModule,
  fetchModuleIndex,
  fetchModuleUfs,
  fetchModuleUas,
  clearNotification,
  deleteModule,
  downloadScorm,
  save,
};
