import {
  MODULE_REQUEST,
  MODULE_SUCCESS,
  MODULE_ERROR,
  MODULE_CLEAR_NOTIFICATION,
} from '../constants/actionTypes';

const initialState = {
  isFetching: false,
  success: false,
  error: false,
  id: '',
};

export default function mfs(state = initialState, action) {
  switch (action.type) {
    case MODULE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      });

    case MODULE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        success: true,
        message: action.message,
        id: action.id,
      });

    case MODULE_ERROR:
      return Object.assign({}, state, {
        isFetching: false,
      });

    case MODULE_CLEAR_NOTIFICATION:
      return {
        ...state,
        error: null,
        success: null,
      };
    default:
      return state;
  }
}
