import React from 'react';
import PropTypes from 'prop-types';
import Label from 'client/ui/Label';
import styled from 'styled-components';
import TextTruncate from 'react-text-truncate';
import Img from 'client/ui/Img';
import FileUploadButton from 'client/ui/FileUploadButton';
import ContentText from './ContentText';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
`;

const UploadWrapper = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 1.5rem;
`;

const FileIconWrapper = styled.div`
  width: 50px;
  margin-bottom: .5rem;
`;

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FileName = styled.div`
  font-size: 0.875rem;
  padding: 0 0.5rem;
  color: #666666;
  margin-bottom: .5rem;
  text-align: center;
`;

const FileWrapper = ({ id, data, descriptionChange, uploadFile, properties }) => (
  <Wrapper>
    <InputWrapper>
      <Label>Descripción</Label>
      <ContentText
        data={data}
        id={id}
        onChange={descriptionChange}
        mediumType="mediumEditorBasic"
      />
    </InputWrapper>
    <UploadWrapper>
      <FileIconWrapper>
        <Img src="/images/contents/fill.png" />
      </FileIconWrapper>
      {properties && properties.name
        && (
          <FileName>
            <TextTruncate
              line={2}
              truncateText="…"
              text={properties.name}
            />
          </FileName>
        )}
      <FileUploadButton
        id={`uploadFile-${id}`}
        label="Subir archivo"
        onChange={(event) => { uploadFile(event, id); }}
        required="required"
      />
    </UploadWrapper>
  </Wrapper>
);


FileWrapper.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.string.isRequired,
  descriptionChange: PropTypes.func.isRequired,
  uploadFile: PropTypes.func.isRequired,
  properties: PropTypes.objectOf(PropTypes.any),
};

FileWrapper.defaultProps = {
  properties: {},
};

export default FileWrapper;
