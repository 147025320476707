import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchPage } from 'client/modules/dashboard-pages/actions/pages';
import { save, clearNotification, fetchUa } from '../actions/uas';
import Wrapper from '../components/Wrapper';

class WrapperContainer extends Component {
  constructor() {
    super();
    this.state = {
      uaToUpdate: {},
      createMode: true,
      tab: '#unidad',
      mfId: null,
      ufId: null,
    };
    this.loadData = this.loadData.bind(this);
  }

  componentWillMount() {
    const { match, history } = this.props;
    if (match.params.id) {
      const { id } = match.params;
      this.loadData(id);
      this.setState({ createMode: false });
    }
    if (history.location && history.location.hash) {
      this.setState({ tab: history.location.hash });
    }
    if (match.params.type === 'mfs') {
      const mfId = localStorage.getItem('mfId');
      if (mfId) {
        this.setState({ mfId });
      }
    } else if (match.params.type === 'ufs') {
      const ufId = localStorage.getItem('ufId');
      if (ufId) {
        this.setState({ ufId });
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    /* Obtenemos los datos actualizados */
    if (nextProps.notification.type === 'success') {
      const { match } = this.props;
      if (match.params.id) {
        const { id } = match.params;
        this.loadData(id);
      }
    }
  }

  loadData(uaId) {
    fetchUa(uaId).then((uaData) => {
      const { _id, title, mf, uf } = uaData;
      const uaToUpdate = uaData;
      Object.assign(uaToUpdate,
        {
          id: _id,
          title: title || '',
        });

      if (mf) {
        this.setState({ mfId: mf._id, uaToUpdate });
      }

      if (uf) {
        this.setState({ ufId: uf._id, uaToUpdate });
      }

      if (uaData.proration && uaData.proration.length > 0) {
        const testIds = uaData.proration.map(t => t._id);
        Promise.all(testIds.map(id => fetchPage(id))).then((result) => {
          const proration = [];
          uaData.proration.forEach((test) => {
            const currentTest = result.find(t => t._id === test._id);
            if (currentTest) {
              Object.assign(test, { title: currentTest.title });
              proration.push(test);
            }
          });
          uaToUpdate.proration = proration;
          this.setState({ uaToUpdate });
        });
      }
    });
  }

  render() {
    const { saveForm, notification, clearMessages, history, match } = this.props;
    const { uaToUpdate, createMode, tab, mfId, ufId } = this.state;
    return (
      <Wrapper
        save={saveForm}
        notification={notification}
        clearMessages={clearMessages}
        uaToUpdate={uaToUpdate}
        history={history}
        createMode={createMode}
        match={match}
        mfId={mfId}
        ufId={ufId}
        tab={tab}
      />
    );
  }
}

WrapperContainer.propTypes = {
  saveForm: PropTypes.func.isRequired,
  clearMessages: PropTypes.func.isRequired,
  notification: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

WrapperContainer.defaultProps = {};
const mapStateToProps = (state) => {
  const { error, success, message, id } = state.uas;
  const notification = {};

  if (error) {
    notification.type = 'error';
    notification.content = message;
  } else if (success) {
    notification.type = 'success';
    notification.content = message;
    notification.id = id;
  }

  return {
    notification,
  };
};

const mapDispatchToProps = dispatch => ({
  saveForm: (data) => { dispatch(save(data)); },
  clearMessages: () => { dispatch(clearNotification()); },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WrapperContainer);
