/**
 * @param {Blob} file - File or Blob object. This parameter is required.
 * @param {string} fileName - Optional file name e.g. "image.png"
 */
function invokeSaveAsDialog(file, fileName) {
  const fileExtension = (file.type).split('/')[1];

  if (fileName && fileName.indexOf('.') !== -1) {
    const splitted = fileName.split('.');
    fileName = splitted[0];
  }

  const fileFullName = `${fileName}.${fileExtension}`;

  if (typeof navigator.msSaveOrOpenBlob !== 'undefined') {
    return navigator.msSaveOrOpenBlob(file, fileFullName);
  }

  if (typeof navigator.msSaveBlob !== 'undefined') {
    return navigator.msSaveBlob(file, fileFullName);
  }

  const hyperlink = document.createElement('a');
  hyperlink.href = URL.createObjectURL(file);
  hyperlink.download = fileFullName;

  hyperlink.style = 'display:none;opacity:0;color:transparent;';
  (document.body || document.documentElement).appendChild(hyperlink);

  if (typeof hyperlink.click === 'function') {
    hyperlink.click();
  } else {
    hyperlink.target = '_blank';
    hyperlink.dispatchEvent(new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    }));
  }

  (window.URL || window.webkitURL).revokeObjectURL(hyperlink.href);
}

export default invokeSaveAsDialog;
