import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { H2 } from 'client/ui/Headings';

const StyledH2 = styled(H2)`
  color: ${props => props.theme.color.black};
`;

const Title = ({ title }) => (
  <StyledH2 className="animated fadeInUp">{title}</StyledH2>
);

Title.propTypes = {
  title: PropTypes.string.isRequired,
};

Title.defaultProps = {};

export default Title;
