import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from 'styled-components';
import configureStore from 'client/store/configureStore';
import { loginWithLocalStorage } from 'client/modules/accounts/actions/login';
import logout from 'client/modules/accounts/actions/logout';
import Modal from 'react-modal';
import moment from 'moment';
import 'plyr.css';
import 'typeface-montserrat';
import 'pladea-flexboxgrid/dist/flexboxgrid.min.css';
import 'moment/locale/es';
import 'normalize.css/normalize.css';
import 'babel-polyfill';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'datatables.net-dt/css/jquery.dataTables.css';
import 'datatables.net-buttons-dt/css/buttons.dataTables.css';
import 'medium-editor/dist/css/medium-editor.css';
import 'medium-editor/dist/css/themes/default.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'animate.css/animate.min.css';
import theme from './client/config/theme';
import Routes from './routes';
import globalStyles from './client/config/globalStyles';

// import registerServiceWorker from './registerServiceWorker';

// Configuramos el idioma de moment
moment.locale('es');

// Configura los estilos globales
globalStyles();

/* View: https://github.com/reactjs/react-modal/issues/133 */
// Weird-- we get warnings in the console if we don't explicitly set the Modal appElement...
Modal.setAppElement('body');

const initialState = {};
const history = createBrowserHistory();

// Configura el Store
const store = configureStore(initialState, history);

function logoutAction() {
  store.dispatch(logout());
}

function redirectTo(page) {
  history.push(page);
}

if (!localStorage.getItem('mode')) {
  localStorage.setItem('mode', 'edit');
}

function currentMode() {
  return localStorage.getItem('mode');
}

function changeMode() {
  const mode = currentMode();
  if (mode === 'edit') {
    localStorage.setItem('mode', 'view');
  } else if (mode === 'view') {
    localStorage.setItem('mode', 'edit');
  }
}

// @TODO: Comprobar token y redireccionar a donde corresponda
const authToken = localStorage.getItem('token');

if (authToken !== null) {
  const userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
  store.dispatch(loginWithLocalStorage({ authToken, userInfo }));
}

export {
  logoutAction,
  redirectTo,
  changeMode,
  currentMode,
};

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <ConnectedRouter history={history}>
        <Routes />
      </ConnectedRouter>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root'),
);
// registerServiceWorker();
