import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from 'client/ui/Button';
import Li from './ContentLi';

const StyledButton = styled(Button)`
  margin-bottom: 2rem;
  align-self: center
  margin-left: auto;
  margin-right: auto;
`;

const Wrapper = styled.div`
  text-align: center;
`;

const List = ({ list, id, onLiChange, addLi, type, handleLiRemove }) => (
  <Wrapper>
    <StyledButton
      onClick={() => addLi(id)}
    >
      Añadir item
    </StyledButton>
    {list && list.length > 0 && (
      <div>{list.map((li, index) => (
        <Li
          key={`li${index}`}
          id={id}
          text={li}
          type={type}
          index={index}
          onLiChange={onLiChange}
          handleLiRemove={handleLiRemove}
        />
      ))}
      </div>
    )}
  </Wrapper>
);

List.propTypes = {
  id: PropTypes.string.isRequired,
  onLiChange: PropTypes.func.isRequired,
  addLi: PropTypes.func.isRequired,
  handleLiRemove: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(PropTypes.string).isRequired,
};

List.defaultProps = {};

export default List;
