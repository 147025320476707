import { css } from 'styled-components';

const inputStyles = css`
  display: inline-block;
  width: 100%;
  margin: 0 0 1rem;
  padding: 0.5rem;
  font-family: inherit;
  font-size: 1rem;
  color: ${props => props.theme.color.black};
  background-color: ${props => props.theme.color.grayLightClear};
  border: 1px solid ${props => props.theme.color.gray};
  border-radius: ${props => props.theme.radius};
  appearance: none;

  &:focus {
    outline: 0;
  }

  &:required {
    box-shadow: inset 0 0 3px ${props => props.theme.color.gray};
  }

  ${props => props.medium && css`
    max-width: 500px;
  `};

  ${props => props.small && css`
    max-width: 300px;
  `};

  ${props => props.noMarginBottom && css`
    margin-bottom: 0;
  `};
`;

export default inputStyles;
