/* eslint no-undef: 0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { savePage } from 'client/modules/dashboard-pages/actions/pages';
import { save } from 'client/modules/dashboard-uas/actions/uas';
import { arrayMove } from 'react-sortable-hoc';
import { clearNotification, deleteTopic, saveTopic, fetchUaTopics } from '../actions/topics';
import List from '../components/ListTopics';

class TopicList extends Component {
  constructor() {
    super();
    this.state = {
      topics: [],
      modalIsOpen: false,
      topicId: '',
    };
    this.loadTopics = this.loadTopics.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.showModal = this.showModal.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.addTopic = this.addTopic.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
  }

  componentWillMount() {
    const { uaId } = this.props;
    this.loadTopics(uaId);
  }

  componentWillReceiveProps(nextProps) {
    /* Obtenemos los datos actualizados */
    if (nextProps.notification.type === 'success') {
      const { uaId } = this.props;
      this.loadTopics(uaId);
    }
  }

  onConfirm() {
    const { removeTopic } = this.props;
    const { topicId } = this.state;
    removeTopic(topicId);
    this.hideModal();
  }

  onSortEnd({ oldIndex, newIndex }) {
    const { uaId, reorderTopics } = this.props;
    const { topics } = this.state;
    const newOrder = arrayMove(topics, oldIndex, newIndex);
    const uaTopicsNewOrder = newOrder.map(topic => topic._id);
    this.setState({ topics: newOrder });
    const newUaData = {
      id: uaId,
      topics: uaTopicsNewOrder,
    };
    reorderTopics(newUaData);
  }

  addTopic() {
    const { uaId, saveCurrentTopic } = this.props;
    const data = {
      ua: uaId,
      title: '',
    };
    saveCurrentTopic(data);
  }

  loadTopics(uaId) {
    fetchUaTopics(uaId).then((topicsData) => {
      this.setState({ topics: topicsData });
    });
  }

  showModal(topicId) {
    if (topicId) {
      this.setState({ modalIsOpen: true, topicId });
    }
  }

  hideModal() {
    this.setState({ modalIsOpen: false });
  }

  render() {
    const { notification, clearMessages, saveCurrentTopic, uaId, addPage,
      modeEdit, colorMf } = this.props;
    const { topics, modalIsOpen } = this.state;
    return (
      <List
        notification={notification}
        clearMessages={clearMessages}
        modalIsOpen={modalIsOpen}
        showModal={this.showModal}
        hideModal={this.hideModal}
        onConfirm={this.onConfirm}
        onSortEnd={this.onSortEnd}
        topics={topics}
        addTopic={this.addTopic}
        addPage={addPage}
        modeEdit={modeEdit}
        save={saveCurrentTopic}
        colorMf={colorMf}
        uaId={uaId}
      />
    );
  }
}

TopicList.propTypes = {
  uaId: PropTypes.string.isRequired,
  clearMessages: PropTypes.func.isRequired,
  saveCurrentTopic: PropTypes.func.isRequired,
  reorderTopics: PropTypes.func.isRequired,
  addPage: PropTypes.func.isRequired,
  removeTopic: PropTypes.func.isRequired,
  notification: PropTypes.objectOf(PropTypes.any).isRequired,
  modeEdit: PropTypes.bool.isRequired,
  colorMf: PropTypes.string,
};

TopicList.defaultProps = {
  colorMf: '',
};

const mapStateToProps = (state) => {
  const { error, success, message, id } = state.topics;
  const notification = {};

  if (error) {
    notification.type = 'error';
    notification.content = message;
  } else if (success) {
    notification.type = 'success';
    notification.content = message;
    notification.id = id;
  }

  return {
    notification,
  };
};

const mapDispatchToProps = dispatch => ({
  clearMessages: () => { dispatch(clearNotification()); },
  saveCurrentTopic: (data) => { dispatch(saveTopic(data)); },
  removeTopic: (topicId) => { dispatch(deleteTopic(topicId)); },
  addPage: (data) => { dispatch(savePage(data)); },
  reorderTopics: (data) => { dispatch(save(data)); },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TopicList);
